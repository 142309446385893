import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import { CardTitle, InputField, Card } from '../../../../components/UI'
import PasswordStrengthComponent from 'components/PasswordStrength'

import userApi from 'api/user'
import useForm from '../../../../hooks/useForm'

import { passwordStrength } from '../../../../helpers/passwordStrength'

const stateSchema = {
  old_password: { value: '', error: '' },
  new_password: { value: '', error: '' },
  new_password_repeat: { value: '', error: '' },
};

const ProfileChangePassword = () => {
  const { t } = useTranslation()

  const validationStateSchema = {
    old_password: {
      required: true,
    },
    new_password: {
      required: true,
      validator: [
        {
          func: (password) => {
            const { isValid } = passwordStrength(password)
            return isValid
          }, error: t("password_strength_error")
        },
      ],
    },
    new_password_repeat: {
      required: true,
      validator: [
        {
          func: (password) => {
            const { isValid } = passwordStrength(password)
            return isValid
          }, error: t("password_strength_error")
        },
      ],
    },
  };

  const [initialState, setInitialState] = useState({
    data: null,
    errors: null,
  });

  const handleSubmit = () => {
    const payload = {
      old_password: state.old_password.value,
      new_password: state.new_password.value,
      new_password_repeat: state.new_password_repeat.value,
    }
    userApi.editPassword(payload)
      .then(() => {
        toast(t("password_changed"), { type: toast.TYPE.INFO })
        setInitialState({ data: null, errors: null })
      })
      .catch(error => {
        if (error.errorStack && error.errorStack.isHaveError) {
          const errors = error.errorStack.toFormik()
          setInitialState({ data: payload, errors });
        } else {
          toast(t("password_change_error"), { type: toast.TYPE.ERROR })
        }
      })
  }

  const { state, handleOnChange, handleOnBlur, handleOnSubmit, disable, isDirty } = useForm(
    initialState,
    stateSchema,
    null,
    validationStateSchema,
    handleSubmit,
  );

  return (
    <Box mb={5} className='changePassword'>
      <CardTitle variant='h2' textAlign='inherit'>
        {t("change_password")}
      </CardTitle>
      <Card>
          <form className='-sizeWidthFull' onSubmit={handleOnSubmit}>
            <Box mb={3}>
              <InputField
                name='old_password'
                type='password'
                className='sa_profile_old_password'
                value={state.old_password.value}
                placeholder={t("old_password")}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                error={isDirty && isDirty.old_password && state.old_password.error}
              />
            </Box>
            <Box mb={3}>
              <InputField
                name='new_password'
                type='password'
                className='sa_profile_new_password'
                value={state.new_password.value}
                placeholder={t("new_password")}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                error={isDirty && isDirty.new_password && state.new_password.error}
              />
            </Box>
            <Box mb={3}>
              <InputField
                name='new_password_repeat'
                type='password'
                className='sa_profile_new_password_repeat'
                value={state.new_password_repeat.value}
                placeholder={t("repeat_new_password")}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                error={
                  (isDirty && isDirty.new_password_repeat && state.new_password_repeat.error)
                  || (
                    isDirty
                    && isDirty.new_password_repeat
                    && isDirty.new_password
                    && state.new_password.value !== state.new_password_repeat.value
                    && t('password_repeat_error')
                  )
                }
              />
            </Box>
            <PasswordStrengthComponent
              value={state.new_password.value}
              touched={isDirty && isDirty.new_password}
              t={t}
            />
            <Button
              fullWidth
              variant='contained'
              color='primary'
              type='submit'
              disabled={disable || state.new_password.value !== state.new_password_repeat.value}
              className='sa_profile_change_password_save'
            >
              {t("save")}
            </Button>
          </form>
        </Card>
    </Box>
  )
}

export default ProfileChangePassword
