import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import API from 'api/user'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CardTitle } from '../../../components/UI'
import { ReactComponent as EnvelopeIcon } from '../../../assets/icons/envelope_3.svg';

const Verifying = ({ token }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { locale } = useParams()
  const partnerSettings = useSelector((state) => state.partnerSettings)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [activationResult, setActivatonResult] = useState(null)

  useEffect(() => {
    if (partnerSettings && partnerSettings.partnerSlug)
      verify()
  }, [partnerSettings])

  const verify = () => {
    setLoading(true)
    API.activateUser(token)
      .then(response => {
        setLoading(false)
        setActivatonResult(response)
      })
      .catch(error => {
        if (error.response && error.response.status === 406) {
          history.push(`/${locale}/account/sme-staff/activate/${token}?partner=${partnerSettings.partnerSlug}`)
        } else {
          setError(error)
          setLoading(false)
        }
      })
  }

  if (loading) {
    return (
      <Box height='100%' display='flex' justifyContent='center' alignItems='center'>
        <CircularProgress />
      </Box>
    )
  }

  if (activationResult && partnerSettings) {
    setTimeout(() => {
      history.push(`/${locale}/dashboard`)
    }, 1500)

    return (
      <Box height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <Box textAlign='center' mb={2}>
          <EnvelopeIcon />
        </Box>
        <CardTitle textAlign='center'>
          {
            activationResult.is_activated
              ? t("email_verified")
              : t("email_already_verified")
          }
        </CardTitle>
      </Box>
    )
  }

  if (error) {
    setTimeout(() => {
      history.push(`/${locale}/signIn`)
    }, 1500)
    return (
      <Box height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <Box textAlign='center' mb={2}>
          <EnvelopeIcon />
        </Box>
        <CardTitle textAlign='center'>
          {t("error_occured")}
        </CardTitle>
      </Box>
    )
  }

  return (
    <Box height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
      <Box textAlign='center' mb={2}>
        <EnvelopeIcon />
      </Box>
      <CardTitle textAlign='center'>
        {t("verify_your_email_header")}
      </CardTitle>
    </Box>
  )
}

export default Verifying;
