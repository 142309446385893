import React from "react";
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  statusBox: {
    border: '0px',
    padding: '30px',
    fontSize: '16px',
    textAlign: 'center',
    '&.error': {
      backgroundColor: theme.variables.$red10,
      color: theme.variables.$red100,
    }
  }
}))

const StaffUserExpiredPlanMsg = (t) => {
  const classes = useStyles()
  return (
    <div style={{ marginTop: "20px" }}>
      <div className={`${classes.statusBox} error`}>
        Your Account is inactive. Please contact your Administrator
      </div>
    </div>
  );
};

export default StaffUserExpiredPlanMsg;
