import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useCourses } from "hooks/useCourses";
import CertificateCard from "./CertificateCard/CertificateCard";

const useStyles = makeStyles((theme) => ({
  textWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  loadingSpinner: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  coursesWrapper: {
    marginTop: "16px",
  },
}));

const CertificatesList = () => {
  const [, isLoading] = useCourses();
  const classes = useStyles();
  const { t } = useTranslation();
  const certificates = useSelector((state) => state.course.certificatesList);

  return (
    <Box mt={3}>
      {isLoading && (
        <Box className={classes.loadingSpinner}>
          <CircularProgress color="inherit" size={40} />
        </Box>
      )}
      {!isLoading && (
        <Grid className={classes.coursesWrapper} container spacing={2}>
          {certificates.map((certificate, idx) => (
            <Grid item xs={12} sm={6} lg={4} key={idx}>
              <CertificateCard certificateData={certificate} />
            </Grid>
          ))}
          {certificates.length === 0 && (
            <Box className={classes.textWrapper} mt={1}>
              <Typography>{t("retail.no_certificates")}</Typography>
            </Box>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default CertificatesList;