import React from "react";
import SelectPlan from "../SelectPlan";
import Price from "../../../components/Price";
import { setItem, getItem } from "helpers/localStorage";
import { Tooltip } from "react-tippy";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  planCard: {
    // height: '100%'
  },
  plan: {
    position: "relative",
    height: "100%",
    "&.--withCoupon": {
      marginTop: "70px",
    },
  },
  featureDisabled: {
    color: theme.variables.$textDarkGray,
  },
  couponInfo: {
    background: theme.variables.$green100,
    color: "#fff",
    fontSize: "1rem",
    textAlign: "center",
    padding: "15px",
    position: "absolute",
    width: "100%",
    zIndex: "1",
    top: "-65px",
    borderRadius: "4px",
    "&:after": {
      content: '""',
      width: "0px",
      height: "0px",
      borderLeft: "7px solid transparent",
      borderRight: "7px solid transparent",
      borderTop: `7px solid ${theme.variables.$green100}`,
      bottom: "-7px",
      left: "50%",
      position: "absolute",
      transform: "translateX(-50%)",
    },
  },
  list: {
    "&.-withDots": {
      textAlign: "initial",
      lineHeight: 1,
      paddingLeft: theme.variables.$isRTLDirection ? 0 : "15px",
      paddingRight: theme.variables.$isRTLDirection ? "15px" : 0,
      "& li": {
        position: "relative",
        marginBottom: "15px",

        "&:last-child": {
          marginBottom: 0, // clear last margin
        },
        "&:after": {
          position: "absolute",
          top: "3px",
          left: theme.variables.$isRTLDirection ? 0 : "-15px",
          right: theme.variables.$isRTLDirection ? "-15px" : 0,
          content: '""',
          width: "8px",
          height: "8px",
          background: "none",
          border: "2px solid black",
          borderRadius: "50%",
        },
        "&.-red": {
          "&:after": {
            borderColor: theme.variables.$red100,
          },
          "&.-filled": {
            "&:after": {
              background: theme.variables.$red100,
            },
          },
        },

        "&.-green": {
          "&:after": {
            borderColor: theme.variables.$green100,
          },
          "&.-filled": {
            "&:after": {
              background: theme.variables.$green100,
            },
          },
        },
      },
    },
  },
  headerPlan: {
    color: "#fff",
    padding: "10px",
    fontSize: "1rem",
    textAlign: "center",
    marginBottom: "0",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    background: theme.palette.primary.main,
  },
  planContent: {
    padding: "16px 16px 32px",
    flexGrow: "1",
  },
  descr: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "14px",
    margin: "24px 0 18px -15px",
    lineHeight: "1.3",
  },
  descrTitle: {
    fontWeight: "bold",
    marginBottom: "8px",
  },
}));

const Currency = ({ currencyStr }) => {
  if (currencyStr === "GBP") {
    return <span>&#163;</span>;
  }
  return <span>{currencyStr}</span>;
};

const Plan = ({ t, data }) => {
  const classes = useStyles();
  const payableOption = data.options.payable;
  const trialOption = data.options.trial;
  const { coupon, price_with_coupon, price } = payableOption;

  // set currency
  if (!getItem("currency")) {
    setItem("currency", price.currency);
  }
  const renderFeatures = (features, availability) =>
    features.map((data) => {
      if (
        t(`plan_feature_description_${data.name}`) ===
        `plan_feature_description_${data.name}`
      ) {
        return "";
      }
      return (
        <li
          className={`${availability ? "-green" : "-red"} -filled`}
          key={data.uuid}
        >
          <Tooltip
            className="tooltip"
            arrow
            delay={100}
            distance={10}
            position="right"
            title={t(`plan_feature_description_${data.name}`)}
          >
            <span
              className={
                availability ? classes.featureEnabled : classes.featureDisabled
              }
            >
              {data.name === "support_type"
                ? t(`plan_feature_support_type_${data.value}`)
                : data.label}
              {data.has_value ? `: ${data.value}` : ""}
            </span>
          </Tooltip>
        </li>
      );
    });

  const renderPlanDescriptions = (descr) => {
    return descr.map((item) => (
      <div className={classes.descr} key={item.uuid}>
        <div className={classes.descrTitle}>{item.title}</div>
        <div className={classes.descrText}>{item.description}</div>
      </div>
    ));
  };
  return (
    <Paper className={classes.planCard}>
      <Box
        pb={3}
        className={`${classes.plan} ${coupon ? "--withCoupon" : null}`}
      >
        {coupon && (
          <div className={classes.couponInfo}>{t("coupon_is_applied")}</div>
        )}
        <Box display="flex" flexDirection="column" height="100%">
          <h3 className={`${classes.headerPlan} header`}>{t(data.slug)}</h3>
          <div className={classes.planContent}>
            <Price
              t={t}
              amount={
                price_with_coupon
                  ? price_with_coupon.amount
                  : payableOption.price.amount
              }
              currency={
                <Currency
                  currencyStr={
                    price_with_coupon
                      ? price_with_coupon.currency
                      : payableOption.price.currency
                  }
                />
              }
              desc={t(payableOption.slug)}
            />
            <p>{t(`plan_${data.slug}_description`)}</p>
            <br />
            <ul className={`${classes.list} -withDots`}>
              {data.description_items && !!data.description_items.length ? (
                <>{renderPlanDescriptions(data.description_items)}</>
              ) : (
                <>
                  {renderFeatures(data.plan_features, true)}
                  {renderFeatures(data.plan_features_negative, false)}
                </>
              )}
            </ul>
          </div>
          <SelectPlan
            plan={data}
            payableOption={payableOption}
            trialOption={trialOption}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default Plan;
