import React from 'react'
import Form from './Form'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'

const AddItem = ({ t, addPhoneNumber }) => {
  return (
    <Paper>
      <Box p={3}>
        <Form t={t} addPhoneNumber={addPhoneNumber} />
      </Box>
    </Paper>
  )
}
export default AddItem
