import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  accentColor: {
    "&.MuiButton-contained": {
      "&.error": {
        background: theme.variables.$red100,
        color: "#fff",
      },
    },
    "&.MuiButton-outlined": {
      "&.error": {
        borderColor: theme.variables.$red100,
        background: "#fff",
        color: theme.variables.$red100,
      },
      "&.reversed": {
        borderColor: theme.palette.primary.contrastText,
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    "&.MuiButton-text": {
      "&.error": {
        background: "#fff",
        color: theme.variables.$red100,
      },
    },
  },
}));

export default function AccentButton({
  children,
  accentColor,
  className = "",
  ...props
}) {
  const classes = useStyles();
  return (
    <Button
      {...props}
      className={`${classes.accentColor} ${[accentColor]} ${className}`}
    >
      {children}
    </Button>
  );
}
