import axios from "axios";
import i18n from "i18next";
import { setItem } from "helpers/localStorage";
import { toast } from "react-toastify";
import { Log } from "services/log/logger";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_LANGS,
  responseType: "json",
  maxContentLength: "200000",
});

const buildResourceUrl = (lang, params) => {
  const paramsKeys = Object.keys(params);
  var urlParams = "";
  for (var key of paramsKeys) {
    urlParams += `&${key}=${params[key]}`;
  }
  return `/${process.env.REACT_APP_LANGS_SOURCE}/${lang}.json?type=json${urlParams}`;
};

const setLanguage = (lang, data) => {
  i18n.options.resources[lang] = { common: data };
  setItem("locale", lang);
  i18n.changeLanguage();
};

const fetchLanguage = async (fileName) => {
  const resourceUrl = buildResourceUrl(fileName, { timestamp: +new Date() });
  return await client.get(resourceUrl);
};

const log = (arr) => {
  toast("The requested language was not found", {
    type: toast.TYPE.ERROR,
    autoClose: 2000,
  });

  Log.error(
    `${arr.slice(0, -1).toString()} was not found. Default was loaded instead`,
    {}
  );
};

export const getLanguageFiles = async (lang, partner_settings) => {
  const partnerSlug = partner_settings.slug || partner_settings.partnerSlug;
  const lang_prefix = lang;
  const arr = [`${lang_prefix}_${partnerSlug}`, lang_prefix, "en_GB"];
  for (let i = 0; i < arr.length; i++) {
    const last = i === arr.length - 1;
    try {
      const fileName = arr[i];
      const resp = await fetchLanguage(fileName);
      setLanguage(lang, resp.data);
      if (last) {
        log(arr);
      }
      return Promise.resolve(true);
    } catch (err) {
      continue;
    }
  }
};
