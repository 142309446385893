/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class TimeDuration.
* @enum {}
* @readonly
*/
export default class TimeDuration {
    
        /**
         * value: -9223372036854775808
         * @const
         */
        "minDuration" = -9223372036854775808;

    
        /**
         * value: 9223372036854775807
         * @const
         */
        "maxDuration" = 9223372036854775807;

    
        /**
         * value: 1
         * @const
         */
        "Nanosecond" = 1;

    
        /**
         * value: 1000
         * @const
         */
        "Microsecond" = 1000;

    
        /**
         * value: 1000000
         * @const
         */
        "Millisecond" = 1000000;

    
        /**
         * value: 1000000000
         * @const
         */
        "Second" = 1000000000;

    
        /**
         * value: 60000000000
         * @const
         */
        "Minute" = 60000000000;

    
        /**
         * value: 3600000000000
         * @const
         */
        "Hour" = 3600000000000;

    

    /**
    * Returns a <code>TimeDuration</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/TimeDuration} The enum <code>TimeDuration</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

