/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ModelChapter from './ModelChapter';
import ModelQuiz from './ModelQuiz';
import RepositoryDifficultyLevel from './RepositoryDifficultyLevel';
import TimeDuration from './TimeDuration';

/**
 * The ModelCourse model module.
 * @module model/ModelCourse
 * @version 1.0.0
 */
class ModelCourse {
    /**
     * Constructs a new <code>ModelCourse</code>.
     * @alias module:model/ModelCourse
     * @param categoryUuid {String} 
     * @param createdAt {Number} 
     * @param description {String} 
     * @param difficulty {module:model/RepositoryDifficultyLevel} 
     * @param duration {module:model/TimeDuration} 
     * @param imageUrl {String} 
     * @param isRecommended {Boolean} 
     * @param popularity {Number} 
     * @param quizUuid {String} 
     * @param summary {String} 
     * @param title {String} 
     * @param uuid {String} 
     */
    constructor(categoryUuid, createdAt, description, difficulty, duration, imageUrl, isRecommended, popularity, quizUuid, summary, title, uuid) { 
        
        ModelCourse.initialize(this, categoryUuid, createdAt, description, difficulty, duration, imageUrl, isRecommended, popularity, quizUuid, summary, title, uuid);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, categoryUuid, createdAt, description, difficulty, duration, imageUrl, isRecommended, popularity, quizUuid, summary, title, uuid) { 
        obj['category_uuid'] = categoryUuid;
        obj['created_at'] = createdAt;
        obj['description'] = description;
        obj['difficulty'] = difficulty;
        obj['duration'] = duration;
        obj['image_url'] = imageUrl;
        obj['is_recommended'] = isRecommended;
        obj['popularity'] = popularity;
        obj['quiz_uuid'] = quizUuid;
        obj['summary'] = summary;
        obj['title'] = title;
        obj['uuid'] = uuid;
    }

    /**
     * Constructs a <code>ModelCourse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ModelCourse} obj Optional instance to populate.
     * @return {module:model/ModelCourse} The populated <code>ModelCourse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ModelCourse();

            if (data.hasOwnProperty('category_uuid')) {
                obj['category_uuid'] = ApiClient.convertToType(data['category_uuid'], 'String');
            }
            if (data.hasOwnProperty('chapters')) {
                obj['chapters'] = ApiClient.convertToType(data['chapters'], [ModelChapter]);
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Number');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('difficulty')) {
                obj['difficulty'] = ApiClient.convertToType(data['difficulty'], RepositoryDifficultyLevel);
            }
            if (data.hasOwnProperty('duration')) {
                obj['duration'] = TimeDuration.constructFromObject(data['duration']);
            }
            if (data.hasOwnProperty('image_url')) {
                obj['image_url'] = ApiClient.convertToType(data['image_url'], 'String');
            }
            if (data.hasOwnProperty('is_recommended')) {
                obj['is_recommended'] = ApiClient.convertToType(data['is_recommended'], 'Boolean');
            }
            if (data.hasOwnProperty('popularity')) {
                obj['popularity'] = ApiClient.convertToType(data['popularity'], 'Number');
            }
            if (data.hasOwnProperty('quiz')) {
                obj['quiz'] = ModelQuiz.constructFromObject(data['quiz']);
            }
            if (data.hasOwnProperty('quiz_uuid')) {
                obj['quiz_uuid'] = ApiClient.convertToType(data['quiz_uuid'], 'String');
            }
            if (data.hasOwnProperty('summary')) {
                obj['summary'] = ApiClient.convertToType(data['summary'], 'String');
            }
            if (data.hasOwnProperty('takeaways')) {
                obj['takeaways'] = ApiClient.convertToType(data['takeaways'], [ModelChapter]);
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Number');
            }
            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ModelCourse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ModelCourse</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ModelCourse.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['category_uuid'] && !(typeof data['category_uuid'] === 'string' || data['category_uuid'] instanceof String)) {
            throw new Error("Expected the field `category_uuid` to be a primitive type in the JSON string but got " + data['category_uuid']);
        }
        if (data['chapters']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['chapters'])) {
                throw new Error("Expected the field `chapters` to be an array in the JSON data but got " + data['chapters']);
            }
            // validate the optional field `chapters` (array)
            for (const item of data['chapters']) {
                ModelChapter.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['image_url'] && !(typeof data['image_url'] === 'string' || data['image_url'] instanceof String)) {
            throw new Error("Expected the field `image_url` to be a primitive type in the JSON string but got " + data['image_url']);
        }
        // validate the optional field `quiz`
        if (data['quiz']) { // data not null
          ModelQuiz.validateJSON(data['quiz']);
        }
        // ensure the json data is a string
        if (data['quiz_uuid'] && !(typeof data['quiz_uuid'] === 'string' || data['quiz_uuid'] instanceof String)) {
            throw new Error("Expected the field `quiz_uuid` to be a primitive type in the JSON string but got " + data['quiz_uuid']);
        }
        // ensure the json data is a string
        if (data['summary'] && !(typeof data['summary'] === 'string' || data['summary'] instanceof String)) {
            throw new Error("Expected the field `summary` to be a primitive type in the JSON string but got " + data['summary']);
        }
        if (data['takeaways']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['takeaways'])) {
                throw new Error("Expected the field `takeaways` to be an array in the JSON data but got " + data['takeaways']);
            }
            // validate the optional field `takeaways` (array)
            for (const item of data['takeaways']) {
                ModelChapter.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
            throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
        }
        // ensure the json data is a string
        if (data['uuid'] && !(typeof data['uuid'] === 'string' || data['uuid'] instanceof String)) {
            throw new Error("Expected the field `uuid` to be a primitive type in the JSON string but got " + data['uuid']);
        }

        return true;
    }


}

ModelCourse.RequiredProperties = ["category_uuid", "created_at", "description", "difficulty", "duration", "image_url", "is_recommended", "popularity", "quiz_uuid", "summary", "title", "uuid"];

/**
 * @member {String} category_uuid
 */
ModelCourse.prototype['category_uuid'] = undefined;

/**
 * @member {Array.<module:model/ModelChapter>} chapters
 */
ModelCourse.prototype['chapters'] = undefined;

/**
 * @member {Number} created_at
 */
ModelCourse.prototype['created_at'] = undefined;

/**
 * @member {String} description
 */
ModelCourse.prototype['description'] = undefined;

/**
 * @member {module:model/RepositoryDifficultyLevel} difficulty
 */
ModelCourse.prototype['difficulty'] = undefined;

/**
 * @member {module:model/TimeDuration} duration
 */
ModelCourse.prototype['duration'] = undefined;

/**
 * @member {String} image_url
 */
ModelCourse.prototype['image_url'] = undefined;

/**
 * @member {Boolean} is_recommended
 */
ModelCourse.prototype['is_recommended'] = undefined;

/**
 * @member {Number} popularity
 */
ModelCourse.prototype['popularity'] = undefined;

/**
 * @member {module:model/ModelQuiz} quiz
 */
ModelCourse.prototype['quiz'] = undefined;

/**
 * @member {String} quiz_uuid
 */
ModelCourse.prototype['quiz_uuid'] = undefined;

/**
 * @member {String} summary
 */
ModelCourse.prototype['summary'] = undefined;

/**
 * @member {Array.<module:model/ModelChapter>} takeaways
 */
ModelCourse.prototype['takeaways'] = undefined;

/**
 * @member {String} title
 */
ModelCourse.prototype['title'] = undefined;

/**
 * @member {Number} updated_at
 */
ModelCourse.prototype['updated_at'] = undefined;

/**
 * @member {String} uuid
 */
ModelCourse.prototype['uuid'] = undefined;






export default ModelCourse;

