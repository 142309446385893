import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ActivateDevice from "pages/Devices/ActivateDevice";
import FlipCard from "components/FlipCard";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import {
  CardTitle,
  BodyText,
  EditableContent,
  DeviceWithOsIcon,
} from "../../../components/UI";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  deviceIconWrapper: {
    "&.withLink": {
      cursor: "pointer",
    },
  },
  info: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  flipCard: {
    position: "relative",
    height: "100%",
  },
  deviceNameWrapper: {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
  },
  actions_count: {
    color: theme.variables.$darkGray,
  },
  status: {
    color: theme.variables.$green100,
    "&.disabled": {
      color: theme.variables.$red100,
    },
    "&.pending": {
      color: theme.variables.$darkGray,
    },
  },
}));

const DeviceCard = ({
  t,
  data,
  deleteDevice,
  activateDevice,
  deviceActivationUuid,
  updateDeviceName,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { locale } = useParams();
  const identity = useSelector((state) => state.identity);
  const updateDevice = (evt) => {
    if (evt.target.value !== data.name) {
      updateDeviceName(data.uuid, evt.target.value);
    }
  };
  const [toggle, toggler] = useState(false);
  const [deviceName, setDeviceName] = useState(data.name);
  const [isCurrentDevice, setIsCurrentDevice] = useState(false);

  useEffect(() => {
    let name = data.name;
    if (
      data.device_type === "tablet" &&
      data.name.indexOf("macosx_tablet") !== -1
    ) {
      name = "apple tablet";
      updateDeviceName(data.uuid, name);
    }
    setDeviceName(name);
  }, [data.name]);

  useEffect(() => {
    if (identity && identity.device_uuid === data.uuid) {
      setIsCurrentDevice(true);
    }
  }, [identity]);

  const isDisabled = data.status === "disabled";

  const goToDeviceDetails = () => {
    if (!isDisabled) {
      history.push(`/${locale}/devices/${data.uuid}`);
    }
  };

  return (
    <FlipCard
      className={classes.flipCard}
      toggle={toggle}
      back={
        <Paper style={{ height: "100%" }}>
          <Box
            padding={3}
            textAlign="center"
            display="flex"
            height="100%"
            flexDirection="column"
            justifyContent="center"
          >
            <CardTitle variant="h2" textAlign="center">
              {t("device.delete_warning")}
            </CardTitle>
            <Box mb={2}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                color="primary"
                onClick={deleteDevice(data.uuid, data.name, data.title)}
                className="sa_devices_delete_confirm_delete"
              >
                {t("device.sure_delete_device")}
              </Button>
            </Box>
            <Button
              fullWidth
              size="small"
              variant="contained"
              color="secondary"
              className="sa_devices_cancel_deletion"
              onClick={() => toggler(false)}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Paper>
      }
    >
      <Paper style={{ height: "100%" }}>
        <Box
          padding={3}
          textAlign="center"
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <div className={classes.info}>
            <div
              className={`${classes.deviceIconWrapper} ${
                !isDisabled ? "withLink" : ""
              }`}
              onClick={goToDeviceDetails}
            >
              <DeviceWithOsIcon type={data.device_type} os={data.os} />
            </div>
            <br />
            <div className={classes.deviceNameWrapper}>
              {deviceName && (
                <EditableContent
                  validate={/^([a-zA-Z0-9\s\-.\_]{0,32})$/}
                  onClick={goToDeviceDetails}
                  value={deviceName}
                  isLink={!isDisabled}
                  callback={updateDevice}
                />
              )}
            </div>
            {data.status === "pending" && (
              // show button to complete assessment if currently logged in device
              // or message for other pending devices
              <>
                {isCurrentDevice ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="small"
                    className="sa_devices_device_assessment"
                    onClick={() => {
                      history.push(`/${locale}/deviceScanning/${data.uuid}`);
                    }}
                  >
                    {t("take_device_assessment")}
                  </Button>
                ) : (
                  <BodyText textAlign="center">
                    <span className={`${classes.status} ${data.status}`}>
                      {t("pending_device_info")}
                    </span>
                  </BodyText>
                )}
              </>
            )}

            {data.status !== "pending" && (
              <Box>
                <BodyText textAlign="center" mb={1}>
                  <span className={`${classes.status} ${data.status}`}>
                    {t(data.status.toLowerCase())}
                  </span>
                </BodyText>
                <BodyText textAlign="center" mb={1}>
                  <span className={classes.actions_count}>
                    {t("actions_tasks")}:{" "}
                    {data.counters.count_user_action_unsolved}
                  </span>
                </BodyText>
              </Box>
            )}
          </div>
          <Box>
            {data.status !== "pending" && (
              <>
                {!isDisabled ? (
                  <Box width="100%" mb={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={() =>
                        history.push(`/${locale}/devices/${data.uuid}`)
                      }
                      className="sa_devices_view_device"
                    >
                      {t("view_device")}
                    </Button>
                  </Box>
                ) : (
                  <ActivateDevice
                    uuid={data.uuid}
                    t={t}
                    activateDevice={activateDevice}
                    deviceActivationUuid={deviceActivationUuid}
                  />
                )}
              </>
            )}

            <Button
              variant="contained"
              color="secondary"
              size="small"
              fullWidth
              onClick={() => toggler(true)}
              className="sa_devices_delete_device"
            >
              {t("device.delete_device")}
            </Button>
          </Box>
        </Box>
      </Paper>
    </FlipCard>
  );
};

export default DeviceCard;
