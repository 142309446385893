import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import qs from "qs";
import { Redirect, useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { CardTitle } from "../../../components/UI";

import userApi from "api/user";

import { ReactComponent as EnvelopeIcon } from "../../../assets/icons/envelope_3.svg";

const resultsToMsgMap = {
  email: {
    success: "protected_data.email_is_now_activated",
    failure: "protected_data.email_activation_error",
  },
  "phone-number": {
    success: "protected_data.phone_is_now_activated",
    failure: "protected_data.phone_activation_error",
  },
};

const VerifyProtectedData = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const { locale, type, token } = useParams();
  const search = qs.parse(location.search.replace("?", ""));

  const redirectToManageData = (type, status) => {
    if (search.type === "family_and_friends") {
      const msgType = status === "success" ? toast.TYPE.INFO : toast.TYPE.ERROR;
      const message = resultsToMsgMap[type][status];

      toast(t(message), { type: msgType });
      return
    }

    setRedirectUrl(`/${locale}`);
  };

  const activateProtectedData = async () => {
    try {
      await userApi.activateProtectedEmail({ type, token });
      redirectToManageData(type, "success");
    } catch (err) {
      redirectToManageData(type, "failure");
    }
  };

  useEffect(() => {
    activateProtectedData();
  }, []);

  useEffect(() => {
    let timer = null;
    if (redirectUrl && partnerSettings) {
      timer = setTimeout(() => history.push(redirectUrl), 1500);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [redirectUrl && partnerSettings]);

  if (!token || !type) {
    return <Redirect to={`/${locale}`} />;
  }

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box textAlign="center" mb={2}>
        {search.type === "family_and_friends" && type === "email" ? (
          <EnvelopeIcon />
        ) : (
          <CircularProgress />
        )}
      </Box>
      <Box maxWidth="1000px" px={3}>
        <CardTitle textAlign="center" variant="h2">
          {search.type === "family_and_friends" && type === "email" ? (
            <Box>
              <Typography>
                {t("retail.email_address_is_now_being_monitored")}
              </Typography>
              <Typography>{t("retail.please_close_this_window")}</Typography>
            </Box>
          ) : (
            t("protected_data.verifying")
          )}
        </CardTitle>
      </Box>
    </Box>
  );
};

export default VerifyProtectedData;
