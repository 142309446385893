import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import API from "api/device";
import DeviceCardLimit from "./DeviceCardLimit";
import { useHistory, useParams } from "react-router-dom";
import { getItem, removeItem } from "helpers/localStorage";
import Page from "components/Page";
import { DEFAULT_PARTNER_NAME } from "constants/common";
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import { SquareButton, CardTitle } from '../../../components/UI'
import { ReactComponent as BackArrowIcon } from '../../../assets/icons/arrow-back.svg'
import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
    '& :nth-child(2)': {
      flexGrow: 1,
      marginLeft: theme.variables.$isRTLDirection ? '0' : '1rem',
      marginRight: theme.variables.$isRTLDirection ? '1rem' : '0',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  upgradeBtn: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '1rem',
      width: '100%'
    }
  }
}))

const DeviceLimit = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { locale } = useParams()
  const identity = useSelector(state => state.identity)
  const partnerSettings = useSelector(state => state.partnerSettings)
  const [devices, setDevices] = useState(null);
  const [loading, setLoading] = useState(false);
  const plan = identity && identity.plan.slug;

  useEffect(() => {
    getDevicesList();
  }, []);

  const getDevicesList = () => {
    setLoading(true);
    API.getDevices()
      .then((data) => {
        setLoading(false);
        setDevices(data.items);
      })
      .catch(() => {
        setLoading(false);
        toast(t("can_t_fetch_devices"), { type: toast.TYPE.ERROR });
      });
  };

  const goToDashboard = () => {
    removeItem("detectedDevice");
    history.push(`/${locale}/dashboard`);
  };

  const goToDevicesList = () => {
    history.push(`/${locale}/devices`);
  };

  const goToPlans = () => {
    history.push(`/${locale}/profile/plans`);
  };

  const replaceDevice = (uuid) => {
    const detectedDevice = getItem("detectedDevice");
    let device = null;
    const promise = new Promise((resolve, reject) => {
      if (detectedDevice) {
        try {
          device = JSON.parse(detectedDevice);
          device.auto_activate = true;
          resolve();
        } catch (err) {
          // if no devce to replace in local storage,
          // no need to delete device
          reject(err);
        }
      }
    });
    setLoading(true);
    promise
      .then(() => API.deleteDevice({ uuid }))
      .then(() => API.addDevice(device))
      .then(() => {
        goToDevicesList();
        toast(t("device_replacement_success"), { type: toast.TYPE.INFO });
      })
      .catch((err) => {
        goToDashboard();
        toast(t("server_error"), { type: toast.TYPE.ERROR });
      });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent='center' alignItems='center' width='100%' height='100%'>
        <CircularProgress />
      </Box>
    );
  }
  if (devices) {
    return (
      <Page name="deviceReplace">
        <div className="replaceDevices">
          <div className={classes.header}>
            <SquareButton
              variant='contained'
              color='secondary'
              className="sa_devices_back"
              onClick={goToDashboard}
            >
              <BackArrowIcon />
            </SquareButton>
            <CardTitle mb={0} textAlign="inherit">
              {t("devices")}
            </CardTitle>
            {partnerSettings &&
              partnerSettings.partnerSlug === DEFAULT_PARTNER_NAME &&
              plan !== "ultimate" && (
                <Button
                  className={classes.upgradeBtn}
                  variant='contained'
                  color='primary'
                  onClick={goToPlans}
                >
                  {t("upgrade_plan")}
                </Button>
              )}
          </div>
          <div className="devicesComponent">
            <Grid container spacing={2}>
              {
                devices.map(device => (
                  <Grid item xs={12} sm={6} lg={4} key={device.uuid}>
                    <DeviceCardLimit
                      replaceDevice={replaceDevice}
                      data={device}
                      t={t}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
      </Page>
    );
  }

  return null;
};

export default DeviceLimit;
