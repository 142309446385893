import React from 'react'
import CardItem from '../CardItem'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import { CardTitle, BodyText } from '../../../../components/UI'

const ItemsList = ({ t, protectedPhones, deletePhoneNumber, loading }) => {
  return (
    <Box className='sa_monitored_data_phones_list'>

      <Box mt={3} mb={2}>
        <CardTitle variant='h2' mb={1}>
          {t("protected_phone_numbers")}
        </CardTitle>
        <BodyText textAlign='inherit'>
          {t("protected_phone_description")}
        </BodyText>
      </Box>
      <Paper elevation={2} className='itemsList'>
        <Box p={3}>
          {loading ?
            <Box height='100%' textAlign='center'>
              <CircularProgress />
            </Box>
            :
            <div>
              {protectedPhones.map(number =>
                <>
                  {number.phone_number ?
                    <CardItem
                      t={t}
                      key={number.uuid}
                      data={number}
                      deletePhoneNumber={deletePhoneNumber}
                    />
                    : ""
                  }
                </>
              )}
            </div>
          }
        </Box>
      </Paper>
    </Box>
  )
}

export default ItemsList
