import React from "react";
import { ReactComponent as IconActionMonitorPc } from "../../assets/icons/action-monitor-pc.svg";
import { ReactComponent as IconActionPhone } from "../../assets/icons/action-phone.svg";
import { ReactComponent as IconPerson } from "../../assets/icons/person.svg";

export const deviceTypes = {
  LAPTOP: "laptop",
  DESKTOP: "desktop",
  TABLET: "tablest",
  PHONE: "phone",
  TERMINAL: "terminal",
  PC: "pc",
};

export const EMPTY_DEVICE_TYPE = "EMPTY";

export const indicatorQuarters = {
  quarter1: ["low", "medium", "high"],
  quarter2: ["medium", "high"],
  quarter3: ["medium", "high"],
  quarter4: ["high"],
};

export const deviceToIconMap = {
  [deviceTypes.LAPTOP]: <IconActionMonitorPc viewBox="-3 -3 100 100" />,
  [deviceTypes.DESKTOP]: <IconActionMonitorPc viewBox="-3 -3 100 100" />,
  [deviceTypes.TERMINAL]: <IconActionMonitorPc viewBox="-3 -3 100 100" />,
  [deviceTypes.PC]: <IconActionMonitorPc viewBox="-3 -3 100 100" />,
  [deviceTypes.PHONE]: <IconActionPhone viewBox="-2 0 100 100" />,
  EMPTY: <IconPerson viewBox="-380 265 75 75" />,
};
