import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import qs from "qs";
import { withRouter, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CardTitle, BodyText } from "../../components/UI";

import userApi from "api/user";

import BasicSignUpForm from "./BasicSignUpForm";
import MinimalisticSignUpForm from "./MinimalisticSignUpForm";
import Page from "components/Page";
import Logo from "components/Logo";

import { Log } from "services/log/logger";

import { useReddirectToDashbordOnLoged } from "hooks/authentication/useReddirectToDashbordOnLoged";

import useAuth from "../../hooks/useAuth";
import { useRegisterContext } from "../../contexts";

const SignUp = ({ history, location, t }) => {
  const { locale, coupon } = useParams();
  const { authorizeUser } = useAuth();
  const {
    registerContextMap,
    registerStatus,
    setRegisterStatus,
    authKey,
    setIsValid,
    formValues,
    setFormValues,
    setFlow,
  } = useRegisterContext();
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const phonePrefixes = useSelector((state) => state.phonePrefixes);

  // If user is loged redirrect to /dashboard
  useReddirectToDashbordOnLoged(partnerSettings);

  const { flow } = qs.parse(location.search.replace("?", ""));
  const isSmoothFlow = flow === "smooth";

  const {termsOfServiceContent, skipConfirmation, noRegistrationFlow} = partnerSettings

  useEffect(() => {
    setFlow(flow);
  }, [flow]);

  useEffect(() => {
    if (registerStatus === registerContextMap.AUTH_REQUEST) {
      authorizeUser(formValues);
    }
    if (registerStatus === registerContextMap.EMAIL_VERYFICATION) {
      history.push({
        pathname: `/${locale}/signup/emailVerify`,
        state: { from: location, authKey },
      });
    }
    if (registerStatus === registerContextMap.AUTH_REQUEST_SUCCESS) {
      history.push(`/${locale}/dashboard`);
    }
    if (registerStatus === "registerFailed") {
      toast(t("an_error_occured"), { type: toast.TYPE.ERROR });
    }
  }, [registerStatus]);

  const validateForm = (form) => {
    const validateFunc = isSmoothFlow
      ? userApi.validateSmoothFlow
      : userApi.validate;

    validateFunc(form)
      .then((resp) => {
        sessionStorage.setItem("userEmail", form.email); //?
        setFormValues(form);
        if (isSmoothFlow || skipConfirmation) {
          setIsValid(true);
        } else {
          setRegisterStatus(registerContextMap.TERMS_AND_CONDITIONS);
        }
      })
      .catch((err) => {
        if (
          err.errorStack &&
          err.errorStack.errors &&
          err.errorStack.errors[0] &&
          err.errorStack.errors[0].message
        ) {
          toast(err.errorStack.errors[0].message, { type: toast.TYPE.ERROR });
        }
        if (process.env.NODE_ENV === "development") {
          return console.log(err);
        }
        Log.error("Error validate form", {
          request: form,
          response: err,
          body: err.body,
          context: {
            skipConfirmation,
            flow,
          },
        });
      });
  };

  if (window.location.href.indexOf("staff") > -1) {
    history.push(`/${locale}/signIn`);
  }

  if (registerStatus === registerContextMap.TERMS_AND_CONDITIONS) {
    history.push(`/${locale}/signup/terms-and-conditions`);
  }

  return (
    <Page name="signUp">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Box
          width="100%"
          overflow="auto"
          pb="8px"
        >
          {registerStatus === registerContextMap.REGISTER_REQUEST ||
          registerStatus === registerContextMap.AUTH_REQUEST ? (
            <CircularProgress />
          ) : (
            <>
              <Box mb={6} textAlign="center">
                <Logo preventRedirection={true} />
              </Box>
              <Paper
                elevation={2}
                style={{ maxWidth: "576px", margin: "0 auto" }}
              >
                <Box p={3}>
                  <CardTitle textAlign="center">
                    {t("sign_up_to_dynarisk", {
                      name: partnerSettings.partnerName,
                    })}
                  </CardTitle>
                  <BodyText textAlign="center" mb={3}>
                    {t("sign_in_to_dynarisk_facebook_subheader", {
                      name: partnerSettings.partnerName,
                    })}
                  </BodyText>

                  {registerStatus === registerContextMap.INIT && (
                    <Box>
                      {isSmoothFlow ? (
                        <MinimalisticSignUpForm
                          coupon={coupon}
                          validateForm={validateForm}
                          termsOfServiceContent={termsOfServiceContent}
                        />
                      ) : (
                        <BasicSignUpForm
                          coupon={coupon}
                          validateForm={validateForm}
                          formValues={formValues}
                          phonePrefixes={phonePrefixes}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </Page>
  );
};

export default withRouter(withTranslation()(SignUp));
