import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useLableStyles = makeStyles((theme) => ({
  medium: {
    marginLeft: "0",
    marginRight: "0",
    "& .MuiFormControlLabel-label": {
      fontSize: "18px",
    },
  },
  small: {
    marginLeft: "0",
    marginRight: "0",
    "& .MuiFormControlLabel-label": {
      fontSize: "14px",
    },
  },
}));

export default function CheckboxInput({
  checked = false,
  name = undefined,
  id = undefined,
  size = "medium",
  color = "primary",
  label = undefined,
  onChange,
  disabled = false,
}) {
  const classes = useLableStyles();
  return (
    <FormControlLabel
      className={classes[size]}
      control={
        <Checkbox
          size={size}
          id={id}
          checked={checked}
          onChange={onChange}
          name={name}
          color={color}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
}
