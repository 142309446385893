const courseStateToBtnKeyMap = {
  PREVIOUS_VIDEO: "retail.previous_lesson",
  PREVIOUS_VIDEO_other: "retail.previous_chapter",
  PREVIOUS_MARKDOWN: "retail.previous_lesson",
  PREVIOUS_MARKDOWN_other: "retail.previous_chapter",
  PREVIOUS_CHAPTER_TAKEAWAY: "retail.back_to_takeaway",
  PREVIOUS_CHAPTER_TAKEAWAY_other: "retail.previous_chapter",
  PREVIOUS_COURSE_TAKEAWAY: "retail.back_to_takeaway",
  PREVIOUS_COURSE_TAKEAWAY_other: "retail.previous_chapter",
  PREVIOUS_CHAPTER_QUIZ: "retail.previous_chapter",
  CURRENT_VIDEO: "retail.complete_lesson",
  CURRENT_MARKDOWN: "retail.complete_lesson",
  CURRENT_CHAPTER_TAKEAWAY: "retail.complete_takeaway",
  CURRENT_COURSE_TAKEAWAY: "retail.complete_takeaway",
  CURRENT_FINAL_QUIZ: "retail.unlock_certificate",
  NEXT_VIDEO: "retail.next_lesson",
  NEXT_VIDEO_other: "retail.next_chapter",
  NEXT_MARKDOWN: "retail.next_lesson",
  NEXT_MARKDOWN_other: "retail.next_chapter",
  NEXT_CHAPTER_TAKEAWAY: "retail.continue_to_takeaway",
  NEXT_COURSE_TAKEAWAY_other: "retail.continue_to_course_takeaway",
  NEXT_COURSE_TAKEAWAY: "retail.continue_to_course_takeaway",
  NEXT_CHAPTER_QUIZ: "retail.continue_to_quiz",
  NEXT_FINAL_QUIZ: "retail.go_to_final_quiz",
};

export function getNavigationLabel(t, option, item, isOtherChapter = false) {
  const key = `${option}_${item.type}${isOtherChapter ? "_other" : ""}`;
  return t(courseStateToBtnKeyMap[key]);
}