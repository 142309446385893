import UAParser from "ua-parser-js";
import sha512 from "js-sha512";
import MobileDetect from "mobile-detect";
import Androids from "./android_systems.json";
import { fingerprintHelper } from "./fingerprintHelper";

const BROWSER_SCAN = "browser";
const APP_SCAN = "app";

var dataAvalible = false;
var browserData = {};
var scan_by = BROWSER_SCAN;
var vendorsMap = {
  linux: "linux",
  windows: "microsoft",
  android: "google",
  ios: "apple",
  macosx: "apple",
  unix: "unix"
};

export const browserMap = {
  chrome: "CHROME",
  firefox: "FIREFOX",
  mozilla: "FIREFOX",
  edge: "EDGE",
  ie: "INTERNET_EXPLORER",
  "internet explorer": "INTERNET_EXPLORER",
  safari: "SAFARI",
  "mobile safari": "SAFARI",
  opera: "OPERA",
  yandex: "YANDEX_BROWSER",
  vivaldi: "VIVALDI"
};

var browserVendorsMap = {
  chrome: "google",
  chromium: "chromium",
  firefox: "mozilla",
  mozilla: "mozilla",
  opera: "opera",
  edge: "microsoft",
  internet_explorer: "microsoft",
  ie: "microsoft",
  safari: "apple",
  "mobile safari": "apple"
};

const androidFinder = version => {
  var ver = version.split(".");
  while (ver.length < 3) {
    ver.push("0");
  }
  ver = ver.join(".");
  var api = "?";
  for (var system of Androids) {
    var osver_min = system.version.min;
    var osver_max = system.version.max;
    if (ver >= osver_min && ver <= osver_max) {
      api = system.api_level;
      break;
    }
  }
  return api;
};

const scanFlashMW = state => {
  let isFlash = navigator.plugins["Shockwave Flash"] !== undefined;
  let ver = null;
  if (isFlash) {
    ver = navigator.plugins["Shockwave Flash"].version;
  }

  return {
    ...state,
    meta: {
      ...state.meta,
      plugins: [{ name: "Shockwave Flash", installed: isFlash, version: ver }]
    }
  };
};

const scanJavaMW = state => {
  let isJava = false;
  let ver = null;
  if (state.meta.browser.product && state.meta.browser.product.indexOf("IE")) {
    try {
      /* eslint-disable-next-line no-undef*/
      if (ActiveXObject) {
        /* eslint-disable-next-line no-undef*/
        let wshShell = new ActiveXObject("WScript.Shell");
        let res = wshShell.Exec(
          'cmd.exe /c for /f "tokens=3" %g in (\'java -version 2^>^&1 ^| findstr /i "version"\') do @echo %g'
        );
        let v = res.stdOut.ReadLine();
        if (v.length > 0) {
          while (v.indexOf('"') > -1) {
            v = v.replace('"', "");
          }
          ver = v;
          isJava = true;
        }
      }
    } catch (e) { }
  }
  return {
    ...state,
    meta: {
      ...state.meta,
      plugins: [
        ...state.meta.plugins,
        { name: "Java", installed: isJava, version: ver }
      ]
    }
  };
};

/**
 * Gets useragent to put inside some information:
 * os (name, version),
 * browser (name, version),
 * @param {Object} state - state to be processed
 */
const scanUserAgentMW = state => {
  // var ua = UAParser("Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:69.0) Gecko/20100101 Firefox/69.0");
  // var ua = UAParser("Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36");
  // var ua = UAParser("Mozilla/5.0 (Windows NT 10.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/72.0.3626.121 Safari/537.36");
  // var ua = UAParser("Opera/9.80 (Windows NT 6.1; WOW64) Presto/2.12.388 Version/12.18");
  // var ua = UAParser("Opera/9.80 (Linux armv7l) Presto/2.12.407 Version/12.51 , D50u-D1-UHD/V1.5.16-UHD (Vizio, D50u-D1, Wireless)");
  // var ua = UAParser("Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/56.0.2924.87 Safari/537.36 OPR/43.0.2442.991");
  // var ua = UAParser("Mozilla/5.0 (Windows NT 5.1; rv:33.0) Gecko/20100101 Firefox/33.0");
  // var ua = UAParser("Mozilla/5.0 (Windows NT 10.0; WOW64; rv:50.0) Gecko/20100101 Firefox/50.0");
  // var ua = UAParser("Mozilla/5.0 (iPhone; CPU iPhone OS 11_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1");
  // var ua = UAParser("Mozilla/5.0 (iPhone; CPU iPhone OS 12_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1 Mobile/15E148 Safari/604.1");
  // var ua = UAParser("Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_5) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1.1 Safari/605.1.15");

  // var ua = UAParser("Mozilla/5.0 (Macintosh; U; Intel Mac OS X 10_6_6; en-en) AppleWebKit/533.19.4 (KHTML, like Gecko) Version/5.0.3 Safari/533.19.4");
  // var ua = UAParser("Mozilla/5.0 (iPhone; CPU iPhone OS 12_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1.2 Mobile/15E148 Safari/604.1");
  // var ua = UAParser("Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/76.0.3809.132 Safari/537.36 OPR/63.0.3368.107");
  // var ua = UAParser("Mozilla/5.0 (Windows NT 6.1; WOW64; rv:54.0) Gecko/20100101 Firefox/71.0 ")
  // var ua = UAParser("Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/72.0.3626.121 Safari/537.36")
  // var ua = UAParser("Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/80.0.3987.87 Chrome/80.0.3987.87 Safari/537.36")
  // correct one
  var ua = UAParser(navigator.userAgent);
  if (
    ua.os.name.toLowerCase() === "macos" ||
    ua.os.name.toLowerCase() === "mac os" ||
    ua.os.name.toLowerCase() === "mac os x" ||
    ua.os.name.toLowerCase() === "mac" ||
    ua.os.name.toLowerCase() === "macosx" ||
    ua.os.name.toLowerCase() === "osx"
  ) {
    ua.os.name = "macosx";
  }
  var flavor = ua.os.version;
  var version = ua.os.version;
  if (ua.os.name.toLowerCase() === "android") {
    flavor = androidFinder(flavor);
  }

  ua.os.name =
    ["LINUX", "UBUNTU"].indexOf(ua.os.name.toUpperCase()) !== -1
      ? "UNIX"
      : ua.os.name;

  var os = {
    vendor: vendorsMap[ua.os.name.toLowerCase()],
    product: `${ua.os.name}_${flavor}`,
    version: version
  };
  var browserVendor = browserVendorsMap[ua.browser.name.toLowerCase()];
  var browser = {
    vendor: null,
    product: null,
    version: null
  };
  if (browserVendor) {
    browser = {
      vendor: browserVendor,
      product: ua.browser.name,
      version: ua.browser.version
    };
  }
  if (dataAvalible) {
    browser = {
      vendor: browserData.vendor,
      product: browserData.product,
      version: browserData.version
    };
  }
  return {
    ...state,
    scan_by,
    meta: {
      os,
      browser
    }
  };
};

/**
 * Gets gpu with vendor
 * (webgl based, so in most cases it returns google as vendor)
 */
const getGPU = () => {
  var canvas = document.createElement("canvas");
  var gl;
  var debugInfo;
  var renderer;
  try {
    gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
    renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
  } catch (e) {
    return "unknown gpu";
  }
  // debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
  // renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);

  try {
    document.removeChild(canvas);
  } catch (e) { }
  // return `${vendor} ${renderer}`; // EDGE and OPERA mess up with vendor
  return `${renderer}`;
};

/**
 * Returns unique fingerprint per device (browser independent)
 * Fingerprint is based on:
 * screen area (is independent of orientation),
 * cpu threads (from browser navigator),
 * cpu architecture (from browser navigator),
 * gpu,
 * user email,
 * salt
 * @param {Object} state
 */

const osNameMap = {
  Linux: "linux",
  Ubuntu: "linux"
};
const getFingerPrint = state => {
  var ua = UAParser(navigator.userAgen);
  var resolution = window.screen.height * window.screen.width;
  var cpuThreads = window.navigator.hardwareConcurrency;
  var cpuArch = ua.cpu.architecture;
  var os = JSON.stringify(ua.os);
  var osName = osNameMap[ua.os.name] || ua.os.name;
  var gpu = getGPU();
  var screenDepth = window.screen.pixelDepth;
  var dpi = getDPI();

  var device = "";
  if (ua.device && ua.device.type === "mobile") {
    device = `|${JSON.stringify(ua.device)}`;
  }

  var salt = "9fac5ee0073f43306a46d84c2cf88c4b";
  var hash = sha512(
    `${salt}|${resolution}|${cpuThreads}|${cpuArch}|${gpu}|${screenDepth}|${dpi}|${os}${device}`
  );
  var h = sha512(
    `${salt}|${resolution}|${cpuThreads}|${cpuArch}|${gpu}|${screenDepth}|${dpi}|${osName}${device}`
  );
  var newHash = "2$" + h.substring(2, h.length);

  if (process.env.REACT_APP_DEBUG === 'true') {
    // console.info("****** fingerprint ********");
    // console.log("resolution", resolution);
    // console.log("cpuThreads", cpuThreads);
    // console.log("cpuArch", cpuArch);
    // console.log("gpu", gpu);
    // console.log("screenDepth", screenDepth);
    // console.log("dpi", dpi);
    // console.log("os", os);
    // console.log("osName", osName);
    // console.log("device", device);
  }

  return {
    ...state,
    fingerprint: hash,
    fingerprint_v2: fingerprintHelper(newHash),
    fingerprint_meta: JSON.stringify({
      resolution,
      cpuThreads,
      cpuArch,
      gpu,
      screenDepth,
      dpi,
      osName,
      device
    })
  };
};

const getDPI = () => {
  let dpiEl = document.getElementById("dpi");
  if (!dpiEl) {
    dpiEl = document.createElement("div");
    dpiEl.id = "dpi";
    dpiEl.style.height = "1in";
    dpiEl.style.width = "1in";
    dpiEl.style.left = "-100%";
    dpiEl.style.position = "absolute";
    dpiEl.style.top = "-100%";
    document.body.appendChild(dpiEl);
  }
  let dpi = dpiEl.offsetHeight;
  return dpi;
};

const getDeviceType = state => {
  var dt = "pc";
  var md = new MobileDetect(navigator.userAgent);
  if (md.mobile()) {
    if (md.phone()) {
      dt = "phone";
    } else if (
      md.tablet() ||
      (!navigator.userAgent.toLowerCase().match("iphone") &&
        navigator.maxTouchPoints > 1)
    ) {
      dt = "tablet";
    }
  }
  if (
    !navigator.userAgent.toLowerCase().match("iphone") &&
    navigator.maxTouchPoints > 1 &&
    navigator.platform === "MacIntel"
  ) {
    dt = "tablet";
  }
  return {
    ...state,
    device_type: dt
  };
};

/**
 * SystemResourceScanner - SRS
 */
const SRS = () => {
  var state = {};

  /**
   * list of middlewares loop
   */
  const mwList = [
    getFingerPrint,
    scanUserAgentMW,
    scanFlashMW,
    scanJavaMW,
    getDeviceType
  ];

  /**
   * Adds middleware to mwList
   * @param {function} mv - middleware to add
   */
  const useMiddleware = mv => {
    if (typeof mv === "function") {
      mwList.push(mv);
    }
    throw { message: `mv must be function, given ${typeof mv}` };
  };

  /**
   * Props processor - can be extended;
   * @param {Object} props
   */
  const processProps = props => {
    state = { ...props };
  };

  /**
   * Main run function - here all magic happens!
   * @param {Object} props
   */
  const run = props => {
    processProps(props);
    for (let mw of mwList) {
      state = mw(state);
    }

    return state;
  };

  const setScanProvider = provider => {
    if (provider === APP_SCAN) {
      scan_by = APP_SCAN;
    } else {
      scan_by = BROWSER_SCAN;
    }
  };

  // const setSystemVersion = version => {
  //   sysVer = version;
  // };

  /**
   * export object representation
   */
  return {
    state,
    run,
    mwList,
    useMiddleware,
    setScanProvider,
    // setSystemVersion
  };
};

export default SRS();
