import { useState, useEffect } from "react";
import deviceApi from "../../api/device";

export default function useFetchDevices() {
    const [options_devices, setOptionsDevices] = useState([
      { value: "all", label: "all_devices" },
    ]);
  
    useEffect(() => {
      async function fetchDevices() {
        const devicesListData = [];
        try {
          const resp = await deviceApi.getDevices();
          resp.items.forEach((current) => {
            devicesListData.push({
              value: current.uuid,
              os: current.os,
              name: current.name,
            });
          });
          setOptionsDevices([...options_devices, ...devicesListData]);
        } catch (err) {
          // handle error
        }
      }
  
      fetchDevices();
    }, []);
  
    return { options_devices };
  }