import React from 'react'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
  placeholderBox: {
    background: theme.variables.$skeletonPlaceholder,
    color: theme.palette.primary.main,
    margin: '0 auto 20px',
    opacity: '1',
    animation: 'fading 1.3s infinite ease-in-out',
    '&.circle': {
      borderRadius: '50%'
    }
  },
  '@keyframes fading': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: .5,
    },
    '100%': {
      opacity: 1,
    }
  }
}))
const PlaceholderBox = ({
  type,
  width = '100%',
  height = '1rem',
  size,
  shrink = 1,
  center,
  mb = '20px',
  ml,
  mr,
}) => {
  const classes = useStyles()

  return <div
    className={`
      placeholderBox
      ${classes.placeholderBox}
      ${type ? type : ''}
      ${center ? 'center' : ''}
    `}
    style={{
      width: size || width,
      height: size || height,
      flexShrink: shrink,
      marginBottom: mb,
      marginLeft: ml,
      marginRight: mr,
    }}
  />
}

export default PlaceholderBox