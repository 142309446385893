/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CxHttpExceptionData from './CxHttpExceptionData';

/**
 * The CxHttpException model module.
 * @module model/CxHttpException
 * @version 1.0.0
 */
class CxHttpException {
    /**
     * Constructs a new <code>CxHttpException</code>.
     * @alias module:model/CxHttpException
     * @param data {module:model/CxHttpExceptionData} 
     * @param status {Boolean} 
     */
    constructor(data, status) { 
        
        CxHttpException.initialize(this, data, status);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, data, status) { 
        obj['data'] = data;
        obj['status'] = status;
    }

    /**
     * Constructs a <code>CxHttpException</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CxHttpException} obj Optional instance to populate.
     * @return {module:model/CxHttpException} The populated <code>CxHttpException</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CxHttpException();

            if (data.hasOwnProperty('data')) {
                obj['data'] = CxHttpExceptionData.constructFromObject(data['data']);
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CxHttpException</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CxHttpException</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CxHttpException.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `data`
        if (data['data']) { // data not null
          CxHttpExceptionData.validateJSON(data['data']);
        }

        return true;
    }


}

CxHttpException.RequiredProperties = ["data", "status"];

/**
 * @member {module:model/CxHttpExceptionData} data
 */
CxHttpException.prototype['data'] = undefined;

/**
 * @member {Boolean} status
 */
CxHttpException.prototype['status'] = undefined;






export default CxHttpException;

