import { SET_PHONE_PREFIXES } from '../constants'

/**
 * @typedef {{icon:string, label:string, value: string}} PhonePrefix
 */
/**
 * @param state PhonePrefix[]|null
 * @param type string
 * @param PhonePrefixArray payload
 * @returns {null|PhonePrefix[]}
 */
export default function phonePrefixes(state = null, {type, payload}) {
  switch (type) {
    case SET_PHONE_PREFIXES:
      return payload
    default:
      return state
  }
}
