import React, { useContext } from "react";
import Plan from "components/Plans/Plan";
import Loading from "./Loading";
import { PlansProvider, PlansContext } from "./PlansContext";
import Grid from '@material-ui/core/Grid'
import { useHistory, useRouteMatch } from "react-router-dom";

const PlanProvider = () => {
  const history = useHistory()
  const match = useRouteMatch()
  return (
    <PlansProvider history={history} match={match}>
      <Plans />
    </PlansProvider>
  );
};

const Plans = () => {
  const { plans, t } = useContext(PlansContext);
  return (
    <>
      {plans ? (
        <div className="plansComponent">
          <Grid container spacing={3}>
            {Object.values(plans).map((value) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={value.slug}>
                  <Plan t={t} data={value} />
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default PlanProvider;
