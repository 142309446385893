import React from "react";
import Box from "@material-ui/core/Box";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  downloadShield: {
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.variables.$textDarkGray,
    "& svg": {
      width: "16px",
      height: "16px",
      // marginLeft: "4px",
      verticalAlign: "middle",
    },
  },
}));

function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

const RiskScoreShield = ({ cyberShieldUrl, partnerSlug, riskValue }) => {
  const classes = useStyles();

  const drawShield = async () => {
    toDataURL(cyberShieldUrl, function (dataUrl) {
      // canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = 600;
      canvas.height = 622;

      // image
      const img = new Image();
      img.src = dataUrl;
      img.onload = function () {
        ctx.drawImage(img, 0, 0, 600, 622);

        // risk score text
        ctx.font = "bold 42px Montserrat,Geometria,sans-serif";
        ctx.fillStyle = "#083c5e";
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.fillText(riskValue, 295, 350);

        // upload
        let anchor = document.createElement("a");
        anchor.download = `${partnerSlug}_cyber_shield.png`;
        anchor.href = canvas.toDataURL("image/png");
        anchor.click();
        anchor.remove();
      };
    });
  };

  return (
    <Box>
      <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
        <image href={cyberShieldUrl} height="200" width="200" />
        <text
          x="50%"
          y="58%"
          fill="#083c5e"
          textAnchor="middle"
          style={{
            font: "900 60px sans-serif",
            letterSpacing: "1.1",
            textShadow: "1px 1px #31363F",
          }}
        >
          {riskValue}
        </text>
      </svg>
      <Box className={classes.downloadShield} onClick={drawShield}>
        <Box component="span" mx={0.5}>
          Download shield
        </Box>
        <Box component="span" mx={0.5}>
          <DownloadIcon />
        </Box>
      </Box>
    </Box>
  );
};

export default RiskScoreShield;
