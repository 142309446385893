import {
  PLAN_EXPIRED,
  SET_CURRENT_PLAN,
  SET_CURRENT_PLAN_FEATURES,
  SET_PLANS,
} from "../constants";

const initialsState = {
  list: null,
  expired: false,
  currentPlan: null,
  currentPlanFeatures: null,
};

export default function plans(state = initialsState, { type, payload }) {
  switch (type) {
    case SET_PLANS:
      return { ...state, list: payload };
    case PLAN_EXPIRED:
      return { ...state, expired: payload.isExpired };
    case SET_CURRENT_PLAN:
      return { ...state, currentPlan: payload };
    case SET_CURRENT_PLAN_FEATURES:
      return { ...state, currentPlanFeatures: payload };
    default:
      return state;
  }
}
