import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CardTitle } from "components/UI";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Tag from "components/Chip";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as VideoIcon } from "assets/icons/camera.svg";
import { ReactComponent as QuizIcon } from "assets/icons/quiz.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as CoursePlaceholder } from "assets/icons/course_placeholder.svg";
import { formatDuration, calculateRemainingTime } from "../../utils/parseTime";
import CourseProgressBar from "../../CommonComponents/Progress";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    "&:hover": {
      cursor: "pointer",
    },
    height: "100%",
  },
  courseCard: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  cardImg: {
    borderRadius: "4px",
    maxHeight: "140px",
    width: "100%",
    objectFit: "cover",
  },
  placeholder: {
    borderRadius: "4px",
    width: "100%",
    display: "flex",
    "& svg": {
      maxHeight: "140px",
    },
  },
  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  statusWrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textWrapper: {
    flexGrow: 1,
  },
  difficulty: {
    color: theme.palette.primary.dark,
  },
  tagWrapper: {
    display: "flex",
    "& svg": {
      height: "16px",
      width: "16px",
    },
  },
  courseChipWrapper: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(1)} 0 0 0"`,
  },
  courseCardChip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const TaskCard = ({ courseData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { locale } = useParams();
  const history = useHistory();

  const redirectToDetails = () => {
    history.push(`/${locale}/training/course-overview/${courseData.uuid}`);
  };

  return (
    <Box className={classes.cardWrapper} onClick={redirectToDetails}>
      <Paper className={classes.courseCard}>
        {courseData.image_url ? (
          <img
            className={classes.cardImg}
            src={courseData.image_url}
            alt={courseData.title}
          />
        ) : (
          <Box className={classes.placeholder}>
            <CoursePlaceholder />
          </Box>
        )}
        <Box className={classes.cardContent} mt={2}>
          <Box className={classes.statusWrapper}>
            <Chip
              label={t(`retail.${courseData.progress_status.toLowerCase()}`)}
              size="small"
              variant="outlined"
            />
            {courseData.completion_date &&
              courseData.progress_status !== "COMPLETED" && (
                <Typography variant="body2">
                  {`${calculateRemainingTime(courseData.completion_date)} ${t(
                    "retail.remaining"
                  )}`}
                </Typography>
              )}
          </Box>
          <Box className={classes.textWrapper} mt={1} mb={1}>
            <CardTitle mb={0} variant="h2" textAlign="inherit">
              {courseData.title}
            </CardTitle>
            <Typography variant="body2">{courseData.description}</Typography>
          </Box>
          <Box mt={1} className={classes.courseChipWrapper}>
            <Chip
              label={t(`retail.${courseData.difficulty.toLowerCase()}`)}
              color="secondary"
              className={`${classes.difficulty} ${classes.courseCardChip}`}
            />
            {courseData.category && (
              <Chip
                label={courseData.category}
                color="primary"
                className={classes.courseCardChip}
              />
            )}
          </Box>
          <Box className={classes.tagWrapper} mt={1} mb={1}>
            <Tag
              icon={<VideoIcon />}
              label={`${t("retail.video")}: ${courseData.video_lessons_count}`}
              mr={0.5}
            />
            <Tag
              icon={<QuizIcon />}
              label={`${t("retail.quiz")}: ${courseData.quizzes_count}`}
              mr={0.5}
            />
            <Tag
              icon={<ClockIcon />}
              label={formatDuration(courseData.duration)}
            />
          </Box>
          <CourseProgressBar course={courseData} />
        </Box>
      </Paper>
    </Box>
  );
};
  
  export default TaskCard;