function parseJwt(token) {
  if (token === null) {
    return {};
  }

  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  } catch (err) {
    return {};
  }
}

export default parseJwt;
