import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { Card } from "../../components/UI";
import MDRender from "components/MDRender";
import { DAYS_OF_WEEK_MON_FIRST_KEYS } from "constants/common";
import { countriesFlags } from "constants/countriesFlags";

import { ReactComponent as IconEarphones } from "../../assets/icons/earphones.svg";

const useSupportScheduleStyles = makeStyles((theme) => ({
  header: {
    textAlign: "center",
  },
  schedule: {
    "& .workingHoursTitle": {
      textAlign: "center",
      marginBottom: "8px",
      fontWeight: 500,
    },
    "& .timeSchedule": { textAlign: "center" },
  },
  markdown: {
    "& mdWrapper p": {
      margin: 0,
      color: theme.variables.$black,
    },
    "& mdWrapper h4": {
      marginTop: "24px",
      color: theme.variables.$black,
    },
  },
  daysShedule: {
    width: "150px",
    margin: "0 auto",
    fontSize: "14px",
    display: "flex",
    justifyContent: "space-between",
  },
  workingHoursTitle: {
    margin: "10px 0",
    textAlign: "center",
  },
  supportPhonesWrapper: {
    width: "170px",
    margin: "15px auto 0",
  },
  supportPhones: {
    textAlign: "center",
    marginBottom: "10px",
  },
  supportCountryFlag: {
    "& svg": {
      width: "24px",
      marginRight: "10px",
      verticalAlign: "bottom",
    },
  },
  supportPhoneNumber: {
    fontSize: "14px",
    "& pre": {
      fontFamily: "inherit",
    },
  },
}));

export default function SupportSchedule() {
  const { t } = useTranslation();
  const classes = useSupportScheduleStyles();
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const schedule = partnerSettings.partnerSchedule;

  const renderSupportSchedule = () => {
    if (schedule) {
      return (
        <>
          {schedule.markdown ? (
            <Box className={classes.markdown}>
              <MDRender t={t} input={schedule.markdown} escapeHtml={false} />
            </Box>
          ) : (
            <>
              {schedule.sub_header && (
                <div className={classes.workingHoursTitle}>
                  {schedule.sub_header}
                </div>
              )}
              {schedule.schedule && Array.isArray(schedule.schedule) && (
                <>
                  {schedule.schedule.length === 1 ? (
                    <div className={classes.schedule}>
                      <div className="workingHoursTitle">
                        {t("working_hours")}
                      </div>
                      <div className="timeSchedule">
                        <span>{`${schedule.schedule[0].from} - ${schedule.schedule[0].to}`}</span>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.schedule}>
                      <div className="workingHoursTitle">
                        {t("working_hours")}
                      </div>
                      {schedule.schedule.map((day, index) => (
                        <div
                          key={DAYS_OF_WEEK_MON_FIRST_KEYS[index]}
                          className={classes.daysShedule}
                        >
                          <span>
                            {t(DAYS_OF_WEEK_MON_FIRST_KEYS[index])
                              .toUpperCase()
                              .substring(0, 3)}
                            &nbsp;
                          </span>
                          <span>{`${day.from} - ${day.to}`}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
              <div className={classes.supportPhonesWrapper}>
                {schedule.phones &&
                  schedule.phones.map(({ country, phone }) => (
                    <div
                      key={country || phone}
                      className={classes.supportPhones}
                    >
                      <span className={classes.supportCountryFlag}>
                        {country && countriesFlags[country.toLowerCase()]}
                      </span>
                      <span className={classes.supportPhoneNumber}>
                        <pre>{phone}</pre>
                      </span>
                    </div>
                  ))}
              </div>
            </>
          )}
        </>
      );
    }
    return (
      <>
        <div className={classes.schedule}>
          <div className="workingHoursTitle">{t("working_hours")}</div>
        </div>
        <span>8:30 - 17:30</span>
        <span>+44 (0) 203 086 8875</span>
      </>
    );
  };

  return (
    <Card>
      <Box fontSize="4rem" textAlign="center">
        <IconEarphones />
      </Box>
      {!schedule.markdown && <h4 className={classes.header}>{t("call_us")}</h4>}
      {renderSupportSchedule()}
    </Card>
  );
}
