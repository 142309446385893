import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CyberSecurityScore from "../CyberSecurityScore/CyberSecurityScore";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { BodyText, CardTitle, Card } from "../../../components/UI";
import PlaceholderBox from "components/ContentPlaceholder/PlaceholderBox";
import ContentPlaceholder from "components/ContentPlaceholder";
import { useCyberScoreContect } from "../../../contexts";
import useFlagSettings from "../../../hooks/useFlagSettings";
import { useSelector } from "react-redux";
import RiskScoreShield from "./RiskScoreShield";

const useStyles = makeStyles((theme) => ({
  riskValuation: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  text: {
    "& h2[class*=MuiTypography-h2]": {
      textAlign: "center",
    },
    "& div[class*=MuiTypography-body2]": {
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.variables.$isRTLDirection ? "0" : "24px",
      paddingRight: theme.variables.$isRTLDirection ? "24px" : "0",
      "& h2[class*=MuiTypography-h2]": {
        textAlign: "unset",
      },
      "& div[class*=MuiTypography-body2]": {
        textAlign: "unset",
      },
    },
  },
}));

const RiskValuation = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { flagsMap, userSettings } = useFlagSettings();
  const { cyberShieldUrl, partnerSlug } = useSelector(
    (state) => state.partnerSettings
  );

  const {
    isLoading,
    cyberScore,
    cyberScoreHeader,
    getRecentCyberScore,
    setLoading,
  } = useCyberScoreContect();

  useEffect(() => {
    let delay = 5000;
    if (userSettings && userSettings[flagsMap.ASSESSMENT_SHOWN]) {
      delay = 1500;
    }
    let timer = null;
    if (!cyberScore && userSettings) {
      setLoading(true);
      timer = setTimeout(() => getRecentCyberScore(), delay);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [cyberScore, userSettings]);

  if (isLoading) {
    return (
      <Box mb={3}>
        <Box mb={3}>
          <PlaceholderBox height="2rem" width="50%" ml="0" mb="0" />
        </Box>
        <ContentPlaceholder type="infoCard" />
      </Box>
    );
  }

  return (
    <Box mb={4}>
      {cyberScore && (
        <Card>
          <div className={classes.riskValuation}>
            <Box>
            {!cyberShieldUrl && <CyberSecurityScore
                riskValue={cyberScore.value_formatted}
                maxRiskvalue={cyberScore.value_max}
              ></CyberSecurityScore>
            }     
              <>
                {cyberShieldUrl && (
                  <RiskScoreShield
                    partnerSlug={partnerSlug}
                    cyberShieldUrl={cyberShieldUrl}
                    riskValue={cyberScore.value_formatted}
                  />
                )}
                <BodyText textAlign="center">
                  <span className="tourMobile1">
                    {t("your_cyber_security_score")}
                  </span>
                </BodyText>
              </>
            </Box>
            <div className={classes.text}>
              <Box display="flex" flexDirection="column">
                <CardTitle variant="h2">{t(cyberScoreHeader)}</CardTitle>
                <BodyText>
                  {t("your_cyber_security_score_tip_dashboard")}
                </BodyText>
              </Box>
            </div>
          </div>
        </Card>
      )}
    </Box>
  );
};

export default RiskValuation;
