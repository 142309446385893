/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CxHttpException from '../model/CxHttpException';
import CxHttpValidationError from '../model/CxHttpValidationError';
import UserCourseTakeawayItem from '../model/UserCourseTakeawayItem';

/**
* CompleteTakeaway service.
* @module api/CompleteTakeawayApi
* @version 1.0.0
*/
export default class CompleteTakeawayApi {

    /**
    * Constructs a new CompleteTakeawayApi. 
    * @alias module:api/CompleteTakeawayApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Complete user course takeaway
     * Complete user course takeaway by uuid
     * @param {String} uuid User course takeaway UUID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserCourseTakeawayItem} and HTTP response
     */
    completeUserCourseTakeawayWithHttpInfo(uuid) {
      let postBody = null;
      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling completeUserCourseTakeaway");
      }

      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = UserCourseTakeawayItem;
      return this.apiClient.callApi(
        '/api/v1/user_course_takeaways/{uuid}/complete', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Complete user course takeaway
     * Complete user course takeaway by uuid
     * @param {String} uuid User course takeaway UUID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserCourseTakeawayItem}
     */
    completeUserCourseTakeaway(uuid) {
      return this.completeUserCourseTakeawayWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
