import React from 'react'
import { toast } from 'react-toastify'

import API from 'api/user'
import Button from '@material-ui/core/Button'

const ActivateDevice = ({ t, email, uuid }) => {
  const sendActivationLink = () => {
    API.resendActivationLink({email, uuid})
    .then(() => toast(t("activation_link_is_sent"), { type: toast.TYPE.INFO }))
    .catch(error => toast(t("activation_link_error"), { type: toast.TYPE.ERROR }))
  }
  return (
    <Button
      size='small'
      variant='contained'
      color='secondary'
      className='sa_monitored_data_email_resend'
      onClick={sendActivationLink}
      style={{
        margin: "0 4px",
      }}
    >
      {t("resend_invitation")}
    </Button>
  )      
}

export default ActivateDevice
