import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { getCourseItemById } from "store/selectors/course";
import MDRender from "components/MDRender";

const useStyles = makeStyles(() => ({
  takeawayWrapper: {
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  markdownWrapper: {
    "& ol": {
      listStyle: "decimal",
    },
    "& ul li::before": {
      color: "initial",
    },
  },
}));

const CourseTakeaway = () => {
  const classes = useStyles();
  const { takeawayid } = useParams();
  const takeaway = useSelector((state) => getCourseItemById(state, takeawayid));

  return (
    <Box className={classes.takeawayWrapper}>
      {takeaway.content && (
        <MDRender
          input={takeaway.content}
          classNames={classes.markdownWrapper}
        />
      )}
    </Box>
  );
};

export default CourseTakeaway;