const isMobile = window.innerWidth < 960;
export const getSteps = () => isMobile ? mobileSteps : desktopSteps
export const desktopSteps = [
	{
		step: 1,
		selector: '.tourDesktop1',
		position: 'bottom',
		verticalOffset: 70,
		horizontalOffset: -100,
		arrow: 'topCenter',
		title: 'tour_desktop_title_1',
		main: 'tour_desktop_description_1',
	},
	{
		step: 2,
		position: 'right',
		selector: '.tourDesktop2',
		arrow: 'leftTop',
		title: 'tour_desktop_title_2',
		main: 'tour_desktop_description_2',
	},
	{
		step: 3,
		position: 'right',
		selector: '.tourDesktop3',
		skipNextStep: ['cattolica', 'tua'],
		arrow: 'leftTop',
		title: 'tour_desktop_title_3',
		main: 'tour_desktop_description_3',
	},
	{
		step: 4,
		position: 'right',
		selector: '.tourDesktop4',
		arrow: 'leftTop',
		title: 'tour_desktop_title_4',
		main: 'tour_desktop_description_4',
	},
	{
		step: 5,
		position: 'right',
		selector: '.tourDesktop5',
		arrow: 'leftTop',
		title: 'tour_desktop_title_5',
		main: 'tour_desktop_description_5'
	},
]

export const mobileSteps = [
	{
		step: 1,
		selector: '.tourMobile1',
		position: 'bottom',
		verticalOffset: 10,
		horizontalOffset: -50,
		arrow: 'topCenter',
		title: 'tour_mobile_title_1',
		main: 'tour_mobile_description_1',
	},
	{
		step: 2,
		position: 'bottom',
		selector: '.tourMobile4',
		horizontalOffset: -245,
		verticalOffset: 10,
		arrow: 'topRight6',
		title: 'tour_mobile_title_4',
		main: 'tour_mobile_description_4'
	},
]