import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tippy";
import { ASSESSMENT_SUBMIT_STEP } from "constants/assessmentSteps";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import QuestionHeader from "./QuestionHeader";
import CheckboxGroupQuestion from "./CheckboxGroupQuestion";
import RadioGroupQuestion from "./RadioGroupQuestion";
import ChbxQuestionSubmitButton from "./ChbxQuestionSubtitleButton";

import { SquareButton, BodyText } from "../../components/UI";

import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import { ReactComponent as BackArrowIcon } from "../../assets/icons/arrow-back.svg";
import { ReactComponent as QuestionIcon } from "../../assets/icons/question.svg";
import { useAssessmentStyles } from "./Assessment.styles";

export default function QuestionForm({
  question,
  loading,
  assessment,
  submitQuestion,
  setAssessmentState,
  currentQuestion,
  prevDirection,
  setPrevDirection,
}) {
  const { t } = useTranslation();
  const classes = useAssessmentStyles();
  const questionWrapper = useRef();

  // checkbox start
  const [oppositeCheckbox] = useState({
    Q006: ["A6"],
  });
  const [disabledAllBesides, setDisabledAllBesides] = useState("");
  const [selectedChbxs, setSelectedChbxs] = useState([]);
  // checkbox end

  const isPending =
    assessment.currentStep === ASSESSMENT_SUBMIT_STEP && loading;

  const goToPrevQuestion = () => {
    if (questionWrapper.current) {
      questionWrapper.current.classList.add("doneRight");
    }
    setTimeout(() => {
      setAssessmentState({
        previousQuestion: true,
        currentQuestion: currentQuestion - 1,
      });
      setPrevDirection(true);
    }, 500);
  };

  const handleChange = (evt) => {
    const { value } = evt.target;
    handleSubmit(value);
  };

  const handleChangeCkbx = (evt, currentQuestion) => {
    const { checked } = evt.target;
    const { alias, uuid } = currentQuestion;
    if (
      oppositeCheckbox[question.alias] &&
      oppositeCheckbox[question.alias].indexOf(alias) !== -1
    ) {
      const disabledBesides = checked ? uuid : "";
      const selected = checked ? [uuid] : [];

      setDisabledAllBesides(disabledBesides);
      setSelectedChbxs(selected);
    } else {
      setDisabledAllBesides("");
      const selected = checked
        ? [...selectedChbxs, uuid]
        : selectedChbxs.filter((chbx) => chbx !== uuid);
      setSelectedChbxs(selected);
    }
  };

  const handleSubmit = (uuid) => {
    const values = buildAnswerValues(uuid, question.type);
    let payload = {
      session_uuid: assessment.session_uuid,
      question_uuid: question.uuid,
      values,
    };

    if (questionWrapper.current) {
      questionWrapper.current.classList.add("done");
    }
    setTimeout(() => {
      submitQuestion(payload);
    }, 500);
  };

  const buildAnswerValues = (uuid, type) => {
    // radio value
    if (type === "radio") {
      return [{ uuid, value: uuid }];
    }
    // checkbox value
    return selectedChbxs.map((uuid) => ({ uuid, value: uuid }));
  };

  return (
    <div>
      <form onSubmit={handleSubmit} id={question.alias}>
        <Paper elevation={2} style={{ padding: "24px" }}>
          <Box overflow="hidden">
            <div
              ref={questionWrapper}
              className={`${
                prevDirection
                  ? classes.previousQuestion
                  : classes.questionWrapper
              }`}
              key={question.uuid}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" className={classes.legend}>
                  <Box
                    component="span"
                    className={classes.questionTitleWrapper}
                  >
                    <QuestionHeader question={question} />
                    {!!question.tooltip && (
                      <Tooltip placement="top" title={question.tooltip}>
                        <QuestionIcon className={classes.questionTooltipIcon} />
                      </Tooltip>
                    )}
                  </Box>
                </FormLabel>
                {question.type === "radio" && (
                  <RadioGroupQuestion
                    alias={question.alias}
                    answerVariants={question.answer_variants}
                    handleChange={handleChange}
                  />
                )}
                {question.type === "checkbox" && (
                  <CheckboxGroupQuestion
                    answerVariants={question.answer_variants}
                    handleChange={handleChangeCkbx}
                    disabledAllBesides={disabledAllBesides}
                    selectedChbxs={selectedChbxs}
                  />
                )}
              </FormControl>
            </div>
            <div className={classes.cardFooter}>
              {currentQuestion > 1 && (
                <SquareButton
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={goToPrevQuestion}
                >
                  <BackArrowIcon />
                </SquareButton>
              )}
              {question.type === "checkbox" && (
                <ChbxQuestionSubmitButton
                  disabled={!selectedChbxs.length || isPending}
                  handleSubmit={handleSubmit}
                  isLoading={isPending}
                />
              )}
            </div>
          </Box>
        </Paper>
        <Box className={classes.messageWrapper}>
          <LockIcon />
          <Box ml={1}>
            <BodyText color="inherit">{t("assessment_footer")}</BodyText>
          </Box>
        </Box>
      </form>
    </div>
  );
}
