// ASSESSMENT
import Assessment from "pages/assessment";
// DASHBOARD
import Dashboard from "pages/Dashboard";
// PROTECTED DATA VERIFY
import ProtectedDataVerify from "pages/ProtectedData/Activate";
//DEVICES
import Devices from "pages/Devices";
import Device from "pages/Devices/Device";
import DeviceVerify from "pages/Devices/DeviceVerify";
import DeviceScanning from "pages/Devices/DeviceScanning";
import DeviceLimit from "pages/Devices/DeviceLimit";
import Profile from "pages/Profile";
import { ManageData } from "pages/ManageData";
// TEST FOR MIKE
import HiddenTest from "pages/HiddenTest/HiddenTest";

import Scanning from "pages/Scanning";
// SIGN IN
import SignUp from "pages/SignUp";
import { SignUpTermsAndConditions } from "pages/SignUpTermsAndConditions";
import TermsAndConditions from "../pages/TermsAndConditions";
import SignUpAddCoupon from "pages/SignUp/SignUpAddCoupon";
import CouponFromPath from "pages/CouponFromPath";
import SignUpEmailEdit from "pages/SignUp/SignUpEmailEdit";
import SignUpEmailVerify from "pages/SignUp/SignUpEmailVerify";
import SignUpPlans from "pages/SignUp/Plans";
import PaymentPage from "pages/PaymentPage/PaymentPage";
import SignUpWithPlanFromCms from "pages/SignUp/SignUpWithPlanFromCms";
import { MfaCode } from "pages/MfaCode";
// SIGN UP
import SignIn from "pages/SignIn";
import SignInPasswordForgot from "pages/SignIn/SignInPasswordForgot";
import SignInPasswordReset from "pages/SignIn/SignInPasswordReset";
import SetPassword from "pages/SignIn/SetPassword";
import SignOut from "pages/SignOut";

import Support from "pages/Support";
import TermsAndPrivacy from "pages/TermsAndPrivacy";
import CookiePolicy from "pages/CPolicy";
import StartScan from "pages/StartScan";
import DeleteAccountVerify from "pages/Profile/UserSettings/DeleteAccount/DeleteAccountVerify";
// expired plan
import ExpiredPlanPage from "pages/ExpiredPlanPage";
// 404
import { NotFoundPage } from "pages/NotFoundPage";
// OTHER
import { Todo } from "pages/Todo";
import { TodoDetails } from "pages";
import SignInByToken from "pages/SignIn/SignInByToken/SignInByToken";
import AcceptCrossServiceLink from "pages/SignIn/AcceptCrossServiceLink/AcceptCrossServiceLink";
import { SUPPORTED_LANGUAGES } from "constants/common";
import ActivateUserStaff from "pages/SME/ActivateUserStaff";
import Welcome from "pages/Welcome";

import PoliciesPage from "pages/Policies";
import { PolicyDetail } from "pages/Policies/PolicyDetails/PolicyDetails";

import TrainingPage from "pages/Training";
import CourseOverview from "pages/Training/CourseOverview/CourseOverview";
import { CourseContent } from "pages/Training/CourseContent/CourseContent";

export const ROUTES_NAMES = [
  "register",
  "dashboard",
  "protected-data",
  "assessment",
  "devices",
  "deviceassessment",
  "devicescanning",
  "plans",
  "policies",
  "profile",
  "managedata",
  "welcometodynarisk",
  "account",
  "scanning",
  "coupon",
  "termsandprivacy",
  "support",
  "signin",
  "signup",
  "payment",
  "training",
];
export const PUBLIC_PATH = `/:locale(${SUPPORTED_LANGUAGES})`;

export const routes = {
  HOME: {
    path: PUBLIC_PATH,
    component: SignIn,
  },
  DASHBOARD: {
    path: `${PUBLIC_PATH}/dashboard`,
    component: Dashboard,
    private: true,
  },
  // PROTECTED DATA VERIFY
  PROTECTED_EMAIL_VERIFY: {
    path: `${PUBLIC_PATH}/protected-data/:type/activate/:token?`,
    component: ProtectedDataVerify,
  },
  // ASSESSMENT
  ASSESSMENT: {
    path: `${PUBLIC_PATH}/assessment`,
    component: Assessment,
    private: true,
  },
  // ASSESSMENT SESSION
  ASSESSMENT_SESSION: {
    path: `${PUBLIC_PATH}/assessment/session/:uuid?`,
    component: Assessment,
    private: true,
  },
  // DEVICES
  DEVICES_DEVICE_VERIFY: {
    path: `${PUBLIC_PATH}/devices/activate/:token?`,
    component: DeviceVerify,
  },
  DEVICE_LIMIT: {
    path: `${PUBLIC_PATH}/devices/replace`,
    component: DeviceLimit,
    private: true,
  },
  DEVICES_DEVICE: {
    path: `${PUBLIC_PATH}/devices/:uuid`,
    component: Device,
    private: true,
  },
  DEVICES: {
    path: `${PUBLIC_PATH}/devices`,
    component: Devices,
    private: true,
  },
  DEVICE_ASSESSMENT: {
    path: `${PUBLIC_PATH}/deviceAssessment/:uuid?`,
    component: Assessment,
    private: true,
  },
  DEVICE_SCANNING: {
    path: `${PUBLIC_PATH}/deviceScanning/:uuid?`,
    component: DeviceScanning,
    private: true,
  },
  WELCOME: {
    path: `${PUBLIC_PATH}/welcome`,
    component: Welcome,
    private: true,
  },
  SIGN_UP_PLANS: {
    path: `${PUBLIC_PATH}/plans`,
    component: SignUpPlans,
    private: true,
  },
  POLICIES: {
    path: `${PUBLIC_PATH}/policies`,
    component: PoliciesPage,
    private: true,
  },
  POLICIES_POLICY: {
    path: `${PUBLIC_PATH}/policies/:uuid`,
    component: PolicyDetail,
    private: true,
  },
  PAYMENT: {
    path: `${PUBLIC_PATH}/payment/:plan_slug/:plan_uuid`,
    component: PaymentPage,
    private: true,
  },
  // https://app.dynarisk.com/en_GB/register/ultimate/trial/GB/GBP?ref=consumerplanpage&flow=smooth
  SET_PLAN_FROM_CMS: {
    path: `${PUBLIC_PATH}/register/:plan(standard|advantage|ultimate|family|individual)/:type(paid|trial)/:country/:currency/`,
    component: SignUpWithPlanFromCms,
  },
  PROFILE: {
    absolutePath: `${PUBLIC_PATH}/profile`,
    path: `${PUBLIC_PATH}/profile/:tab(personal-data|plans)`,
    component: Profile,
    private: true,
  },
  MANAGE_DATA: {
    absolutePath: `${PUBLIC_PATH}/manage-data`,
    path: `${PUBLIC_PATH}/manage-data/:activeTab(emails|credit-cards|phone-numbers)`,
    component: ManageData,
    private: true,
  },
  WELCOME_TO_DYNARISK: {
    path: `${PUBLIC_PATH}/start-scan`,
    component: StartScan,
    private: true,
  },
  ACCOUNT_DELETE: {
    path: `${PUBLIC_PATH}/account/delete/:hash`,
    component: DeleteAccountVerify,
  },
  // SIGN IN, SIGN UP
  SCANNING: {
    path: `${PUBLIC_PATH}/scanning`,
    component: Scanning,
    private: true,
  },
  SIGN_UP_ADD_COUPON: {
    path: `${PUBLIC_PATH}/signUp/addCoupon`,
    component: SignUpAddCoupon,
  },
  MFA_CODE: {
    path: `${PUBLIC_PATH}/signin/mfa/:type(email|sms|google_app)`,
    component: MfaCode,
  },
  COUPON_FROM_PATH: {
    path: `${PUBLIC_PATH}/coupon/:couponName`,
    component: CouponFromPath,
  },
  SIGN_UP_EMAIL_EDIT: {
    path: `${PUBLIC_PATH}/signUp/edit-email`,
    component: SignUpEmailEdit,
  },
  SIGN_UP_EMAIL_VERIFY: {
    path: `${PUBLIC_PATH}/signUp/emailVerify/:token?`,
    component: SignUpEmailVerify,
  },
  SIGN_UP: {
    path: `${PUBLIC_PATH}/signUp`,
    component: SignUp,
  },
  SIGN_UP_TERMS_AND_CONDITIONS: {
    path: `${PUBLIC_PATH}/signup/terms-and-conditions`,
    component: SignUpTermsAndConditions,
  },
  SIGN_UP_WITH_COUPON: {
    path: `${PUBLIC_PATH}/signUp/:coupon`,
    component: SignUp,
  },
  SIGN_UP_WITH_COUPON_TEMP: {
    path: `${PUBLIC_PATH}/signUp/coupon/:coupon`,
    component: SignUp,
  },
  SIGN_IN_BY_TOKEN: {
    path: `${PUBLIC_PATH}/signIn/token/:token?`,
    component: SignInByToken,
  },
  SIGN_IN_ACCEPT_CROSS_SERVICE_LINK: {
    path: `${PUBLIC_PATH}/signIn/accept-cross-service-link/:token?`,
    component: AcceptCrossServiceLink,
  },
  SIGN_IN_PASSWORD_FORGOT: {
    path: `${PUBLIC_PATH}/signIn/passwordForgot`,
    component: SignInPasswordForgot,
  },
  SIGN_IN_PASSWORD_RESET: {
    path: `${PUBLIC_PATH}/signIn/passwordReset/:token?`,
    component: SignInPasswordReset,
  },
  SET_PASSWORD: {
    path: `${PUBLIC_PATH}/set-password/:token`,
    component: SetPassword,
  },
  SIGN_IN: {
    path: `${PUBLIC_PATH}/signIn`,
    component: SignIn,
  },
  SIGN_OUT: {
    path: `${PUBLIC_PATH}/signOut`,
    component: SignOut,
  },
  LOG_OUT: {
    path: `${PUBLIC_PATH}/logout`,
    component: SignOut,
  },
  TERMS_AND_PRIVACY: {
    path: `${PUBLIC_PATH}/privacy-policy`,
    component: TermsAndPrivacy,
  },
  TERMS_AND_CONDITIONS: {
    path: `${PUBLIC_PATH}/terms-and-conditions`,
    component: TermsAndConditions,
  },
  // legacy route
  TERMS_AND_CONDITIONS_LEGACY: {
    path: `${PUBLIC_PATH}/termsAndPrivacy`,
    component: TermsAndPrivacy,
  },
  TRAINING: {
    absolutePath: `${PUBLIC_PATH}/training`,
    path: `${PUBLIC_PATH}/training/:activeTab(tasks|certificates)`,
    component: TrainingPage,
    private: true,
  },
  TRAINING_COURSE_OVERVIEW: {
    path: `${PUBLIC_PATH}/training/course-overview/:courseid`,
    component: CourseOverview,
    private: true,
  },
  TRAINING_CHAPTER_LESSON: {
    path: `${PUBLIC_PATH}/training/course/:courseid/lesson/:lessonid`,
    component: CourseContent,
    private: true,
  },
  TRAINING_CHAPTER_QUIZ: {
    path: `${PUBLIC_PATH}/training/course/:courseid/quiz/:quizid`,
    component: CourseContent,
    private: true,
  },
  TRAINING_CHAPTER_TAKEWAY: {
    path: `${PUBLIC_PATH}/training/course/:courseid/takeaway/:takeawayid`,
    component: CourseContent,
    private: true,
  },
  TRAINING_COURSE_FINAL_TAKEAWAY: {
    path: `${PUBLIC_PATH}/training/course/:courseid/takeaway/:takeawayid`,
    component: CourseContent,
    private: true,
  },
  TRAINING_COURSE_FINAL_QUIZ: {
    path: `${PUBLIC_PATH}/training/course/:courseid/final-quiz/:quizid`,
    component: CourseContent,
    private: true,
  },

  TRAINING_COURSE_CERTIFICATE: {
    path: `${PUBLIC_PATH}/training/course/:courseid/certificate`,
    component: CourseContent,
    private: true,
  },
  COOKIE_POLICY: {
    path: `${PUBLIC_PATH}/cookiePolicy`,
    component: CookiePolicy,
  },
  SUPPORT: {
    absolutePath: `${PUBLIC_PATH}/support`,
    path: `${PUBLIC_PATH}/support/:subroute?`,
    component: Support,
    private: true,
  },

  //SME
  // route with typo
  SME_USER_STUFF_ACTIVATE: {
    path: `${PUBLIC_PATH}/account/sme-stuff/activate/:hash?`,
    component: ActivateUserStaff,
  },
  SME_USER_STAFF_ACTIVATE: {
    path: `${PUBLIC_PATH}/account/sme-staff/activate/:hash?`,
    component: ActivateUserStaff,
  },

  EXPIRED_PLAN: {
    path: `${PUBLIC_PATH}/expired-plan/staff`,
    component: ExpiredPlanPage,
  },

  HIDDEN_TEST: {
    path: `${PUBLIC_PATH}/hidden/test/info/me`,
    component: HiddenTest,
    private: true,
  },

  // OTHER
  TODO: {
    path: `${PUBLIC_PATH}/todo`,
    component: Todo,
    private: true,
  },
  TODO_DETAILS: {
    path: `${PUBLIC_PATH}/todo/:uuid`,
    component: TodoDetails,
    private: true,
  },

  // 404
  NOT_FOUND_404_PAGE: {
    path: `${PUBLIC_PATH}/not-found`,
    component: NotFoundPage,
  },

  NOT_FOUND_404: {
    path: `*`,
    component: NotFoundPage,
  },
};
