import { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import userAPI from 'api/user'
import { setIdentity } from 'store/actions/identity'
import { setExpiredPlan } from '../store/actions/plans'
import { Log } from '../services/log/logger'
import { useSelector } from 'react-redux';

export const useIdentity = (dispatch, identity) => {
  const { User } = useContext(AuthContext);
  const flagSettings = useSelector((state) => state.flagSettings)

  const compare = (obj1, obj2) => {
    if ((obj1 === null && obj2 !== null) ||
      (obj2 === null && obj1 !== null)) {
      return false;
    }
    let result = true;
    for (let key in obj1) {
      if (typeof obj1[key] === "object") {
        result = compare(obj1[key], obj2[key]);
      } else {
        result = obj1[key] === obj2[key];
      }
      if (!result) {
        return result;
      }
    }
    return result;
  };

  /*
    Add in future recognise for whitch part is changed,
    so only part of it will be geted from server
  */

  const checkIfPlanExpired = (validTo) => {
    const planValidTo = validTo && Number.isInteger(validTo) ? parseInt(validTo.toString().padEnd(13, '0')) : 0;
    const isExpired = planValidTo < Date.now()
    if (isExpired) {
      dispatch(setExpiredPlan(isExpired))
    }
  }

  const getActiveProfileType = (profiles, profileUuid) => {
    let [activeProfile] = profiles.filter(profile => profile && profile.uuid === profileUuid)
    const activeProfileType = activeProfile && activeProfile.profile_type

    if (!activeProfileType) {
      Log.error("Active profile type not found", {
        profiles,
        profileUuid,
      });
    }
    return activeProfileType;
  }

  const getIdentity = () => {
    userAPI.getJWTAdditionalData().then(({ data }) => {
      let userData = data.user || {};
      // server respond with empty profiles array for short signup flow
      // in this case we need to use profile from user data
      let profiles = data.profiles.length ? data.profiles : [userData.profile];

      // get active profile type
      const activeProfileType = getActiveProfileType(profiles, userData.profile_uuid)
      // check if plan expired;
      // prevent check if plan wasn't selected yet
      if (flagSettings && flagSettings['flag.welcome.shown'] === true) {
        
        checkIfPlanExpired(userData.plan_valid_to)
      }

      let identityData = { ...userData, profiles, activeProfileType };
      if (!compare(identity, identityData)) {
        dispatch(setIdentity(identityData))
      }
    })
      .catch((err) => {});

  }
  useEffect(() => {
    if (User.JWT && !identity) {
      getIdentity()
    }
  }, [User.JWT, identity])
}
