import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import useAuth from "../../hooks/useAuth";
import { useReddirectToDashbordOnLoged } from "../../hooks/authentication/useReddirectToDashbordOnLoged";

import {
  CardTitle,
  BodyText,
  InputField,
  SquareButton,
} from "../../components/UI";
import Page from "../../components/Page";
import Logo from "../../components/Logo";

import { ReactComponent as BackArrowIcon } from "../../assets/icons/arrow-back.svg";
import { toast } from "react-toastify";

const MfaCode = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { emailSupport } = useSelector((state) => state.partnerSettings);
  const { locale, type } = useParams();
  const { state } = useLocation();
  const email = state.email || "";
  const [code, setCode] = useState("");
  const { authWithMfaCode, isLoadingMfa } = useAuth();
  const subtitle =
    type === "email"
      ? t("retail.enter_2fa_code", { email })
      : t("retail.enter_2fa_code_form_sms");

  useReddirectToDashbordOnLoged();

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      await authWithMfaCode(code);
      setCode("");
    } catch (err) {
      if (err) {
        toast(t("retail_invalid_2fa_code"), {
          type: toast.TYPE.ERROR,
        });
        history.push({
          pathname: `/${locale}/signIn`,
          state: { skipAnimation: true },
        });
      }
    }
  };

  const handleChange = (evt) => {
    const value = evt.target.value.toUpperCase();
    setCode(value);
  };

  return (
    <Page name="mfaCode">
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <Box mb={6} textAlign="center">
            <Logo preventRedirection={true} />
          </Box>
          <Paper elevation={2} style={{ maxWidth: "576px", margin: "0 auto" }}>
            <Box p={3}>
              <CardTitle textAlign="center">
                {" "}
                {t("retail.fa_code_required")}
              </CardTitle>
              <BodyText textAlign="center" mb={3}>
                {subtitle}
              </BodyText>
              <form className="-sizeWidthFull" onSubmit={handleSubmit}>
                <Box mb={3}>
                  <InputField
                    name="name"
                    placeholder={t("retail.two_factor_code_here")}
                    type="text"
                    className="sa_signup_addcoupon_code"
                    value={code}
                    onChange={handleChange}
                  />
                </Box>
                <Box display="flex" mx={-1}>
                  <Box mx={1}>
                    <SquareButton
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        history.push({
                          pathname: `/${locale}/signIn`,
                          state: { skipAnimation: true },
                        });
                      }}
                    >
                      <BackArrowIcon />
                    </SquareButton>
                  </Box>
                  <Box mx={1} width="100%">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isLoadingMfa}
                    >
                      {t("login")}
                    </Button>
                  </Box>
                </Box>
                {emailSupport && (
                  <Box mt={3}>
                    <BodyText textAlign="center">
                      <a href={`mailto:${emailSupport}`}>{emailSupport}</a>
                    </BodyText>
                  </Box>
                )}
              </form>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Page>
  );
};

export default MfaCode;
