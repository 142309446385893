import React, { createContext, useContext, useEffect, useState } from 'react'
import qs from "qs"
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import API from 'api/device'
import { actionsStatuses } from 'constants/actionsStatuses'
import { useCyberScoreContect } from '../contexts'


const ActionsContext = createContext()

const ActionsProvider = ({ children }) => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState(null)
  const [actionStatus, changeActionStatus] = useState(null)

  const [actions, setActions] = useState({ items: [], meta: {} })
  const [deviceActions, setDeviceActions] = useState({ items: [], meta: {} })
  const [loading, setLoading] = useState(true)
  const { getRecentCyberScore } = useCyberScoreContect()

  const saveFilters = filters => {
    filters = filters || {}
    setFilters(filters)
  }

  useEffect(() => {
    if (filters) {
      getTasks()
    }
  }, [filters])

  useEffect(() => {
    if (actionStatus) {
      if (actionStatus.remind_value) {
        updateTaskRemindStatus()
      } else {
        setTaskStatus()
      }
    }
  }, [actionStatus])

  const prepareFilters = (filters) => {
    let readyFilters = Object.entries(filters).reduce((prev, [key, value]) => {
      if (value === 'all') {
        return prev
      }
      prev[key] = value
      return prev
    }, {})

    if (readyFilters.status === 'to_be_completed') {
      readyFilters.status = ['to_be_completed', 'overdue', 'postponed']
    }

    if (!Array.isArray(readyFilters.status)) {
      const status = readyFilters.status ? [readyFilters.status] : []
      readyFilters.status = status;
    }
    return readyFilters
  }

  const getTasks = async () => {
    const readyToSendFilters = prepareFilters(filters)
    try {
      setLoading(true)
      const response = await API.getTasks(filters.currentPage, qs.stringify({ filter: readyToSendFilters }))
      setLoading(false)
      if(readyToSendFilters.device_uuid) {
        setDeviceActions({ items: response.data.items, meta: response.data.meta })
        return
      }
      setActions({ items: response.data.items, meta: response.data.meta })
    } catch (err) {
      setLoading(false)
      let status = err.response ? err.response.status : null;
      let name = err.response && err.response.data.data ? err.response.data.data.name : null
      if (status !== 401 || (name !== 'InvalidTokenException' && name !== 'UnauthorizedHttpException')) {
        toast(t("tasks_loading_error"), { type: toast.TYPE.ERROR })
      }
    }
  }

  const setTaskStatus = () => {
    API.updateTaskStatus(actionStatus.uuid, actionStatus.status)
      .then(response => {
        const updatedItem = response.data;
        if ((filters && filters.status && filters.status.indexOf(updatedItem.status) === -1) || actionStatus.status === 'completed') {
          const updatedItems = {};
          updatedItems.items = actions.items.filter(el => el.uuid !== updatedItem.uuid)
          updatedItems.meta = actions.meta;
          if (updatedItem.status === actionsStatuses.completed) {
            updatedItems.meta = {
              ...updatedItems.meta,
              total_items: updatedItems.meta.total_items - 1,
              total_items_unsolved: updatedItems.meta.total_items_unsolved - 1
            }
          }
          setActions(updatedItems)
          if (actionStatus.status === 'completed') {
            toast(t("action_completed_success_msg"), { type: toast.TYPE.SUCCESS })
          }
        }

        // add timeout to get valid score
        setTimeout(() => {
          getRecentCyberScore()
        }, 2000)
      })
      .catch(error => {
        toast(t("set_task_status_error"), { type: toast.TYPE.ERROR })
      })
  }

  const updateTaskRemindStatus = () => {
    API.updateTaskStatus(actionStatus.uuid, actionsStatuses.postponed, actionStatus.remind_value)
      .then(response => {
        const updatedItem = response.data;
        if (updatedItem.status === actionsStatuses.postponed) {
          const updatedItems = {};
          const loc_tem = actions.items.find(item => {
            return actionStatus.uuid === item.uuid
          });
          updatedItems.items = actions.items.filter(item => {
            return actionStatus.uuid !== item.uuid
          })
          loc_tem.status = "postponed";
          updatedItems.items.push(loc_tem);
          updatedItems.meta = actions.meta;
          setActions(updatedItems)
          changeActionStatus(null)
        }
      })
      .catch(error => {
        toast(t("set_remind_status_error"), { type: toast.TYPE.ERROR })
      })
  }

  const defaultContext = {
    actions,
    deviceActions,
    filters,
    changeActionStatus,
    saveFilters,
    actionsLoading: loading
  }

  return (
    <ActionsContext.Provider value={defaultContext}>
      {children}
    </ActionsContext.Provider>
  )
}

const useActionsContext = () => useContext(ActionsContext)

export { ActionsContext, ActionsProvider, useActionsContext }