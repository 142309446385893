import React, { useContext } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SubHeaderContext } from "components/SubHeader/SubHeaderContext";
import { AuthContext } from "../../contexts/AuthContext";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow_down.svg";

const useStyles = makeStyles((theme) => ({
  nameLabel: {
    display: "flex",
    color: theme.variables.$black,

    "& span": {
      fontFamily: "Montserrat,Geometria,sans-serif",
      fontSize: "12px",
      fontWeight: "600",
      lineHeight: "18px",
      textTransform: "uppercase",
    },
  },
  userNameComponent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      display: "block",
      marginRight: "5px",
      marginLeft: "5px",
    },
  },
  userName: {
    color: theme.variables.$black,
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "600",
  },
  withLink: {
    paddingTop: "2px",
    cursor: "pointer",
    "&:hover, &.active": {
      textDecoration: "underline",
      textDecorationThickness: "2px",
    },
    "& i": {
      display: "inline-block",
    },
  },
  opened: {
    transform: "rotate(180deg)",
  },
  shortName: {
    marginRight: theme.variables.$isRTLDirection ? "0" : "20px",
    marginLeft: theme.variables.$isRTLDirection ? "20px": "0",
  },
}));

const UserName = ({
  partnerSettings,
  shortName,
  identity,
  location,
  match,
  history,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { subHeaderContext } = useContext(SubHeaderContext);
  const { User } = useContext(AuthContext);
  const isProfilePage = location.pathname.indexOf("/profile") !== -1;
  const hideProfileSettings = partnerSettings.hideProfileSettings;
  const hideUserName = partnerSettings.hideUserName;

  const toggleProfile = () => {
    if (!isProfilePage) {
      return history.push(`/${match.params.locale}/profile/personal-data`);
    }
    history.goBack();
  };
  return (
    <>
      {subHeaderContext &&
        subHeaderContext.userProfile &&
        subHeaderContext.userProfile.display && (
          <div className={`${classes.userNameComponent} sa_dashboard_profile`}>
            {User.isGuest || !identity || !identity.user ? null : (
              <>
                {partnerSettings.partnerSlug === "cyhunt" ||
                hideProfileSettings ? (
                  <>
                    <div style={{ position: "relative" }}>
                      <div className={`${classes.nameLabel}`}>
                        <span>
                          {identity
                            ? `${identity.user.first_name} ${identity.user.last_name}`
                            : ""}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    onClick={toggleProfile}
                    className={`${classes.nameLabel}
                    ${classes.withLink}
                    ${isProfilePage ? "active" : ""}`}
                  >
                    {shortName ? (
                      <span>{`${identity.user.first_name}`}</span>
                    ) : (
                      <span>
                        
                        {hideUserName ? t("setting") : `${identity.user.first_name} ${identity.user.last_name}`}
                        </span>
                    )}
                    <i
                      className={`${isProfilePage ? classes.opened : ""} ${
                        shortName ? classes.shortName : ""
                      }`}
                    >
                      <ArrowDownIcon width="8px" height="8px" />
                    </i>
                  </div>
                )}
              </>
            )}
          </div>
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  partnerSettings: state.partnerSettings,
  identity: state.identity,
});

export default connect(mapStateToProps)(withRouter(UserName));
