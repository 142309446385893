import React from 'react'
import { useTranslation } from "react-i18next"
import { toast } from 'react-toastify'
import Button from '@material-ui/core/Button'

import API from 'api/user'

const VerificationEmailResend = () => {
  const { t } = useTranslation()
  const email = sessionStorage.getItem('userEmail');

  const resendEmail = () => {
    API.resendUserActivationLink({ email })
      .then(() => toast(t("verification_is_resent", { email }), { type: toast.TYPE.INFO }))
      .catch(() => toast(t("can_t_resend_email"), { type: toast.TYPE.ERROR }))
  }

  return (
    <Button
      fullWidth
      variant='contained'
      color='primary'
      onClick={resendEmail}
      className='sa_signup_emailverify_resend_email'>
      {t("resend_activation_email")}
    </Button>
  )
}

export default VerificationEmailResend

