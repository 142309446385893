/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CourseSettingSettingCreateResponse model module.
 * @module model/CourseSettingSettingCreateResponse
 * @version 1.0.0
 */
class CourseSettingSettingCreateResponse {
    /**
     * Constructs a new <code>CourseSettingSettingCreateResponse</code>.
     * @alias module:model/CourseSettingSettingCreateResponse
     * @param completionDate {Number} 
     * @param courseUuid {String} 
     * @param createdAt {Number} 
     * @param notificationContent {String} 
     * @param notificationSubject {String} 
     * @param smeOwnerUuid {String} 
     * @param startDate {Number} 
     * @param uuid {String} 
     */
    constructor(completionDate, courseUuid, createdAt, notificationContent, notificationSubject, smeOwnerUuid, startDate, uuid) { 
        
        CourseSettingSettingCreateResponse.initialize(this, completionDate, courseUuid, createdAt, notificationContent, notificationSubject, smeOwnerUuid, startDate, uuid);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, completionDate, courseUuid, createdAt, notificationContent, notificationSubject, smeOwnerUuid, startDate, uuid) { 
        obj['completion_date'] = completionDate;
        obj['course_uuid'] = courseUuid;
        obj['created_at'] = createdAt;
        obj['notification_content'] = notificationContent;
        obj['notification_subject'] = notificationSubject;
        obj['sme_owner_uuid'] = smeOwnerUuid;
        obj['start_date'] = startDate;
        obj['uuid'] = uuid;
    }

    /**
     * Constructs a <code>CourseSettingSettingCreateResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CourseSettingSettingCreateResponse} obj Optional instance to populate.
     * @return {module:model/CourseSettingSettingCreateResponse} The populated <code>CourseSettingSettingCreateResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CourseSettingSettingCreateResponse();

            if (data.hasOwnProperty('completion_date')) {
                obj['completion_date'] = ApiClient.convertToType(data['completion_date'], 'Number');
            }
            if (data.hasOwnProperty('course_uuid')) {
                obj['course_uuid'] = ApiClient.convertToType(data['course_uuid'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Number');
            }
            if (data.hasOwnProperty('notification_content')) {
                obj['notification_content'] = ApiClient.convertToType(data['notification_content'], 'String');
            }
            if (data.hasOwnProperty('notification_subject')) {
                obj['notification_subject'] = ApiClient.convertToType(data['notification_subject'], 'String');
            }
            if (data.hasOwnProperty('sme_owner_uuid')) {
                obj['sme_owner_uuid'] = ApiClient.convertToType(data['sme_owner_uuid'], 'String');
            }
            if (data.hasOwnProperty('start_date')) {
                obj['start_date'] = ApiClient.convertToType(data['start_date'], 'Number');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Number');
            }
            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CourseSettingSettingCreateResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CourseSettingSettingCreateResponse</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CourseSettingSettingCreateResponse.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['course_uuid'] && !(typeof data['course_uuid'] === 'string' || data['course_uuid'] instanceof String)) {
            throw new Error("Expected the field `course_uuid` to be a primitive type in the JSON string but got " + data['course_uuid']);
        }
        // ensure the json data is a string
        if (data['notification_content'] && !(typeof data['notification_content'] === 'string' || data['notification_content'] instanceof String)) {
            throw new Error("Expected the field `notification_content` to be a primitive type in the JSON string but got " + data['notification_content']);
        }
        // ensure the json data is a string
        if (data['notification_subject'] && !(typeof data['notification_subject'] === 'string' || data['notification_subject'] instanceof String)) {
            throw new Error("Expected the field `notification_subject` to be a primitive type in the JSON string but got " + data['notification_subject']);
        }
        // ensure the json data is a string
        if (data['sme_owner_uuid'] && !(typeof data['sme_owner_uuid'] === 'string' || data['sme_owner_uuid'] instanceof String)) {
            throw new Error("Expected the field `sme_owner_uuid` to be a primitive type in the JSON string but got " + data['sme_owner_uuid']);
        }
        // ensure the json data is a string
        if (data['uuid'] && !(typeof data['uuid'] === 'string' || data['uuid'] instanceof String)) {
            throw new Error("Expected the field `uuid` to be a primitive type in the JSON string but got " + data['uuid']);
        }

        return true;
    }


}

CourseSettingSettingCreateResponse.RequiredProperties = ["completion_date", "course_uuid", "created_at", "notification_content", "notification_subject", "sme_owner_uuid", "start_date", "uuid"];

/**
 * @member {Number} completion_date
 */
CourseSettingSettingCreateResponse.prototype['completion_date'] = undefined;

/**
 * @member {String} course_uuid
 */
CourseSettingSettingCreateResponse.prototype['course_uuid'] = undefined;

/**
 * @member {Number} created_at
 */
CourseSettingSettingCreateResponse.prototype['created_at'] = undefined;

/**
 * @member {String} notification_content
 */
CourseSettingSettingCreateResponse.prototype['notification_content'] = undefined;

/**
 * @member {String} notification_subject
 */
CourseSettingSettingCreateResponse.prototype['notification_subject'] = undefined;

/**
 * @member {String} sme_owner_uuid
 */
CourseSettingSettingCreateResponse.prototype['sme_owner_uuid'] = undefined;

/**
 * @member {Number} start_date
 */
CourseSettingSettingCreateResponse.prototype['start_date'] = undefined;

/**
 * @member {Number} updated_at
 */
CourseSettingSettingCreateResponse.prototype['updated_at'] = undefined;

/**
 * @member {String} uuid
 */
CourseSettingSettingCreateResponse.prototype['uuid'] = undefined;






export default CourseSettingSettingCreateResponse;

