import React, { useState } from 'react'
import { toast } from 'react-toastify'
import API from 'api/user'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import { InputField } from '../../../../../components/UI'

const DeleteAccountForm = ({ deleteAccountConfirmation, t, hash, loading }) => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleOnSubmit = async (evt) => {
    evt.preventDefault()
    try {
      await API.checkPassword({ hash, password })
      deleteAccountConfirmation()
    } catch (error) {
      if (error.response.status === 422) {
        return setError(t("wrong_password"))
      }
      toast(t("server_error"), { type: toast.TYPE.ERROR })
    }
  }

  return (
    <form className='-sizeWidthFull' onSubmit={handleOnSubmit}>
      <Box mb={3}>
        <InputField
          fullWidth
          name='password'
          placeholder={t("password")}
          type='password'
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          className='sa_account_delete_password'
          error={error}
        />
      </Box>
      <Box textAlign='center' width="100%">
        <Button
          fullWidth
          type="submit"
          variant='contained'
          color='primary'
          disabled={!password || loading}
          className='sa_account_delete_delete_account'
        >
          {loading
            ? <CircularProgress size='14px' color='inherit' />
            : t("delete_account")
          }
        </Button>
      </Box>
    </form>
  )
}

export default DeleteAccountForm

