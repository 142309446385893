import { useEffect } from "react";
import { useSelector } from "react-redux";
import softwareAPI from "api/software";
import SRS, { browserMap } from "../helpers/systemResourcesScanner";

import { NULLABLE_DEVICE_ID } from "../constants/common";

export const useSoftwareMerge = () => {
  const identity = useSelector((state) => state.identity);

  useEffect(() => {
    if (
      identity &&
      identity.device &&
      identity.device_uuid !== NULLABLE_DEVICE_ID
    ) {
      const { meta } = SRS.run({});
      const product = meta.browser.product
        ? browserMap[meta.browser.product.toLowerCase()]
        : null;
      const data = {
        slug: meta.os.product.split("_")[0].toUpperCase(),
        version: meta.os.version,
        build: null,
        model: null,
        browsers: [
          {
            slug: product,
            version: meta.browser.version,
          },
        ],
      };
      softwareAPI.softwareMerge(data).catch(() => {});
    }
  }, [identity]);
};
