import React from "react";
import ReactMarkdown from "react-markdown";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mdWrapper: {
    all: "unset",
    fontFamily: "Montserrat, sans-serif",
    color: "black",
    listStyleType: "disc",
    listStyle: "inside",
    // In case secondary color is dark color. WhyItIsNessessary component.
    "&.secondary *": {
      color: theme.variables.$secondaryTextColor,
    },
    "& *": {
      lineHeight: "1.5em",
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
      fontSize: "1rem",
      fontWeight: "bold",
    },
    "& ol": {
      listStyle: "inside",
      margin: "1.5rem 0",
    },
    "& ul": {
      listStyle: "none",
      margin: "1.5rem 0",
      marginLeft: "1rem",
      "& li::before": {
        content: '"\\2022"',
        color: theme.variables.$red100,
        fontWeight: "bold",
        display: "inline-block",
        width: "1.5em",
        marginLeft: "-1em",
      },
    },
    "& table": {
      borderCollapse: "unset",
      borderSpacing: "initial",
    },
    "& tr": {
      borderTop: "1px solid #c6cbd1",
      background: "#fff",
    },
    "& th": {
      padding: "6px 13px",
      border: "1px solid #dfe2e5",
    },
    "& td": {
      padding: "6px 13px",
      border: "1px solid #dfe2e5",
    },

    "& pre": {
      border: "1px solid #ccc",
      overflow: "auto",
    },

    "& code": {
      fontFamily: "monospace, monospace",
      fontSize: "1em",
    },
    "& kbd": {
      fontFamily: "monospace, monospace",
      fontSize: "1em",
    },
    "& pre": {
      fontFamily: "monospace, monospace",
      fontSize: "1em",
    },
    "& samp": {
      fontFamily: "monospace, monospace",
      fontSize: "1em",
    },

    "& blockquote": {
      color: "#666",
      margin: "0",
      paddingДeft: "3em",
      borderДeft: "0.5em #eee solid",
    },

    "& .language-js": {
      display: "block",
      overflowX: "auto",
      padding: "0.5em",
      color: "#333",
      background: "#f8f8f8",
    },
    "& h1": {
      margin: ".67em 0",
      fontWeight: 600,
      fontSize: "2em",
    },
    "& h2": {
      margin: ".67em 0",
      fontWeight: 600,
      fontSize: "1.5em",
    },
  },
}));

const MDRender = (props) => {
  const classes = useStyles();
  const { input, escapeHtml = true, textColor = "" , classNames} = props;

  return (
    <div className={`${classes.mdWrapper} ${textColor} ${classNames}`}>
      <ReactMarkdown
        children={input}
        skipHtml={escapeHtml}
      />
    </div>
  );
};

export default MDRender;
