import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import PlaceholderBox from "components/ContentPlaceholder/PlaceholderBox";
import { ReactComponent as IconArrowBack } from "../../assets/icons/arrow-back.svg";

const useStyles = makeStyles((theme) => ({
  taskContentPlaceholder: {
    padding: 0,
    height: "100%",
    width: "100%",
    overflow: "hidden",
    border: 0,
    borderRadius: 0,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px",
    paddingRight: theme.variables.$isRTLDirection ? 0 : "16px",
    paddingLeft: theme.variables.$isRTLDirection ? "16px" : 0,
    height: "60px",
    borderBottom: `1px solid ${theme.variables.$dividerLightGray}`,
  },
  backBtnWrapper: {
    width: "50px",
    margin: 0,
    marginRight: theme.variables.$isRTLDirection ? 0 : "16px",
    marginLeft: theme.variables.$isRTLDirection ? "16px" : 0,
    position: "relative",
  },
  backBtn: {
    cursor: "pointer",
    position: "absolute",
    border: 0,
    background: "transparent",
    display: "block",
    height: "60px",
    top: 0,
    width: "50px",
    color: theme.variables.$darkGray,
    "& svg": {
      transform: theme.variables.$isRTLDirection ? "scaleX(-1)" : "unset",
    },
  },
  greyText: {
    color: theme.variables.$textDarkGray,
  },
  contentHeader: {
    marginBottom: "30px",
    fontSize: "32px",
  },
  placeholderWithStep: {
    flexBasis: "50px",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    marginTop: "24px",
  },
  placeholderStep: {
    width: "1rem",
    marginRight: theme.variables.$isRTLDirection ? 0 : "16px",
    marginLeft: theme.variables.$isRTLDirection ? "16px" : 0,
  },
  placeholderLine: {
    flexBasis: "50px",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
  },
  placeholderLineContentWrapper: {
    marginLeft: theme.variables.$isRTLDirection ? 0 : "32px",
    marginRight: theme.variables.$isRTLDirection ? "32px" : 0,
    width: "100%",
  },
  taskFooter: {
    flexDirection: theme.variables.$isRTLDirection ? "row-reverse" : "row",
    flexBasis: "50px",
    flexShrink: 0,
    borderTop: `1px solid ${theme.variables.$dividerLightGray}`,
    display: "flex",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "50px",
    background: theme.variables.$white,
  },
}));

export default function TaskContentSkeleton() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { locale } = useParams();
  const history = useHistory();
  return (
    <Paper
      style={{
        padding: 0,
        border: 0,
        borderRadius: 0,
        height: "100vh",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div className={classes.taskContentPlaceholder}>
        <div className={classes.title}>
          <div className={classes.backBtnWrapper}>
            <PlaceholderBox width="50px" mb={0} height="60px" />
            <button
              className={classes.backBtn}
              onClick={() => history.push(`/${locale}/todo`)}
            >
              <IconArrowBack />
            </button>
          </div>
          <div style={{ width: "40px" }}>
            <PlaceholderBox type="circle" size="40px" shrink={0} mb={0} />
          </div>
          <PlaceholderBox height="1rem" width="100%" mr="5px" ml="5px" mb="0" />
          <div style={{ width: "100%" }} />
          <div style={{ width: "150px" }}>
            <PlaceholderBox
              height="2rem"
              width="150px"
              shrink={0}
              mr="0"
              mb="0"
            />
          </div>
        </div>
        <div style={{ padding: "16px" }}>
          <div className={`${classes.contentHeader} ${classes.greyText}`}>
            {t("instructions_header")}
          </div>
          <div style={{ width: "100%" }}>
            <PlaceholderBox
              height="1rem"
              width="100%"
              shrink={0}
              mr="0"
              mb="16px"
            />
            <PlaceholderBox
              height="1rem"
              width="100%"
              shrink={0}
              mr="0"
              mb="24px"
            />
          </div>
          <h3 className={classes.greyText}>{t("steps")}</h3>
          {[1, 2, 3].map((item) => {
            return (
              <div key={item}>
                <div className={classes.placeholderWithStep}>
                  <div className={classes.placeholderStep}>
                    <PlaceholderBox height="1rem" width="1rem" ml="0" mb="0" />
                  </div>
                  <PlaceholderBox height="1rem" width="100%" ml="0" mb="0" />
                </div>
                <div className={classes.placeholderLine}>
                  <div className={classes.placeholderLineContentWrapper}>
                    <PlaceholderBox
                      height="1rem"
                      width="100%"
                      ml="0"
                      mb="16px"
                    />
                    <PlaceholderBox
                      height="1rem"
                      width="100%"
                      ml="0"
                      mb="16px"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.taskFooter}>
        <div style={{ width: "1rem", margin: "0 16px" }}>
          <PlaceholderBox height="1rem" width="1rem" ml="5px" mb="0" />
        </div>
        <PlaceholderBox height="1rem" width="40%" ml="5px" mb="0" />
      </div>
    </Paper>
  );
}
