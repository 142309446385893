import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  flipBox: {
    perspective: 1000,
    height: '100%',
    position: 'relative',
    '&.-active': {
      '& $back': {
        visibility: 'visible',
        transform: 'rotateX(0deg)',
        zIndex: 10,

        '&> *': {
          opacity: 1
        }
      },

      '& $front': {
        transform: 'rotateX(180deg)',
        zIndex: -1,
        

        '&> *': {
          opacity: 0,
          transition: 'opacity 0ms 200ms'
        }
      }
    }
  },
  card: {
    position: 'relative',
    height: '100%',
    transition: 'all 1000ms cubic-bezier(.5,1.3,.5,1.3)',
    transformStyle: 'preserve-3d',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    background: '#fff',
  },
  back: {
    visibility: 'hidden',
    position: 'absolute',
    left: '0',
    top: '0',
    bottom: '0',
    width: '100%',
    zIndex: '-1',
    transform: 'rotateX(-180deg)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&> *': {
      opacity: '0',
      transition: 'opacity 0ms 200ms',
    }
  },

  front: {
    zIndex: 10,
  }
}))

const Flipcard = ({ className, toggle, back, children }) => {
  const classes = useStyles()
  return (
    <div className={`${classes.flipBox} ${toggle ? '-active' : ''} ${className}`}>
      <div className={`${classes.card} ${classes.front}`}>
        {children}
      </div>
      <div className={`${classes.card} ${classes.back}`}>
        {back}
      </div>
    </div>
  )
}


export default Flipcard


