import MuiIconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'


export default withStyles((theme) => ({
  root: {
    width: '20px',
    height: '20px',
    padding: '4px'
  }
}))(MuiIconButton)
