import { makeStyles } from "@material-ui/core/styles";

export const usePageStyles = makeStyles((theme) => ({
  page: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "100%",
    marginLeft: "auto",
  },
  simplePage: {
    "& .customPartnerHeader": {
      "&.w200": {
        "& img": {
          width: "200px",
        },
      },
    },
    "& .customPartnerFooter": {
      "&.w200": {
        "& img": {
          width: "200px",
        },
      },
    },
  },
  simplePageContainer: {
    height: "100%",
    width: "100%",
    flexGrow: 1,
    // maxWidth: "980px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "30px 0",
    overflow: "hidden",
    "@media (max-width: 600px)": {
      padding: "30px 15px",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "60px",
    },
  },
  pageContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    maxWidth: "980px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  contentWrapper: {
    padding: "0 20px",
  },
  pageToast: {
    position: "fixed",
    left: 0,
    right: 0,
    zIndex: 20,
    color: "#fff",
    padding: "8px 16px",
    background: theme.palette.primary.main,
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&.collapse": {
      animation: "$collapse 1s",
      animationFillMode: "forwards",
      height: "0px",
      overflow: "hidden",
    },
  },
  pageFooter: {
    flex: "none",
    marginTop: "auto",
  },
  footerContentWrapper: {
    margin: "20px",
  },
  "@keyframes collapse": {
    "0%": {
      height: "40px",
      padding: "8px 16px",
    },
    "100%": {
      height: "0px",
      padding: 0,
    },
  },
}));