import { makeStyles } from '@material-ui/core/styles';


export const useTourStyles = makeStyles(theme => ({
  tour: {
    overflow: 'hidden',
    '& .react-user-tour-container > div': {
      backgroundColor: `${theme.palette.primary.main}`,
      width: '285px',
      position: 'absolute',
      zIndex: 2100,
      borderRadius: '4px',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 6px 8px 0px',
    }
  },
  tourOverlay: {
    backgroundColor: `rgba(0, 0, 0, .1)`,
    zIndex: 2000,
    position: 'fixed',
    width: '100%',
    height: '100%',
  },
  tourTextBox: {
    color: theme.palette.primary.contrastText
  },
  tourFooter: {
    display: "flex",
    background: theme.palette.primary.main,
    padding: "5px 15px",
    justifyContent: "space-between",
    borderRadius: "0 0 4px 4px",
  },
  stepContent: {
    '&:before': {
      content: "''",
      width: 0,
      height: 0,
      position: 'absolute',
      borderTop: "6px solid transparent",
      borderBottom: "6px solid transparent",
      borderRight: `9px solid ${theme.palette.primary.main}`,
    },
    '&.topCenter:before': {
      top: "-10px",
      transform: "rotate(90deg) translateY(50%)",
      left: "50%",
    },
    '&.toLeft20:before': {
      top: "-10px",
      transform: "rotate(90deg) translateY(50%)",
      left: "20%",
    },
    '&.topRight20:before': {
      top: "-10px",
      transform: "rotate(90deg) translateY(50%)",
      right: "20%",
    },
    '&.topRight6:before': {
      top: "-10px",
      transform: "rotate(90deg) translateY(50%)",
      right: "6%",
    },
    '&.leftTop:before': {
      top: "15px",
      left: "-9px",
    },
  },
}))