import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  page404: {
    minHeight: '100%',
    boxSizing: 'border-box',
    height: '100%',
    width: '100%',
    backgroundColor: theme.variables.$black,
    backgroundImage: 'radial-gradient(#11581E, #041607)',
    fontFamily: 'Inconsolata, Helvetica, sans-serif',
    fontSize: '1.5rem',
    color: 'rgba(128, 255, 128, 0.8)',
    textShadow: '0 0 1ex #33ff33, 0 0 2px rgba(255, 255, 255, 0.8)',
  },
  overlay: {
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'repeating-linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0) 100%)',
    backgroundSize: 'auto 4px',
    zIndex: 99,
    '&::before': {
      content: '" "',
      pointerEvents: 'none',
      position: 'absolute',
      display: 'block',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      // backgroundImage: 'linear-gradient(0deg, transparent 0%, rgba(32, 128, 32, 0.2) 2%, rgba(32, 128, 32, 0.8) 3%, rgba(32, 128, 32, 0.2) 3%, transparent 100%)',
      backgroundRepeat: 'no-repeat',
      '-webkit-animation': 'scan 7.5s linear 0s infinite',
      animation: 'scan 7.5s linear 0s infinite',
    }
  },
  terminal: {
    height: '100%',
    width: '100%',
    padding: '4rem',
    textTransform: 'uppercase',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  output: {
    marginTop: '20px',
    color: 'rgba(128, 255, 128, 0.8)',
    textShadow: '0 0 1px rgba(51, 255, 51, 0.4), 0 0 2px rgba(255, 255, 255, 0.8)',
    '&::before': {
      content: '"> "',
    },
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      '&::before': {
        content: '"["',
      },
      '&::after': {
        content: '"]"',
      }
    }
  },
  errorcode: {
    color: '#fff',
  },
  "@keyframes scan": {
    '0%': {
      backgroundPosition: '0 -100vh'
    },
    '35%': {
      backgroundPosition: '0 100vh'
    },
    '100%': {
      backgroundPosition: '0 100vh'
    }
  }
}))

const NotFoundPage = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.page404}>
      <div className={classes.overlay}></div>
      <div className={classes.terminal}>
        <h1>{t("error")}&nbsp;<span className={classes.errorcode}>404</span></h1>
        <p className={classes.output}>{t("page_might_have_been_removed")}</p>
        <p className={classes.output}>{t("please_try")}&nbsp;
          <Link to='/dashboard'>{t("this_link")}</Link>&nbsp;
          {t("or")}&nbsp;
          <Link to='/'>{t("this_link")}</Link>
        </p>
        <p className={classes.output}>{t("good_luck")}</p>
      </div>
    </div>
  )
}

export default NotFoundPage;

