/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CxHttpViolation from './CxHttpViolation';

/**
 * The CxHttpValidationError model module.
 * @module model/CxHttpValidationError
 * @version 1.0.0
 */
class CxHttpValidationError {
    /**
     * Constructs a new <code>CxHttpValidationError</code>.
     * @alias module:model/CxHttpValidationError
     * @param detail {String} 
     * @param title {String} 
     * @param type {String} 
     * @param violations {Array.<module:model/CxHttpViolation>} 
     */
    constructor(detail, title, type, violations) { 
        
        CxHttpValidationError.initialize(this, detail, title, type, violations);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, detail, title, type, violations) { 
        obj['detail'] = detail;
        obj['title'] = title;
        obj['type'] = type;
        obj['violations'] = violations;
    }

    /**
     * Constructs a <code>CxHttpValidationError</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CxHttpValidationError} obj Optional instance to populate.
     * @return {module:model/CxHttpValidationError} The populated <code>CxHttpValidationError</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CxHttpValidationError();

            if (data.hasOwnProperty('detail')) {
                obj['detail'] = ApiClient.convertToType(data['detail'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('violations')) {
                obj['violations'] = ApiClient.convertToType(data['violations'], [CxHttpViolation]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CxHttpValidationError</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CxHttpValidationError</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CxHttpValidationError.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['detail'] && !(typeof data['detail'] === 'string' || data['detail'] instanceof String)) {
            throw new Error("Expected the field `detail` to be a primitive type in the JSON string but got " + data['detail']);
        }
        // ensure the json data is a string
        if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
            throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
        }
        // ensure the json data is a string
        if (data['type'] && !(typeof data['type'] === 'string' || data['type'] instanceof String)) {
            throw new Error("Expected the field `type` to be a primitive type in the JSON string but got " + data['type']);
        }
        if (data['violations']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['violations'])) {
                throw new Error("Expected the field `violations` to be an array in the JSON data but got " + data['violations']);
            }
            // validate the optional field `violations` (array)
            for (const item of data['violations']) {
                CxHttpViolation.validateJSON(item);
            };
        }

        return true;
    }


}

CxHttpValidationError.RequiredProperties = ["detail", "title", "type", "violations"];

/**
 * @member {String} detail
 */
CxHttpValidationError.prototype['detail'] = undefined;

/**
 * @member {String} title
 */
CxHttpValidationError.prototype['title'] = undefined;

/**
 * @member {String} type
 */
CxHttpValidationError.prototype['type'] = undefined;

/**
 * @member {Array.<module:model/CxHttpViolation>} violations
 */
CxHttpValidationError.prototype['violations'] = undefined;






export default CxHttpValidationError;

