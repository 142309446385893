/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CxHttpException from '../model/CxHttpException';
import CxHttpValidationError from '../model/CxHttpValidationError';
import QuestionResponseList from '../model/QuestionResponseList';

/**
* ViewQuestionsWithAnswers service.
* @module api/ViewQuestionsWithAnswersApi
* @version 1.0.0
*/
export default class ViewQuestionsWithAnswersApi {

    /**
    * Constructs a new ViewQuestionsWithAnswersApi. 
    * @alias module:api/ViewQuestionsWithAnswersApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * View questions with answers
     * Get list of questions by quiz UUID.
     * @param {String} quizUuid Quiz UUID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/QuestionResponseList} and HTTP response
     */
    viewQuestionsWithAnswersWithHttpInfo(quizUuid) {
      let postBody = null;
      // verify the required parameter 'quizUuid' is set
      if (quizUuid === undefined || quizUuid === null) {
        throw new Error("Missing the required parameter 'quizUuid' when calling viewQuestionsWithAnswers");
      }

      let pathParams = {
        'quiz_uuid': quizUuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = QuestionResponseList;
      return this.apiClient.callApi(
        '/api/v1/quizzes/{quiz_uuid}/questions/answers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * View questions with answers
     * Get list of questions by quiz UUID.
     * @param {String} quizUuid Quiz UUID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/QuestionResponseList}
     */
    viewQuestionsWithAnswers(quizUuid) {
      return this.viewQuestionsWithAnswersWithHttpInfo(quizUuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
