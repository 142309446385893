import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import API from "api/crossservice";
import { setPhonePrefixes } from "store/actions/phonePrefixes";
import {
  countriesPhoneCodes,
  cyhuntPhoneCodes,
  gisPhoneCodes,
} from "../constants/countriesPhoneCodes";

export const usePhonePrefixes = () => {
  const dispatch = useDispatch();
  const phonePrefixes = useSelector((state) => state.phonePrefixes);
  const partnerSettings = useSelector((state) => state.partnerSettings);

  const partner = partnerSettings && partnerSettings.partnerSlug;
  const getPhonesPrefixes = () => {
    const partnerFromSession = window.sessionStorage.getItem("partner");
    // in the future server will send correct countries codes for every partner
    if (partner === "cyhunt") {
      return dispatch(setPhonePrefixes(cyhuntPhoneCodes));
    }
    if (partnerFromSession && partnerFromSession === "gis") {
      window.sessionStorage.removeItem("partner");
      return dispatch(setPhonePrefixes(gisPhoneCodes));
    }
    API.getPhonesPrefixes(partner)
      .then((response) => {
        if (partnerSettings.diallingCode) {
          const index = response.data.findIndex(
            (country) => country.value === partnerSettings.diallingCode
          );
          if (index !== -1) {
            const [country] = response.data.splice(index, 1);
            response.data.unshift(country);
          }
        }
        dispatch(setPhonePrefixes(response.data));
      })
      .catch(() => {
        dispatch(setPhonePrefixes(countriesPhoneCodes));
      });
  };

  useEffect(() => {
    if (partner && !phonePrefixes) {
      getPhonesPrefixes();
    }
  }, [partner]);
};
