import React from "react";
import ChangePassword from "./ChangePassword";
import DeleteAccount from "./DeleteAccount";
import PersonalInformation from "./PersonalInformation";
import { PROFILE_TYPE_RETAIL } from "constants/common";
import UserSettingsSkeleton from "./UserSettingsSkeleton";

const UserSettings = ({ t, partnerSettings, identity }) => {
  const isCrossServicePartner = partnerSettings.isCrossServicePartner;
  const disableDeleteAccount = partnerSettings.hideAccountDeleteOption;
  const hidePersonalInfoSettings = partnerSettings.hideProfileSettings;
  return !identity ||
    (identity &&
      !identity.user &&
      !identity.user.first_name &&
      !identity.user.last_name) ? (
    <UserSettingsSkeleton />
  ) : (
    <>
      {!!hidePersonalInfoSettings && <PersonalInformation />}
      {!isCrossServicePartner && (
        <>
          <ChangePassword />
          {identity.profile &&
            identity.profile.profile_type.toUpperCase() ===
              PROFILE_TYPE_RETAIL &&
            !disableDeleteAccount && <DeleteAccount t={t} />}
        </>
      )}
    </>
  );
};

export default UserSettings;
