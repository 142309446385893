import React from 'react'
import { connect } from 'react-redux'

const HiddenTest = ({ identity }) => {
  const user = identity.user
  return (
    <>
      {user &&
        <>
          <div id="user_id" data-id={user.uuid}>{user.uuid}</div>
          <div id="user_email" data-email={user.email}>{user.email}</div>
        </>
      }
    </>
  )
}

const mapStateToProps = state => ({
  identity: state.identity,
})

export default connect(mapStateToProps)(HiddenTest)