import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Logo from "components/Logo";
import Navigation from "components/Navigation";
import PoweredByDynarisk from "components/PoweredByDynarisk/PoweredByDynarisk";
import SpringScrollbars from "../../pages/SignUp/SpringScrollbars";
import {
  DEFAULT_PARTNER_NAME,
  MANAGE_DATA_DISABLE_PARTNERS,
} from "constants/common";
import { useIdentityFeature } from "hooks/useIdentiyFeature";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import MDRender from "../MDRender";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    minWidth: "270px",
    minHeight: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  sidebarComponent: {
    position: "fixed",
    minWidth: "270px",
    height: "100%",
    minHeight: "100%",
    maxHeight: "100%",
    backgroundColor: theme.variables.$sidebarBgColor,
    borderRight: theme.variables.$isRTLDirection ? 0 : `1px solid ${theme.variables.$dividerLightGray}`,
    borderLeft: theme.variables.$isRTLDirection ? `1px solid ${theme.variables.$dividerLightGray}` : 0,
    "&.staff": {
      backgroundColor: theme.variables.$black,
    },
    '& .scrollView': {
      marginRight: theme.variables.$isRTLDirection ? "0!important" : "-14px!important",
      marginLeft: theme.variables.$isRTLDirection ? "-14px!important" : "0!important",
    }
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "30px",
    paddingLeft: "30px",
    height: "100%",

    "&.-opened": {
      width: "275px",
      minWidth: "275px",
      transition: "width 0.3s linear, min-width .3s linear",
    },
  },

  btns: {
    "&:after": {
      content: '""',
      display: "block",
      marginTop: "20px",
      borderBottom: `1px solid ${theme.variables.$dividerLightGray}`,
    },
    "&:before": {
      content: '""',
      display: "block",
      borderBottom: `1px solid ${theme.variables.$dividerLightGray}`,
      marginTop: "0px",
      marginBottom: "20px",
    },
  },
  customSidebarFooter: {
    marginBottom: "24px",
    "& > div": {
      textAlign: "center",
    },
    
  },
}));

const Sidebar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { locale } = useParams();
  const identity = useSelector((state) => state.identity);
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const {
    sidebarFooterCustom,
    logoPoweredByPosition,
    logoPoweredByUrl,
    isShowPoweredBy,
  } = partnerSettings;

  const maxDevicesFeature = useIdentityFeature(identity, "max_devices");

  return (
    <aside className={classes.sidebar}>
      <div className={classes.sidebarComponent}>
        <SpringScrollbars renderView={props => <div {...props} className="scrollView"/>}>
          <div className={classes.wrapper}>
            <Box my={4}>
              <Logo redirectTo="dashboard" center={true} />
            </Box>
            {maxDevicesFeature && (
              <div className={classes.btns}>
                {partnerSettings &&
                  MANAGE_DATA_DISABLE_PARTNERS.indexOf(
                    partnerSettings.partnerSlug
                  ) === -1 && (
                    <Box mb={2}>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          history.push(`/${locale}/manage-data/emails`)
                        }
                      >
                        {`+ ${t("sidebar.manage_data")}`}
                      </Button>
                    </Box>
                  )}
              </div>
            )}
            <Navigation />
            <Box
              // justifyContent="space-between"
              display="flex"
              flexDirection="column"
              height="100%"
            >
              {partnerSettings &&
                logoPoweredByPosition === "top" &&
                isShowPoweredBy &&
                partnerSettings.partnerSlug !== DEFAULT_PARTNER_NAME &&
                partnerSettings.partnerSlug !== "cyhunt" && (
                  <Box mb={3}>
                    <PoweredByDynarisk />
                  </Box>
                )}
              {!!sidebarFooterCustom && (
                <Box className={classes.customSidebarFooter}>
                  <MDRender
                    t={t}
                    input={sidebarFooterCustom}
                    escapeHtml={false}
                  />
                </Box>
              )}
              {partnerSettings &&
                logoPoweredByPosition === "bottom" &&
                isShowPoweredBy &&
                partnerSettings.partnerSlug !== DEFAULT_PARTNER_NAME &&
                partnerSettings.partnerSlug !== "cyhunt" && (
                  <Box mt={3}>
                    {logoPoweredByUrl ? (
                      <MDRender
                        t={t}
                        input={logoPoweredByUrl}
                        escapeHtml={false}
                      />
                    ) : (
                      <PoweredByDynarisk />
                    )}
                  </Box>
                )}
            </Box>
          </div>
        </SpringScrollbars>
      </div>
    </aside>
  );
};

export default Sidebar;
