const ONE_MIN = 60 * 1000;
const ONE_HOUR = 60 * ONE_MIN;
const ONE_DAY = 24 * ONE_HOUR;

export function formatDuration(duration) {
  const durationInMs = duration / 1e6;
  const hours = Math.floor(durationInMs / ONE_HOUR);
  const minutes = Math.floor((durationInMs % ONE_HOUR) / ONE_MIN);

  if (hours === 0) {
    return `${minutes}m`;
  } else if (minutes === 0) {
    return `${hours}h`;
  } else {
    return `${hours}h ${minutes}m`;
  }
}

export function calculateRemainingTime(completionDate) {
  const nowMs = Date.now();
  const completionMs = completionDate * 1000; 
  const remainingMs = completionMs - nowMs;
  
  if (remainingMs <= 0) {
    return "0m";
  }

  const days = Math.floor(remainingMs / ONE_DAY);
  const hours = Math.floor((remainingMs % ONE_DAY) / ONE_HOUR);
  const minutes = Math.floor((remainingMs % ONE_HOUR) / ONE_MIN);

  let result = [];
  if (days > 0) result.push(`${days}d`);
  if (days > 0 || hours > 0) result.push(`${hours}h`);
  result.push(`${minutes}m`);

  return result.join(" ");
}