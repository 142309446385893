import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardTitle } from "components/UI";
import { useTranslation } from "react-i18next";
import { ReactComponent as PolicyIcon } from "../../../assets/icons/policy.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { useHistory, useParams } from "react-router-dom";
import PolicyAPI from "../../../api/policies/index";
import { Tooltip } from "react-tippy";

const useStyles = makeStyles(() => ({
  policyCard: {
    padding: "24px",
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    marginBottom: "0px",
  },
  policyIcon: {
    height: "96px",
    width: "96px",
    cursor: "pointer",
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginBottom: "16px",
  },
  bottomBar: {
    display: "flex",
    justifyContent: "space-between",
  },
  btn: {
    width: "100%",
  },
  iconBtn: {
    width: "fit-content",
    alignSelf: "flex-end",
  },
  download: {
    all: "unset",
  },
  notFound: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  policyTitle: {
    overflow: "hidden",
    wordWrap: "normal",
    width: "calc(100% - 1px)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const PolicyCard = ({ policy }) => {
  const classes = useStyles();
  const history = useHistory();
  const { locale } = useParams();
  const { t } = useTranslation();
  const [isTitleOverflowed, setIsTitleOverflowed] = useState(false);

  const redirectToDetails = () => {
    history.push(`/${locale}/policies/${policy.uuid}`);
  };

  function isEllipsisActive(e) {
    setIsTitleOverflowed(e.target.offsetWidth < e.target.scrollWidth);
  }

  const PolicyTitle = () => (
    <CardTitle
      mb={0}
      variant="h2"
      textAlign="center"
      className={classes.policyTitle}
      noWrap={true}
      onMouseOver={(e) => isEllipsisActive(e)}
    >
      {policy.title}
    </CardTitle>
  );

  return (
    <Paper className={classes.policyCard}>
      <Box className={classes.cardWrapper}>
        <IconButton
          className={classes.iconBtn}
          size="small"
          aria-label="download policy"
        >
          <a
            className={classes.download}
            href={policy.pdf_download_link}
            download
          >
            <DownloadIcon />
          </a>
        </IconButton>
        <Box className={classes.iconWrapper}>
          <PolicyIcon
            className={classes.policyIcon}
            onClick={redirectToDetails}
          />
        </Box>
        {isTitleOverflowed ? (
          <Tooltip
            className="tooltip"
            arrow
            distance={8}
            delay={100}
            position="top"
            title={policy.title}
            trigger="mouseenter focus"
          >
            <PolicyTitle />
          </Tooltip>
        ) : (
          <PolicyTitle />
        )}
      </Box>
      <Box className={classes.bottomBar} mt={2}>
        <Button
          className={classes.btn}
          onClick={redirectToDetails}
          size="small"
          variant="contained"
          color="primary"
          name="see details"
        >
          {t("retail.policy.see_details")}
        </Button>
      </Box>
    </Paper>
  );
};

export const PoliciesList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [policies, setPolicies] = useState(null);

  const fetchPolicies = async () => {
    try {
      const res = await PolicyAPI.getPolicies();
      setPolicies(res);
    } catch (e) {}
  };

  useEffect(() => {
    fetchPolicies();
  }, []);

  return (
    <Box>
      <CardTitle mt={3} textAlign="inherit">{t("retail.policy.policies")}</CardTitle>
      <Grid container spacing={2}>
        {(!policies || policies.length === 0) && (
          <Box className={classes.notFound}>
            <Typography>{t("retail.policy.no_policies_found")}</Typography>
          </Box>
        )}
        {policies &&
          Object.values(policies).map((policy) => {
            return (
              <Grid item xs={12} sm={6} lg={4} key={policy.uuid}>
                <PolicyCard policy={policy} />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
