/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CxHttpException from '../model/CxHttpException';
import CxHttpValidationError from '../model/CxHttpValidationError';
import RequestUserQuizCreateForm from '../model/RequestUserQuizCreateForm';
import UserQuizUserQuizCreateResponse from '../model/UserQuizUserQuizCreateResponse';

/**
* CreateUserQuiz service.
* @module api/CreateUserQuizApi
* @version 1.0.0
*/
export default class CreateUserQuizApi {

    /**
    * Constructs a new CreateUserQuizApi. 
    * @alias module:api/CreateUserQuizApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create user quiz
     * Create user quiz
     * @param {module:model/RequestUserQuizCreateForm} params Items
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserQuizUserQuizCreateResponse} and HTTP response
     */
    createUserQuizWithHttpInfo(params) {
      let postBody = params;
      // verify the required parameter 'params' is set
      if (params === undefined || params === null) {
        throw new Error("Missing the required parameter 'params' when calling createUserQuiz");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = UserQuizUserQuizCreateResponse;
      return this.apiClient.callApi(
        '/api/v1/user_quizzes', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create user quiz
     * Create user quiz
     * @param {module:model/RequestUserQuizCreateForm} params Items
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserQuizUserQuizCreateResponse}
     */
    createUserQuiz(params) {
      return this.createUserQuizWithHttpInfo(params)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
