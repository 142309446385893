import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { BodyText, CardTitle } from "../../components/UI";
import QuestionsForm from "./QuestionForm";
const AssessmentQuestion = ({
  t,
  assessment,
  loading,
  assessmentPostAnswer,
  assessmentHeader,
  assessmentSubheader,
}) => {
  const [questionId, setQuestionId] = useState("");
  const [prevDirection, setPrevDirection] = useState(false);
  const [assessmentState, setAssessmentState] = useState({
    currentQuestion: assessment.number_current_question,
    previousQuestion: false,
  });

  useEffect(() => {
    if (!questionId) {
      setQuestionId(assessment.question.uuid);
    }
    if (questionId && assessment.question.uuid !== questionId) {
      setQuestionId(assessment.question.uuid);
    }
  }, [assessment.question]);

  useEffect(() => {
    setAssessmentState({
      ...assessmentState,
      currentQuestion: assessment.number_current_question,
    });
  }, [assessment.number_current_question]);

  const question = assessmentState.previousQuestion
    ? assessment.past_questions[assessmentState.currentQuestion - 1]
    : assessment.question;

  const assessmentAnswer = (answer) => {
    setPrevDirection(false);
    assessmentPostAnswer(answer);
    if (
      assessmentState.previousQuestion &&
      assessmentState.currentQuestion + 1 < assessment.number_current_question
    ) {
      setAssessmentState({
        previousQuestion: true,
        currentQuestion: assessmentState.currentQuestion + 1,
      });
    } else {
      setAssessmentState({
        previousQuestion: false,
        currentQuestion: assessment.number_current_question,
      });
    }
  };

  return (
    <Box overflow="hidden" style={{ maxWidth: "980px", margin: "0 auto" }}>
      <Box mx={2}>
        <Box mb={2}>
          <CardTitle>{assessmentHeader}</CardTitle>
          <BodyText>{assessmentSubheader}</BodyText>
        </Box>
        {question && questionId && (
          <QuestionsForm
            t={t}
            loading={loading}
            question={question}
            assessment={assessment}
            assessmentHeader={assessmentHeader}
            assessmentSubheader={assessmentSubheader}
            submitQuestion={assessmentAnswer}
            setAssessmentState={setAssessmentState}
            currentQuestion={assessmentState.currentQuestion}
            prevDirection={prevDirection}
            setPrevDirection={setPrevDirection}
          />
        )}
      </Box>
    </Box>
  );
};

export default AssessmentQuestion;
