/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UserChapterTakeawayItem model module.
 * @module model/UserChapterTakeawayItem
 * @version 1.0.0
 */
class UserChapterTakeawayItem {
    /**
     * Constructs a new <code>UserChapterTakeawayItem</code>.
     * @alias module:model/UserChapterTakeawayItem
     * @param chapterTakeawayUuid {String} 
     * @param chapterUuid {String} 
     * @param createdAt {Number} 
     * @param isCompleted {Boolean} 
     * @param takeawayUuid {String} 
     * @param userCourseUuid {String} 
     * @param uuid {String} 
     */
    constructor(chapterTakeawayUuid, chapterUuid, createdAt, isCompleted, takeawayUuid, userCourseUuid, uuid) { 
        
        UserChapterTakeawayItem.initialize(this, chapterTakeawayUuid, chapterUuid, createdAt, isCompleted, takeawayUuid, userCourseUuid, uuid);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, chapterTakeawayUuid, chapterUuid, createdAt, isCompleted, takeawayUuid, userCourseUuid, uuid) { 
        obj['chapter_takeaway_uuid'] = chapterTakeawayUuid;
        obj['chapter_uuid'] = chapterUuid;
        obj['created_at'] = createdAt;
        obj['is_completed'] = isCompleted;
        obj['takeaway_uuid'] = takeawayUuid;
        obj['user_course_uuid'] = userCourseUuid;
        obj['uuid'] = uuid;
    }

    /**
     * Constructs a <code>UserChapterTakeawayItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserChapterTakeawayItem} obj Optional instance to populate.
     * @return {module:model/UserChapterTakeawayItem} The populated <code>UserChapterTakeawayItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserChapterTakeawayItem();

            if (data.hasOwnProperty('chapter_takeaway_uuid')) {
                obj['chapter_takeaway_uuid'] = ApiClient.convertToType(data['chapter_takeaway_uuid'], 'String');
            }
            if (data.hasOwnProperty('chapter_uuid')) {
                obj['chapter_uuid'] = ApiClient.convertToType(data['chapter_uuid'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Number');
            }
            if (data.hasOwnProperty('is_completed')) {
                obj['is_completed'] = ApiClient.convertToType(data['is_completed'], 'Boolean');
            }
            if (data.hasOwnProperty('takeaway_uuid')) {
                obj['takeaway_uuid'] = ApiClient.convertToType(data['takeaway_uuid'], 'String');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Number');
            }
            if (data.hasOwnProperty('user_course_uuid')) {
                obj['user_course_uuid'] = ApiClient.convertToType(data['user_course_uuid'], 'String');
            }
            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UserChapterTakeawayItem</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UserChapterTakeawayItem</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of UserChapterTakeawayItem.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['chapter_takeaway_uuid'] && !(typeof data['chapter_takeaway_uuid'] === 'string' || data['chapter_takeaway_uuid'] instanceof String)) {
            throw new Error("Expected the field `chapter_takeaway_uuid` to be a primitive type in the JSON string but got " + data['chapter_takeaway_uuid']);
        }
        // ensure the json data is a string
        if (data['chapter_uuid'] && !(typeof data['chapter_uuid'] === 'string' || data['chapter_uuid'] instanceof String)) {
            throw new Error("Expected the field `chapter_uuid` to be a primitive type in the JSON string but got " + data['chapter_uuid']);
        }
        // ensure the json data is a string
        if (data['takeaway_uuid'] && !(typeof data['takeaway_uuid'] === 'string' || data['takeaway_uuid'] instanceof String)) {
            throw new Error("Expected the field `takeaway_uuid` to be a primitive type in the JSON string but got " + data['takeaway_uuid']);
        }
        // ensure the json data is a string
        if (data['user_course_uuid'] && !(typeof data['user_course_uuid'] === 'string' || data['user_course_uuid'] instanceof String)) {
            throw new Error("Expected the field `user_course_uuid` to be a primitive type in the JSON string but got " + data['user_course_uuid']);
        }
        // ensure the json data is a string
        if (data['uuid'] && !(typeof data['uuid'] === 'string' || data['uuid'] instanceof String)) {
            throw new Error("Expected the field `uuid` to be a primitive type in the JSON string but got " + data['uuid']);
        }

        return true;
    }


}

UserChapterTakeawayItem.RequiredProperties = ["chapter_takeaway_uuid", "chapter_uuid", "created_at", "is_completed", "takeaway_uuid", "user_course_uuid", "uuid"];

/**
 * @member {String} chapter_takeaway_uuid
 */
UserChapterTakeawayItem.prototype['chapter_takeaway_uuid'] = undefined;

/**
 * @member {String} chapter_uuid
 */
UserChapterTakeawayItem.prototype['chapter_uuid'] = undefined;

/**
 * @member {Number} created_at
 */
UserChapterTakeawayItem.prototype['created_at'] = undefined;

/**
 * @member {Boolean} is_completed
 */
UserChapterTakeawayItem.prototype['is_completed'] = undefined;

/**
 * @member {String} takeaway_uuid
 */
UserChapterTakeawayItem.prototype['takeaway_uuid'] = undefined;

/**
 * @member {Number} updated_at
 */
UserChapterTakeawayItem.prototype['updated_at'] = undefined;

/**
 * @member {String} user_course_uuid
 */
UserChapterTakeawayItem.prototype['user_course_uuid'] = undefined;

/**
 * @member {String} uuid
 */
UserChapterTakeawayItem.prototype['uuid'] = undefined;






export default UserChapterTakeawayItem;

