import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import classnames from "classnames";
import { CheckboxInput, AccentButton } from "../../../components/UI";
import ItemRemindModal from "./ItemRemindModal";
import ActionRiskIndicator from "../../../components/actionRiskIndicator";
import { getItem } from "helpers/localStorage";
import { actionsStatuses } from "constants/actionsStatuses";
import { Tooltip } from "react-tippy";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useActionsContext } from "../../../contexts/ActionsContext";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
  },
  actionBtn: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
    "& .MuiButton-text.error.overideBtn": {
      color: theme.variables.$actionsOverdueTextColor,
    },
  },
  btnHowToDo: {
    color: theme.variables.$actionsButtonTextColor,
  },
  actionsWrapper: {
    display: "flex",
  },
  completedTask: {
    justifyContent: "flex-end",
  },
  actionName: {
    cursor: "pointer",
    // marginRight: theme.spacing(1),
    // marginLeft: theme.spacing(1),
    "&.checked": {
      cursor: "default",
      color: theme.variables.$textDarkGray,
      textDecoration: "line-through",
    },
  },
  labelPostponed: {
    opacity: 0.6,
  },
  taskCheckboxContainer: {
    position: "relative",
    width: "100%",
    // paddingRight: "10px",
    display: "flex",
    alignItems: "center",
  },
}));

const Item = ({ data, uuid }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { locale } = useParams();
  const history = useHistory();
  const identity = useSelector((state) => state.identity);
  const { actionsButtonsStyle } = useSelector((state) => state.partnerSettings);

  const [isModal, toggleModal] = useState(false);
  const [isActivated, setIsActivated] = useState(false);

  const { changeActionStatus } = useActionsContext();

  const goToTaskDetails = () => {
    if (checkActivationStatus()) {
      history.push(`/${locale}/todo/${uuid}`);
    }
  };

  const modalHandler = () => {
    if (checkActivationStatus()) {
      toggleModal(!isModal);
    }
  };

  const [isChecked, toggleCheckbox] = useState(false);
  const changeCheckbox = () => toggleCheckbox(!isChecked);

  const isTaskCompleted = data.status === actionsStatuses.completed;
  const isTaskPostponed = data.status === actionsStatuses.postponed;
  const isTaskOverdue = data.status === actionsStatuses.overdue;

  const [activeTour, setActiveTour] = useState(false);
  const [finishTour, setFinishTour] = useState(false);

  useEffect(() => {
    isTaskCompleted && changeCheckbox();
  }, []);

  useEffect(() => {
    setTaskStatusHandler();
  }, [finishTour]);

  useEffect(() => {
    if (
      identity &&
      identity.profile &&
      isActivated !== identity.profile.is_activated
    ) {
      setIsActivated(identity.profile.is_activated);
    }
  }, [identity]);

  const classValues = classnames({
    item: true,
    "-removed": isModal,
  });

  const remindBtnClick = (remind_value) => () => {
    modalHandler();
    changeActionStatus({ uuid: data.uuid, remind_value });
  };

  const deviceTypes = {
    LAPTOP: "laptop",
    DESKTOP: "desktop",
    TABLET: "tablest",
    PHONE: "phone",
    TERMINAL: "terminal",
  };

  const setPiorityColor = (data) => {
    const deviceName = data.device ? data.device.name : "";
    let color = "";
    if (data.priority === "high") color = "red";
    if (data.priority === "medium") color = "rgb(241, 239, 111)";
    if (data.priority === "low") color = "#75B78B";

    return `${
      deviceName
        ? '<span style="text-transform: capitalize">' +
          deviceName +
          "</span> <br />"
        : ""
    }
    <span style="color: ${color}">
      ${t("to_do_filter." + data.priority + "_risk")}
    </span>`;
  };

  const setTaskStatusHandler = (evt) => {
    if (finishTour) {
      changeCheckbox();
      const status = actionsStatuses.completed;
      changeActionStatus({ uuid: data.uuid, status });
    }
    if (evt) {
      setActiveTour(false);
      changeCheckbox();
      const status = evt.target.checked
        ? actionsStatuses.completed
        : actionsStatuses.toBeCompleted;
      changeActionStatus({ uuid: data.uuid, status });
    }
  };

  const checkActivationStatus = () => {
    let isActive = true;
    if (!isActivated) {
      isActive = false;
      toast(t("retail.account_not_activated"), {
        type: toast.TYPE.INFO,
        autoClose: 1500,
      });
    }
    return isActive;
  };

  const handleChangeCheckbox = (evt) => {
    if (checkActivationStatus() && !isTaskCompleted) {
      setTaskStatusHandler(evt);
    }
  };

  return (
    <Grid
      item
      xs={12}
      container
      className={`${classes.item} ${classValues}`}
      key={data.uuid}
    >
      {isModal && (
        <ItemRemindModal
          t={t}
          remindBtnClick={remindBtnClick}
          modalHandler={modalHandler}
        />
      )}
      <Grid
        item
        xs={12}
        md={5}
        className={`${classes.taskCheckboxContainer} ${
          isTaskPostponed ? classes.labelPostponed : ""
        }`}
      >
        <CheckboxInput
          color="primary"
          id={`task-checkbox-${data.uuid}`}
          name={`task-checkbox-${data.uuid}`}
          onChange={handleChangeCheckbox}
          checked={isChecked}
          disabled={
            isChecked || activeTour || getItem(`isTaskTourOpened`) === "true"
          }
        />

        <Box onClick={goToTaskDetails} mx={1}>
          <Typography
            variant="body2"
            component="span"
            className={`${classes.actionName} ${isChecked ? "checked" : ""}`}
          >
            {data.title}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        className={`${classes.actionsWrapper} ${true && classes.completedTask}`}
      >
        {!isTaskPostponed && !isTaskOverdue && (
          <Box mx={0.5} className={classes.actionBtn}>
            {/* hide btn and keep the container */}
            {!isChecked && (
              <Button
                fullWidth
                variant="contained"
                size="small"
                color="secondary"
                onClick={modalHandler}
              >
                <span
                  style={{
                    textTransform: !!actionsButtonsStyle
                      ? actionsButtonsStyle
                      : "inherit",
                  }}
                >
                  {t("dashboard.tasks.item_remind_later")}
                </span>
              </Button>
            )}
          </Box>
        )}
        {isTaskOverdue && (
          <Box mx={0.5} className={classes.actionBtn}>
            <AccentButton
              fullWidth
              variant="text"
              size="small"
              accentColor="error"
              className="overideBtn"
              disabled={true}
            >
              <span
                style={{
                  textTransform: !!actionsButtonsStyle
                    ? actionsButtonsStyle
                    : "inherit",
                }}
              >
                {t("overdue")}
              </span>
            </AccentButton>
          </Box>
        )}
        {isTaskPostponed && (
          <Box mx={0.5} className={classes.actionBtn}>
            <Button
              fullWidth
              variant="contained"
              size="small"
              color="default"
              disabled={true}
            >
              <span
                style={{
                  textTransform: !!actionsButtonsStyle
                    ? actionsButtonsStyle
                    : "inherit",
                }}
              >
                {t("postponed")}
              </span>
            </Button>
          </Box>
        )}
        {/* {isTaskCompleted && <div style={{ width: "100%" }} />} */}
        <Box mx={0.5} className={classes.actionBtn}>
          <Button
            id="sa_dashboard_how_todo"
            fullWidth
            variant="contained"
            size="small"
            color="primary"
            onClick={goToTaskDetails}
            className={classes.btnHowToDo}
          >
            <span
              style={{
                textTransform: !!actionsButtonsStyle
                  ? actionsButtonsStyle
                  : "inherit",
                  color: "inherit"
              }}
            >
              {t("how_to_do_it")}
            </span>
          </Button>
        </Box>
        {data && (
          <Tooltip
            className="tooltip"
            arrow
            distance={10}
            delay={100}
            position="bottom"
            title={setPiorityColor(data)}
          >
            <ActionRiskIndicator
              t={t}
              data={data}
              setPosition={true}
              deviceTypes={deviceTypes}
              className={`${isTaskPostponed ? classes.labelPostponed : ""}`}
            />
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

export default Item;
