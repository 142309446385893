import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "i18n";
import { AuthProvider } from "./AuthContext";
import { SubHeaderProvider } from "components/SubHeader/SubHeaderContext";
import { ActionsProvider } from "./ActionsContext";
import {
  CyberScoreContextProvider,
  useCyberScoreContect,
} from "./CyberScoreContext";
import { UserTourContextProvider, useUserTourContext } from "./UserTourContext";
import { PlansContextProvider, usePlansContext } from "./PlansContext";
import { RegisterContextProvider, useRegisterContext } from "./RegisterContext";

const State = ({ children }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <RegisterContextProvider>
          <PlansContextProvider>
            <CyberScoreContextProvider>
              <UserTourContextProvider>
                <ActionsProvider>
                  <SubHeaderProvider>{children}</SubHeaderProvider>
                </ActionsProvider>
              </UserTourContextProvider>
            </CyberScoreContextProvider>
          </PlansContextProvider>
        </RegisterContextProvider>
      </AuthProvider>
    </I18nextProvider>
  );
};

export default State;

export { useCyberScoreContect, useUserTourContext, usePlansContext, useRegisterContext };
