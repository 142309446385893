import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Footer } from "components/Footer";
import MobileNav from "components/MobileNav";
import SubHeader from "components/SubHeader";
import Sidebar from "components/Sidebar";
import { RoundButton, LanguageSelect } from "../UI";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

import { setAppNotification } from "store/actions/basicNotification";
import { usePageStyles } from "./Page.styles";

const simplePages = [
  "signIn",
  "signUp",
  "signUpEmailVerify",
  "signUpEditEmail",
  "termsAndConditions",
  "assessment",
  "signOut",
  "plans",
  "addCoupon",
  "privacyPolicy",
  "resetPassword",
  "planExpired",
  "mfaCode",
  "startScan",
  "scanning",
  "signUpTermsAndConditions",
];

const simplePagesNoFooter = [
  "plans",
  "termsAndConditions",
  "signUpTermsAndConditions",
  "privacyPolicy",
];

const Page = ({ authKey, children, name, withAside, className }) => {
  const { t } = useTranslation();
  const classes = usePageStyles();
  const { locale } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const snackeBarElRef = useRef();

  const identity = useSelector((state) => state.identity);
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const basicNotification = useSelector((state) => state.basicNotification);

  const [snackHeight, setSnackHeight] = useState(0);

  const simplePage = simplePages.some((page) => page === name) || !name;

  // hide footer based on partner settings and list of pages that should not have footer
  const pageWithoutFooter =
    partnerSettings.partnerCustomFooter ||
    simplePagesNoFooter.some((page) => page === name);

  useEffect(() => {
    if (
      identity &&
      identity.profile &&
      !identity.profile.is_activated &&
      snackeBarElRef &&
      snackeBarElRef.current
    ) {
      const snackHeight = snackeBarElRef.current.offsetHeight;
      setSnackHeight(snackHeight);
    }
  }, [identity, snackeBarElRef]);

  useEffect(() => {
    if (
      location.pathname.indexOf("support") !== -1 &&
      window.zE &&
      typeof window.zE.show === "function"
    ) {
      window.zE.show();
    }
    if (
      location.pathname.indexOf("support") === -1 &&
      window.zE &&
      typeof window.zE.hide === "function"
    ) {
      window.zE.hide();
    }
  }, []);

  useEffect(() => {
    if (
      identity &&
      identity.profile &&
      !identity.profile.is_activated &&
      basicNotification === null
    ) {
      dispatch(setAppNotification(true));
    }
    if (
      location.pathname.indexOf("support") === -1 &&
      identity &&
      identity.plan &&
      !identity.plan.is_active
    ) {
      history.push(`/${locale}/profile/plans`);
    }
  }, [identity]);

  const closeToast = () => {
    dispatch(setAppNotification(false));
  };

  return (
    <>
      <div
        className={`${classes.page} ${name ? "-" + name : ""} ${
          className ? className : ""
        }`}
      >
        {simplePage ? (
          <div
            className={classes.simplePage}
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {["signIn", "signUp"].indexOf(name) !== -1 && <LanguageSelect />}
            <main
              className={classes.simplePageContainer}
              style={{ height: "calc(100vh - 60px)", overflow: "auto" }}
              data-authkey={authKey}
            >
              {children}
            </main>
            {!pageWithoutFooter && (
              <footer
                className={classes.pageFooter}
                style={{ height: "60px)" }}
              >
                <div className={classes.footerContentWrapper}>
                  <Footer
                    url={`/${locale}`}
                    t={t}
                    partnerSettings={partnerSettings}
                  />
                </div>
              </footer>
            )}
          </div>
        ) : (
          <>
            {basicNotification && (
              <>
                {snackHeight && (
                  <div
                    style={{ height: `${snackHeight ? snackHeight : 0}px` }}
                  ></div>
                )}
                <div
                  ref={snackeBarElRef}
                  className={`${classes.pageToast} ${
                    true ? "open" : "collapse"
                  }`}
                >
                  <div style={{ flexGrow: 1 }}>
                    {t("retail.please_activate_to_get_full_access")}
                  </div>

                  <div
                    style={{
                      flexBasis: "12px",
                      cursor: "pointer",
                      color: "#fff",
                    }}
                  >
                    <RoundButton color="inherit" onClick={closeToast}>
                      <CloseIcon />
                    </RoundButton>
                  </div>
                </div>
              </>
            )}
            <div className={`${classes.wrapper}`}>
              {withAside && <Sidebar partnerSettings={partnerSettings} />}
              <div className={classes.pageContent}>
                <main>
                  <MobileNav />
                  <SubHeader
                    partnerSettings={partnerSettings}
                    identity={identity}
                  />
                  <div className={classes.contentWrapper}>{children}</div>
                </main>
                <footer className={classes.pageFooter}>
                  <div className={classes.footerContentWrapper}>
                    <Footer
                      url={`/${locale}`}
                      t={t}
                      partnerSettings={partnerSettings}
                    />
                  </div>
                </footer>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Page;
