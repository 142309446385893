import React from "react"
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ReactComponent as DynariskLogo } from '../../assets/icons/dynarisk.svg'
import useBreakpoints from 'hooks/useBreakpoint';
import { DEFAULT_SIDEBAR_LOGO_WIDTH } from '../../constants/common'
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  logoComponent: {
    '&.-center': {
      textAlign: 'center'
    },
    '& img': {
      maxWidth: '100%'
    }

  }
}))

const Logo = ({ center, redirectTo = '', preventRedirection = false, isMobile }) => {
  const { locale } = useParams()
  const classes = useStyles()

  const partnerSettings = useSelector(state => state.partnerSettings);

  const bPiont = useBreakpoints()
  const logoUri = partnerSettings.logoUri
  const logoWidthSize = partnerSettings.logoWidthSize || DEFAULT_SIDEBAR_LOGO_WIDTH;
  const logoWidthMobile = partnerSettings.logoWidthMobile;
  const shortLogoUrl = partnerSettings.shortLogoUrl
  const isCrossServicePartner = partnerSettings.isCrossServicePartner

  const logoWidth = isMobile && logoWidthMobile ? logoWidthMobile : logoWidthSize

  const logoComponent = logoUri ?
    <>
      {bPiont === 'md' && shortLogoUrl
        ? <img style={{ fontSize: 10 }} width={logoWidth} src={shortLogoUrl} alt='logo' />
        : <img style={{ fontSize: 10 }} width={logoWidth} src={logoUri} alt='logo' />
      }
    </>
    :
    <DynariskLogo width='130px' />

  const setRedirectUrl = () => {
    if (redirectTo) {
      return `/${locale}/${redirectTo}`
    } else if (isCrossServicePartner) {
      return `/${locale}/logout`
    } else {
      return `/${locale}/dashboard`
    }
  }
  return (
    <div className={`${classes.logoComponent} ${center ? '-center' : ''}`}>
      {preventRedirection
        ? <i>
          {logoComponent}
        </i>
        : <a href={setRedirectUrl()}>
          <i>
            {logoComponent}
          </i>
        </a>
      }
    </div>
  )
}

export default Logo;

