import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  squareButton: {
    "&.medium": {
      minWidth: "38px",
      padding: "12px",
      "& .MuiButton-label": {
        lineHeight: 1.75,
        display: "initial",
      },
    },
    "&.small": {
      minWidth: "24px",
    },
    "& .MuiButton-label svg": {
      transform: theme.variables.$isRTLDirection ? "scale(-1, 1)" : "",
    },
  },
}));

export default function SquareButton({ size = "medium", ...props }) {
  const classes = useStyles();
  return (
    <Button
      {...props}
      size={size}
      className={`${classes.squareButton} ${size}`}
    />
  );
}
