import React, { useState } from 'react'

import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'

import { ReactComponent as EyeIcon } from '../../../assets/icons/eye.svg'
import { ReactComponent as EyeFilledIcon } from '../../../assets/icons/eye_filled.svg'

import { makeStyles } from '@material-ui/core/styles'

export const useSignInFormStyles = makeStyles((theme) => ({
  input: {
    fontWeight: 600,
    '&::placeholder': {
      color: theme.variables.$placeholderTextColor,
      fontWeight: 'normal',
    },
  },
  inputStatusIcon: {
    paddingRight: '10px',
  },
  inputIcon: {
    height: '32px',
    width: '32px',
    padding: 0,
    color: theme.palette.primary.main,
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  readOnly: {
    background: theme.variables.$lightGray
  }
}))

export default function InputField({
  error,
  name,
  placeholder,
  value,
  readOnly = false,
  disabled = false,
  onChange,
  onBlur,
  type = 'text',
  multiline = false,
  rows = null,
  className = ''
}) {
  const classes = useSignInFormStyles();
  const [isPasswordType, setIsPasswordType] = useState(type === 'password')
  return (
    <>
      <TextField
        name={name}
        error={!!error}
        type={isPasswordType ? 'password' : 'text'}
        placeholder={placeholder}
        value={value}
        multiline={multiline}
        rows={rows}
        onChange={onChange}
        onBlur={onBlur}
        fullWidth
        readOnly={readOnly}
        disabled={disabled}
        className={readOnly ? classes.readOnly : className}
        InputProps={{
          classes: { input: `${classes.input}` },
          endAdornment: type === 'password'
            ? (
              <InputAdornment position="end"
                className={classes.inputStatusIcon}
              >
                <IconButton
                  className={classes.inputIcon}
                  aria-label="toggle password visibility"
                  onClick={() => setIsPasswordType(!isPasswordType)}
                >
                  {isPasswordType ? <EyeIcon /> : <EyeFilledIcon />}
                </IconButton>
              </InputAdornment>
            )
            : null
        }}
      />
      { !!error && <FormHelperText error children={error} />}
    </>
  )
}