import { combineReducers } from 'redux'
import plans from './plans'
import phonePrefixes from './phonePrefixes'
import partnerSettings from './partnerSettings'
import identity from './identity'
import checks from './checks'
import basicNotification from './basicNotification'
import flagSettings from './flagSettings'
import quizes from "./quizes";
import course from "./course"

export default combineReducers({
  plans,
  phonePrefixes,
  partnerSettings,
  identity,
  checks,
  basicNotification,
  flagSettings,
  quizes,
  course
});