import React from "react";
import Button from "@material-ui/core/Button";
import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow_right.svg";

const QuizButton = ({
  onClick,
  disabled = false,
  startIcon = null,
  endIcon = null,
  text,
  variant = "contained",
  className,
}) => {
  const arrow = (
    <ArrowRightIcon className={startIcon === "left" ? "left" : ""} />
  );

  return (
    <Button
      variant={variant}
      startIcon={startIcon ? arrow : null}
      endIcon={endIcon ? arrow : null}
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {text}
    </Button>
  );
};

export default QuizButton;
