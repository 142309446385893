import client from "../axiosInstance";

export default {
  login(payload) {
    const headers = {
      Authorization: null,
    };
    return client
      .post("/user/api/v1/users/auth", payload, { headers })
      .then((response) => {
        return Promise.resolve({ ...response.data, status: response.status });
      });
  },
  loginWithMfaCode(payload) {
    return client.post("/user/api/v1/mfa", payload, {
      headers: { Authorization: null },
    });
  },
  profileSwitch(payload) {
    return client
      .post(`/user/api/v2/users/auth/switch-profile`, payload)
      .then((response) => {
        return Promise.resolve(response.data);
      });
  },

  validate(payload) {
    return client
      .post("/user/api/v1/users/register/validate", payload, {
        headers: { Authorization: null },
      })
      .then((response) => {
        return Promise.resolve(response.data);
      });
  },

  validateSmoothFlow(payload) {
    return client
      .post("/user/api/v2/users/register/minimal/validate", payload, {
        headers: { Authorization: null },
      })
      .then((response) => {
        return Promise.resolve(response.data);
      });
  },

  register(payload) {
    return client
      .post("/user/api/v1/users/register", payload.data, {
        headers: { Authorization: null, ...payload.headers },
      })
      .then((response) => {
        return Promise.resolve(response.data);
      });
  },

  registerSmoothFlow(payload) {
    return client
      .post("/user/api/v2/users/register/minimal", payload.data, {
        headers: { Authorization: null, ...payload.headers },
      })
      .then((response) => {
        return Promise.resolve(response.data);
      });
  },

  checkPassword(payload) {
    return client.post("/user/api/v1/user-management/check-password", payload, {
      headers: { Authorization: null },
    });
  },

  refreshTokenRaw(payload) {
    return client.post("/user/api/v1/users/refresh-token", payload, {
      headers: { Authorization: null },
    });
  },

  resendActivationLink({ uuid }) {
    return client.post(`/user/api/v1/user-management/email/${uuid}/resend`, {});
  },

  resendUserActivationLink(payload) {
    return client.post(
      "/user/api/v1/users/register/resend-activation",
      payload,
      { headers: { Authorization: null } }
    );
  },

  activateUser(token) {
    return client
      .post(
        `/user/api/v1/users/register/activate/${token}`,
        {},
        { headers: { Authorization: null } }
      )
      .then((response) => {
        return Promise.resolve(response.data);
      });
  },

  forgotPassword(payload) {
    return client.put("/user/api/v1/users/forgot-password", payload, {
      headers: { Authorization: null },
    });
  },

  resetPassword(payload) {
    return client.post("/user/api/v1/users/reset-password", payload, {
      headers: { Authorization: null },
    });
  },

  checkEmailUniqueness(payload) {
    return client.post("/user/api/v1/users/register/check-email", payload, {
      headers: { Authorization: null },
    });
  },

  applyCoupon(payload) {
    return client.post("/user/api/v1/coupons/apply", payload);
  },

  getPlans() {
    return client.get("/user/api/v1/plans").then((response) => {
      return Promise.resolve(response.data.plans);
    });
  },

  selectPlan({ type, data }) {
    return client.post(`/user/api/v1/plans/profile/${type}`, data);
  },

  getPlanFeatures() {
    return client.get("/user/api/v1/plans/profile/features/promo");
  },

  calculatePlanPrice({ uuid }) {
    return client.get(`/user/api/v1/plans/profile/duration/${uuid}/`);
  },

  createPaymentOrder(payload) {
    return client.post(`/user/api/v1/plans/profile/order/create`, payload);
  },

  viewProfilePlan(planUuid) {
    return client.get(`/plan/api/v1/plans/profile/${planUuid}/`);
  },

  // user management
  editPersonalInfo(payload) {
    return client.post("/user/api/v1/user-management/personal/edit", payload);
  },

  getPersonalInfo() {
    return client.get("/user/api/v1/user-management/personal/edit");
  },

  editPassword(payload) {
    return client.post("/user/api/v1/user-management/password/edit", payload);
  },

  updateUserSettings({ flag, data }) {
    return client.patch(`/user/api/v1/settings/users/profiles/${flag}/`, data);
  },

  getUserSettings() {
    return client.get("/user/api/v1/settings/users/profiles");
  },

  getProtectedEmails() {
    return client
      .get("/user/api/v1/user-management/email/list")
      .then((response) => {
        if (response && response.data && Array.isArray(response.data.data)) {
          return Promise.resolve(response.data.data);
        }
        return Promise.reject();
      });
  },

  addProtectedEmail(payload) {
    return client.post(
      "/user/api/v1/user-management/protected-email/edit",
      payload
    );
  },

  activateProtectedEmail({ type, token }) {
    return client.get(
      `/user/api/v1/user-management/protected-data/${type}/activate/${token}`,
      { headers: { Authorization: null } }
    );
  },

  deleteProtectedEmail({ uuid }) {
    return client.delete(`/user/api/v1/user-management/email/${uuid}/delete`);
  },

  getProtectedCreditCards() {
    return client.get("/user/api/v1/user-management/credit-card/list");
  },

  addProtectedCreditCard(payload) {
    return client.post(
      "/user/api/v1/user-management/credit-card/edit",
      payload
    );
  },

  deleteProtectedCreditCard({ uuid }) {
    return client.delete(
      `/user/api/v1/user-management/credit-card/${uuid}/delete`
    );
  },

  getProtectedPhoneNumbers() {
    return client.get("/user/api/v1/user-management/phone-number/list");
  },

  addProtectedPhoneNumber(payload) {
    return client.post(
      "/user/api/v1/user-management/protected-phone/edit",
      payload
    );
  },

  deleteProtectedPhoneNumber({ uuid }) {
    return client.delete(
      `/user/api/v1/user-management/phone-number/${uuid}/delete`
    );
  },

  deleteAccount(payload) {
    return client.post("/user/api/v1/user-management/delete/edit", payload);
  },

  deleteAccountConfirmation(hash) {
    return client.delete(
      `/user/api/v1/user-management/delete/${hash}/account`,
      { headers: { Authorization: null } }
    );
  },

  getDeleteReasons() {
    return client
      .get("/user/api/v1/user-management/delete/reasons")
      .then((response) => {
        if (response && response.data) {
          return Promise.resolve(response.data);
        }
      });
  },

  contactSupport(payload) {
    return client.post("/user/api/v1/contact-form", payload);
  },

  getUserData() {
    return client.get("/user/api/v1/users/user");
  },

  // change this in future.
  // FEATURES_PROMO returns only 3 of features,
  // at least 17 needed!
  getPlanFullFeaturesList() {
    return client.get("/user/api/v1/plans/profile/features/promo");
  },

  getUserPlan() {
    return client.get("/user/api/v1/users/plan");
  },

  getUserProfile() {
    return client.get("/user/api/v1/users/profile");
  },

  getJWTAdditionalData() {
    return client.get("/user/api/v1/users/me");
  },

  signInByToken(body) {
    return client.post("/user/api/v1/users/token-auth", body, {
      headers: { Authorization: null },
    });
  },

  acceptCrossserviceLink(token, body) {
    return client.post(
      `/user/api/v1/users/cross-service/accept-link/${token}`,
      body,
      { headers: { Authorization: null } }
    );
  },
};
