import React from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core'

const SuccessButton = withStyles(theme => ({
  root: {
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: theme.variables.$green100,
      color: '#fff',
      cursor: 'auto',
    }
  }
}))(Button)

const ActivateDevice = ({ t, uuid, activateDevice, deviceActivationUuid }) => {

  return (
    <Box mb={2} width='100%'>
      {deviceActivationUuid === uuid
        ?
        <SuccessButton
          fullWidth
          size='small'
          variant='contained'
          disabled={true}
          className='sa_devices_activation_sent'>
          {t("activation_link_is_sent").toUpperCase()} &#10003;
          </SuccessButton>
        : <Button
          fullWidth
          size='small'
          variant='contained'
          color='primary'
          onClick={() => activateDevice(uuid)}
          className='sa_devices_activate_device'>
          {t("resend_activation_link")}
        </Button>
      }
    </Box>
  )
}

export default ActivateDevice
