import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import Logo from "components/Logo";
import MDRender from "components/MDRender";
import SpringScrollbars from "pages/SignUp/SpringScrollbars";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import { CardTitle } from "../../components/UI";

const TermsAndPrivacy = ({ t, partnerSettings }) => {
  const cookiePolicyContent = partnerSettings.cookiePolicyContent;
  const partnerName = partnerSettings.partnerName
    ? `${partnerSettings.partnerName} ${t("cookie_policy")}`
    : `Dynarisk ${t("cookie_policy")}`;

  return (
    <Page name="termsAndConditions">
      <Box
        style={{
          maxWidth: "980px",
          margin: "0 auto",
        }}
      >
        <Fade in={true} timeout={{ enter: 1500 }}>
          <Box mb={2} textAlign="center" minHeight="30px">
            <Logo redirectTo="dashboard" />
          </Box>
        </Fade>

        <hr />

        <div
          style={{
            overflow: "auto",
            height: "auto",
            left: "calc((100vw - 510px)/2)",
            right: "calc((100vw - 510px)/2)",
            top: "180px",
            bottom: "60px",
          }}
          className={partnerSettings.partnerName}
        >
          <CardTitle variant="h2" mt={3}>
            {partnerName}
          </CardTitle>
          {cookiePolicyContent ? (
            <SpringScrollbars
              style={{ height: 550, textAlign: "justify", lineHeight: 1.7 }}
            >
              <MDRender t={t} input={cookiePolicyContent} escapeHtml={false} />
            </SpringScrollbars>
          ) : null}
        </div>
      </Box>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  partnerSettings: state.partnerSettings,
});

export default connect(mapStateToProps)(withTranslation()(TermsAndPrivacy));
