import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  modal: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 3000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    '& .wrapper': {
      position: 'relative',
      zIndex: 3000,
      background: '#fff',
      padding: '20px',
      borderRadius: '5px',
    },
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2999,
    width: '100%',
    height: '100%',
    background: theme.variables.$overlay,
  }
}))

export const Modal = props => {
  const {
    children,
    handleClose,
    className
  } = props

  const classes = useStyles()

  return (
    <div className={`${classes.modal} ${className ? className : ''}`}>
      <div className='wrapper'>
        {children}
      </div>
      <div
        onClick={handleClose}
        className={classes.overlay} />
    </div>
  )
}

export default Modal
