import { DataDogHandler } from "./DataDogHandler";
import * as monolog from "monolog";

/**
 * @returns {monolog.Logger}
 */
export function logFactory() {
  const dataDogHandler = new DataDogHandler(monolog.Logger.INFO, true, process.env.REACT_APP_DATADOG_CLIENT_TOKEN);
  dataDogHandler.setFormatter(new monolog.formatter.NormalizerFormatter());

  return new monolog.Logger('frontend', [
    new monolog.handler.ConsoleLogHandler(),
    dataDogHandler
  ], []);
}

/**
 * @type {monolog.Logger}
 */
export const Log = logFactory();
