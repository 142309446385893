import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import API from 'api/user'
import Modal from 'components/Modal'
import AddItem from './AddItem'
import ItemsList from './ItemsList'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import Box from '@material-ui/core/Box'
import { RoundButton } from '../../../components/UI'
import { useMonitoredDataStyles } from '../MnitoredData.styles'

const MonitoredDataEmails = () => {
  const { t } = useTranslation();
  const classes = useMonitoredDataStyles()
  let isSubscribed = true
  const [isOpen, toggleModal] = useState(false)
  useEffect(() => {
    if (isSubscribed) {
      getEmailsList()
    }
    return () => isSubscribed = false
  }, [])

  const [loading, setLoading] = useState(true);
  const [protectedEmails, setProtectedEmails] = useState([])

  const addEmail = (payload) => {
    return API.addProtectedEmail(payload)
      .then((resp) => {
        toggleModal(true)
        setTimeout(() => toggleModal(false), 4000)
        getEmailsList()
        return Promise.resolve(null)
      })
      .catch(({ errorStack }) => {
        if (errorStack) {
          errorStack.errors.forEach(err => {
            if (err.name !== 'protected_email') {
              toast(err.message, { type: toast.TYPE.ERROR })
            }
          })
          return Promise.reject(errorStack.toFormik())
        }
        toast(t("add_email_error"), { type: toast.TYPE.ERROR })
        return Promise.resolve(null)
      })
  }

  const getEmailsList = () => {
    API.getProtectedEmails()
      .then(emails => {
        setProtectedEmails(emails.reverse());
        setLoading(false);
      })
      .catch(() => toast(t("get_emails_error"), { type: toast.TYPE.ERROR }))
  }

  const deleteEmail = (email, uuid) => {
    API.deleteProtectedEmail({ uuid })
      .then(response => {
        if (response && response.status && response.status === 200) {
          toast(t("email_deleted", { email }), { type: toast.TYPE.INFO })
          getEmailsList()
        }
      })
      .catch(() => {
        toast(t("email_delete_error", { email }), { type: toast.TYPE.ERROR })
      })
  }

  const closeModalHandler = () => {
    toggleModal(false)
  }

  // split translation by sentences and display each on new line
  const setModalContent = () => {
    return {
      __html:
        t("personal_data_scans_info", {
          data_type: `${t("data_email")}`
        })
          .split('.')
          .join('.<br/>')
    }
  }

  return (
    <Box mt={3}>
      {isOpen &&
        <Modal
          className={classes.modalComponent}
          center
          handleClose={closeModalHandler}
        >
          <RoundButton
            className={classes.closeBtn}
            onClick={closeModalHandler}
          >
            <CloseIcon />
          </RoundButton>
          <div className={classes.modalContent}>
            <h3 dangerouslySetInnerHTML={setModalContent()} />
          </div>
        </Modal>
      }
      <AddItem addEmail={addEmail} t={t} />
      {!!protectedEmails && <ItemsList
        t={t}
        protectedEmails={protectedEmails}
        deleteEmail={deleteEmail}
        loading={loading}
      />
      }
    </Box>
  )
}

export default MonitoredDataEmails
