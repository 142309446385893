import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    width: "100%",
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[200],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.variables.$green100,
  },
}))(LinearProgress);

const ProgressBar = ({ value, maxValue }) => {
  return (
    <StyledLinearProgress
      variant="determinate"
      value={Math.round((100 / maxValue) * value)}
    />
  );
};

export default ProgressBar;
