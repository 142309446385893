import React, { useState } from 'react'
import ActivateItem from '../ActivateItem'
import FlipCard from 'components/FlipCard'
import { ReactComponent as PersonIcon } from '../../../../assets/icons/person.svg'
import { ReactComponent as FamilyIcon } from '../../../../assets/icons/family.svg'

import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import { BodyText } from '../../../../components/UI'
import { useCardItemStyles } from '../../CardItem.styles'

const EmailItemCard = ({ t, data, deleteEmail, userEmail }) => {
  const classes = useCardItemStyles()
  const [fliped, setFlied] = useState(false)

  return (
    <FlipCard
      className={`${classes.cardItem} ${
        fliped ? "flipable" : ""
      } sa_monitored_data_email_item`}
      toggle={fliped}
      back={
        <Box textAlign="center">
          <Box position="relative">
            <BodyText>{t("delete_emails_warning")}</BodyText>
          </Box>
          <Box>
            <Button
              style={{ margin: "0 4px" }}
              size="small"
              variant="contained"
              color="primary"
              id="sa_monitored_data_confirm_delete"
              onClick={() => deleteEmail(data.email, data.uuid)}
            >
              {t("emails_delete.delete_email")}
            </Button>
            <Button
              style={{ margin: "0 4px" }}
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => setFlied(false)}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      }
    >
      <Box className={classes.frontWrapper}>
        <Box display="flex" alignItems="center">
          <Tooltip
            arrow
            placement="top"
            title={`${
              data.isFamily ? t("family_member") : t("non_family_member")
            }`}
          >
            <span className={classes.emailType}>
              {data.isFamily ? (
                <FamilyIcon width="22px" height="22px" />
              ) : (
                <PersonIcon width="22px" height="22px" />
              )}
            </span>
          </Tooltip>
          <span className={classes.info}>{data.email}</span>
        </Box>
        <Box className={classes.btns} mx={-0.5}>
          {!data.is_active && userEmail !== data.email && (
            <ActivateItem uuid={data.uuid} email={data.email} t={t} />
          )}
          {data.is_updatable && (
            <Box>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  setFlied(true);
                }}
                className="sa_monitored_data_email_delete"
                style={{
                  margin: "0 4px",
                }}
              >
                {t("delete")}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </FlipCard>
  );
}

export default EmailItemCard
