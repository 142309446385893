import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import Page from 'components/Page'
import Logo from 'components/Logo'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { CardTitle } from '../../components/UI'

const ExpiredPlanPage = () => {
  const { t } = useTranslation()
  const { locale } = useParams()
  const history = useHistory()

  const logout = () => {
    history.push(`/${locale}/logout`)
  }

  return (
    <Page name="planExpired">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Box>
          <Box mb={6} textAlign='center'>
            <Logo  preventRedirection={true} />
          </Box>
          <Paper>
            <Box p={3}>
              <CardTitle>
                {t("ask_your_admin_to_renew_subscription")}
              </CardTitle>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" color="primary" onClick={logout}>
                  {t("back_to_login_page")}
                </Button>
              </div>
            </Box>
          </Paper>
        </Box>
      </div>
    </Page>
  )
}

export default ExpiredPlanPage;