import { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { clearIdentity } from "../../store/actions/identity";

const Logout = ({ history, match, partnerSettings, clearIdentity }) => {
  const { User, setJWT } = useContext(AuthContext);

  useEffect(() => {
    const { redirectBackUrl, isCrossServicePartner } = partnerSettings;
    clearIdentity();
    User.logout();
    setJWT(null);
    if (isCrossServicePartner && redirectBackUrl) {
      const state =
        history.location.state && history.location.state.logoutState === 0
          ? 0
          : 1;
      window.location.href = `${redirectBackUrl}?state=${state}`;
    } else {
      history.push({ pathname: `/${match.params.locale}/signIn`, state: { skipAnimation: true } });
    }
  }, []);

  return null;
};

const mapStateToProps = (state) => ({
  partnerSettings: state.partnerSettings,
});

const mapDispatchToProps = (dispatch) => ({
  clearIdentity: () => dispatch(clearIdentity()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Logout));
