import client from "../axiosInstance";

export default {
  getPolicies() {
    return client
      .get("sme/api/v1/policies/in-effect")
      .then((response) => {
        if (response && response.data)
          return Promise.resolve(response.data.items);
      })
      .catch((err) => {
        throw err;
      });
  },
  getPolicyByID(uuid) {
    return client
      .get(`sme/api/v1/policies/${uuid}/`)
      .then((response) => {
        if (response && response.data) return Promise.resolve(response.data);
      })
      .catch((err) => {
        throw err;
      });
  },
};
