import React from 'react'
import Form from './Form'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'

const AddItem = ({ addCard, t }) => {
  return (
    <Paper >
      <Box p={3}>
        <Form addCard={addCard} t={t} />
      </Box>
    </Paper>
  )
}
export default AddItem