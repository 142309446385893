import React from 'react'
import reactStringReplace from 'react-string-replace'
import { Tooltip } from 'react-tippy'

const QuestionHeader = ({question}) => {
  let replacedText = question ? question.title : ''

  question && question.items.forEach((value) => {
    replacedText = reactStringReplace(replacedText, value.word, () => (
      <Tooltip
        className='tooltip'
        key={value.word}
        arrow
        distance={10}
        delay={100}
        position='bottom'
        title={value.description}>
        { value.word}
      </Tooltip>
    ))
  })

  return (
    <>
      {replacedText}
    </>
  )
}

export default QuestionHeader
