import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import PlaceholderBox from "components/ContentPlaceholder/PlaceholderBox";
import { CardTitle } from "../../../components/UI";

export default function UserSettingsSkeleton() {
  const { t } = useTranslation();
  return (
    <>
      <Box my={3}>
        <CardTitle variant="h2" mb={2} textAlign="inherit">
          {t("personal_information")}
        </CardTitle>
        <Paper>
          <Box p={3}>
            <PlaceholderBox height="2rem" width="100%" />
            <PlaceholderBox height="2rem" width="100%" />
            <PlaceholderBox height="2rem" width="100%" />
            <PlaceholderBox height="2rem" width="100%" mb="0" />
          </Box>
        </Paper>
      </Box>
      <Box my={3}>
        <CardTitle variant="h2" mb={2} textAlign="inherit">
          {t("change_password")}
        </CardTitle>
        <Paper>
          <Box p={3}>
            <PlaceholderBox height="2rem" width="100%" />
            <PlaceholderBox height="2rem" width="100%" />
            <PlaceholderBox height="2rem" width="100%" />
            <PlaceholderBox height="2rem" width="100%" mb="0" />
          </Box>
        </Paper>
      </Box>
      <Box my={3}>
        <CardTitle variant="h2" mb={2} textAlign="inherit">
          {t("delete_account")}
        </CardTitle>
        <Paper>
          <Box p={3}>
            <div style={{ display: "flex" }}>
              <PlaceholderBox height="2rem" width="80%" mb="0" />
              <PlaceholderBox height="2rem" width="20%" mb="0" ml="20px" />
            </div>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
