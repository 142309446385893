import { courseApi, request } from "../openapiConfig";

export async function startCourse(courseUuid) {
  return request(courseApi.startUserCourse, courseUuid);
}

export async function completeCourse(courseUuid) {
  return request(courseApi.completeUserCourse, courseUuid);
}

export function getAllCourses(opts) {
  return request(courseApi.getAllCourses, opts);
}

export async function viewUserCourse(courseUuid) {
  return request(courseApi.viewUserCourse, courseUuid);
}

export async function listQuestionsWithAnswers(uuid) {
  return request(courseApi.viewQuestionsWithAnswers, uuid);
}

export async function viewQuizScore(uuid) {
  return request(courseApi.viewUserQuiz, uuid);
}

export async function sendQuizAnswers(params) {
  return request(courseApi.createUserQuiz, params);
}

export async function completeChapterLesson(uuid) {
  return request(courseApi.completeUserLessons, uuid);
}

export async function completeTakeaway(uuid) {
  return request(courseApi.completeUserChapterTakeaway, uuid);
}

export async function completeUserCourseTakeaway(uuid) {
  return request(courseApi.completeUserCourseTakeaway, uuid);
}