import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import SRS from "helpers/systemResourcesScanner";
import DeviceAPI from "api/device";
import { setItem } from "helpers/localStorage";

import Box from "@material-ui/core/Box";
import Modal from "components/Modal";
import { CardTitle, SelectField } from "../../../components/UI";
import { RoundButton } from "../../../components/UI";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";

import useDeviceDetection from "../../../hooks/useDeviceDetection";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    position: "absolute",
    top: "10px",
    right: theme.variables.$isRTLDirection ? "auto" : "8px",
    left: theme.variables.$isRTLDirection ? "8px": "auto",
  },
  modalHeader: {
    position: "relative",
    background: theme.palette.secondary.main,
    margin: "-20px",
    position: "relative",
    marginBottom: "0",
  },
  headerText: {
    lineHeight: "40px",
    textAlign: "center",
    marginRight: "30px",
    color: theme.variables.$black,
    fontSize: "18px",
  },
  modalContent: {
    maxWidth: "480px",
    textAlign: "center",
  },
  addDeviceControls: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function AddDeviceModal({
  identity,
  maxDevicesFeature,
  partnerSettings,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { locale } = useParams();

  let systemData = SRS.run({});
  const { customNewDeviceIdenifiedModal } = partnerSettings;
  const disableUpgradePlanModal = customNewDeviceIdenifiedModal === "disableUpgradePlan";

  const [addDeviceModal, showAddDeviceModal] = useState(false);
  const [skippedToCheck, setSkippedToCheck] = useState([]);
  const [deviceUuid, setDeviceUuid] = useState(null);
  const [selectOptions, setSelectOptions] = useState([]);

  const notifyAboutNewDevice = () => {
    if (identity && identity.profile && identity.profile.is_activated) {
      showAddDeviceModal(true);
    }
  };

  const checkIfNeedToSkip = (fp) => {
    let skipThisDevice = false;
    try {
      const skippedDevices = JSON.parse(
        sessionStorage.getItem("checkedDevice")
      );
      if (skippedDevices) {
        setSkippedToCheck(skippedDevices);
        skipThisDevice = skippedDevices.indexOf(fp) !== -1;
      }
    } catch (err) {}
    return skipThisDevice;
  };

  const bulildSelectOptions = (devices, systemData) => {
    const systemOs = systemData.meta.os.product.split("_")[0].toLowerCase();
    const selectOptions = devices.reduce((options, device) => {
      const { os, uuid, name, fingerprint_browser } = device;
      if (os === systemOs) {
        options.push({
          value: uuid,
          label: name,
          fingerprint: fingerprint_browser,
        });
      }
      return options;
    }, []);
    if (selectOptions) {
      setSelectOptions(selectOptions);
    }
  };

  const { userDevices, addDevice, detectNewDevice } =
    useDeviceDetection(checkIfNeedToSkip);

  useEffect(() => {
    if (userDevices) {
      bulildSelectOptions(userDevices, systemData);
    }
  }, [userDevices]);

  useEffect(() => {
    if (maxDevicesFeature) {
      detectNewDevice(notifyAboutNewDevice);
    }
  }, [maxDevicesFeature]);

  const chooseFromExistingDevices = ({ target }) => {
    const selectedDeviceUuid =
      target.value && target.value.value ? target.value.value : "";
    const device = userDevices.find(
      (device) => device.uuid === selectedDeviceUuid
    );
    DeviceAPI.updateDevice(
      { ...device, fingerprint: systemData.fingerprint_v2, scan_by: "browser" },
      selectedDeviceUuid
    )
      .then(() => {
        setItem("fingerprint", systemData.fingerprint_v2);
        showAddDeviceModal(false);
      })
      .catch(() => {});
  };

  const upgradePlanFlow = () => {
    const device = prepareDeviceData();
    setItem("detectedDevice", JSON.stringify(device));

    return history.push({
      pathname: `/${locale}/profile/plans`,
      state: { message_key: "device_limit_standard_msg" },
    });
  };

  const replaceDeviceFlow = () => {
    const device = prepareDeviceData();
    setItem("detectedDevice", JSON.stringify(device));
    return history.push(`/${locale}/devices/replace`);
  };

  const addDetectedDevice = async () => {
    try {
      const device = prepareDeviceData(true);
      const { data } = await addDevice(device);
      setDeviceUuid(data.uuid);
      toast(t("device_add_success"), { type: toast.TYPE.INFO });
    } catch (err) {
      if (process.env.NODE_ENV === "development") {
        console.log(err);
      }
    }
  };

  const handleYes = () => {
    const isLimitExceeded = maxDevicesFeature.maxValue <= userDevices.length;
    if (isLimitExceeded) {
      sessionStorage.setItem(
        "checkedDevice",
        JSON.stringify([...skippedToCheck, systemData.fingerprint_v2])
      );
    }
    const plan = identity.plan;
    if (plan && (plan.trial || plan.slug === "standard")) {
      upgradePlanFlow();
    }
    if (
      plan &&
      (plan.slug === "advantage" || plan.slug === "ultimate") &&
      isLimitExceeded
    ) {
      replaceDeviceFlow();
    }

    //add device if max_devices feature is not exceeded
    addDetectedDevice();
  };

  const handleNo = () => {
    sessionStorage.setItem(
      "checkedDevice",
      JSON.stringify([...skippedToCheck, systemData.fingerprint_v2])
    );
    showAddDeviceModal(false);
  };

  const prepareDeviceData = (auto_activate) => {
    let deviceData = SRS.run({});
    const date = new window.Date();
    deviceData.name = `${deviceData.meta.os.product}_${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    deviceData.auto_activate = auto_activate || false;
    deviceData.fingerprint_browser = null;
    deviceData.fingerprint = deviceData.fingerprint_v2;
    delete deviceData.fingerprint_v2;
    const osProduct = deviceData.meta.os.product.toLowerCase();
    deviceData.os = `${
      osProduct.indexOf("android") > -1
        ? "android"
        : osProduct.indexOf("windows") > -1
        ? "windows"
        : deviceData.device_type === "phone" ||
          deviceData.device_type === "tablet"
        ? "ios"
        : "macosx"
    }`;
    delete deviceData.meta;
    return deviceData;
  };

  return (
    <>
      {addDeviceModal && (
        <Modal center handleClose={handleNo}>
          <div className={classes.modalHeader}>
            <div className={classes.headerText}>
              {disableUpgradePlanModal
                ? t("cx.for_your_information")
                : t("device_assessment")}
            </div>
            <RoundButton
              // color='primary'
              style={{ color: "#111" }}
              className={classes.closeBtn}
              onClick={handleNo}
            >
              <CloseIcon />
            </RoundButton>
          </div>
          <div className={classes.modalContent}>
            {deviceUuid ? (
              <>
                <Box my={3}>
                  <CardTitle variant="h2" mb={0} textAlign="center">
                    {t("we_have_acctivated_your_device")}
                  </CardTitle>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  className="sa_devices_device_assessment"
                  onClick={() => {
                    history.push(`/${locale}/deviceScanning/${deviceUuid}`);
                  }}
                >
                  {t("take_device_assessment")}
                </Button>
              </>
            ) : (
              <>
                <Box my={3}>
                  {disableUpgradePlanModal ? (
                    <CardTitle variant="h2" mb={0} textAlign="center">
                      {t("cx.add_device_modal.you_reached_plan_limit")}
                    </CardTitle>
                  ) : (
                    <CardTitle variant="h2" mb={0} textAlign="center">
                      {t("device_identified_as_new")}
                      {selectOptions.length !== 0 &&
                        t("select_device_from_the_list")}
                    </CardTitle>
                  )}
                </Box>
                {selectOptions.length !== 0 && (
                  <Box mb={3}>
                    <SelectField
                      name="replace-with-selected-device"
                      options={selectOptions}
                      placeholder={`${t("select")}...`}
                      onChange={chooseFromExistingDevices}
                    />
                  </Box>
                )}
                <div className={classes.addDeviceControls}>
                  {disableUpgradePlanModal ? (
                    <Box mx={1}>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleNo}
                      >
                        {t("cx.back")}
                      </Button>
                    </Box>
                  ) : (
                    <>
                      <Box mx={1}>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={handleYes}
                        >
                          {t("yes")}
                        </Button>
                      </Box>
                      <Box mx={1}>
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          onClick={handleNo}
                        >
                          {t("no")}
                        </Button>
                      </Box>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </Modal>
      )}
    </>
  );
}
