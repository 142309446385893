import React from 'react'
import { withRouter } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { ReactComponent as EyeIcon } from '../../../../assets/icons/malware_icon.svg'
import { CardTitle, BodyText } from '../../../../components/UI'
import RippleLoading from '../RippleLoading'

const ScanAccountVulnerability = ({ t, browser, os }) => {
  const renderScannerSteps = (browser, os) => {
    if (browser === "scanning" && !os) {
      return t("scanning_browser")
    }
    if (browser && browser !== "scanning" && !os) {
      return `${t("found")}: ${browser.vendor} ${browser.product} ${browser.version}`
    }
    if (os === "scanning") {
      return t("scanning_os")
    }
    if (os && os !== "scanning") {
      return `${t("found")}: ${os.vendor} ${os.product.split("_")[0]} ${os.product.split("_")[1]}`
    }
  }

  return (
    <Box maxWidth='500px' margin='0 auto'>
      <Box mb={2}>
        <RippleLoading
          mb={2}
          icon={<EyeIcon />}
        />
        <CardTitle textAlign='inherit'>
          {t("account_vulnerability_header")}
        </CardTitle>
        <BodyText textAlign='inherit'>
          {t("account_vulnerability_subheader")}
        </BodyText>
        <BodyText textAlign='inherit'>
          {t("personal_data_scans_info", { data_type: `${t("device_for_vulnerabilities")}` })}
        </BodyText>
      </Box>
      <Box ml={5}>
        <BodyText textAlign='inherit'>
          {renderScannerSteps(browser, os)}
        </BodyText>
      </Box>
    </Box>
  )
}

export default withRouter(ScanAccountVulnerability)
