import React from 'react'
import { toast, Zoom } from "react-toastify";
import ToastContent from './ToastContent';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
// to use you must pass the parameter: 
// message, 
// one of type toast (confirm,info,error,alert,question,lightning),
// url(not required),
// autoclose(true/false) toast - default true
// example : ToastWrapper(t("can_t_get_tasks"),"confirm","https://www.google.com",autoclose);


const CloseButton = ({ closeToast }) => (
	<div
		type="button"
		onClick={closeToast}
		style={{
			position: "absolute",
			right: 20,
			top: 15
		}}
	>
		<CloseIcon fill="var(--text-alert)" />
	</div>
);

function ToastWrapper(description, type, url, autoclose = true, onClose = () => { }, pauseOnHover, pauseOnFocusLoss) {
	return toast(<ToastContent
		description={description}
		url={url}
		type={type}
	/>, {
		onClose: onClose,
		type: toast.TYPE.DEFAULT,
		autoClose: autoclose,
		pauseOnHover: pauseOnHover !== undefined ? pauseOnHover : true,
		pauseOnFocusLoss: pauseOnFocusLoss !== undefined ? pauseOnFocusLoss : true,
		className: `${document.body.offsetWidth < 750 ? 'toast-box-mobile' : 'toast-box'} ${type}`,
		position: toast.POSITION.TOP_RIGHT,
		closeOnClick: false,
		draggablePercent: 30,
		transition: Zoom,
		progressClassName: `progress-${type}`,
		closeButton: <CloseButton />
	})
}

export default ToastWrapper;