import React, { useEffect, useState } from "react";
import Page from "components/Page";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { CardTitle, SquareButton } from "components/UI";
import { ReactComponent as BackArrowIcon } from "../../../assets/icons/arrow-back.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PROFILE_TYPE_STAFF } from "constants/common";
import { useSelector } from "react-redux";
import { useIdentityFeature } from "hooks/useIdentiyFeature";
import PolicyAPI from "../../../api/policies/index";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerGroup: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  contentWrapper: {
    overflow: "auto",
  },
  btn: {
    "& svg": {
      width: "16px",
      height: "16px",
      marginRight: theme.variables.$isRTLDirection ? "8px" : "0"
    },
  },
  download: {
    all: "unset",
  },
}));

export const PolicyDetail = ({}) => {
  const classes = useStyles();
  const history = useHistory();
  const { locale, uuid } = useParams();
  const { t } = useTranslation();
  const identity = useSelector((state) => state.identity);
  const hasPoliciesFeature = useIdentityFeature(identity, "sme_policy_module");
  const [policy, setPolicy] = useState(null);

  if (
    identity &&
    !hasPoliciesFeature &&
    identity.profile.profile_type.toUpperCase() !== PROFILE_TYPE_STAFF
  )
    return <Redirect to={`/${locale}/not-found`} />;

  const fetchPolicy = async () => {
    await PolicyAPI.getPolicyByID(uuid)
      .then((res) => setPolicy(res))
      .catch((err) => history.push(`/${locale}/not-found`));
  };

  useEffect(() => {
    fetchPolicy();
  }, []);

  return (
    <Page name="policydetails" withAside withSubHeader>
      {policy && (
        <div>
          <Box className={classes.headerWrapper}>
            <Box className={classes.headerGroup}>
              <SquareButton
                variant="contained"
                color="secondary"
                onClick={() => {
                  history.push(`/${locale}/policies`);
                }}
                aria-label="go back"
              >
                <BackArrowIcon />
              </SquareButton>
              <CardTitle mt={3}>{policy.title}</CardTitle>
            </Box>
            <a
              className={classes.download}
              href={policy.pdf_download_link}
              download
            >
              <Button
                className={classes.btn}
                size="small"
                name="download policy"
                endIcon={<DownloadIcon />}
              >
                {t("retail.policy.download_policy")}
              </Button>
            </a>
          </Box>
          <Box
            className={classes.contentWrapper}
            dangerouslySetInnerHTML={{ __html: policy.content }}
          />
        </div>
      )}
    </Page>
  );
};
