/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UserQuizUserQuizViewResponse model module.
 * @module model/UserQuizUserQuizViewResponse
 * @version 1.0.0
 */
class UserQuizUserQuizViewResponse {
    /**
     * Constructs a new <code>UserQuizUserQuizViewResponse</code>.
     * @alias module:model/UserQuizUserQuizViewResponse
     * @param countAnswers {Number} 
     * @param countCorrectAnswers {Number} 
     * @param createdAt {Number} 
     * @param isCompleted {Boolean} 
     * @param quizUuid {String} 
     * @param userCourseUuid {String} 
     * @param uuid {String} 
     */
    constructor(countAnswers, countCorrectAnswers, createdAt, isCompleted, quizUuid, userCourseUuid, uuid) { 
        
        UserQuizUserQuizViewResponse.initialize(this, countAnswers, countCorrectAnswers, createdAt, isCompleted, quizUuid, userCourseUuid, uuid);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, countAnswers, countCorrectAnswers, createdAt, isCompleted, quizUuid, userCourseUuid, uuid) { 
        obj['count_answers'] = countAnswers;
        obj['count_correct_answers'] = countCorrectAnswers;
        obj['created_at'] = createdAt;
        obj['is_completed'] = isCompleted;
        obj['quiz_uuid'] = quizUuid;
        obj['user_course_uuid'] = userCourseUuid;
        obj['uuid'] = uuid;
    }

    /**
     * Constructs a <code>UserQuizUserQuizViewResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserQuizUserQuizViewResponse} obj Optional instance to populate.
     * @return {module:model/UserQuizUserQuizViewResponse} The populated <code>UserQuizUserQuizViewResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserQuizUserQuizViewResponse();

            if (data.hasOwnProperty('count_answers')) {
                obj['count_answers'] = ApiClient.convertToType(data['count_answers'], 'Number');
            }
            if (data.hasOwnProperty('count_correct_answers')) {
                obj['count_correct_answers'] = ApiClient.convertToType(data['count_correct_answers'], 'Number');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Number');
            }
            if (data.hasOwnProperty('is_completed')) {
                obj['is_completed'] = ApiClient.convertToType(data['is_completed'], 'Boolean');
            }
            if (data.hasOwnProperty('quiz_uuid')) {
                obj['quiz_uuid'] = ApiClient.convertToType(data['quiz_uuid'], 'String');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Number');
            }
            if (data.hasOwnProperty('user_course_uuid')) {
                obj['user_course_uuid'] = ApiClient.convertToType(data['user_course_uuid'], 'String');
            }
            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UserQuizUserQuizViewResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UserQuizUserQuizViewResponse</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of UserQuizUserQuizViewResponse.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['quiz_uuid'] && !(typeof data['quiz_uuid'] === 'string' || data['quiz_uuid'] instanceof String)) {
            throw new Error("Expected the field `quiz_uuid` to be a primitive type in the JSON string but got " + data['quiz_uuid']);
        }
        // ensure the json data is a string
        if (data['user_course_uuid'] && !(typeof data['user_course_uuid'] === 'string' || data['user_course_uuid'] instanceof String)) {
            throw new Error("Expected the field `user_course_uuid` to be a primitive type in the JSON string but got " + data['user_course_uuid']);
        }
        // ensure the json data is a string
        if (data['uuid'] && !(typeof data['uuid'] === 'string' || data['uuid'] instanceof String)) {
            throw new Error("Expected the field `uuid` to be a primitive type in the JSON string but got " + data['uuid']);
        }

        return true;
    }


}

UserQuizUserQuizViewResponse.RequiredProperties = ["count_answers", "count_correct_answers", "created_at", "is_completed", "quiz_uuid", "user_course_uuid", "uuid"];

/**
 * @member {Number} count_answers
 */
UserQuizUserQuizViewResponse.prototype['count_answers'] = undefined;

/**
 * @member {Number} count_correct_answers
 */
UserQuizUserQuizViewResponse.prototype['count_correct_answers'] = undefined;

/**
 * @member {Number} created_at
 */
UserQuizUserQuizViewResponse.prototype['created_at'] = undefined;

/**
 * @member {Boolean} is_completed
 */
UserQuizUserQuizViewResponse.prototype['is_completed'] = undefined;

/**
 * @member {String} quiz_uuid
 */
UserQuizUserQuizViewResponse.prototype['quiz_uuid'] = undefined;

/**
 * @member {Number} updated_at
 */
UserQuizUserQuizViewResponse.prototype['updated_at'] = undefined;

/**
 * @member {String} user_course_uuid
 */
UserQuizUserQuizViewResponse.prototype['user_course_uuid'] = undefined;

/**
 * @member {String} uuid
 */
UserQuizUserQuizViewResponse.prototype['uuid'] = undefined;






export default UserQuizUserQuizViewResponse;

