import React, {
  useState,
  useContext,
  createContext,
} from 'react';
import { useSelector } from 'react-redux'
import { getSteps } from '../components/UserTour'
import useFlagSettings from '../hooks/useFlagSettings'
import { useIdentityFeature } from "hooks/useIdentiyFeature";

const UserTourContext = createContext({})

const UserTourContextProvider = ({ children }) => {
  const identity = useSelector(state => state.identity)
  const partnerSettings = useSelector(state => state.partnerSettings)
  const hasDeviceFeature = useIdentityFeature(identity, 'max_devices')

  const [canStartTour, setCanStartTour] = useState(false)
  const [userTourStep, setUserTourStep] = useState(1);
  const { flagsMap, userSettings, updateUserSetting } = useFlagSettings()

  const goToNextStep = (steps, step, skipNextStep) => {
    if (step > steps.length) {
      return skipTour()
    }
    // skip tour steps for business stuff accounts
    if (step === 3 && !hasDeviceFeature) {
      step = 5
    }

    // skip step after this if skipNextStep array of current step contain partner slug

    if (skipNextStep && skipNextStep.indexOf(partnerSettings.partnerSlug) !== -1) {
      step += 1
    }
    setUserTourStep(step)
  }

  const skipTour = () => {
    if (!userSettings[flagsMap.USER_TOUR_SHOWN]) {
      updateUserSetting(flagsMap.USER_TOUR_SHOWN, true)
    }
    setUserTourStep(1)
    setCanStartTour(false)
  }

  const defaultContext = {
    canStartTour,
    setCanStartTour,
    userTourStep,
    setUserTourStep,
    goToNextStep,
    skipTour,
    isReady: !!partnerSettings && !!userSettings,
    steps: getSteps(),
  }

  return (
    <UserTourContext.Provider value={defaultContext}>
      {children}
    </UserTourContext.Provider>
  )
}

const useUserTourContext = () => useContext(UserTourContext)

export { UserTourContext, UserTourContextProvider, useUserTourContext }