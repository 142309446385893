import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function RadioGroupQuestion({
  alias,
  answerVariants,
  handleChange,
}) {
  return (
    <RadioGroup aria-label="assessment" name={alias} onChange={handleChange}>
      {answerVariants.map((option) => (
        <FormControlLabel
          style={{ marginLeft: 0, marginRight: 0 }}
          key={option.uuid}
          value={option.uuid}
          control={<Radio color="primary" />}
          label={option.value}
        />
      ))}
    </RadioGroup>
  );
}
