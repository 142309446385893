import client from '../axiosInstance';

export default {
  getPartnerSettingsByDomain(payload, options) {
    const headers = { 'Authorization': null }
    return client.get(`/cross-service/api/v1/partner/by-domain/${payload.domain}`, { ...options, headers: { ...options.headers, ...headers } })
      .then(response => {
        if (response.data && response.data.partner_settings) {
          return Promise.resolve(response.data.partner_settings)
        }
      })
  },
  getPhonesPrefixes(partner) {
    return client.get(`/cross-service/api/v1/partner/${partner}/phone`, {headers: {'Authorization': null}})
  }

}
