import { useContext, useEffect } from 'react'
import { getItem } from 'helpers/localStorage'
import { usePlansContext } from '../contexts'

export const usePlanFromCMS = () => {
  const { planFromCMS, setCMSPlan } = usePlansContext()
  const cmsPlan = getItem('planFromCms')

  useEffect(() => {
    if (!planFromCMS && cmsPlan) {
      getPlanFromCMS()
    }
  }, [planFromCMS])

  const getPlanFromCMS = () => {
    if (cmsPlan) {
      try {
        const plan = JSON.parse(cmsPlan)
        setCMSPlan(plan)
      } catch(err) {}
    }
  }
}
