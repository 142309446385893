import React, { useEffect } from "react";
import get from "lodash/get";
import passwordStrength from "helpers/passwordStrength";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  passwordStrength: {
    paddingLeft: "30px",
    marginBottom: "20px",
    "&:after": {
      content: '""',
      display: "table",
      clear: "both",
    },
  },
  indicator: {
    position: "relative",
    color: theme.variables.$textDarkGray,
    marginRight: theme.variables.$isRTLDirection ? 0 : "80px",
    marginLeft: theme.variables.$isRTLDirection ? "80px" : 0,
    marginTop: "15px",
    marginBottom: "15px",
    float: theme.variables.$isRTLDirection ? "right" : "left",
    "&:before": {
      position: "absolute",
      top: "3px",
      left: theme.variables.$isRTLDirection ? 0 : "-20px",
      right: theme.variables.$isRTLDirection ? "-20px" : 0,
      content: '""',
      width: "10px",
      height: "10px",
      background: "none",
      border: `2px solid ${theme.variables.$darkGray}`,
      borderRadius: "50%",
    },
    "&.-red": {
      "&:before": {
        borderColor: theme.variables.$red100,
      },
      "&.-filled": {
        "&:before": {
          background: theme.variables.$red100,
        },
      },
    },
    "&.-green": {
      "&:before": {
        borderColor: theme.variables.$green100,
      },
      "&.-filled": {
        "&:before": {
          background: theme.variables.$green100,
        },
      },
    },
  },
}));

const passwordStrengthComponent = ({ value, t, onValidChange = () => {} }) => {
  if (!value) {
    return null;
  }

  const classes = useStyles();
  const strength = passwordStrength(value);
  let errors = strength.errors;

  useEffect(() => {
    onValidChange(strength.isValid);
  }, [strength.isValid]);

  return (
    <ul className={classes.passwordStrength}>
      <li
        className={`
          -filled
          ${classes.indicator}
          ${!get(errors, "passwordStrength.uppercase") ? "-red" : "-green"}
          `}
      >
        {t("uppercase")}
      </li>
      <li
        className={`
          -filled
          ${classes.indicator}
          ${!get(errors, "passwordStrength.lowercase") ? "-red" : "-green"}
        `}
      >
        {t("lowercase")}
      </li>
      <li
        className={`
          -filled
          ${classes.indicator}
          ${!get(errors, "passwordStrength.numbers") ? "-red" : "-green"}
          `}
      >
        {t("numbers")}
      </li>
      <li
        className={`
          -filled
          ${classes.indicator}
          ${
            !get(errors, "passwordStrength.charactersLength")
              ? "-red"
              : "-green"
          }
        `}
      >
        {t("x_characters", { number: value.length })}
      </li>
      <li
        className={`
          -filled
          ${classes.indicator}
          ${
            !get(errors, "passwordStrength.specialCharacters")
              ? "-red"
              : "-green"
          }
        `}
      >
        {t("special_characters")}
      </li>
    </ul>
  );
};

export default passwordStrengthComponent;
