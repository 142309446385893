import isLength from 'validator/lib/isLength'
import set from 'lodash/set'

export const passwordStrength = value => {
  if (!value) {
    return {
      errors: {},
      isValid: false
    }
  }

  let errors = {}
              
  // password strength
  // uppercase
  if (
    value !== '' &&
    value.match(/[A-Z]/) === null
  ) {
    set(errors, 'passwordStrength.uppercase', false)
  } else if (
    value !== '' &&
    value.match(/[A-Z]/) !== null
  ) {
    set(errors, 'passwordStrength.uppercase', true)
  }
  
  // lowercase
  if (
    value !== '' &&
    value.match(/[a-z]/) === null
  ) {
    set(errors, 'passwordStrength.lowercase', false)
  } else if (
    value !== '' &&
    value.match(/[a-z]/) !== null
  ){
    set(errors, 'passwordStrength.lowercase', true)
  }
  
  // min length  
  if (
    value !== '' &&
    isLength(value, {min: 8})
    ) {
      set(errors, 'passwordStrength.charactersLength', true)
    } else if (
      value !== '' &&
      !isLength(value, {min: 8})
    ) {
      set(errors, 'passwordStrength.charactersLength', false)
    }

  // numbers
  if (
    value !== '' &&
    value.match(/[0-9]/) === null
  ) {
    set(errors, 'passwordStrength.numbers', false)
  } else if(
    value !== '' &&
    value.match(/[0-9]/) !== null    
  ) {
    set(errors, 'passwordStrength.numbers', true)
  }

  // special characters
  if (
    value !== '' &&
    // value.match(/[\`\~\!\@\#\$\%\^&\*\(\)\-\_\=\+\[\]\{\}\\\|\:\;\"\'\<\>\,\.\?\/]/) !== null
    value.match(/\W/) !== null
  ) {
    set(errors, 'passwordStrength.specialCharacters', true)
  } else if (
    value !== '' &&
    // value.match(/[\`\~\!\@\#\$\%\^&\*\(\)\-\_\=\+\[\]\{\}\\\|\:\;\"\'\<\>\,\.\?\/]/) === null
    value.match(/\W/) === null
  ) {
    set(errors, 'passwordStrength.specialCharacters', false)
  }

  // if is valid
  let isValid = false

  if (
    errors.passwordStrength &&
    errors.passwordStrength.uppercase === true &&
    errors.passwordStrength.lowercase === true &&
    errors.passwordStrength.numbers === true &&
    errors.passwordStrength.specialCharacters === true &&
    errors.passwordStrength.charactersLength === true
  ) {
    isValid = true
  }

  return {
    errors: errors,
    isValid: isValid
  }
}

export default passwordStrength
