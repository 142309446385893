import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

import FlipCard from 'components/FlipCard'
import { BodyText } from '../../../../components/UI'
import { useCardItemStyles } from '../../CardItem.styles'

const DeviceCard = ({ data, deleteCard }) => {
  const { t } = useTranslation()
  const classes = useCardItemStyles()
  const [fliped, setFlied] = useState(false)

  return (
    <FlipCard
      className={`${classes.cardItem} ${fliped ? 'flipable' : ''} sa_monitored_data_card_item`}
      toggle={fliped}
      back={
        <Box textAlign='center'>
          <Box position='relative'>
            <BodyText>
              {t("delete_card_warning")}
            </BodyText>
          </Box>
          <Box>
          <Button
            size='small'
            variant='contained'
            color='primary'
            id="sa_monitored_data_card_confirm_delete"
            onClick={() => deleteCard(data.cardNumber, data.uuid)}
          >
            {t("delete_credit_card")}
          </Button>
            <Button
              style={{ margin: '0 4px' }}
              size='small'
              variant='contained'
              color='secondary'
              onClick={() => setFlied(false)}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      }
    >
      <Box className={classes.frontWrapper} mx={-0.5}>
        <Box display='flex' alignItems='center' mx={0.5}>
          <span className={classes.info}>{data.number}</span>
        </Box>
        <div className={classes.btns}>
          <Box mx={0.5}>
            <Button
              size='small'
              variant='contained'
              color='primary'
              onClick={() => { setFlied(true) }}
              className='sa_monitored_data_delete'
            >
              {t("delete")}
            </Button>
          </Box>
        </div>
      </Box>
    </FlipCard>
  )
}

export default DeviceCard
