import {
  SET_QUESTIONS,
  SET_ANSWER,
  COMPLETE_QUIZ,
  RESET_QUIZ,
} from "../constants";

const initialState = {
  questions: [],
  answers: [],
  isQuizCompleted: false,
};

export default function quizes(state = initialState, { type, payload }) {
  switch (type) {
    case SET_QUESTIONS:
      return {
        ...state,
        questions: payload,
      };
    case SET_ANSWER:
      return {
        ...state,
        answers: [
          ...state.answers.filter(answer => answer.question_uuid !== payload.questionUuid),
          {
            answer_question_item_uuid: payload.answer,
            question_uuid: payload.questionUuid,
          },
        ],
      };
    case COMPLETE_QUIZ:
      return {
        ...state,
        isQuizCompleted: true,
      };
    case RESET_QUIZ:
      return {
        ...state,
        answers: [],
        isQuizCompleted: false,
        questions: state.questions,
      };
    default:
      return state;
  }
}