import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { resetQuiz } from "store/actions/quiz";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  scoreWrapper: {
    position: "relative",
    "& svg": {
      width: "100%",
    },
  },
  scoreText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  retakeQuizBtn: {
    backgroundColor: "rgba(92, 194, 247, 0.8)",
    "&:hover": {
      backgroundColor: "rgba(92, 194, 247, 1)",
    },
  },
}));

const QuizScore = ({ correctAnswers, total, isPassed }) => {
  const percentage = Math.round((correctAnswers / total) * 100);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleRetake = () => {
    dispatch(resetQuiz());
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.scoreWrapper}>
        <svg
          width="358"
          height="358"
          viewBox="0 0 358 358"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: "rotate(-90deg)",
          }}
        >
          <circle
            r="178"
            cx="179"
            cy="179"
            fill="transparent"
            stroke="#EAECF0"
            strokeWidth="1"
            strokeDasharray="1118px"
            strokeDashoffset="0"
            shapeRendering="geometricPrecision"
          ></circle>
          <circle
            r="121"
            cx="179"
            cy="179"
            fill="transparent"
            stroke="#EAECF0"
            strokeWidth="32"
            strokeDasharray="759.88px"
            strokeDashoffset="0"
            shapeRendering="geometricPrecision"
          ></circle>
          <circle
            r="121"
            cx="179"
            cy="179"
            stroke="#57a022"
            strokeWidth="32"
            strokeLinecap="round"
            fill="transparent"
            strokeDasharray="759.88px"
            shapeRendering="geometricPrecision"
            style={{
              strokeDashoffset: `calc(759.88px * ((100 - ${percentage}) / 100))`,
            }}
          ></circle>
        </svg>
        <Box className={classes.scoreText}>
          <Typography variant="h2">{`${correctAnswers} / ${total}`}</Typography>
          <Typography variant="body1">{t("retail.your_score")}</Typography>
        </Box>
      </Box>
      <Box mt={4}>
        <Typography variant="h2">{t("retail.quiz_completed")}</Typography>
      </Box>
      {!isPassed && (
        <Box mt={2} mb={2}>
          <Button
            variant="contained"
            className={classes.retakeQuizBtn}
            onClick={handleRetake}
          >
            {t("retail.retake_quiz")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default QuizScore;
