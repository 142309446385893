import React from 'react'
import { connect } from 'react-redux'
import selectPlanStatus from 'constants/selectPlanStatus';
import { removeItem } from 'helpers/localStorage'
import PaypalButton from 'components/PaypalButton/PaypalButton'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { SquareButton } from '../UI';
import { ReactComponent as IconArrowBack } from '../../assets/icons/arrow-back.svg'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	paymentCard: {
	},
	selected: {
		marginTop: '20px',
		display: 'flex'
	},
	price: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minWidth: '80px',
		minHeight: '50px',
		background: theme.palette.primary.main,
		color: '#fff',
		padding: '10px',
		flexShrink: 0,
	},
	planCurrency: {
		fontSize: '12px',
		height: '80%'
	},
	planInfo: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: '10px',
		width: '100%',
	},
	name: {
		fontSize: '20px',
		textTransform: 'capitalize'
	},
	desc: {
		color: '#999'
	},
	planStatusBox: {
		marginTop: '20px',
		'&.init': {
			display: 'none'
		},
		'&.processing': {},
		'&.success': {
			backgroundColor: theme.variables.$green10,
			color: theme.variables.$green100,
			border: 0,
		},

		'&.error': {
			backgroundColor: theme.variables.$red10,
			color: theme.variables.$red100,
			border: 0
		}
	}
}))

const Payments = ({
	t,
	selectedPlan,
	requestStatus,
	history,
	setPlanStatusRequest,
	match,
	identity,
}) => {
	const classes = useStyles()
	const { price, slug, price_offered } = selectedPlan
	const [planName] = selectedPlan.slug_full.split('.')
	const isPlan = identity && identity.plan
	const getStatusMessage = () => {
		switch (requestStatus) {
			case selectPlanStatus.PROCESSING: return t('plan_status_processing')
			case selectPlanStatus.SUCCESS: return t('plan_status_success', { plan: t(planName) })
			case selectPlanStatus.ERROR: return t('plan_status_error')
			default: return '';
		}
	}

	const goBack = () => {
		removeItem('planFromCms');
		history.goBack();
	}

	const redirectTo = () => {
		const nextPage = !!isPlan ? `/${match.params.locale}/dashboard` : `/${match.params.locale}/start-scan`;
		setPlanStatusRequest(selectPlanStatus.INIT)
		history.push(nextPage)
	}

	return (
		<div className={classes.paymentCard}>
			<h3>{!!isPlan ? t("finish_upgrade") : t("finish_signup")}</h3>
			<Paper
				variant='outlined'
				className={classes.selected}
			>
				<div className={classes.planInfo}>
					<div className={classes.desc}>{'/ ' + t(slug)}</div>
					<div className={classes.name}>{planName}</div>
				</div>
				<div className={classes.price}>
					<h4>{price_offered ? price_offered.amount : price.amount}</h4>
					<span className={classes.planCurrency}>{price.currency}</span>
				</div>
			</Paper>
			<Paper
				className={`${classes.planStatusBox} ${requestStatus}`}
			>
				<Box p={4}>
					{getStatusMessage()}
				</Box>
			</Paper>
			{requestStatus === selectPlanStatus.INIT
				&& <PaypalButton
					t={t}
					history={history}
					match={match}
					setPlanStatusRequest={setPlanStatusRequest}
					selectedPlan={selectedPlan}
				/>
			}
			<Box display='flex'>
				{requestStatus === selectPlanStatus.INIT
					&&
					<SquareButton variant='contained' color='secondary' onClick={goBack} className='sa_payment_back'>
						<IconArrowBack />
					</SquareButton>

				}
				{requestStatus === selectPlanStatus.SUCCESS &&
					<Box width="100%" textAlign='center'>
						<Button
							variant='contained'
							color='primary'
							onClick={redirectTo}
							className='sa_payment_continue'
						>
							{t("continue")}
						</Button>
					</Box>
				}
			</Box>
		</div>
	)
}

const mapStateToProps = state => ({
	identity: state.identity,
})

export default connect(mapStateToProps)(withRouter(Payments));
