import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import API from 'api/device'
import DeviceCard from 'pages/Devices/DeviceCard'
import ContentPlaceholder from 'components/ContentPlaceholder'
import Box from '@material-ui/core/Box'
import { BodyText, CardTitle } from '../../../components/UI'
import Grid from '@material-ui/core/Grid'

const DevicesList = ({ t }) => {
  const [loading, setLoading] = useState(false)
  const [devices, setDevices] = useState(null)
  const [deviceActivationUuid, setDeviceActivationUuid] = useState(false)
  const [meta, setMeta] = useState(null)

  useEffect(() => {
    getDevicesList()
  }, [])

  const getDevicesList = () => {
    setLoading(true)
    API.getDevices()
      .then(data => {
        setLoading(false)
        setDevices(data.items)
        setMeta(data.meta)
      })
      .catch(() => {
        setLoading(false)
        toast(t("can_t_fetch_devices"), { type: toast.TYPE.ERROR })
      })
  }

  const activateDevice = (uuid) => {
    API.activateDeviceResend(uuid)
      .then(response => {
        setDeviceActivationUuid(uuid)
      })
      .catch(err => {
        if (err.response && err.response.status === 429) {
          toast(t("call_limit"), { type: toast.TYPE.ERROR })
        }
      })
  }

  const deleteDevice = (uuid, name, title) => () => {
    API.deleteDevice({ uuid })
      .then(resp => {
        const filteredDevices = devices.filter(d => d.uuid !== uuid)
        setDevices(filteredDevices)
        setMeta({ ...meta, total_items: meta.total_items - 1 })
        toast(t("device_was_deleted", { name, title }), { type: toast.TYPE.INFO })
      })
      .catch(() => {
        toast(t("device_delete_error", { name, title }), { type: toast.TYPE.ERROR })
      })
  }

  const updateDeviceName = (uuid, name) => {
    let deviceToUpdate = null
    const newDevicesList = devices.map(device => {
      if (device.uuid === uuid) {

        device.name = name
        // switch fingerprints
        // server gets fingerprint
        // returns fingerprint_browser
        // returns an error if request with fingerprint_browser
        deviceToUpdate = {
          ...device,
          scan_by: 'browser',
          fingerprint: device.fingerprint_browser,
          fingerprint_browser: null,
        }
        return device
      }
      return device
    })
    setDevices(newDevicesList)
    API.updateDevice(deviceToUpdate, uuid)
      .catch(err => { })
  }

  if (loading) {
    return (
      <ContentPlaceholder type='cardsGrid' quantity={6} />
    )
  }

  if (devices) {
    return (
      <div className='devicesComponent'>
        <Box display='flex' justifyContent='space-between' alignItems='center' mt={3} mb={1}>
          <CardTitle mb={0} textAlign='inherit'>
            {t("devices")}
          </CardTitle>
          {
            meta
            &&
            <BodyText textAlign='inherit'>
              {meta.total_items || 0} {t("devices")}
            </BodyText>
          }
        </Box>
        <BodyText mb={3} textAlign='inherit'>
          {t('retail.login_from_the_device_you_need_to_add')}
        </BodyText>
        <Grid container spacing={2}>
          {
            devices.map(device => (
              <Grid item xs={12} sm={6} lg={4} key={device.uuid}>
                <DeviceCard
                  updateDeviceName={updateDeviceName}
                  deleteDevice={deleteDevice}
                  activateDevice={activateDevice}
                  deviceActivationUuid={deviceActivationUuid}
                  data={device}
                  t={t}
                />
              </Grid>
            ))}
        </Grid>
      </div >
    )
  }

  return null
}

export default DevicesList
