import React from "react";
import { useTranslation } from "react-i18next";
import Tour from "react-user-tour"

import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

import { BodyText, AccentButton } from "../UI";
import { useUserTourContext } from '../../contexts'

import { useTourStyles } from './userTour.styles'

const TourBody = ({
	title,
	arrow,
	main,
	skipNextStep,
	step,
	steps,
	goToNextStep,
	skipTour,
}) => {
	const { t } = useTranslation()
	const classes = useTourStyles()
	return (
		<div className={`${classes.stepContent} ${arrow}`}>
			<Box className={classes.tourTextBox} px={2} pt={2}>
				<BodyText color="inherit" mb={0} fontWeight='bold'>
					{t(title)}
				</BodyText>
			</Box>
			<Box p={2} className={classes.tourTextBox}>
				<BodyText color="inherit">
					{t(main)}
				</BodyText>
			</Box>
			<div className={classes.tourFooter}>
				{
					step < steps.length
					&& (
						<AccentButton
							size='small'
							variant='outlined'
							accentColor='reversed'
							onClick={skipTour}
						>
							{t("skip_tour")}
						</AccentButton>
					)
				}
				<Button
					variant='contained'
					color='secondary'
					size='small'
					onClick={goToNextStep.bind(null, steps, step + 1, skipNextStep)}
				>
					{step < steps.length ? t('next') : t('finish')}
				</Button>
			</div>
		</ div>
	)
}


const UserTour = () => {
	const classes = useTourStyles()
	const {
		canStartTour,
		userTourStep,
		setUserTourStep,
		goToNextStep,
		skipTour,
		isReady,
		steps,
	} = useUserTourContext()
	const stepsList = () => steps.map(({
		step,
		selector,
		position,
		title,
		main,
		arrow,
		skipNextStep,
		horizontalOffset,
		verticalOffset,
	}) => {
		return {
			step,
			selector,
			position,
			horizontalOffset,
			verticalOffset,
			body: (
				() => (
					<TourBody
						title={title}
						arrow={arrow}
						main={main}
						step={step}
						steps={steps}
						skipNextStep={skipNextStep}
						goToNextStep={goToNextStep}
						skipTour={skipTour}
					/>
				)
			)()
		}
	})
	return (
		<div className={classes.tour}>
			<div className={classes.tourOverlay} />
			<Tour
				active={canStartTour}
				step={userTourStep}
				onNext={setUserTourStep}
				onBack={setUserTourStep}
				onCancel={skipTour}
				closeButtonText=""
				hideButtons={true}
				// reset styles
				style={{}}
				arrowSize={0}
				steps={stepsList()}
			/>
		</div>
	);
}

export default UserTour;
