import { MANAGE_DATA_DISABLE_PARTNERS } from "constants/common";

export const menuItems = [
  {
    name: "dashboard",
    translationKey: "dashboard.dashboard",
    link: "/dashboard",
    classes: ["sa_sidebar_dashboard"],
  },
  {
    name: "todo",
    translationKey: "action_list",
    link: "/todo",
    classes: ["tourDesktop2", "sa_sidebar_todo"],
  },
  {
    name: "manageDevices",
    translationKey: "dashboard.manage_devices",
    link: "/devices",
    classes: ["tourDesktop3", "sa_sidebar_devices"],
    requiredFeatures: ["max_devices"],
  },
  {
    name: "protectedData",
    translationKey: "dashboard.manage_monitored_data",
    link: "/manage-data/emails",
    classes: ["tourDesktop4", "sa_sidebar_manage_data"],
    requiredFeatures: ["max_devices"],
    disabledForPartnersList: [...MANAGE_DATA_DISABLE_PARTNERS],
  },
  {
    name: "support",
    translationKey: "support",
    link: "/support",
    classes: ["tourDesktop5", "sa_sidebar_support"],
  },
  {
    name: "userTour",
    translationKey: "take_a_tour",
    link: null,
    classes: [],
    controledBySetting: [
      { key: "isTourButtonDisplayed", value: "true" },
      // front-end setting for rtl languages
      { key: "disableUserTour", value: "false" },
    ],
  },
  {
    name: "policies",
    translationKey: "retail.policy.policies",
    requiredFeatures: ["sme_policy_module"],
    link: "/policies",
    classes: ["policies"],
  },
  {
    name: "training",
    translationKey: "retail.training.training",
    requiredFeatures: ["sme_training_courses_module"],
    link: "/training/tasks",
    classes: ["training"],
  },
  {
    name: "logout",
    translationKey: "log_out",
    translationKeyCrossServicePartner: "exit_cyber_risk_assessment",
    link: "/logout",
    classes: ["logout", "sa_sidebar_logout"],
  },
];
