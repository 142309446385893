import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useCourse } from "hooks/useCourse";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import certificateTemplate from "assets/icons/certificate_template.png";
import useDownloadCertificate from "hooks/useDownloadCertificate";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflow: "auto",
  },
  contentWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  placeholder: {
    maxWidth: "400px",
    width: "100%",
  },
  actionWrapper: {
    textAlign: "center",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  mainBox: {
    display: "flex",
    height: "100%",
    width: "100%",
  },
  heading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const CourseCertificate = () => {
  const classes = useStyles();
  const { locale, courseid } = useParams();
  const [, isLoading] = useCourse(courseid);
  const course = useSelector((state) => state.course.course);
  const { downloadCertificate } = useDownloadCertificate(course);
  const { t } = useTranslation();

  if (
    (!isLoading && !course.progress_status) ||
    (!isLoading && course.progress_status !== "COMPLETED")
  )
    return <Redirect to={`/${locale}/not-found`} />;

  return (
    <Box className={classes.mainBox}>
      {!isLoading && (
        <Box className={classes.container}>
          <Box className={classes.contentWrapper}>
            <Box>
              <img
                className={classes.placeholder}
                src={certificateTemplate}
                alt="certificate"
              />
            </Box>
            <Box className={classes.actionWrapper}>
              <Typography variant="h2" className={classes.heading}>
                {t("retail.get_your_certificate")}
              </Typography>
              <Box className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  onClick={downloadCertificate}
                  endIcon={<DownloadIcon />}
                >
                  {t("retail.download_certificate")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CourseCertificate;
