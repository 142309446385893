/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class RepositoryCourseProgressStatus.
* @enum {}
* @readonly
*/
export default class RepositoryCourseProgressStatus {
    
        /**
         * value: "PENDING"
         * @const
         */
        "CourseProgressStatusPENDING" = "PENDING";

    
        /**
         * value: "IN_PROGRESS"
         * @const
         */
        "CourseProgressStatusINPROGRESS" = "IN_PROGRESS";

    
        /**
         * value: "COMPLETED"
         * @const
         */
        "CourseProgressStatusCOMPLETED" = "COMPLETED";

    
        /**
         * value: "INITIATED"
         * @const
         */
        "CourseProgressStatusINITIATED" = "INITIATED";

    

    /**
    * Returns a <code>RepositoryCourseProgressStatus</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/RepositoryCourseProgressStatus} The enum <code>RepositoryCourseProgressStatus</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

