import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import VideoLesson from "../CourseLesson/VideoLesson/VideoLesson";
import TextLesson from "../CourseLesson/TextLesson/TextLesson";
import CourseTakeaway from "../CourseTakeaway/CourseTakeway";
import CourseQuiz from "../CourseQuiz";
import CourseCertificate from "../CourseCertificate/CourseCertificate";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 24px",
  },
  content: {
    padding: "32px 24px",
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
}));

const CourseItem = ({ type, setIsDisabled, title, isCertificate }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    const isDisabled = ["VIDEO", "FINAL_QUIZ", "CHAPTER_QUIZ"].includes(type);
    setIsDisabled(isDisabled);
  }, [type, setIsDisabled]);

  return (
    <>
      <Box className={classes.header}>
        <Typography variant="h2">
          {isCertificate ? t("retail.certificate") : title}
        </Typography>
      </Box>
      <Divider />
      <Box className={classes.content}>
        {isCertificate && <CourseCertificate />}
        {type === "VIDEO" && <VideoLesson disableBtn={setIsDisabled} />}
        {type === "MARKDOWN" && <TextLesson />}
        {type === "COURSE_TAKEAWAY" && <CourseTakeaway />}
        {type === "CHAPTER_TAKEAWAY" && <CourseTakeaway />}
        {type === "CHAPTER_QUIZ" && <CourseQuiz disableBtn={setIsDisabled} />}
        {type === "FINAL_QUIZ" && <CourseQuiz disableBtn={setIsDisabled} />}
      </Box>
    </>
  );
};

export default CourseItem;
