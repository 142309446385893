import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  priceComponent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
  amount: {
    display: 'flex',
    fontSize: '2rem',
  },
  currency: {
    fontSize: '0.8rem',
  },
  desc: {
    marginTop: '5px',
    fontSize: '0.8rem'
  }
}))

const Price = ({ amount, currency, desc }) => {
  const classes = useStyles()
  return (
    <span className={classes.priceComponent}>
      {amount &&
        <div className={classes.amount}>
          {currency &&
            <span className={classes.currency}>{currency}</span>
          }
          {amount}
        </div>
      }
      {desc &&
        <span className={classes.desc}>{desc}</span>
      }
    </span>
  )
}

export default Price
