import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { InputField, SelectField } from "../../../components/UI";
import PasswordStrengthComponent from "../../../components/PasswordStrength";

import {
  isValidString,
  isStringMinMaxLength,
} from "../../../helpers/validators";
import isMobilePhone from "validator/lib/isMobilePhone";

import useForm from "../../../hooks/useForm";

const stateSchema = {
  first_name: { value: "", error: "" },
  last_name: { value: "", error: "" },
  country_code: { value: "", error: "" },
  phone_number: { value: "", error: "" },
  password: { value: "", error: "" },
  repeat_password: { value: "", error: "" },
};

const Form = ({ t, hash, phonePrefixes, onSubmit }) => {
  const handleSubmit = () => {
    const formData = {
      hash,
      first_name: state.first_name.value,
      last_name: state.last_name.value,
      country_code: state.country_code.value.value,
      phone_number: state.phone_number.value,
      password: state.password.value,
    };

    onSubmit(formData);
  };

  const [isPasswordStrength, setIsPasswordStrength] = useState(false);

  const validationStateSchema = {
    first_name: {
      required: true,
      validator: [
        { func: isValidString, error: t("first_name_invalid") },
        {
          func: (str) => isStringMinMaxLength(str, null, 100),
          error: t("too_long_max_x", { count: 100 }),
        },
      ],
    },
    last_name: {
      required: true,
      validator: [
        { func: isValidString, error: t("last_name_invalid") },
        {
          func: (str) => isStringMinMaxLength(str, null, 100),
          error: t("too_long_max_x", { count: 100 }),
        },
      ],
    },
    country_code: {
      required: true,
    },
    phone_number: {
      required: true,
      validator: {
        func: isMobilePhone,
        error: t("enter_valid_phone_number"),
      },
    },
    password: {
      required: true,
    },
    repeat_password: {
      required: true,
    },
  };

  const [initialState, setInitialState] = useState({
    data: {
      first_name: "",
      last_name: "",
      password: "",
      repeat_password: "",
      phone_number: "",
      country_code: null,
    },
    errors: null,
  });

  const {
    state,
    handleOnChange,
    handleOnBlur,
    handleOnSubmit,
    disable,
    isDirty,
  } = useForm(
    initialState,
    stateSchema,
    null,
    validationStateSchema,
    handleSubmit
  );

  useEffect(() => {
    if (!initialState.data.country_code && phonePrefixes) {
      let country_code = phonePrefixes[0];
      const state = { data: { ...initialState.data, country_code } };
      setInitialState(state);
    }
  }, [phonePrefixes]);

  return (
    <form
      className="-sizeWidthFull"
      onSubmit={handleOnSubmit}
      id="form-activate-user-stuff"
    >
      <Box mb={4}>
        <InputField
          name="first_name"
          placeholder={t("first_name")}
          type="text"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          value={state.first_name.value}
          className="sa_signup_first_name"
          error={isDirty && isDirty.first_name && state.first_name.error}
        />
      </Box>
      <Box mb={4}>
        <InputField
          name="last_name"
          placeholder={t("last_name")}
          type="text"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          value={state.last_name.value}
          className="sa_signup_last_name"
          error={isDirty && isDirty.last_name && state.last_name.error}
        />
      </Box>
      <Box mb={4} display="flex">
        <Box mr={2} flexShrink="0" flexBasis="160px">
          {phonePrefixes && (
            <SelectField
              name="country_code"
              options={phonePrefixes}
              value={state.country_code.value}
              className="sa_signup_country_code"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
          )}
        </Box>
        <Box width="100%">
          <InputField
            name="phone_number"
            placeholder={t("phone_number")}
            type="text"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            value={state.phone_number.value}
            className="sa_signup_phone_number"
            error={
              isDirty &&
              ((isDirty.phone_number && state.phone_number.error) ||
                (isDirty.country_code && state.country_code.error))
            }
          />
        </Box>
      </Box>
      <Box mb={4}>
        <InputField
          name="password"
          placeholder={t("password")}
          type="password"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          value={state.password.value}
          className="sa_signup_password"
          error={isDirty && isDirty.password && state.password.error}
        />
      </Box>
      <Box mb={4}>
        <InputField
          name="repeat_password"
          placeholder={t("confirm_password")}
          type="password"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          value={state.repeat_password.value}
          className="sa_signup_confirm_password"
          error={
            isDirty &&
            ((isDirty.repeat_password && state.repeat_password.error) ||
              (isDirty.repeat_password &&
                isDirty.password &&
                state.repeat_password.value !== state.password.value &&
                t("passwords_doesn_t_match")))
          }
        />
      </Box>
      <PasswordStrengthComponent
        onValidChange={setIsPasswordStrength}
        value={state.password.value}
        touched={false}
        t={t}
      />
      <Box mt={3}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="sa_signup_continue"
          id="btn-sme-activate"
          disabled={
            !isPasswordStrength ||
            disable ||
            state.password.value !== state.repeat_password.value
          }
        >
          {t("accept_and_continue")}
        </Button>
      </Box>
    </form>
  );
};

export default withTranslation()(Form);
