import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { withRouter } from 'react-router-dom'
import { CardTitle, DeviceWithOsIcon, BodyText } from '../../../../components/UI'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  deviceIconWrapper: {
    '&.withLink': {
      cursor: 'pointer'
    }
  },
  status: {
    color: theme.variables.$green100,
    '&.disabled': {
      color: theme.variables.$red100
    },
    '&.pending': {
      color: theme.variables.$darkGray
    }
  }
}))


const DeviceCardLimit = ({ t, data, replaceDevice }) => {
  const classes = useStyles()
  return (
    <Paper style={{ height: '100%' }}>
      <Box padding={3} textAlign='center' display='flex' flexDirection='column' height='100%'>
        <div
          className={`${classes.deviceIconWrapper}`}
        >
          <DeviceWithOsIcon
            type={data.device_type}
            os={data.os}
          />
        </div>

        <Box mb={1} flexGrow={1}>
          <CardTitle variant='h2' textAlign='center' mb={0}>
            {data.name}
          </CardTitle>
          <BodyText textAlign='center'>
            <span className={`${classes.status} ${data.status}`}>
              {t(data.status.toLowerCase())}
            </span>
          </BodyText>
        </Box>
        <Box width='100%'>
          <Button
            fullWidth
            size='small'
            variant='contained'
            color='primary'
            onClick={() => replaceDevice(data.uuid)}
          >
            {t("replace_device")}
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

export default withRouter(DeviceCardLimit)
