/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ModelTakeaway from './ModelTakeaway';

/**
 * The ModelUserCourseTakeaway model module.
 * @module model/ModelUserCourseTakeaway
 * @version 1.0.0
 */
class ModelUserCourseTakeaway {
    /**
     * Constructs a new <code>ModelUserCourseTakeaway</code>.
     * @alias module:model/ModelUserCourseTakeaway
     * @param courseTakeawayUuid {String} 
     * @param createdAt {Number} 
     * @param isCompleted {Boolean} 
     * @param takeawayUuid {String} 
     * @param userCourseUuid {String} 
     * @param uuid {String} 
     */
    constructor(courseTakeawayUuid, createdAt, isCompleted, takeawayUuid, userCourseUuid, uuid) { 
        
        ModelUserCourseTakeaway.initialize(this, courseTakeawayUuid, createdAt, isCompleted, takeawayUuid, userCourseUuid, uuid);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, courseTakeawayUuid, createdAt, isCompleted, takeawayUuid, userCourseUuid, uuid) { 
        obj['course_takeaway_uuid'] = courseTakeawayUuid;
        obj['created_at'] = createdAt;
        obj['is_completed'] = isCompleted;
        obj['takeaway_uuid'] = takeawayUuid;
        obj['user_course_uuid'] = userCourseUuid;
        obj['uuid'] = uuid;
    }

    /**
     * Constructs a <code>ModelUserCourseTakeaway</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ModelUserCourseTakeaway} obj Optional instance to populate.
     * @return {module:model/ModelUserCourseTakeaway} The populated <code>ModelUserCourseTakeaway</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ModelUserCourseTakeaway();

            if (data.hasOwnProperty('course_takeaway_uuid')) {
                obj['course_takeaway_uuid'] = ApiClient.convertToType(data['course_takeaway_uuid'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Number');
            }
            if (data.hasOwnProperty('is_completed')) {
                obj['is_completed'] = ApiClient.convertToType(data['is_completed'], 'Boolean');
            }
            if (data.hasOwnProperty('takeaway')) {
                obj['takeaway'] = ModelTakeaway.constructFromObject(data['takeaway']);
            }
            if (data.hasOwnProperty('takeaway_uuid')) {
                obj['takeaway_uuid'] = ApiClient.convertToType(data['takeaway_uuid'], 'String');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Number');
            }
            if (data.hasOwnProperty('user_course_uuid')) {
                obj['user_course_uuid'] = ApiClient.convertToType(data['user_course_uuid'], 'String');
            }
            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ModelUserCourseTakeaway</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ModelUserCourseTakeaway</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ModelUserCourseTakeaway.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['course_takeaway_uuid'] && !(typeof data['course_takeaway_uuid'] === 'string' || data['course_takeaway_uuid'] instanceof String)) {
            throw new Error("Expected the field `course_takeaway_uuid` to be a primitive type in the JSON string but got " + data['course_takeaway_uuid']);
        }
        // validate the optional field `takeaway`
        if (data['takeaway']) { // data not null
          ModelTakeaway.validateJSON(data['takeaway']);
        }
        // ensure the json data is a string
        if (data['takeaway_uuid'] && !(typeof data['takeaway_uuid'] === 'string' || data['takeaway_uuid'] instanceof String)) {
            throw new Error("Expected the field `takeaway_uuid` to be a primitive type in the JSON string but got " + data['takeaway_uuid']);
        }
        // ensure the json data is a string
        if (data['user_course_uuid'] && !(typeof data['user_course_uuid'] === 'string' || data['user_course_uuid'] instanceof String)) {
            throw new Error("Expected the field `user_course_uuid` to be a primitive type in the JSON string but got " + data['user_course_uuid']);
        }
        // ensure the json data is a string
        if (data['uuid'] && !(typeof data['uuid'] === 'string' || data['uuid'] instanceof String)) {
            throw new Error("Expected the field `uuid` to be a primitive type in the JSON string but got " + data['uuid']);
        }

        return true;
    }


}

ModelUserCourseTakeaway.RequiredProperties = ["course_takeaway_uuid", "created_at", "is_completed", "takeaway_uuid", "user_course_uuid", "uuid"];

/**
 * @member {String} course_takeaway_uuid
 */
ModelUserCourseTakeaway.prototype['course_takeaway_uuid'] = undefined;

/**
 * @member {Number} created_at
 */
ModelUserCourseTakeaway.prototype['created_at'] = undefined;

/**
 * @member {Boolean} is_completed
 */
ModelUserCourseTakeaway.prototype['is_completed'] = undefined;

/**
 * @member {module:model/ModelTakeaway} takeaway
 */
ModelUserCourseTakeaway.prototype['takeaway'] = undefined;

/**
 * @member {String} takeaway_uuid
 */
ModelUserCourseTakeaway.prototype['takeaway_uuid'] = undefined;

/**
 * @member {Number} updated_at
 */
ModelUserCourseTakeaway.prototype['updated_at'] = undefined;

/**
 * @member {String} user_course_uuid
 */
ModelUserCourseTakeaway.prototype['user_course_uuid'] = undefined;

/**
 * @member {String} uuid
 */
ModelUserCourseTakeaway.prototype['uuid'] = undefined;






export default ModelUserCourseTakeaway;

