import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import API from "api/user";
import Logo from "components/Logo";
import { getItem, setItem } from "helpers/localStorage";
import { useTranslation } from "react-i18next";
import Page from "components/Page";
import AddCouponForm from "./AddCouponForm";
import { RefreshTokenService } from "services/authentication/RefreshTokenService";
import { setCurrentPlan, setCurrentPlanFeatures } from "store/actions/plans";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { CardTitle, BodyText } from "../../../components/UI";
import CircularProgress from "@material-ui/core/CircularProgress";
import { usePlansContext } from "../../../contexts";

const AddCouponPage = () => {
  const { setPlans, plans } = usePlansContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { locale } = useParams();
  const identity = useSelector((state) => state.identity);
  const [planFromCMS, setPlanFromCMS] = useState(null);
  const couponCode = getItem("coupon");
  let cmsPlan = getItem("planFromCms");
  const [pageLoader, setPageLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const isPlanSelected = getItem("isPlanSelected");

  useEffect(() => {
    try {
      cmsPlan = JSON.parse(cmsPlan);
      setPlanFromCMS(cmsPlan);
    } catch (err) {}

    if (identity && identity.plan && identity.plan.is_active) {
      toast(t("payment_plan_already_activated"), { type: toast.TYPE.INFO });
      return <Redirect to={`/${locale}/assessment`} />;
    }

    if (couponCode) {
      API.applyCoupon({ name: couponCode }).catch(() => {
        toast(t("coupon_not_applied"), { type: toast.TYPE.ERROR });
      });
    }
  }, []);

  useEffect(() => {
    if (planFromCMS && planFromCMS.type === "trial") {
      setPageLoader(true);
      getPlans();
    }
  }, [planFromCMS]);

  useEffect(() => {
    if (plans) {
      history.push(`/${locale}/plans`);
    }
  }, [plans]);

  const selectFreePlan = (planOptions, plans) => {
    API.selectPlan({
      type: "free",
      data: { plan_option_uuid: planOptions.uuid },
    })
      .then((response) => {
        RefreshTokenService.refresh()
          .then(() => {
            if (isPlanSelected === "false") {
              setItem("isPlanSelected", true);
            }
            dispatch(setCurrentPlan(planOptions));
            dispatch(setCurrentPlanFeatures(plans));
            history.push(`/${locale}/start-scan`);
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  const getPlans = (needSelect) => {
    setLoading(true);
    API.getPlans()
      .then((plans) => {
        if (planFromCMS) {
          plans = plans.filter((plan) => plan.slug === planFromCMS.slug);
          if (planFromCMS.type === "trial") {
            let [planOptions] = plans[0].options.filter(
              (options) => options.model === "TRIAL"
            );
            return selectFreePlan(planOptions, plans[0]);
          }
        }
        // needSelect is false if `I don't have a coupon` button is clicked
        if (plans.length === 1 && needSelect) {
          setPageLoader(false);
          let [planOptions] = plans[0].options.filter(
            (options) => options.model === "PAYABLE"
          );
          if (
            (planOptions.price_with_coupon &&
              parseInt(planOptions.price_with_coupon.amount, 10) === 0) ||
            planOptions.price.amount <= 0
          ) {
            selectFreePlan(planOptions, plans[0]);
          } else {
            setPlans(plans);
          }
        } else {
          setPlans(plans);
        }
      })
      .catch((err) => {
        history.push(`/${locale}/plans`);
      });
  };

  // show spiner if plan from cms is trial
  if (pageLoader) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Page name="addCoupon">
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <Box mb={6} textAlign="center">
            <Logo preventRedirection={true} />
          </Box>
          <Paper elevation={2} style={{ maxWidth: "576px", margin: "0 auto" }}>
            <Box p={3}>
              <CardTitle textAlign="center"> {t("add_discount")}</CardTitle>
              <Typography
                style={{marginBottom: "24px"}}
                variant="body2"
                textAlign="center"
                dangerouslySetInnerHTML={{
                  __html: t("add_discount_subheader"),
                }}
              />
              <AddCouponForm
                t={t}
                couponCode={couponCode}
                getPlans={getPlans}
                loading={loading}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </Page>
  );
};

export default AddCouponPage;
