import { datadogLogs } from "@datadog/browser-logs";
import * as monolog from "monolog";
import { store } from 'store'

export class DataDogHandler extends monolog.handler.AbstractProcessingHandler {
  token = '';

  constructor(level = monolog.Logger.DEBUG, bubble = true, token) {
    super(level, bubble);
    this.token = token;
    datadogLogs.init({
      clientToken: token,
      forwardErrorsToLogs: true,
      sampleRate: 100,
      service: 'frontend',
      version: process.env.VERSION,
      env: process.env.NODE_ENV,
    });
    datadogLogs.setLoggerGlobalContext({
      service: 'frontend',
      version: process.env.VERSION,
      env: process.env.NODE_ENV,
    });
  }

  write(message, fb) {
    const context = {
      ...message.context,
      ...{ status: message.level_name },
      ...this.globalContext
    };
    const msg = message.message;
    if (msg instanceof Error) {
      message.message = msg.message;
      context.error = context.error ? context.error : {};
      context.error.kind = msg.name
      context.error.stack = msg.stack
    }
    datadogLogs.logger.log(message.message, context);
  }

  get globalContext() {
    let { identity } = store.getState();

    if (!identity) {
      identity = {
        uuid: null,
        profile_uuid: null,
        device_uuid: null,
        user: { partner: null },
        device: { uuid: null }
      }
    }

    return {
      // any global things for state
      service: 'frontend',
      context: {
        partner: identity.user.partner,
        user_uuid: identity.uuid,
        profile_uuid: identity.profile_uuid,
        device_uuid: identity.device_uuid,
      },
      http: {
        referrer: window.location.href,
        url: window.location.pathname,
      }
    };
  }
}
