import { SET_IDENTITY, CLEAR_IDENTITY } from '../constants'

export default function identity(state = null, {type, payload}) {
  switch (type) {
    case SET_IDENTITY:
      return payload
    case CLEAR_IDENTITY: {
      return null
    }
    default:
      return state
  }
}