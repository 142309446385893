import React from "react";
import { withTranslation } from "react-i18next";

const WhatToDoNextScreen = ({ t }) => {
  const isChrome = navigator.userAgent.includes("Chrome") && navigator;
  const fitContentBrowserWidth = isChrome ? "fit-content" : "-moz-fit-content";

  return (
    <svg
      width="590"
      height="307"
      viewBox="0 0 1536 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1536 0H0V800H1536V0Z" fill="#F6F7F8" />
      <foreignObject
        x="171"
        y="180"
        width="700"
        height="48"
        style={{
          fontSize: "28px",
          color: "#000000",
          fontFamily: "Montserrat, sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        }}
      >
        <span style={{ display: "flex", alignItems: "center", height: "100%" }}>
          {t("dashboard.tasks.what_to_do")}
        </span>
      </foreignObject>
      <foreignObject
        x="900"
        y="185"
        width="462"
        height="38"
        className="partnerText"
        style={{
          textTransform: "uppercase",
          fontSize: "14px",
          // color: "#1A47B9",
          fontFamily: "Montserrat, sans-serif",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "18px",
        }}
      >
        <span
          className="partnerObjBg"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            // background: "rgba(26, 71, 185, 0.1)",
            padding: "0 14px",
            marginLeft: "auto",
            width: fitContentBrowserWidth,
            minWidth: "167px",
            justifyContent: "center",
          }}
        >
          {t("dashboard.tasks_view_all")}
        </span>
      </foreignObject>
      <path
        d="M1357.4 251.044H177.6C175.059 251.044 173 253.189 173 255.833V610.211C173 612.856 175.059 615 177.6 615H1357.4C1359.94 615 1362 612.856 1362 610.211V255.833C1362 253.189 1359.94 251.044 1357.4 251.044Z"
        fill="white"
      />
      <path
        d="M1311.4 307.314H1318.3"
        stroke="#001E2E"
        strokeOpacity="0.2"
        strokeLinecap="round"
      />
      <path
        d="M1307.95 291.151C1307 291.151 1306.23 291.955 1306.23 292.947V303.722C1306.23 304.714 1307 305.518 1307.95 305.518H1321.75C1322.71 305.518 1323.48 304.714 1323.48 303.722V292.947C1323.48 291.955 1322.71 291.151 1321.75 291.151H1307.95Z"
        stroke="#001E2E"
        strokeOpacity="0.2"
      />
      <path
        opacity="0.3"
        d="M1314.85 318.089C1325.02 318.089 1333.25 309.513 1333.25 298.933C1333.25 288.354 1325.02 279.778 1314.85 279.778C1304.69 279.778 1296.46 288.354 1296.46 298.933C1296.46 309.513 1304.69 318.089 1314.85 318.089Z"
        stroke="#415770"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
      <path
        d="M1314.85 318.089C1325.02 318.089 1333.25 309.513 1333.25 298.933C1333.25 288.354 1325.02 279.778 1314.85 279.778C1304.69 279.778 1296.46 288.354 1296.46 298.933C1296.46 309.513 1304.69 318.089 1314.85 318.089Z"
        stroke="#FF3636"
        strokeWidth="1.5"
      />
      <foreignObject
        x="248.5"
        y="276"
        width="700"
        height="48"
        style={{
          fontSize: "16px",
          color: "#000000",
          fontFamily: "Lato, sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        }}
      >
        <span style={{ display: "flex", alignItems: "center", height: "100%" }}>
          {t(
            "staff.contact_the_appropriate_person_in_the_event_of_an_incident"
          )}
        </span>
      </foreignObject>
      <foreignObject
        x="955"
        y="276"
        width="120"
        height="48"
        style={{
          fontSize: "14px",
          color: "#FF3636",
          fontFamily: "Lato, sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "flex-end",
          }}
        >
          {t("overdue")}
        </span>
      </foreignObject>
      <foreignObject
        x="1080"
        y="285"
        width="210"
        height="28"
        className="partnerText"
        style={{
          textTransform: "uppercase",
          fontSize: "12px",
          // color: "#1A47B9",
          fontFamily: "Montserrat, sans-serif",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "12px",
        }}
      >
        <span
          className="partnerObjBg"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            // background: "rgba(26, 71, 185, 0.1)",
            padding: "0 14px",
            margin: "0 auto",
            borderRadius: "3px",
            width: fitContentBrowserWidth,
            minWidth: "120px",
            justifyContent: "center",
          }}
        >
          {t("how_to_do_it")}
        </span>
      </foreignObject>
      <path
        d="M222.446 289.946H208.647C207.694 289.946 206.922 290.75 206.922 291.742V306.109C206.922 307.1 207.694 307.904 208.647 307.904H222.446C223.398 307.904 224.171 307.1 224.171 306.109V291.742C224.171 290.75 223.398 289.946 222.446 289.946Z"
        fill="white"
        stroke="#415770"
      />
      <path
        d="M1311.4 374.358H1318.3"
        stroke="#001E2E"
        strokeOpacity="0.2"
        strokeLinecap="round"
      />
      <path
        d="M1307.95 358.196C1307 358.196 1306.23 359 1306.23 359.992V370.767C1306.23 371.758 1307 372.562 1307.95 372.562H1321.75C1322.71 372.562 1323.48 371.758 1323.48 370.767V359.992C1323.48 359 1322.71 358.196 1321.75 358.196H1307.95Z"
        stroke="#001E2E"
        strokeOpacity="0.2"
      />
      <path
        opacity="0.3"
        d="M1314.85 385.133C1325.02 385.133 1333.25 376.557 1333.25 365.978C1333.25 355.398 1325.02 346.822 1314.85 346.822C1304.69 346.822 1296.46 355.398 1296.46 365.978C1296.46 376.557 1304.69 385.133 1314.85 385.133Z"
        stroke="#415770"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
      <path
        d="M1314.85 385.133C1325.02 385.133 1333.25 376.557 1333.25 365.978C1333.25 355.398 1325.02 346.822 1314.85 346.822C1304.69 346.822 1296.46 355.398 1296.46 365.978C1296.46 376.557 1304.69 385.133 1314.85 385.133Z"
        stroke="#FF3636"
        strokeWidth="1.5"
      />
      <foreignObject
        x="248.5"
        y="343"
        width="700"
        height="48"
        style={{
          fontSize: "16px",
          color: "#000000",
          fontFamily: "Lato, sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        }}
      >
        <span style={{ display: "flex", alignItems: "center", height: "100%" }}>
          {t(
            "staff.stop_emailing_company_information_to_personal_email_accounts"
          )}
        </span>
      </foreignObject>

      <foreignObject
        x="955"
        y="343"
        width="120"
        height="48"
        style={{
          fontSize: "14px",
          color: "#FF3636",
          fontFamily: "Lato, sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "flex-end",
          }}
        >
          {t("overdue")}
        </span>
      </foreignObject>
      <foreignObject
        x="1080"
        y="352"
        width="210"
        height="28"
        className="partnerText"
        style={{
          textTransform: "uppercase",
          fontSize: "12px",
          // color: "#1A47B9",
          fontFamily: "Montserrat, sans-serif",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "12px",
        }}
      >
        <span
          className="partnerObjBg"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            // background: "rgba(26, 71, 185, 0.1)",
            padding: "0 14px",
            margin: "0 auto",
            borderRadius: "3px",
            width: fitContentBrowserWidth,
            minWidth: "120px",
            justifyContent: "center",
          }}
        >
          {t("how_to_do_it")}
        </span>
      </foreignObject>
      <path
        d="M222.446 356.99H208.647C207.694 356.99 206.922 357.794 206.922 358.786V373.153C206.922 374.145 207.694 374.949 208.647 374.949H222.446C223.398 374.949 224.171 374.145 224.171 373.153V358.786C224.171 357.794 223.398 356.99 222.446 356.99Z"
        fill="white"
        stroke="#415770"
      />
      <path
        d="M1311.4 441.403H1318.3"
        stroke="#001E2E"
        strokeOpacity="0.2"
        strokeLinecap="round"
      />
      <path
        d="M1307.95 425.24C1307 425.24 1306.23 426.045 1306.23 427.036V437.811C1306.23 438.802 1307 439.607 1307.95 439.607H1321.75C1322.71 439.607 1323.48 438.802 1323.48 437.811V427.036C1323.48 426.045 1322.71 425.24 1321.75 425.24H1307.95Z"
        stroke="#001E2E"
        strokeOpacity="0.2"
      />
      <path
        opacity="0.3"
        d="M1314.85 452.178C1325.02 452.178 1333.25 443.602 1333.25 433.022C1333.25 422.443 1325.02 413.867 1314.85 413.867C1304.69 413.867 1296.46 422.443 1296.46 433.022C1296.46 443.602 1304.69 452.178 1314.85 452.178Z"
        stroke="#415770"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
      <path
        d="M1314.85 452.178C1325.02 452.178 1333.25 443.602 1333.25 433.022C1333.25 422.443 1325.02 413.867 1314.85 413.867C1304.69 413.867 1296.46 422.443 1296.46 433.022C1296.46 443.602 1304.69 452.178 1314.85 452.178Z"
        stroke="#FF3636"
        strokeWidth="1.5"
      />
      <foreignObject
        x="248"
        y="410"
        width="700"
        height="48"
        style={{
          fontSize: "16px",
          color: "#000000",
          fontFamily: "Lato, sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        }}
      >
        <span style={{ display: "flex", alignItems: "center", height: "100%" }}>
          {t(
            "staff.stop_sending_company_information_to_personal_cloud_services"
          )}
        </span>
      </foreignObject>
      <foreignObject
        x="955"
        y="410"
        width="120"
        height="48"
        style={{
          fontSize: "14px",
          color: "#FF3636",
          fontFamily: "Lato, sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "flex-end",
          }}
        >
          {t("overdue")}
        </span>
      </foreignObject>
      <foreignObject
        x="1080"
        y="419"
        width="210"
        height="28"
        className="partnerText"
        style={{
          textTransform: "uppercase",
          fontSize: "12px",
          // color: "#1A47B9",
          fontFamily: "Montserrat, sans-serif",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "12px",
        }}
      >
        <span
          className="partnerObjBg"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            // background: "rgba(26, 71, 185, 0.1)",
            padding: "0 14px",
            margin: "0 auto",
            borderRadius: "3px",
            width: fitContentBrowserWidth,
            minWidth: "120px",
            justifyContent: "center",
          }}
        >
          {t("how_to_do_it")}
        </span>
      </foreignObject>
      <path
        d="M222.446 424.035H208.647C207.694 424.035 206.922 424.839 206.922 425.83V440.197C206.922 441.189 207.694 441.993 208.647 441.993H222.446C223.398 441.993 224.171 441.189 224.171 440.197V425.83C224.171 424.839 223.398 424.035 222.446 424.035Z"
        fill="white"
        stroke="#415770"
      />
      <path
        d="M1311.4 508.447H1318.3"
        stroke="#001E2E"
        strokeOpacity="0.2"
        strokeLinecap="round"
      />
      <path
        d="M1307.95 492.285C1307 492.285 1306.23 493.089 1306.23 494.081V504.856C1306.23 505.847 1307 506.651 1307.95 506.651H1321.75C1322.71 506.651 1323.48 505.847 1323.48 504.856V494.081C1323.48 493.089 1322.71 492.285 1321.75 492.285H1307.95Z"
        stroke="#001E2E"
        strokeOpacity="0.2"
      />
      <path
        opacity="0.3"
        d="M1314.85 519.222C1325.02 519.222 1333.25 510.646 1333.25 500.067C1333.25 489.487 1325.02 480.911 1314.85 480.911C1304.69 480.911 1296.46 489.487 1296.46 500.067C1296.46 510.646 1304.69 519.222 1314.85 519.222Z"
        stroke="#415770"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
      <path
        d="M1314.85 519.222C1325.02 519.222 1333.25 510.646 1333.25 500.067C1333.25 489.487 1325.02 480.911 1314.85 480.911C1304.69 480.911 1296.46 489.487 1296.46 500.067C1296.46 510.646 1304.69 519.222 1314.85 519.222Z"
        stroke="#FF3636"
        strokeWidth="1.5"
      />
      <foreignObject
        x="248"
        y="476.7"
        width="700"
        height="48"
        style={{
          fontSize: "16px",
          color: "#000000",
          fontFamily: "Lato, sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        }}
      >
        <span style={{ display: "flex", alignItems: "center", height: "100%" }}>
          {t("staff.keep_your_desk_clear_of_confidential_documents")}
        </span>
      </foreignObject>
      <foreignObject
        x="955"
        y="476.7"
        width="120"
        height="48"
        style={{
          fontSize: "14px",
          color: "#FF3636",
          fontFamily: "Lato, sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "flex-end",
          }}
        >
          {t("overdue")}
        </span>
      </foreignObject>
      <foreignObject
        x="1080"
        y="485.7"
        width="210"
        height="28"
        className="partnerText"
        style={{
          textTransform: "uppercase",
          fontSize: "12px",
          // color: "#1A47B9",
          fontFamily: "Montserrat, sans-serif",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "12px",
        }}
      >
        <span
          className="partnerObjBg"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            // background: "rgba(26, 71, 185, 0.1)",
            padding: "0 14px",
            margin: "0 auto",
            borderRadius: "3px",
            width: fitContentBrowserWidth,
            minWidth: "120px",
            justifyContent: "center",
          }}
        >
          {t("how_to_do_it")}
        </span>
      </foreignObject>
      <path
        d="M222.446 491.079H208.647C207.694 491.079 206.922 491.883 206.922 492.875V507.242C206.922 508.233 207.694 509.037 208.647 509.037H222.446C223.398 509.037 224.171 508.233 224.171 507.242V492.875C224.171 491.883 223.398 491.079 222.446 491.079Z"
        fill="white"
        stroke="#415770"
      />
      <path
        d="M1311.4 575.492H1318.3"
        stroke="#001E2E"
        strokeOpacity="0.2"
        strokeLinecap="round"
      />
      <path
        d="M1307.95 559.329C1307 559.329 1306.23 560.134 1306.23 561.125V571.9C1306.23 572.891 1307 573.696 1307.95 573.696H1321.75C1322.71 573.696 1323.48 572.891 1323.48 571.9V561.125C1323.48 560.134 1322.71 559.329 1321.75 559.329H1307.95Z"
        stroke="#001E2E"
        strokeOpacity="0.2"
      />
      <path
        opacity="0.3"
        d="M1314.85 586.267C1325.02 586.267 1333.25 577.69 1333.25 567.111C1333.25 556.532 1325.02 547.956 1314.85 547.956C1304.69 547.956 1296.46 556.532 1296.46 567.111C1296.46 577.69 1304.69 586.267 1314.85 586.267Z"
        stroke="#415770"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
      <path
        d="M1314.85 586.267C1325.02 586.267 1333.25 577.69 1333.25 567.111C1333.25 556.532 1325.02 547.956 1314.85 547.956C1304.69 547.956 1296.46 556.532 1296.46 567.111C1296.46 577.69 1304.69 586.267 1314.85 586.267Z"
        stroke="#FF3636"
        strokeWidth="1.5"
      />
      <foreignObject
        x="248"
        y="544"
        width="700"
        height="48"
        style={{
          fontSize: "16px",
          color: "#000000",
          fontFamily: "Lato, sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        }}
      >
        <span style={{ display: "flex", alignItems: "center", height: "100%" }}>
          {t("staff.dispose_of_physical_documents_securely")}
        </span>
      </foreignObject>
      <foreignObject
        x="955"
        y="544"
        width="120"
        height="48"
        style={{
          fontSize: "14px",
          color: "#FF3636",
          fontFamily: "Lato, sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "flex-end",
          }}
        >
          {t("overdue")}
        </span>
      </foreignObject>
      <foreignObject
        x="1080"
        y="553"
        width="210"
        height="28"
        className="partnerText"
        style={{
          textTransform: "uppercase",
          fontSize: "12px",
          // color: "#1A47B9",
          fontFamily: "Montserrat, sans-serif",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "12px",
        }}
      >
        <span
          className="partnerObjBg"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            // background: "rgba(26, 71, 185, 0.1)",
            padding: "0 14px",
            margin: "0 auto",
            borderRadius: "3px",
            width: fitContentBrowserWidth,
            minWidth: "120px",
            justifyContent: "center",
          }}
        >
          {t("how_to_do_it")}
        </span>
      </foreignObject>
      <path
        d="M222.446 558.124H208.647C207.694 558.124 206.922 558.928 206.922 559.919V574.286C206.922 575.278 207.694 576.082 208.647 576.082H222.446C223.398 576.082 224.171 575.278 224.171 574.286V559.919C224.171 558.928 223.398 558.124 222.446 558.124Z"
        fill="white"
        stroke="#415770"
      />
      <path
        d="M1357.4 251.643H177.6C175.377 251.643 173.575 253.519 173.575 255.833V610.211C173.575 612.525 175.377 614.401 177.6 614.401H1357.4C1359.62 614.401 1361.43 612.525 1361.43 610.211V255.833C1361.43 253.519 1359.62 251.643 1357.4 251.643Z"
        stroke="#415770"
        strokeOpacity="0.2"
      />
      <defs>
        <clipPath id="clip0">
          <rect
            width="1189"
            height="431"
            fill="white"
            transform="translate(173 184)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTranslation()(WhatToDoNextScreen);
