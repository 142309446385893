import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CardTitle } from "../UI";
import { PROFILE_TYPE_STAFF } from "constants/common";
import { useFilterStyles } from "./Filters.styles";
import FilterSelect from "./FilterSelect";
import { options_priority, options_status, detectedOsToIconMap } from "./types";
import useFetchDevices from "./useFetchDevice";

const formatDeviceLabel = (option) => {
  if (option.value === "all") {
    return option.label;
  }
  const os = option.os ? option.os.toLowerCase() : "noDevice";
  return (
    <div className="deviceSelectOption">
      {option.name}
      {detectedOsToIconMap[os]}
    </div>
  );
};

export default function Filters({ setFilters, filters }) {
  const classes = useFilterStyles();
  const { t } = useTranslation();
  const identity = useSelector((state) => state.identity);
  const { options_devices } = useFetchDevices();

  const profileType = identity && identity.profile.profile_type;
  const isDeviceFilterVisible =
    profileType && profileType !== PROFILE_TYPE_STAFF;

  return (
    <div className={classes.top}>
      <CardTitle mb={0} variant="h2">
        <span style={{ lineHeight: "40px" }}>{t("to_do_list_filters")}</span>
      </CardTitle>
      <div className={classes.filters}>
        <FilterSelect
          name="priority"
          value={filters && filters.priority}
          options={options_priority}
          placeholder={t("priority_filter_placeholder")}
          onChange={setFilters}
        />
        <FilterSelect
          name="status"
          value={filters && filters.status}
          options={options_status}
          placeholder={t("status_filter_placeholder")}
          onChange={setFilters}
        />
        {isDeviceFilterVisible && (
          <FilterSelect
            name="device_uuid"
            value={filters && filters.device_uuid}
            options={options_devices}
            placeholder={t("device_filter_placeholder")}
            formatOptionLabel={formatDeviceLabel}
            onChange={setFilters}
          />
        )}
      </div>
    </div>
  );
}
