import React, { useEffect, useState } from 'react'
import CountUp from "react-countup";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  chart: {
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      borderRight: theme.variables.$isRTLDirection ? "0" :`1px solid ${theme.variables.$dividerLightGray}`,
      borderLeft: theme.variables.$isRTLDirection ? `1px solid ${theme.variables.$dividerLightGray}` : "0"
    }
  },
  circle: {
    position: 'relative',
    height: '170px',
    width: '170px',
    margin: '0 auto',
    display: 'flex',
    fontSize: '2.1rem',
    padding: '10px',
    '& .CircularProgressbar': {
      '&-trail': {
        strokeDasharray: '2 4!important',
        strokeWidth: '1px!important',
        stroke: theme.variables.$secondary
      },
      '&-path': {
        stroke: 'url(#chartGradient)!important'
      }
    }
  },
  riskValueWrapper: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
  },
  maxValue: {
    color: theme.variables.$textDarkGray,
    fontSize: ' 1.4rem',
  },
  scoreProgressbar: {

  }
}))


const GradientSVG = () => {
  let gradientTransform = `rotate(${30})`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id="chartGradient" gradientTransform={gradientTransform}>
          <stop offset="30%" stopColor="#57A022" />
          <stop offset="50%" stopColor="#00A3DA" />
          <stop offset="100%" stopColor="#00A3DA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const ChangingProgressProvider = ({ children, values }) => {
  const [score, setScore] = useState(0)
  useEffect(() => {
    const timer = setTimeout(() => {
      setScore((score + 1) % values.length)
    }, 1000);
    return () => { clearTimeout(timer) }
  }, [])
  return children(values[score]);
}

const CyberSecurityScore = ({ riskValue, children, maxRiskvalue }) => {
  const classes = useStyles()
  const maxValue = maxRiskvalue || 999
  const userRiskValue = riskValue / (maxValue / 100)
  const theme = useTheme()

  return (
    <div className={classes.chart}>
      <GradientSVG />
      <div className={`${classes.circle} tourDesktop1`}>
        <ChangingProgressProvider values={[0, userRiskValue]}>
          {percentage => (
            <CircularProgressbar
              value={percentage}
              className={classes.scoreProgressbar}
              strokeWidth={2}
              text=''
              styles={buildStyles({
                pathTransitionDuration: 0.5,
                rotation: 0.5,
              })}
              counterClockwise={theme.variables.$isRTLDirection ? true : false}
            />
          )}
        </ChangingProgressProvider>
        <div className={classes.riskValueWrapper}>
          <CountUp end={riskValue} />
          <div className={classes.maxValue}>/{maxValue}</div>
        </div>
      </div>
    </div>
  )
}

export default CyberSecurityScore
