import React from "react";
import { useAcrionRiskIndicatorStyles } from "./ActionRiskIndicator.styles";
import { indicatorQuarters, deviceToIconMap, EMPTY_DEVICE_TYPE } from "./types";

export default function ActionRiskIndicator({ data, className }) {
  const classes = useAcrionRiskIndicatorStyles();
  const deviceType = data.device ? data.device.device_type : EMPTY_DEVICE_TYPE;

  return (
    <div className={`${classes.icon} ${className} iconInsideDialog`}>
      {/* coloring chat qarters based on priority */}
      {Object.entries(indicatorQuarters).map(([quarter, value]) => (
        <span
          key={quarter}
          className={`${classes.quarter} ${quarter} ${
            value.includes(data.priority) ? data.priority : ""
          }`}
        ></span>
      ))}
      <div className={classes.riskIndicatorIcon}>
        {/* choose icon based on device type */}
        {deviceToIconMap[deviceType]}
      </div>
    </div>
  );
}
