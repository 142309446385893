/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CxHttpException from '../model/CxHttpException';
import CxHttpValidationError from '../model/CxHttpValidationError';
import UserCourseIndexResponse from '../model/UserCourseIndexResponse';

/**
* UserCourse service.
* @module api/UserCourseApi
* @version 1.0.0
*/
export default class UserCourseApi {

    /**
    * Constructs a new UserCourseApi. 
    * @alias module:api/UserCourseApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Filterable collection of user courses
     * Get user courses with pagination
     * @param {Object} opts Optional parameters
     * @param {Number} [page = 1)] Page number
     * @param {Number} [perPage = 10)] PerPage items
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserCourseIndexResponse} and HTTP response
     */
    userCourseIndexWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'per_page': opts['perPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = UserCourseIndexResponse;
      return this.apiClient.callApi(
        '/api/v1/user_courses', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Filterable collection of user courses
     * Get user courses with pagination
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Page number (default to 1)
     * @param {Number} opts.perPage PerPage items (default to 10)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserCourseIndexResponse}
     */
    userCourseIndex(opts) {
      return this.userCourseIndexWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
