import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCertificatesList } from "../store/actions/course";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getAllCourses } from "../api/training";

export const useCourses = (opts = { page: 1, perPage: 100 }) => {
  const dispatch = useDispatch();
  const [courses, setCourses] = useState([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAllCourses();
  }, [dispatch]);

  // move items filtering and map to sepatate function

  const setCompletedCertificates = (items) => {
    const certificatesList = items
      .filter((item) => item.progress_status === "COMPLETED")
      .map((item) => ({
        course_uuid: item.uuid,
        course_title: item.title,
        course_end_date: new Date(item.updated_at * 1000).toLocaleDateString(
          "en-US",
          {
            day: "2-digit",
            month: "long",
            year: "numeric",
          }
        ),
      }));
    dispatch(setCertificatesList(certificatesList));
  };

  const fetchAllCourses = async () => {
    try {
      const res = await getAllCourses(opts);
      setCourses(res.items);
      setIsLoading(false);
      setCompletedCertificates(res.items);
    } catch (e) {
      setIsLoading(false);
      toast(t("an_error_occured"), { type: toast.TYPE.ERROR });
    }
  };

  return [courses, isLoading];
};
