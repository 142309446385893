import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import { CardTitle, BodyText } from '../../components/UI'
import { ReactComponent as RouterIcon } from '../../assets/icons/router.svg'
import RippleLoading from './RippleLoading'

const ScanSecurityBreaches = ({ t }) => {
  return (
    <Box maxWidth='500px' margin='0 auto'>
      <Box>
        <RippleLoading
          mb={2}
          icon={<RouterIcon />}
        />
        <CardTitle>
          {t("performing_a_router_scan")}
        </CardTitle>
        <BodyText>
          {t("personal_data_scans_info", { data_type: `${t("router_for_vulnerabilities")}` })}
        </BodyText>
      </Box>
    </Box>
  )
}

export default withTranslation()(withRouter(ScanSecurityBreaches))