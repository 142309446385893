import React, { useState } from "react";
import isEmail from "validator/lib/isEmail";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import useForm from "../../../hooks/useForm";

import { CardTitle, InputField, SquareButton } from "../../../components/UI";
import { ReactComponent as EnvelopeIcon } from "../../../assets/icons/envelope_3.svg";
import { ReactComponent as BackArrowIcon } from "../../../assets/icons/arrow-back.svg";

const stateSchema = {
  email: { value: "", error: "" },
};

const Form = ({ sendResetLink, loading }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { locale } = useParams();

  const [initialState, setInitialState] = useState({
    data: null,
    errors: null,
  });

  const validationStateSchema = {
    email: {
      required: true,
      validator: {
        func: isEmail,
        error: t("enter_valid_email"),
      },
    },
  };

  const handleSubmit = () => {
    sendResetLink(state.email.value);
    setInitialState({
      data: null,
      errors: null,
    });
  };

  const { state, handleOnChange, handleOnBlur, handleOnSubmit, isDirty } =
    useForm(
      initialState,
      stateSchema,
      null,
      validationStateSchema,
      handleSubmit
    );

  return (
    <Box display="flex" alignItems="center" flexDirection="column" mt={10}>
      <Box textAlign="center" mb={2}>
        <EnvelopeIcon />
      </Box>
      <Paper
        elevation={2}
        style={{
          maxWidth: "576px",
          margin: "0 auto",
        }}
      >
        <Box p={3}>
          <CardTitle textAlign="center">{t("reset_your_password")}</CardTitle>
          <form
            className="-sizeWidthFull"
            id="form-login"
            onSubmit={handleOnSubmit}
          >
            <Box mb={4}>
              <InputField
                name="email"
                placeholder={t("email_address")}
                value={state.email.value}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                error={isDirty && isDirty.email && state.email.error}
              />
            </Box>
            <Box display="flex" mx={-1}>
              <Box mx={1}>
                <SquareButton
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    history.push({
                      pathname: `/${locale}/signIn`,
                      state: { skipAnimation: true },
                    });
                  }}
                >
                  <BackArrowIcon />
                </SquareButton>
              </Box>
              <Button
                mx={1}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size="14px" color="inherit" />
                ) : (
                  t("reset_password")
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Paper>
    </Box>
  );
};

export default Form;
