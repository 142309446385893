import axios from 'axios';
import { getItem } from 'helpers/localStorage';
import { ValidationErrorResponse } from '../services/http/Response';
import { RefreshTokenService } from 'services/authentication/RefreshTokenService';
import { store } from 'store';
import { setExpiredPlan } from 'store/actions/plans';
import { Log } from "services/log/logger";
import {onTooManyRequestsInterceptor} from "api/onTooManyRequests";

const MAX_CALL_ATTEMPTS = 5;
const urlAttemptMap = [];
let refreshSubscribers = [];

const responseOnFullfilled = (response) => {
  // Do something with response data.
  if (response.status === 401) { // not possible
    return handleInvalidTokenError(response).then((resp) => {
      return resp;
    })
  }
  return response;
}

const responseOnRejected = (error) => {

  // Do something with response error
  const jwt = getItem('JWT');
  let status = error.response ? error.response.status : 0;

  if (status === 423 && jwt) {
    store.dispatch(setExpiredPlan(true))
  }

  if (status === 422 && error.response.data) {
    error = new ValidationErrorResponse(error);

  } else if (status === 401) {
    if (jwt) {
      return handleInvalidTokenError(error);
    }
  }
  return Promise.reject(error);
}

const requestOnFullfilled = (config) => {
  if (!config.headers.hasOwnProperty('Content-Language')) {
    const localeFromPath = window.location.pathname.split('/')[1]
    const contentLang = getItem('locale') || localeFromPath
    config.headers['Content-Language'] = contentLang
  }
  if (
    !config.headers.hasOwnProperty('Authorization')
    && getItem('JWT')
  ) {
    config.headers['Authorization'] = 'Bearer ' + getItem('JWT')
  }
  if (
    config.headers.hasOwnProperty('Authorization')
    && config.headers.Authorization === null
  ) {
    delete config.headers.Authorization
  }
  // Do something before request is sent
  return config;
}

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
}

const onRrefreshed = (token) => {
  refreshSubscribers.map(cb => cb(token));
}

const handleInvalidTokenError = async (error) => {
  const errUrl = error.config.url
  const originalRequest = error.config

  urlAttemptMap[errUrl] = !urlAttemptMap[errUrl] ? 0 : urlAttemptMap[errUrl] + 1

  if (urlAttemptMap[errUrl] === MAX_CALL_ATTEMPTS) {
    urlAttemptMap[errUrl] = 0;
    return error;
  }

  if (!RefreshTokenService.isRefreshing) {
    RefreshTokenService.refresh().then((token) => {
      onRrefreshed(token)
    });
  }
  const retryOrigReq = new Promise((resolve, reject) => {
    subscribeTokenRefresh(token => {
      // replace the expired token and retry
      originalRequest.headers['Authorization'] = 'Bearer ' + token;
      resolve(client(originalRequest));
    }, originalRequest);
  });
  return retryOrigReq;
}



/**
 * @template T
 * @param {function():Promise<T>} fun
 * @param {string} message
 * @param {object} request
 * @returns {Promise<T>}
 */
export function logOnError(fun, message, request = {}) {
  try {
    return fun()
  } catch (e) {
    Log.error(message, { ...request, ...{ response: e.body } });
    throw e;
  }
}

const client = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY,
});

// Add a request interceptor
client.interceptors.request.use(
  requestOnFullfilled,
  (error) => { return Promise.reject(error) }
);

// Add a response interceptor
client.interceptors.response.use(responseOnFullfilled, responseOnRejected);
client.interceptors.response.use(undefined, onTooManyRequestsInterceptor);

export default client

