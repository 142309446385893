import React from "react";
import { SelectField } from "../UI";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

export default function FilterSelect({
  name,
  options,
  value,
  onChange,
  placeholder,
  formatOptionLabel,
}) {
  const { t } = useTranslation();
  const filterValue = options.find((el) => el.value === value);

  const formatLabel = (data) => {
    // tranlate all labels first
    data.label = t(data.label);

    if (typeof formatOptionLabel === "function") {
      return formatOptionLabel(data);
    }
    return data.label;
  };

  return (
    <div>
      <Typography variant="inherit">{placeholder}:</Typography>
      <SelectField
        formatOptionLabel={formatLabel}
        value={filterValue}
        options={options}
        onChange={(evt) => onChange({ [name]: evt.target.value.value })}
        placeholder={placeholder}
      />
    </div>
  );
}
