import { SET_BASIC_NOTIFICATION } from "../actions/basicNotification";

export default function basicNotification(state = null, { type, payload }) {
  switch (type) {
    case SET_BASIC_NOTIFICATION:
      return payload;
    default:
      return state;
  }
}
