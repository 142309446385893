import {useEffect, useState} from "react";

export const useIdentityFeature = (identity, featureName) => {
    const [feature, setFeature] = useState(null)
    useEffect(() => {
        if (identity !== null) {
            const f = identity.features.filter(feature => feature.name === featureName)[0]
            setFeature(f ? f : null)
        }
    }, [identity])

    return feature;
}