const checkLS = () => {
  if (!localStorage) {
    console.warn('No localStorage available');
    return false
  }

  return true
}

export function clear() {
  localStorage.clear()
}

export function setItem(key, value) {
  if (!checkLS()) {
    return
  }

  if (typeof value !== 'string') {
    value = JSON.stringify(value)
  }

  try {
    localStorage.setItem(key, value)
  } catch (err) {
    // probably no space in LS
    console.error(err);
  }
}

export function removeItem(key) {
  if (!checkLS()) {
    return
  }

  try {
    localStorage.removeItem(key)
  } catch(err) {
    // do nothing
  }
}

export function getItem(key, defaultValue='', isJSON=false) {
  if (!checkLS()) {
    return defaultValue
  }

  let value

  try {
    value = localStorage.getItem(key)
  } catch (err) {
    console.error(err)

    return defaultValue
  }

  if (isJSON) {
    try {
      value = JSON.parse(value)
    } catch (err) {
      console.error(err)

      return defaultValue
    }
  }

  return value
}
