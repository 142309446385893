import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import CardItem from '../CardItem'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import { CardTitle, BodyText } from '../../../../components/UI'

const ItemsList = ({ t, protectedEmails, deleteEmail, loading }) => {
  const identity = useSelector(state => state.identity)

  useEffect(() => {
    if (identity && identity.user) {
      const email = protectedEmails.find(el => el.email === identity.user.email);
      if (email) {
        let index = protectedEmails.indexOf(email);
        protectedEmails.splice(index, 1);
      }
      protectedEmails.unshift({
        uuid: identity.user.uuid,
        email: identity.user.email,
        deleteEnabled: false,
        is_family: false,
      });
    }
  }, identity)

  return (
    <div className='sa_monitored_data_email_list'>
      <Box mt={3} mb={2}>
        <CardTitle variant='h2' mb={1} >
          {t("protected_emails")}
        </CardTitle>
        <BodyText textAlign='inherit'>
          {t("protected_emails_description")}
        </BodyText>
      </Box>
      <Paper elevation={2}>
        <Box p={3}>
          {loading ?
            <Box height='100%' textAlign='center'>
              <CircularProgress />
            </Box>
            :
            <div>
              {identity
                && identity.user
                && protectedEmails.map(email =>
                  <CardItem
                    t={t}
                    key={email.uuid}
                    data={email}
                    userEmail={identity.user.email}
                    deleteEmail={deleteEmail}
                  />
                )
              }
            </div>
          }
        </Box>
      </Paper>
    </div>
  )
}

export default ItemsList
