import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => {
  return {
    chip: {
      display: "flex",
      border: theme.overrides.MuiInput.root.border,
      borderRadius: "4px",
      width: "fit-content",
      alignItems: "center",
      padding: theme.spacing(1),
    },
    small: {
      padding: theme.spacing(0.5),
    },
    iconWrapper: {
      marginRight: theme.spacing(1),
      display: "flex",
      alignItems: "center",
    },
    label: {
      marginBottom: 0,
    },
  };
});

const Chip = ({ label, icon, ml = 0, mr = 0, small = false }) => {
  const classes = useStyles();
  
  return (
    <Box
      className={`${classes.chip} ${small ? classes.small : ''}`}
      ml={ml}
      mr={mr}
    >
      {icon && <Box className={classes.iconWrapper}>{icon}</Box>}
      <Typography variant="caption" className={classes.label}>
        {label}
      </Typography>
    </Box>
  );
};

export default Chip;
