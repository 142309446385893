import React from "react";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getLastUnlockedItem } from "store/selectors/course";
import { startCourse } from "api/training";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const courseProgressStatus = {
  PENDING: "retail.start_course",
  IN_PROGRESS: "retail.continue",
  COMPLETED: "retail.view_certificate",
};

const CourseNavigationBtn = () => {
  const course = useSelector((store) => store.course.course);
  const lastCourseItem = useSelector((store) => getLastUnlockedItem(store));
  const { locale, courseid } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const startNewCourse = async () => {
    try {
      await startCourse(courseid);
    } catch (e) {
      toast(t("an_error_occured"), { type: toast.TYPE.ERROR });
    }
  };

  const redirectByStatus = (status) => {
    if (status === "PENDING") {
      history.push(`/${locale}/training/${lastCourseItem.view_path}`);
    } else if (status === "IN_PROGRESS") {
      history.push(`/${locale}/training/${lastCourseItem.view_path}`);
    } else if (status === "COMPLETED") {
      history.push(`/${locale}/training/course/${courseid}/certificate`);
    }
  };

  const handleButtonClick = () => {
    if (course.progress_status === "PENDING") {
      startNewCourse();
    }
    redirectByStatus(course.progress_status);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={handleButtonClick}
    >
      {t(courseProgressStatus[course.progress_status])}
    </Button>
  );
};

export default CourseNavigationBtn;
