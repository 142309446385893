import React from "react";
import { DYNARISK_CMS_URL } from "constants/common";
import { ReactComponent as DynariskLogo } from '../../assets/icons/dynarisk.svg'
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  poweredBy: {
    textAlign: 'center',
    '& a': {
      color: theme.variables.$black
    },
    '& svg': {
      verticalAlign: 'middle',
      marginLeft: '5px',
      width: '90px',
      height: '20px',
    },
    '& span': {
      lineHeight: '10px',
      fontSize: '10px',
      verticalAlign: 'top',
      fontWeight: 'bold',
    }
  }
}))

const PoweredByDynarisk = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.poweredBy}>
      <a
        href={DYNARISK_CMS_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span
        >
          {t("powered_by")}
        </span>
        <DynariskLogo />
      </a>
    </div>
  );
};

export default PoweredByDynarisk;
