import React, { useState } from 'react'

const SubHeaderContext = React.createContext()

const SubHeaderProvider = props => {
  const [subHeaderContext, useSubHeaderContext] = useState({
    isVisible: true,
    logo: { display: false },
    iThinkIveBeenHacked: { display: true },
    upgradePlan: { display: true },
    userProfile: { display: true },
    logout: { display: true }
  })

  const defaultContext = {
    subHeaderContext,
    useSubHeaderContext,
  }

  return (
    <SubHeaderContext.Provider value={defaultContext}>
      {props.children}
    </SubHeaderContext.Provider>
  )
}

const SubHeaderConsumer = SubHeaderContext.Consumer

export {SubHeaderContext, SubHeaderProvider, SubHeaderConsumer}
