import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import List from "./List";
import ContentPlaceholder from "components/ContentPlaceholder";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { CardTitle, Card } from "../../components/UI";

const Tasks = ({
  data,
  filters,
  itemsToShow,
  showFilters,
  loading,
  t,
  history,
  match,
}) => {
  if (loading) {
    return <ContentPlaceholder type="actionsList" quantity={itemsToShow} />;
  }

  return (
    <div className="tasks">
      {!showFilters && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <CardTitle mb={0}>{t("dashboard.tasks.what_to_do")}</CardTitle>
          <Box>
            <Button
              size="small"
              className="sa_dashboard_view_all_tasks"
              variant="contained"
              color="secondary"
              onClick={() => history.push(`/${match.params.locale}/todo`)}
            >
              {t("dashboard.tasks_view_all")}
            </Button>
          </Box>
        </Box>
      )}
      <Card>
        {data && (
          <List
            t={t}
            data={data}
            filters={filters}
            itemsToShow={itemsToShow}
            match={match}
          />
        )}
      </Card>
    </div>
  );
};

export default withTranslation()(withRouter(Tasks));
