import React from 'react'
import Item from './Item'
import { ReactComponent as CheckedIcon } from '../../../assets/icons/check-circle.svg'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { CardTitle } from '../../UI'


const List = props => {
  const data = props.data.items || []
  const itemsToShow = props.itemsToShow || 10
  const { t, match } = props
  const emptyList = data.length === 0

  return emptyList ? (
    <Box>
      <Box my={3} textAlign='center'>
        <CheckedIcon width='3.75rem' height='3.75rem' />
      </Box>
      <CardTitle textAlign='center' variant='h2' fontWeight='normal'>
        {t("everything_ok")}
      </CardTitle>
    </Box>
  ) : (
    <Grid container>
      {data.map((value, index) => {
        if ((index + 1) > itemsToShow) return null
        return <Item data={value} key={value.uuid} uuid={value.uuid} match={match} />
      })}
    </Grid>
  )
}

export default List
