import React from "react";
import PlaceholderBox from "./PlaceholderBox";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  contentPlaceholder: {
    overflow: "hidden",
  },
  cardsList: {
    margin: "0 -20px",
  },
  cardsListItem: {
    width: "calc(100% - 40px)",
    float: "left",
    marginLeft: "20px",
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      width: "calc(33.33333% - 26.66667px)",
      float: "left",
      marginLeft: "20px",
    },
  },
  infoCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    "& .riskPlaceholderWrapper": {
      width: "100%",
      marginLeft: theme.variables.$isRTLDirection ? 0 : "20px",
      marginRight: theme.variables.$isRTLDirection ? "20px" : 0,
      [theme.breakpoints.down("md")]: {
        marginTop: "20px",
      },
      [theme.breakpoints.up("lg")]: {
        "& .placeholderBox": {
          margin: 0,
        },
      },
    },
  },
  actionsList: {
    display: "flex",
    marginRight: "-3px",
    marginLeft: "-3px",
    "&:last-child": {
      "& .placeholderBox": {
        marginBottom: "0!important",
      },
    },
  },
}));

const ContentPlaceholder = ({ type, quantity }) => {
  const classes = useStyles();
  const createMockData = () => {
    const arr = [];
    for (let i = 0; i < quantity; i++) {
      arr.push(i);
    }
    return arr;
  };
  const renderPlaceholder = () => {
    switch (type) {
      case "cardsGrid":
        return (
          <div className={classes.contentPlaceholder}>
            <ul className={classes.cardsList}>
              {createMockData().map((value) => (
                <li key={value} className={classes.cardsListItem}>
                  <div style={{ position: "relative" }}>
                    <Paper>
                      <Box p={3}>
                        <PlaceholderBox type="circle" size="100px" />
                        <PlaceholderBox height="2rem" width="100%" />
                        <PlaceholderBox height="1rem" width="100%" />
                        <PlaceholderBox height="1rem" width="100%" />
                        <PlaceholderBox height="1rem" width="100%" />
                      </Box>
                    </Paper>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        );
      case "infoCard":
        return (
          <Paper className={classes.contentPlaceholder}>
            <Box p={3}>
              <div className={classes.infoCard}>
                <PlaceholderBox type="circle" size="130px" shrink={0} mb={0} />
                <div className="riskPlaceholderWrapper">
                  <PlaceholderBox height="2rem" width="80%" />
                  <PlaceholderBox height="1rem" width="100%" />
                  <PlaceholderBox height="1rem" width="100%" />
                  <PlaceholderBox height="1rem" width="100%" mb={0} />
                </div>
              </div>
            </Box>
          </Paper>
        );
      case "actionsList":
        return (
          <Paper className={classes.contentPlaceholder}>
            <Box p={3}>
              {createMockData().map((value) => (
                <div className={classes.actionsList} key={value}>
                  <PlaceholderBox
                    height="1.5rem"
                    width="1.5rem"
                    shrink={0}
                    mr="3px"
                    ml="3px"
                  />
                  <PlaceholderBox height="1.5rem" mr="3px" ml="3px" />
                  <PlaceholderBox
                    height="1.5rem"
                    width="100px"
                    mr="3px"
                    ml="3px"
                  />
                  <PlaceholderBox
                    height="1.5rem"
                    width="100px"
                    mr="3px"
                    ml="3px"
                  />
                </div>
              ))}
            </Box>
          </Paper>
        );
      case "planCard":
        return (
          <Paper className={classes.contentPlaceholder}>
            <PlaceholderBox height="4rem" width="100%" />
            <Box p={3}>
              {createMockData().map((value) => (
                <div key={value}>
                  <PlaceholderBox height="0.8rem" width="90%" mb={15} />
                </div>
              ))}
              <PlaceholderBox height="2rem" width="100px" mb={0} />
            </Box>
          </Paper>
        );
      default:
        return null;
    }
  };
  return <>{renderPlaceholder()}</>;
};

export default ContentPlaceholder;
