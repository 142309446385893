import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Page from "components/Page";
import Verifying from "./Verifying";
import SignUpEmailResend from "../SignUpEmailResend";

import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { CardTitle } from '../../../components/UI'
import { ReactComponent as EnvelopeIcon } from '../../../assets/icons/envelope_3.svg';
import Paper from "@material-ui/core/Paper";

const SignUpEmailVerify = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { locale, token } = useParams()
  const location = useLocation()
  const authKey = location.state ? location.state.authKey : ''

  const goToSigninPage = () => {
    history.push({
      pathname: `/${locale}/signIn`,
      state: { skipAnimation: true }
    });
  };

  if (token) {
    return (
      <Page name="signUpEmailVerify">
        <Verifying token={token} />
      </Page>
    );
  }
  return (
    // set auth_key to the page
    <Page name="signUpEmailVerify" authKey={authKey}>
      <Box height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <Box textAlign='center' mb={2}>
          <EnvelopeIcon />
        </Box>
        <Paper elevation={2} style={{ maxWidth: '576px', margin: "0 auto", }}>
          <Box p={3}>
            <CardTitle textAlign='center'>
              {t("verify_your_email_header")}
            </CardTitle>
            <Box display='flex' flexDirection='column' textAlign='center'>
              <Box mb={2} display='flex' justifyContent='center'>
                <Box flexBasis='70%'>
                  <SignUpEmailResend />
                </Box>
              </Box>
              <Box display='flex' justifyContent='center'>
                <Box flexBasis='70%'>
                  <Button
                    fullWidth
                    id="sa_signup_emailverify_return"
                    variant='text'
                    color='secondary'
                    onClick={goToSigninPage}
                  >
                    {t("return_to_sign_in")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Page>
  );
};

export default SignUpEmailVerify;
