/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CourseViewCourse from '../model/CourseViewCourse';
import CxHttpException from '../model/CxHttpException';
import CxHttpValidationError from '../model/CxHttpValidationError';
import UserCourseViewCourse from '../model/UserCourseViewCourse';

/**
* ViewCourse service.
* @module api/ViewCourseApi
* @version 1.0.0
*/
export default class ViewCourseApi {

    /**
    * Constructs a new ViewCourseApi. 
    * @alias module:api/ViewCourseApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Course details by uuid
     * View course with lessons and chapters
     * @param {String} uuid Course UUID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CourseViewCourse} and HTTP response
     */
    viewCourseWithHttpInfo(uuid) {
      let postBody = null;
      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling viewCourse");
      }

      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = CourseViewCourse;
      return this.apiClient.callApi(
        '/api/v1/courses/{uuid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Course details by uuid
     * View course with lessons and chapters
     * @param {String} uuid Course UUID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CourseViewCourse}
     */
    viewCourse(uuid) {
      return this.viewCourseWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * User course details by uuid
     * View user course with lessons and chapters
     * @param {String} uuid Course UUID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserCourseViewCourse} and HTTP response
     */
    viewUserCourseWithHttpInfo(uuid) {
      let postBody = null;
      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling viewUserCourse");
      }

      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = UserCourseViewCourse;
      return this.apiClient.callApi(
        '/api/v1/user_courses/{uuid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * User course details by uuid
     * View user course with lessons and chapters
     * @param {String} uuid Course UUID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserCourseViewCourse}
     */
    viewUserCourse(uuid) {
      return this.viewUserCourseWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
