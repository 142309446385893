import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import API from "api/user";
import { withTranslation } from "react-i18next";
import { AuthContext } from "../../../contexts/AuthContext";
import i18next from "i18next";
import { setItem } from "helpers/localStorage";
import SRS from "helpers/systemResourcesScanner";
import ToastWrapper from "components/ToastWrapper";
import { Log } from "services/log/logger";
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useReddirectToDashbordOnLoged } from "../../../hooks/authentication/useReddirectToDashbordOnLoged";

const SignInByToken = ({ t, history, match }) => {
  const { User, setJWT } = useContext(AuthContext);
  const [redirectCondition, toggleRedirectCondition] = useState(false)

  const token = match.params.token;

  const signInBytoken = async () => {
    try {
      const meta = SRS.run({});
      const body = { token, ...meta };
      const { data } = await API.signInByToken(body);
      setJWT(data.auth);
      User.refreshToken = data.refresh_token;
      User.isPlanSelected = data.is_plan_selected;
      setLanguage(data.language);
      // enable redirection check after tokens
      toggleRedirectCondition(true)
    } catch (err) {
      const error = err.response.data;
      Log.error("Signin by token error", { error, token });

      ToastWrapper(
        error ? error.detail : "Invalid token",
        "error",
        null,
        true,
        () => {
          // clear storage and redirect
          return history.push(`/${match.params.locale}/logout`);
        }
      );
    }
  };

  useReddirectToDashbordOnLoged(redirectCondition);

  const setLanguage = (dataLang) => {
    const targetLanguage = dataLang || match.params.locale;
    i18next.changeLanguage(targetLanguage);
    setItem("locale", targetLanguage);
  };

  useEffect(() => {
    signInBytoken();
  }, []);

  return (
    <Box display="flex" justifyContent='center' alignItems='center' width='100%' height='100%'>
      <CircularProgress />
    </Box>
  );
};

export default withTranslation()(withRouter(SignInByToken));
