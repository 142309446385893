import React from "react";
import Page from "components/Page";
import { useIdentityFeature } from "hooks/useIdentiyFeature";
import { useSelector } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import { Training } from "./Training";


const TrainingPage = () => {
  const identity = useSelector((state) => state.identity);
  const hasTrainingFeature = useIdentityFeature(identity, "sme_course_module");
  const { locale } = useParams();

  // if (identity && !hasTrainingFeature)
  //   return <Redirect to={`/${locale}/not-found`} />;

  return (
    <Page name="training" withAside withSubHeader>
      <Training />
    </Page>
  );
};

export default TrainingPage;
