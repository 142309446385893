import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import isEmail from "validator/lib/isEmail";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import useForm from "../../hooks/useForm";
import { InputField } from "../../components/UI";
import {useSelector} from "react-redux";

const stateSchema = {
  email: { value: "", error: "" },
  password: { value: "", error: "" },
};

const Form = ({ authorizeUser }) => {
  const { locale } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const partnerSettings = useSelector(state => state.partnerSettings)
  const validationStateSchema = {
    email: {
      required: true,
      validator: {
        func: isEmail,
        error: t("enter_valid_email"),
      },
    },
    password: {
      required: true,
    },
  };

  const [initialState, setInitialState] = useState({
    data: null,
    errors: null,
  });

  const handleSubmit = (state) => {
    authorizeUser({
      email: state.email.value,
      password: state.password.value,
      profile_type: partnerSettings.context.domain_type
    }).catch((err) => {
      const { password, email } = err;
      setInitialState({ ...initialState, errors: { password, email } });
    });
  };

  const {
    state,
    handleOnChange,
    handleOnBlur,
    handleOnSubmit,
    disable,
    isDirty,
  } = useForm(
    initialState,
    stateSchema,
    null,
    validationStateSchema,
    handleSubmit
  );

  const goToPasswordForgot = () => {
    history.push(`/${locale}/signIn/passwordForgot`);
  };

  return (
    <form className="-sizeWidthFull" onSubmit={handleOnSubmit} id="form-login">
      <Box mb={4}>
        <InputField
          name="email"
          placeholder={t("email_address")}
          value={state.email.value}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          error={isDirty && isDirty.email && state.email.error}
        />
      </Box>
      <Box mb={4}>
        <InputField
          type="password"
          name="password"
          placeholder={t("password")}
          value={state.password.value}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          error={isDirty && isDirty.password && state.password.error}
        />
      </Box>
      <Box mb={2}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={goToPasswordForgot}
        >
          {t("forgot_password")}
        </Button>
      </Box>
      <Button
        type="submit"
        fullWidth={true}
        variant="contained"
        color="primary"
        id="btn-login"
      >
        {t("sign_in")}
      </Button>
    </form>
  );
};

export default Form;
