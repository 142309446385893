import React from "react";
import Grid from '@material-ui/core/Grid'
import ContentPlaceholder from "components/ContentPlaceholder";

const Loading = () => {
  return (
    <div className="plansSkeleton">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <ContentPlaceholder type="planCard" quantity={15} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ContentPlaceholder type="planCard" quantity={15} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ContentPlaceholder type="planCard" quantity={15} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Loading;
