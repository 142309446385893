import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import Logo from "components/Logo";
import TermsAndPrivacyContent from "components/TermsAndPrivacy";
import MDRender from "components/MDRender";
import SpringScrollbars from "pages/SignUp/SpringScrollbars";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import { CardTitle } from "../../components/UI";

const useStyles = makeStyles((theme) => ({
  terms: {
    "& .mdWrapper": {
      "&>*": {
        marginRight: "15px",
      },
      "& ol": {
        listStyleType: "decimal",
        "& li": {
          marginTop: "10px",
          "& p": {
            display: "inline",
          },
        },
        "& ol": {
          marginLeft: "20px",
        },
      },
    },
  },
}));

const TermsAndPrivacy = ({ t, partnerSettings }) => {
  const classes = useStyles();
  const privacyPolicyContent = partnerSettings.privacyPolicyContent;
  const partnerName = partnerSettings.partnerName
    ? t("privacy_policy_title", { name: partnerSettings.partnerName })
    : t("privacy_policy_title", { name: "Dynarisk" });
  const isTocCustomHeader = partnerSettings.isTocCustomHeader;
  const partnerCustomHeader = partnerSettings.partnerCustomHeader;

  return (
    <Page name="privacyPolicy">
      <Box
        style={{
          maxWidth: "980px",
          margin: "0 auto",
        }}
      >
        <Fade in={true} timeout={{ enter: 1500 }}>
          <Box mb={2} textAlign="center" minHeight="30px">
            {isTocCustomHeader && partnerCustomHeader ? (
              <MDRender t={t} input={partnerCustomHeader} escapeHtml={false} />
            ) : (
              <Logo redirectTo="dashboard" />
            )}
          </Box>
        </Fade>
        <hr />
        <div
          style={{
            overflow: "auto",
            height: "auto",
            left: "calc((100vw - 510px)/2)",
            right: "calc((100vw - 510px)/2)",
            top: "180px",
            bottom: "60px",
          }}
          className={`${classes.terms} ${partnerSettings.partnerName}`}
        >
          <CardTitle variant="h2" mt={3}>
            {partnerName}
          </CardTitle>
          {privacyPolicyContent ? (
            <SpringScrollbars
              style={{ height: 550, textAlign: "justify", lineHeight: 1.7 }}
            >
              <MDRender t={t} input={privacyPolicyContent} escapeHtml={false} />
            </SpringScrollbars>
          ) : (
            <TermsAndPrivacyContent t={t} />
          )}
        </div>
      </Box>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  partnerSettings: state.partnerSettings,
});

export default connect(mapStateToProps)(withTranslation()(TermsAndPrivacy));
