import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textContainer: {
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function BodyText({
  children,
  textAlign = "inherit",
  mb = 0,
  fontWeight = "normal",
  component = "div",
  color = "initial",
  variant = "body2",
  ...props
}) {
  const classes = useStyles();
  return (
    <Box textAlign={textAlign} mb={mb} {...props}>
      <Typography
        className={classes.textContainer}
        variant={variant}
        style={{ fontWeight }}
        component={component}
        color={color}
      >
        {children}
      </Typography>
    </Box>
  );
}
