/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ModelUserChapter from './ModelUserChapter';
import ModelUserCourseTakeaway from './ModelUserCourseTakeaway';
import RepositoryCourseProgressStatus from './RepositoryCourseProgressStatus';
import RepositoryDifficultyLevel from './RepositoryDifficultyLevel';
import TimeDuration from './TimeDuration';

/**
 * The UserCourseViewCourse model module.
 * @module model/UserCourseViewCourse
 * @version 1.0.0
 */
class UserCourseViewCourse {
    /**
     * Constructs a new <code>UserCourseViewCourse</code>.
     * @alias module:model/UserCourseViewCourse
     * @param countItemsCompleted {Number} 
     * @param countItemsToDo {Number} 
     * @param courseSettingUuid {String} 
     * @param courseUuid {String} 
     * @param createdAt {Number} 
     * @param profileUuid {String} 
     * @param progressStatus {module:model/RepositoryCourseProgressStatus} 
     * @param quizzesCount {Number} 
     * @param uuid {String} 
     * @param videoLessonsCount {Number} 
     */
    constructor(countItemsCompleted, countItemsToDo, courseSettingUuid, courseUuid, createdAt, profileUuid, progressStatus, quizzesCount, uuid, videoLessonsCount) { 
        
        UserCourseViewCourse.initialize(this, countItemsCompleted, countItemsToDo, courseSettingUuid, courseUuid, createdAt, profileUuid, progressStatus, quizzesCount, uuid, videoLessonsCount);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, countItemsCompleted, countItemsToDo, courseSettingUuid, courseUuid, createdAt, profileUuid, progressStatus, quizzesCount, uuid, videoLessonsCount) { 
        obj['count_items_completed'] = countItemsCompleted;
        obj['count_items_to_do'] = countItemsToDo;
        obj['course_setting_uuid'] = courseSettingUuid;
        obj['course_uuid'] = courseUuid;
        obj['created_at'] = createdAt;
        obj['profile_uuid'] = profileUuid;
        obj['progress_status'] = progressStatus;
        obj['quizzes_count'] = quizzesCount;
        obj['uuid'] = uuid;
        obj['video_lessons_count'] = videoLessonsCount;
    }

    /**
     * Constructs a <code>UserCourseViewCourse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserCourseViewCourse} obj Optional instance to populate.
     * @return {module:model/UserCourseViewCourse} The populated <code>UserCourseViewCourse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserCourseViewCourse();

            if (data.hasOwnProperty('completion_date')) {
                obj['completion_date'] = ApiClient.convertToType(data['completion_date'], 'Number');
            }
            if (data.hasOwnProperty('count_items_completed')) {
                obj['count_items_completed'] = ApiClient.convertToType(data['count_items_completed'], 'Number');
            }
            if (data.hasOwnProperty('count_items_to_do')) {
                obj['count_items_to_do'] = ApiClient.convertToType(data['count_items_to_do'], 'Number');
            }
            if (data.hasOwnProperty('course_setting_uuid')) {
                obj['course_setting_uuid'] = ApiClient.convertToType(data['course_setting_uuid'], 'String');
            }
            if (data.hasOwnProperty('course_uuid')) {
                obj['course_uuid'] = ApiClient.convertToType(data['course_uuid'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Number');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('difficulty')) {
                obj['difficulty'] = RepositoryDifficultyLevel.constructFromObject(data['difficulty']);
            }
            if (data.hasOwnProperty('duration')) {
                obj['duration'] = TimeDuration.constructFromObject(data['duration']);
            }
            if (data.hasOwnProperty('image_url')) {
                obj['image_url'] = ApiClient.convertToType(data['image_url'], 'String');
            }
            if (data.hasOwnProperty('profile_uuid')) {
                obj['profile_uuid'] = ApiClient.convertToType(data['profile_uuid'], 'String');
            }
            if (data.hasOwnProperty('progress_status')) {
                obj['progress_status'] = ApiClient.convertToType(data['progress_status'], RepositoryCourseProgressStatus);
            }
            if (data.hasOwnProperty('quiz_uuid')) {
                obj['quiz_uuid'] = ApiClient.convertToType(data['quiz_uuid'], 'String');
            }
            if (data.hasOwnProperty('quiz_uuid_2')) {
                obj['quiz_uuid_2'] = ApiClient.convertToType(data['quiz_uuid_2'], 'String');
            }
            if (data.hasOwnProperty('quizzes_count')) {
                obj['quizzes_count'] = ApiClient.convertToType(data['quizzes_count'], 'Number');
            }
            if (data.hasOwnProperty('started_at')) {
                obj['started_at'] = ApiClient.convertToType(data['started_at'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Number');
            }
            if (data.hasOwnProperty('user_chapters')) {
                obj['user_chapters'] = ApiClient.convertToType(data['user_chapters'], [ModelUserChapter]);
            }
            if (data.hasOwnProperty('user_course_takeaways')) {
                obj['user_course_takeaways'] = ApiClient.convertToType(data['user_course_takeaways'], [ModelUserCourseTakeaway]);
            }
            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
            if (data.hasOwnProperty('video_lessons_count')) {
                obj['video_lessons_count'] = ApiClient.convertToType(data['video_lessons_count'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UserCourseViewCourse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UserCourseViewCourse</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of UserCourseViewCourse.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['course_setting_uuid'] && !(typeof data['course_setting_uuid'] === 'string' || data['course_setting_uuid'] instanceof String)) {
            throw new Error("Expected the field `course_setting_uuid` to be a primitive type in the JSON string but got " + data['course_setting_uuid']);
        }
        // ensure the json data is a string
        if (data['course_uuid'] && !(typeof data['course_uuid'] === 'string' || data['course_uuid'] instanceof String)) {
            throw new Error("Expected the field `course_uuid` to be a primitive type in the JSON string but got " + data['course_uuid']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['image_url'] && !(typeof data['image_url'] === 'string' || data['image_url'] instanceof String)) {
            throw new Error("Expected the field `image_url` to be a primitive type in the JSON string but got " + data['image_url']);
        }
        // ensure the json data is a string
        if (data['profile_uuid'] && !(typeof data['profile_uuid'] === 'string' || data['profile_uuid'] instanceof String)) {
            throw new Error("Expected the field `profile_uuid` to be a primitive type in the JSON string but got " + data['profile_uuid']);
        }
        // ensure the json data is a string
        if (data['quiz_uuid'] && !(typeof data['quiz_uuid'] === 'string' || data['quiz_uuid'] instanceof String)) {
            throw new Error("Expected the field `quiz_uuid` to be a primitive type in the JSON string but got " + data['quiz_uuid']);
        }
        // ensure the json data is a string
        if (data['quiz_uuid_2'] && !(typeof data['quiz_uuid_2'] === 'string' || data['quiz_uuid_2'] instanceof String)) {
            throw new Error("Expected the field `quiz_uuid_2` to be a primitive type in the JSON string but got " + data['quiz_uuid_2']);
        }
        // ensure the json data is a string
        if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
            throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
        }
        if (data['user_chapters']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['user_chapters'])) {
                throw new Error("Expected the field `user_chapters` to be an array in the JSON data but got " + data['user_chapters']);
            }
            // validate the optional field `user_chapters` (array)
            for (const item of data['user_chapters']) {
                ModelUserChapter.validateJSON(item);
            };
        }
        if (data['user_course_takeaways']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['user_course_takeaways'])) {
                throw new Error("Expected the field `user_course_takeaways` to be an array in the JSON data but got " + data['user_course_takeaways']);
            }
            // validate the optional field `user_course_takeaways` (array)
            for (const item of data['user_course_takeaways']) {
                ModelUserCourseTakeaway.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['uuid'] && !(typeof data['uuid'] === 'string' || data['uuid'] instanceof String)) {
            throw new Error("Expected the field `uuid` to be a primitive type in the JSON string but got " + data['uuid']);
        }

        return true;
    }


}

UserCourseViewCourse.RequiredProperties = ["count_items_completed", "count_items_to_do", "course_setting_uuid", "course_uuid", "created_at", "profile_uuid", "progress_status", "quizzes_count", "uuid", "video_lessons_count"];

/**
 * @member {Number} completion_date
 */
UserCourseViewCourse.prototype['completion_date'] = undefined;

/**
 * @member {Number} count_items_completed
 */
UserCourseViewCourse.prototype['count_items_completed'] = undefined;

/**
 * @member {Number} count_items_to_do
 */
UserCourseViewCourse.prototype['count_items_to_do'] = undefined;

/**
 * @member {String} course_setting_uuid
 */
UserCourseViewCourse.prototype['course_setting_uuid'] = undefined;

/**
 * @member {String} course_uuid
 */
UserCourseViewCourse.prototype['course_uuid'] = undefined;

/**
 * @member {Number} created_at
 */
UserCourseViewCourse.prototype['created_at'] = undefined;

/**
 * @member {String} description
 */
UserCourseViewCourse.prototype['description'] = undefined;

/**
 * @member {module:model/RepositoryDifficultyLevel} difficulty
 */
UserCourseViewCourse.prototype['difficulty'] = undefined;

/**
 * @member {module:model/TimeDuration} duration
 */
UserCourseViewCourse.prototype['duration'] = undefined;

/**
 * @member {String} image_url
 */
UserCourseViewCourse.prototype['image_url'] = undefined;

/**
 * @member {String} profile_uuid
 */
UserCourseViewCourse.prototype['profile_uuid'] = undefined;

/**
 * @member {module:model/RepositoryCourseProgressStatus} progress_status
 */
UserCourseViewCourse.prototype['progress_status'] = undefined;

/**
 * @member {String} quiz_uuid
 */
UserCourseViewCourse.prototype['quiz_uuid'] = undefined;

/**
 * @member {String} quiz_uuid_2
 */
UserCourseViewCourse.prototype['quiz_uuid_2'] = undefined;

/**
 * @member {Number} quizzes_count
 */
UserCourseViewCourse.prototype['quizzes_count'] = undefined;

/**
 * @member {Number} started_at
 */
UserCourseViewCourse.prototype['started_at'] = undefined;

/**
 * @member {String} title
 */
UserCourseViewCourse.prototype['title'] = undefined;

/**
 * @member {Number} updated_at
 */
UserCourseViewCourse.prototype['updated_at'] = undefined;

/**
 * @member {Array.<module:model/ModelUserChapter>} user_chapters
 */
UserCourseViewCourse.prototype['user_chapters'] = undefined;

/**
 * @member {Array.<module:model/ModelUserCourseTakeaway>} user_course_takeaways
 */
UserCourseViewCourse.prototype['user_course_takeaways'] = undefined;

/**
 * @member {String} uuid
 */
UserCourseViewCourse.prototype['uuid'] = undefined;

/**
 * @member {Number} video_lessons_count
 */
UserCourseViewCourse.prototype['video_lessons_count'] = undefined;






export default UserCourseViewCourse;

