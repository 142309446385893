import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import userApi from "api/user";
import { setPlans } from "store/actions/plans";

const enhancePlanOptions = (options) => {
  return options.reduce((prev, curr) => {
    const planModel = curr.model.toLowerCase();

    // prioritaze 1 year plan
    if (prev.payable && prev.payable.slug === "1_year") {
      return prev;
    }

    prev[planModel] = curr;
    return prev;
  }, {});
};

const enhancePlansData = (plans) => {
  const enhancedPlans = plans.reduce((prev, curr) => {
    const options = enhancePlanOptions(curr.options);
    prev[curr.slug] = { ...curr, options };
    return prev;
  }, {});
  return enhancedPlans;
};

const usePlans = (t) => {
  const plans = useSelector((state) => state.plans.list);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const refetchPlans = () => {
    dispatch(setPlans(null));
  };

  useEffect(() => {
    if (!plans) {
      setLoading(true);
      userApi
        .getPlans()
        .then((plans) => {
          const enhancedPlansObj = enhancePlansData(plans);
          dispatch(setPlans(enhancedPlansObj));
          setLoading(false);
        })
        .catch((err) => {
          toast(t("can_t_get_plans"), { type: toast.TYPE.ERROR });
          setLoading(false);
        });
    }
  }, [plans]);
  return { plans, loading, refetchPlans };
};

export default usePlans;
