import { flagSettingsActionTypes } from "../actions/flagSettings";

export default function flagSettings(state = null, { type, payload }) {
  switch (type) {
    case flagSettingsActionTypes.SET_FLAG_SETTINGS:
      return payload;
    case flagSettingsActionTypes.UPDATE_FLAG_SETTINGS: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
}
