import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import userApi from "../api/user";
import { AuthContext } from "../contexts/AuthContext";


import {
  setFlagSettings,
} from "../store/actions/flagSettings";

export const flagsMap = {
  USER_TOUR_SHOWN: 'flag.user_tour.shown',
  WELCOME_SCREEN_SHOWN: 'flag.welcome.shown',
  ASSESSMENT_SHOWN: 'flag.assessment.shown',
  TERMS_AND_CONDITIONS_SHOWN: 'flag.terms_and_condition_shown',
  IS_RESET_PASSWORD_SHOWN: 'flag.is_reset.shown',
}

const useFlagSettings = (query = true) => {
  const dispatch = useDispatch();
  const flagSettings = useSelector((state) => state.flagSettings);
  const { jwtToken } = useContext(AuthContext);
  const [userSettings, setUserSettings] = useState(null)

  useEffect(() => {
    if (jwtToken && query && !flagSettings) {
      getUserSettings();
    }

  }, [query, jwtToken]);

  const getUserSettings = async () => {
    try {
      const { data } = await userApi.getUserSettings();
      saveFetchedSettings(data.items)
    } catch (err) {
      if (process.env.NODE_ENV === "development") {
        console.log(err);
      }
    }
  };

  const saveFetchedSettings = (settings) => {
    const formattedSettings = settings.reduce((prev, curr) => {
      prev[curr.key] = curr.value;
      return prev;
    }, {});
    dispatch(setFlagSettings(formattedSettings));
  }

  const updateUserSetting = async (flag, value) => {
    try {
      await userApi.updateUserSettings({ flag, data: { value } });
      const settings = { ...flagSettings, [flag]: value };
      dispatch(setFlagSettings(settings));
    } catch (err) {
      if (process.env.NODE_ENV === "development") {
        console.log(err);
      }
    }
  };

  const updateDeviceAssessmentFlag = (uuid, value) => {
    const flag = `flag.assessment.${uuid}.shown`
    window.localStorage.setItem(flag, value)
  }

  useEffect(() => {
    if (flagSettings) {
      setUserSettings(flagSettings);
    }
  }, [flagSettings]);
  return { flagsMap, userSettings, updateUserSetting, updateDeviceAssessmentFlag, saveFetchedSettings };
};

export default useFlagSettings;
