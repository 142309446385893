import React from 'react';
import Lottie from 'react-lottie';

import Box from '@material-ui/core/Box';

import { ReactComponent as SpinnerIcon } from '../../assets/icons/default_spinner.svg'
import { DEFAULT_PARTNER_NAME } from 'constants/common'

import splashAnimData from './Animations/splash.json'

/**
   * Lottie options
   * loop - play animation in loops,
   * autoplay - play animation imidietly after creation,
   * animationData - imported data from json (import or require pointed at json)
   * render settings - setting for render (positioning, repeating, cropping, etc)
   * More at: https://github.com/airbnb/lottie-web
   */
const lottieOptons = {
  loop: false,
  autoplay: true,
  animationData: splashAnimData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function InitAnimation({ isEnded, toggleAnimation, slug }) {


  const handleLottieFinished = {
    eventName: "complete",
    callback: () => {
      toggleAnimation(false);
    },
  };

  const showDefaultSpinner = () => {
    setTimeout(() => {
      toggleAnimation(false);
    }, 1500);
    return <SpinnerIcon />;
  };

  return (
    <Box>
      {slug === DEFAULT_PARTNER_NAME ? (
        <Lottie
          height='200px'
          options={lottieOptons}
          isClickToPauseDisabled={true}
          isStopped={isEnded}
          eventListeners={[handleLottieFinished]}
        />
      ) : (
        <>{showDefaultSpinner()}</>
      )}
    </Box>
  )
}