import React, { useEffect, useState } from 'react'
import { setItem } from '../helpers/localStorage'
import { User } from '../services/authentication/AuthUser'

const AuthContext = React.createContext()

const AuthProvider = props => {
  const [jwtToken, setJWT] = useState(User.JWT)
  const [mfaData, setMfaData] = useState(null)

  useEffect(
    () => {
      setItem('JWT', jwtToken)
      if (jwtToken !== User.JWT) {
        User.JWT = jwtToken
      }
    },
    [jwtToken]
  )

  useEffect(
    () => {
      window.addEventListener('refreshToken', () => {
        setJWT(User.JWT)
      })

    },
    []
  )

  const defaultContext = {
    jwtToken,
    setJWT,
    mfaData,
    setMfaData,
    User
  }

  return (
    <AuthContext.Provider value={defaultContext}>
      { props.children}
    </AuthContext.Provider>
  )
}

const AuthConsumer = AuthContext.Consumer

export { AuthContext, AuthProvider, AuthConsumer }
