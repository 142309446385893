import React from "react";
import Button from "@material-ui/core/Button";
import { withTooltip } from "react-tippy";
import { getTooltipLabel } from "../../../utils/getTooltipLabel";
import { getNavigationLabel } from "../../../utils/getNavigationLabel";

const FooterButton = ({
  t,
  option,
  item,
  isOtherChapter = false,
  itemToCompare = null,
  color = "primary",
  onClick,
  disabled = false,
}) => {
  const tooltipTitle = getTooltipLabel(
    t,
    option,
    item,
    isOtherChapter,
    itemToCompare
  );

  const buttonLabel = getNavigationLabel(t, option, item, isOtherChapter);

  const NavigationButton = () => (
    <Button
      color={color}
      variant="contained"
      size="small"
      onClick={onClick}
      disabled={disabled}
    >
      {buttonLabel}
    </Button>
  );

  const NavigationButtonWithTooltip = withTooltip(NavigationButton, {
    title: tooltipTitle,
    arrow: true,
  });

  return disabled ? <NavigationButtonWithTooltip /> : <NavigationButton />;
};

export default FooterButton;
