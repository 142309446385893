import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { InputField } from '../../../../components/UI'
import useForm from '../../../../hooks/useForm'

import { isValidString, isStringMinMaxLength } from '../../../../helpers/validators'

const stateSchema = {
  email: { value: '', error: '' },
  first_name: { value: '', error: '' },
  last_name: { value: '', error: '' },
};

const Form = ({ editInfo, email, identity }) => {
  const { t } = useTranslation()
  const [disabled, setDisabled] = useState(true)

  const validationStateSchema = {
    email: {},
    first_name: {
      required: true,
      validator: [
        { func: isValidString, error: t("first_name_invalid") },
        { func: (str) => isStringMinMaxLength(str, null, 100), error: t("too_long_max_x", { count: 100 }) },
      ],
    },
    last_name: {
      required: true,
      validator: [
        { func: isValidString, error: t("last_name_invalid") },
        { func: (str) => isStringMinMaxLength(100, null, 100), error: t("too_long_max_x", { count: 100 }) },
      ]
    },
  };

  const [initialState, setInitialState] = useState({
    data: null,
    errors: null,
  });

  const handleSubmit = () => {
    const payload = {
      email: state.email.value,
      first_name: state.first_name.value,
      last_name: state.last_name.value,
    }
    editInfo(payload)
    setDisabled(true)
  }


  const { state, handleOnChange, handleOnBlur, handleOnSubmit, disable, isDirty } = useForm(
    initialState,
    stateSchema,
    null,
    validationStateSchema,
    handleSubmit,
  );

  const getStringValue = x => x ? x : '';

  useEffect(() => {
    if (identity && email) {
      setInitialState({
        ...initialState,
        data: {
          email,
          first_name: getStringValue(identity.user.first_name),
          last_name: getStringValue(identity.user.last_name),
        }
      }
      )
    }
  }, [identity, email])

  const handleChange = (evt) => {
    const { value, name } = evt.target
    const values = { first_name: state.first_name.value, last_name: state.last_name.value, [name]: value }
    if (identity && (identity.user.first_name !== values.first_name || identity.user.last_name !== values.last_name)) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
    handleOnChange(evt)
  }

  return (
    <form className='-sizeWidthFull' onSubmit={handleOnSubmit}>
      <Box mb={3}>
        <InputField
          name='email'
          type='text'
          className='sa_profile_email'
          value={email}
          readOnly
          disabled
        />
      </Box>
      <Box mb={3}>
        <InputField
          type='text'
          name='first_name'
          value={state.first_name.value}
          className='sa_profile_first_name'
          placeholder={t("first_name")}
          onChange={handleChange}
          onBlur={handleOnBlur}
          error={isDirty && isDirty.first_name && state.first_name.error}
        />
      </Box>
      <Box mb={3}>
        <InputField
          type='text'
          name='last_name'
          value={state.last_name.value}
          className='sa_profile_last_name'
          placeholder={t("last_name")}
          onChange={handleChange}
          onBlur={handleOnBlur}
          error={isDirty && isDirty.last_name && state.last_name.error}
        />
      </Box>
      <Button
        fullWidth
        variant='contained'
        color='primary'
        disabled={disabled}
        type='submit'
        className='sa_profile_personal_info_save'
      >
        {t("save")}
      </Button>
    </form>
  )
}

export default Form

