import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function CheckboxGroupQuestion({
  answerVariants,
  handleChange,
  disabledAllBesides,
  selectedChbxs,
}) {
  return (
    <FormGroup>
      {answerVariants.map((variant) => {
        return (
          <FormControlLabel
            style={{ marginLeft: 0, marginRight: 0 }}
            control={
              <Checkbox
                name={variant.alias}
                color="primary"
                checked={selectedChbxs.indexOf(variant.uuid) !== -1}
                onChange={(evt) =>
                  handleChange(evt, {
                    alias: variant.alias,
                    uuid: variant.uuid,
                  })
                }
                disabled={
                  disabledAllBesides && disabledAllBesides !== variant.uuid
                }
              />
            }
            label={variant.value}
            key={variant.uuid}
          />
        );
      })}
    </FormGroup>
  );
}
