import React from "react";
import SpringScrollbars from "./SpringScrollbars";
import MDRender from "components/MDRender";

const TermsAndConditionsContent = ({ t, locale, tacData, height = 400 }) => {
  return (
    <SpringScrollbars
      style={{ height, textAlign: "justify", boxSizing: "border-box" }}
    >
      {!tacData ? (
        <div className="calibre" id="calibre_link-0">
          <br />
          <p>
            <strong>1. {t("terms_1_strong")}</strong>
          </p>
          <p>
            <strong>1.1 {t("terms_1_1_strong")}</strong>&nbsp;{t("terms_1_1")}
          </p>
          <p>
            <strong>1.2 {t("terms_1_2_strong")}</strong>&nbsp;{t("terms_1_2")}
          </p>
          <p>
            <strong>1.3 {t("terms_1_3_strong")}</strong>
            &nbsp;{t("terms_1_3/1")}&nbsp;
            <a
              href={`/${locale}/privacy-policy`}
              target="_blank "
              rel="noopener noreferrer"
            >
              <u>{t("privacy_policy")}</u>
            </a>
            {t("terms_1_3/2")}
          </p>
          <p>
            <strong>2. {t("terms_2_strong")}</strong>&nbsp;
          </p>
          <p>
            <strong>2.1 {t("terms_2_1_strong")}</strong>&nbsp;{t("terms_2_1")}
          </p>
          <p>
            <strong>2.2 {t("terms_2_2_strong")}</strong>&nbsp;{t("terms_2_2")}{" "}
            <a href="mailto:info@dynarisk.com">
              <u>info@dynarisk.com</u>
            </a>
            .
          </p>
          <p>
            <strong>2.3 {t("terms_2_3_strong")}</strong>&nbsp;{t("terms_2_3")}
          </p>
          <p>
            <strong>2.4 {t("terms_2_4_strong")}</strong>&nbsp;{t("terms_2_4")}
          </p>
          <p>
            <strong>3. {t("terms_3_strong")}</strong>&nbsp;
          </p>
          <p>
            <strong>3.1 {t("terms_3_1_strong")}</strong>&nbsp;{t("terms_3_1/1")}
            <strong>{t("terms_3_1_service_strong")}</strong>&nbsp;
            {t("terms_3_1/2")}
          </p>
          <p>
            <strong>3.2 {t("terms_3_2_strong")}</strong>&nbsp;{t("terms_3_2")}
          </p>
          <p>
            <strong>3.3 {t("terms_3_3_strong")}</strong>&nbsp;
          </p>
          <p style={{ paddingLeft: 15 }}> 1. {t("terms_3_3_1")} </p>
          <p style={{ paddingLeft: 15 }}> 2. {t("terms_3_3_2")} </p>
          <p style={{ paddingLeft: 15 }}> 3. {t("terms_3_3_3")} </p>
          <p style={{ paddingLeft: 15 }}> 4. {t("terms_3_3_4")} </p>
          <p style={{ paddingLeft: 15 }}> 5. {t("terms_3_3_5")} </p>
          <p style={{ paddingLeft: 15 }}> 6. {t("terms_3_3_6")} </p>
          <p style={{ paddingLeft: 15 }}> 7. {t("terms_3_3_7")} </p>
          <p>
            <strong>4. {t("terms_4_strong")} </strong>&nbsp;{t("terms_4_1")}
          </p>
          <p>
            {" "}
            <strong>5. {t("terms_5_strong")}</strong>&nbsp;
          </p>
          <p>
            <strong>5.1 {t("terms_5_1_strong")}</strong>&nbsp;{t("terms_5_1")}
          </p>
          <p>
            <strong>(a)</strong>&nbsp;{t("terms_5_1_a")}
          </p>
          <p>
            <strong>(b)</strong>&nbsp;{t("terms_5_1_b")}
          </p>
          <p>{t("terms_5_additional")}</p>
          <p>
            <strong>6. {t("terms_6_strong")}</strong>&nbsp;
          </p>
          <p>
            <strong>6.1 {t("terms_6_1_strong")}</strong>&nbsp;{t("terms_6_1")}
          </p>
          <p>
            <strong>(a)</strong>&nbsp;
            <strong>{t("terms_6_1_a_strong")}</strong>&nbsp;
            {t("terms_6_1_a")}
            <strong>{t("terms_6_1_a_strong_1")}</strong>&nbsp;
            {t("terms_6_1_a_1")}
          </p>
          <p>
            <strong>(b)</strong>&nbsp;
            <strong> {t("terms_6_1_b_strong")}</strong>&nbsp;
            {t("terms_6_1_b")}
          </p>
          <p>
            <strong>(c)</strong>&nbsp;
            <strong>{t("terms_6_1_c_strong")}</strong>&nbsp;
            {t("terms_6_1_c")}
          </p>
          <p>
            {" "}
            <strong>6.2 {t("terms_6_2_strong")} </strong>&nbsp;{t("terms_6_2")}
          </p>
          <p>
            <strong>(a)</strong>&nbsp;{t("terms_6_2_a")}
          </p>
          <p>
            <strong>(b)</strong>&nbsp;{t("terms_6_2_b")}
          </p>
          <p>
            <strong>(c)</strong>&nbsp;{t("terms_6_2_c")}
          </p>
          <p>
            <strong>(d)</strong>&nbsp;{t("terms_6_2_d")}
          </p>
          <p>
            <strong>(e)</strong>&nbsp;{t("terms_6_2_e")}
          </p>
          <p>
            <strong> 6.3 {t("terms_6_3_strong")}</strong>&nbsp;{t("terms_6_3")}
          </p>
          <p>
            <strong>6.4 {t("terms_6_4_strong")}</strong>&nbsp;{t("terms_6_4")}
          </p>
          <p>
            <strong>(a)</strong>&nbsp;{t("terms_6_4_a")}
          </p>
          <p>
            <strong>6.5 {t("terms_6_5_strong")}</strong>&nbsp;{t("terms_6_5")}
          </p>
          <p>
            <strong>(a) {t("terms_6_5_a_strong")}</strong>&nbsp;
            {t("terms_6_5_a")}
          </p>
          <p>
            <strong>7. {t("terms_7_strong")}</strong>&nbsp;
          </p>
          <p>
            <strong>7.1 {t("terms_7_1_strong")}</strong>&nbsp;{t("terms_7_1")}
          </p>
          <p>
            <strong>(a)</strong>&nbsp;<strong>{t("terms_7_1_a_strong")}</strong>
            &nbsp;{t("terms_7_1_a")}
          </p>
          <p>
            <strong>7.2 {t("terms_7_2_strong")}</strong>&nbsp;{t("terms_7_2")}
          </p>
          <p>
            <strong>7.3 {t("terms_7_3_strong")}</strong>&nbsp;{t("terms_7_3")}
          </p>
          <p>
            <strong>(a)</strong>&nbsp;{t("terms_7_3_a")}
          </p>
          <p>
            <strong>7.4 {t("terms_7_4_strong")}</strong>&nbsp;{t("terms_7_4")}
          </p>
          <p>
            <strong>
              8. {t("terms_8_strong")}
              <br />
              <br />
              {t("terms_8_strong_1")}
            </strong>
            &nbsp;{t("terms_8")}
          </p>
          <p>
            <strong>8.1 {t("terms_8_1_strong")}</strong>&nbsp;{t("terms_8_1")}
          </p>
          <p>
            <strong>8.2 {t("terms_8_2_strong")}</strong>&nbsp;{t("terms_8_2")}
            &nbsp;
            <a
              href={`/${locale}/privacy-policy`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>{t("privacy_policy")}</u>
            </a>
            {t("terms_8_2_1")}
          </p>
          <p>
            <strong>8.3 {t("terms_8_3_strong")}</strong>&nbsp;{t("terms_8_3")}
          </p>
          <p>
            <strong>(a)</strong>&nbsp;{t("terms_8_3_a")}
          </p>
          <p>
            <strong>(b)</strong>&nbsp;{t("terms_8_3_b")}
          </p>
          <p>
            <strong>(c)</strong>&nbsp;{t("terms_8_3_c")}
          </p>
          <p>
            <strong> 8.4 {t("terms_8_4_strong")}</strong>&nbsp;{t("terms_8_4")}
          </p>
          <p>
            <strong>9. {t("terms_9_strong")}</strong>&nbsp;
          </p>
          <p>
            <strong>{t("terms_9_strong_1")}</strong>&nbsp;{t("terms_9_1")}
          </p>
          <p>
            <strong>10. {t("terms_10")}</strong>&nbsp;
          </p>
          <p>
            <strong>10.1 {t("terms_10_1_strong")}</strong>&nbsp;
            {t("terms_10_1")}
          </p>
          <p>
            <strong>10.2 {t("terms_10_2_strong")}</strong>&nbsp;
            {t("terms_10_2")}
          </p>
          <br />
          <div
            style={{
              padding: "20px",
              border: "solid 1px #dadada",
              background: "#eaf6ff",
            }}
          >
            <p>***</p>
            <p>
              <strong>{t("terms_rights")}</strong>&nbsp;
            </p>
            <p>{t("terms_rights_1")}</p>
            <p>
              {t("terms_rights_1_1")} <strong>{t("services")}</strong>&nbsp;
              {t("terms_rights_1_2")}{" "}
            </p>
            <p>
              <strong>(a)</strong>&nbsp;{t("terms_rights_a")}
            </p>
            <p>
              <strong>(b)</strong>&nbsp;{t("terms_rights_b")}
            </p>
            <p>
              <strong>(c) </strong>&nbsp;{t("terms_rights_c")}
            </p>
            <p>{t("terms_rights_2")}</p>
            <p>***</p>
          </div>
          <p>
            <strong>11. {t("terms_11_strong")}</strong>&nbsp;
          </p>
          <p>
            <strong>11.1 {t("terms_11_1_strong")}</strong>&nbsp;
            {t("terms_11_1")}
          </p>
          <p>
            <strong>11.2 {t("terms_11_2_strong")}</strong>&nbsp;
            {t("terms_11_2")}
          </p>
          <p>
            <strong>11.3 {t("terms_11_3_strong")}</strong>&nbsp;
            {t("terms_11_3")}
          </p>
          <p>
            <strong>11.4 {t("terms_11_4_strong")}</strong>&nbsp;
            {t("terms_11_4")}
          </p>
          <p>
            <strong>11.5 {t("terms_11_5_strong")}</strong>&nbsp;
            {t("terms_11_5")}
          </p>
          <p>
            <strong>12. {t("terms_12_strong")}</strong>&nbsp;
          </p>
          <p>
            <strong>12.1 {t("terms_12_1_strong")}</strong>&nbsp;
          </p>
          <p>
            <strong>12.2 {t("terms_12_2_strong")}</strong>&nbsp;
            {t("terms_12_2")}
            <strong>{t("terms_12_2_strong_1")}</strong>&nbsp;
          </p>
          <p>
            <strong>12.3 {t("terms_12_3_strong")}</strong>&nbsp;
            {t("terms_12_3")}
          </p>
          <p>
            <strong>13. {t("terms_13_strong")}</strong>&nbsp;
          </p>
          <p>
            {t("terms_13")}&nbsp;
            <a
              href={`/${locale}/privacy-policy`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>{t("privacy_policy")}</u>
            </a>
            .
          </p>
          <p>
            <strong>14. {t("terms_14_strong")}</strong>&nbsp;
          </p>
          <p>
            <strong>14.1 {t("terms_14_1_strong")}</strong>&nbsp;
            {t("terms_14_1")}
          </p>
          <p>
            <strong>14.2 {t("terms_14_2_strong")}</strong>&nbsp;
            {t("terms_14_2")}
          </p>
          <p>
            <strong>14.3 {t("terms_14_3_strong")}</strong>&nbsp;
            {t("terms_14_3")}
          </p>
          <p>
            <strong>14.4 {t("terms_14_4_strong")}</strong>&nbsp;
            {t("terms_14_4")}
          </p>
          <p>
            <strong>14.5 {t("terms_14_5_strong")}</strong>&nbsp;
            {t("terms_14_5")}
          </p>
          <p>
            <strong>14.6 {t("terms_14_6_strong")}</strong>&nbsp;
            {t("terms_14_6")}
          </p>
          <br />
        </div>
      ) : (
        <MDRender t={t} input={tacData} escapeHtml={false} />
      )}
    </SpringScrollbars>
  );
};

export default TermsAndConditionsContent;
