import React, { useState, useEffect, useContext, createContext } from "react";
import { useSelector } from "react-redux";

import userApi from "api/user";
import { getItem } from "helpers/localStorage";
import { Log } from "services/log/logger";

const RegisterContext = createContext({});

const registerContextMap = {
  INIT: "INIT",
  TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
  REGISTER_REQUEST: "REGISTER_REQUEST",
  EMAIL_VERYFICATION: "EMAIL_VERYFICATION",
  AUTH_REQUEST: "AUTH_REQUEST",
  AUTH_REQUEST_SUCCESS: "AUTH_REQUEST_SUCCESS",
};

const RegisterContextProvider = ({ children }) => {
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const [registerStatus, setRegisterStatus] = useState(registerContextMap.INIT);
  const [isValid, setIsValid] = useState(false);
  const [authKey, setAuthKey] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [flow, setFlow] = useState('')

  const isSmoothFlow = flow === "smooth";

  useEffect(() => {
    if (isValid) {
      registerUser(formValues);
    }
  }, [isValid]);

  const getPartner = () => {
    const cmsPlan = getItem("planFromCms");
    let partner = partnerSettings.partnerSlug;
    if (cmsPlan) {
      try {
        const plan = JSON.parse(cmsPlan);
        partner = plan.search.partner;
      } catch (err) {
        if (process.env.NODE_ENV === "development") {
          console.error("Partner doesn't set from cms");
        }
      }
    }
    return partner;
  };

  const setXRequestedWithHeader = () => {
    let headers = null;
    const xRequestedWithHeader = getItem("xRequestedWithHeader");
    if (xRequestedWithHeader) {
      headers = { "X-Requested-With": xRequestedWithHeader };
    }
    return headers;
  };

  const registerUser = async (form) => {
    setRegisterStatus(registerContextMap.REGISTER_REQUEST);
    let payload = {};
    let user;
    let nextStep;

    try {
      payload = {
        data: {
          ...form,
          partner: getPartner(),
        },
        headers: setXRequestedWithHeader(),
      };

      if (isSmoothFlow) {
        user = await userApi.registerSmoothFlow(payload);
        nextStep = registerContextMap.AUTH_REQUEST;
      } else {
        user = await userApi.register(payload);
        nextStep = registerContextMap.EMAIL_VERYFICATION;
      }
      setAuthKey(user.user.auth_key);
      setRegisterStatus(nextStep);
    } catch (err) {
      setRegisterStatus(registerContextMap.INIT);

      //   if (err && err.errorStack) {
      //     setValidationError(err.errorStack.toFormik());
      //   }
      if (!err.errorStack) {
        Log.error("Error register user", {
          request: payload,
          response: err.body,
          flow,
        });
      }
    }
  };

  const defaultContext = {
    registerContextMap,
    registerStatus,
    setRegisterStatus,
    isValid,
    setIsValid,
    authKey,
    formValues,
    setFormValues,
    setFlow
  };

  return (
    <RegisterContext.Provider value={defaultContext}>
      {children}
    </RegisterContext.Provider>
  );
};

const useRegisterContext = () => useContext(RegisterContext);

export { RegisterContext, RegisterContextProvider, useRegisterContext };
