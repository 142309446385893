import React from "react";
import logo from "../../../../../assets/icons/logo.png";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf",
      fontStyle: "normal",
      fontWeight: 400,
    },
    {
      src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf",
      fontStyle: "normal",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: "32px 48px",
    backgroundColor: "white",
    border: "32px solid #001E2E",
    fontFamily: "Montserrat",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  logo: {
    width: "100%",
    alignSelf: "center",
    maxWidth: "200px",
    marginBottom: 32,
  },
  textCenter: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
  },
  certTitle: {
    fontSize: 40,
    fontWeight: 400,
    marginBottom: 8,
  },
  certText: {
    fontSize: 16,
  },
  certUsername: {
    fontSize: 20,
    textDecoration: "underline",
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
  },
  certDate: {
    marginTop: 8,
    fontSize: 12,
  },
  signatureWrapper: {
    textAlign: "center",
    marginTop: 24,
    maxWidth: "150px",
  },
  signatureImage: {
    width: "100px",
    height: "30px",
    margin: "0 auto",
  },
  signatureText: {
    borderTop: "1px solid black",
    marginTop: 5,
    fontSize: 10,
  },
});

const Certificate = ({ identity, course }) => {
  const courseEndDate = new Date(course.updated_at * 1000).toLocaleDateString(
    "en-US",
    {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }
  );

  const sampleSignatureURL =
    "https://upload.wikimedia.org/wikipedia/commons/3/3a/Jon_Kirsch%27s_Signature.png";

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.textCenter}>
          <Image src={logo} style={styles.logo} />
          <Text style={styles.certTitle}>Certificate of Completion</Text>
          <Text style={styles.certText}>is granted to</Text>
          <Text
            style={styles.certUsername}
          >{`${identity.user.first_name} ${identity.user.last_name}`}</Text>
          <Text
            style={styles.certText}
          >{`For successfully completing "${course.title}" training.`}</Text>
          <Text style={styles.certDate}>{`Given on ${courseEndDate}`}</Text>
        </View>
        <View style={styles.signatureWrapper}>
          <Image src={sampleSignatureURL} style={styles.signatureImage} />
          <Text style={styles.signatureText}>Signature</Text>
        </View>
      </Page>
    </Document>
  );
};

export default Certificate;
