import React from "react";
import SpringScrollbars from "../../pages/SignUp/SpringScrollbars";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  termsAndPrivacy: {
    '&.marginRight': {
      marginRight: '20px'
    },
    '& p': {
      paddingLeft: '15px'
    },
    '& li': {
      paddingLeft: '15px'
    },
    '& .paddingTop': {
      paddingTop: '10px',
    },
    '& .paddingLeft': {
      paddingLeft: '15px'
    }
  }
}))

const termsAndPrivacy = ({ t }) => {
  const classes = useStyles()
  return (
    <SpringScrollbars
      style={{ height: 550, textAlign: "justify", lineHeight: 1.7 }}
    >
      <div className={`${classes.termsAndPrivacy} marginRight`}>
        <em>
          {t("tp_last_revision", {
            date: `10 ${t("month_name.december")} 2020`
          })}
        </em>
        <p>
          <br />
          {t("tp_intro")}
        </p>
        <br />
        <div>
          <ol type="1">
            <li>
              <strong>1. {t("tp_1_strong")}&nbsp;</strong>
              {t("tp_1")}{" "}
              <a href="mailto:info@dynarisk.com">info@dynarisk.com</a>.
            </li>
            <li className="paddingTop">
              <strong>2. {t("tp_2_strong")}&nbsp;</strong>
              {t("tp_2")}
              <ol type="1">
                <li className="paddingLeft">2.1 {t("tp_2_1")}</li>
                <li className="paddingLeft">2.2 {t("tp_2_2")}</li>
                <li className="paddingLeft">2.3 {t("tp_2_3")}</li>
                <li className="paddingLeft">2.4 {t("tp_2_4")}</li>
                <li className="paddingLeft">2.5 {t("tp_2_5")}</li>
                <li className="paddingLeft">2.6 {t("tp_2_6")}</li>
                <li className="paddingLeft">2.7 {t("tp_2_7")}</li>
              </ol>
            </li>
            <li className="paddingTop">
              <strong>3. {t("tp_3_strong")}&nbsp;</strong>
              {t("tp_3")}
            </li>
            <li className="paddingTop">
              <strong>4. {t("tp_4_strong")}&nbsp;</strong>
              <ol type="1">
                <li className="paddingLeft">
                  <strong>4.1 {t("tp_4_1_strong")}&nbsp;</strong>
                  {t("tp_4_1")}
                  <ol type="a">
                    <li className="paddingLeft">
                      <strong>(a)&nbsp;</strong>
                      {t("tp_4_1_a")}
                    </li>
                    <li className="paddingLeft">
                      <strong>(b)&nbsp;</strong>
                      {t("tp_4_1_b")}
                    </li>
                    <li className="paddingLeft">
                      <strong>(c)&nbsp;</strong>
                      {t("tp_4_1_c")}
                    </li>
                    <li className="paddingLeft">
                      <strong>(d)&nbsp;</strong>
                      {t("tp_4_1_d")}
                    </li>
                  </ol>
                  {t("tp_4_1_outro")}
                </li>
                <li className="paddingLeft">
                  <strong>4.2 {t("tp_4_2_strong")}&nbsp;</strong>
                  {t("tp_4_2")}
                </li>
                <li className="paddingLeft">
                  <strong>4.3 {t("tp_4_3_strong")}&nbsp;</strong>
                  {t("tp_4_3")}
                </li>
                <li className="paddingLeft">
                  <strong>4.4 {t("tp_4_4_strong")}&nbsp;</strong>
                  {t("tp_4_4")}
                </li>
                <li className="paddingLeft">
                  <strong>4.5 {t("tp_4_5_strong")}&nbsp;</strong>
                  {t("tp_4_5")}
                </li>
              </ol>
            </li>
            <li className="paddingTop">
              <strong>5. {t("tp_5_strong")}&nbsp;</strong>
              {t("tp_5")}
              <ol type="1">
                <li className="paddingLeft">5.1 {t("tp_5_1")}</li>
                <li className="paddingLeft">5.2 {t("tp_5_2")}</li>
                <li className="paddingLeft">5.3 {t("tp_5_3")}</li>
              </ol>
            </li>
            <li className="paddingTop">
              <strong>6. {t("tp_6_strong")}&nbsp;</strong>
              {t("tp_6")}(
              <a
                href="http://eur-lex.europa.eu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://eur-lex.europa.eu/
              </a>
              ).
            </li>
            <li className="paddingTop">
              <strong>7. {t("tp_7_strong")}&nbsp;</strong>
              {t("tp_7")}
            </li>
            <li className="paddingTop">
              <strong>8. {t("tp_8_strong")}&nbsp;</strong>
              <a href="mailto:info@dynarisk.com">info@dynarisk.com</a>.&nbsp;
              {t("tp_8")}
            </li>
            <li className="paddingTop">
              <strong>9. {t("tp_9_strong")}&nbsp;</strong>
              {t("tp_9")}&nbsp;
              <a
                href="https://ico.org.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ico.org.uk/
              </a>
              .
            </li>
            <li className="paddingTop">
              <strong>10. {t("tp_10_strong")}&nbsp;</strong>
              {t("tp_10")}
              <a href="mailto:info@dynarisk.com"> info@dynarisk.com</a>.
            </li>
            <li className="paddingTop">
              <strong>11. {t("tp_11_strong")}&nbsp;</strong>
              {t("tp_11")}&nbsp;
              <a
                href="https://ico.org.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ico.org.uk/
              </a>
              .
            </li>
            <li className="paddingTop">
              <strong>12. {t("tp_12_strong")}&nbsp;</strong>
              {t("tp_12_1")} (
              <a
                href="https://www.dynarisk.com/cookie-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.dynarisk.com
              </a>
              ) {t("tp_12_2")}
            </li>
            <li className="paddingTop">
              <strong>13. {t("tp_13_strong")}&nbsp;</strong>
              {t("tp_13")}
            </li>
          </ol>
        </div>
      </div>
    </SpringScrollbars>
  );
};

export default termsAndPrivacy;
