/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CxCollectionMeta model module.
 * @module model/CxCollectionMeta
 * @version 1.0.0
 */
class CxCollectionMeta {
    /**
     * Constructs a new <code>CxCollectionMeta</code>.
     * @alias module:model/CxCollectionMeta
     * @param pageCount {Number} 
     * @param pageCurrent {Number} 
     * @param totalItems {Number} 
     */
    constructor(pageCount, pageCurrent, totalItems) { 
        
        CxCollectionMeta.initialize(this, pageCount, pageCurrent, totalItems);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, pageCount, pageCurrent, totalItems) { 
        obj['page_count'] = pageCount;
        obj['page_current'] = pageCurrent;
        obj['total_items'] = totalItems;
    }

    /**
     * Constructs a <code>CxCollectionMeta</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CxCollectionMeta} obj Optional instance to populate.
     * @return {module:model/CxCollectionMeta} The populated <code>CxCollectionMeta</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CxCollectionMeta();

            if (data.hasOwnProperty('page_count')) {
                obj['page_count'] = ApiClient.convertToType(data['page_count'], 'Number');
            }
            if (data.hasOwnProperty('page_current')) {
                obj['page_current'] = ApiClient.convertToType(data['page_current'], 'Number');
            }
            if (data.hasOwnProperty('total_items')) {
                obj['total_items'] = ApiClient.convertToType(data['total_items'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CxCollectionMeta</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CxCollectionMeta</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CxCollectionMeta.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }

        return true;
    }


}

CxCollectionMeta.RequiredProperties = ["page_count", "page_current", "total_items"];

/**
 * @member {Number} page_count
 */
CxCollectionMeta.prototype['page_count'] = undefined;

/**
 * @member {Number} page_current
 */
CxCollectionMeta.prototype['page_current'] = undefined;

/**
 * @member {Number} total_items
 */
CxCollectionMeta.prototype['total_items'] = undefined;






export default CxCollectionMeta;

