import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Payments from 'components/Payments';
import SelectPlanStatus from 'constants/selectPlanStatus';
import API from 'api/user';
import { setCurrentPlan, setCurrentPlanFeatures } from 'store/actions/plans'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { usePlansContext } from '../../contexts';

const useStyles = makeStyles(theme => ({
  paymentPage: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  paymentWrapper: {
    width: '480px',
    minWidth: '300px',
    margin: '0 auto',
  }
}))

const PaymentPage = ({ t, history, match, setCurrentPlan, currentPlan, setCurrentPlanFeatures }) => {
  const classes = useStyles()
  const { plans, setPlans } = usePlansContext()
  const [planStatusRequest, setPlanStatusRequest] = useState(SelectPlanStatus.INIT)
  const planUuid = match.params.plan_uuid;
  const planSlug = match.params.plan_slug;

  useEffect(() => {
    if (!plans) {
      getPlans();
    }
  }, [])

  const findAndSetSelectedPlan = (plans) => {
    const [plan] = plans.filter(plan => plan.slug === planSlug)
    const [selectedPlan] = plan.options.filter(option => option.uuid === planUuid)
    setCurrentPlan(selectedPlan)
    setCurrentPlanFeatures(plan)
  }

  const getPlans = () => {
    API.getPlans()
      .then(plans => {
        setPlans(plans)
        findAndSetSelectedPlan(plans)
      })
      .catch((err) => {
        history.push('/plans')
      })
  }
  return (
    <div className={classes.paymentPage}>
      {!currentPlan || !currentPlan.uuid
        ?
        <Box height='100%' width='100%' display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress />
        </Box>
        : <Paper className={classes.paymentWrapper}>
          <Box p={3}>
            <Payments
              t={t}
              selectedPlan={currentPlan}
              requestStatus={planStatusRequest}
              setPlanStatusRequest={setPlanStatusRequest}
            />
          </Box>
        </Paper>
      }
    </div>
  )
}

const mapStateToProps = state => ({
  currentPlan: state.plans.currentPlan,
})

const mapDispatchToProps = dispatch => ({
  setCurrentPlan: plan => dispatch(setCurrentPlan(plan)),
  setCurrentPlanFeatures: planFeatures => dispatch(setCurrentPlanFeatures(planFeatures)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(PaymentPage)));
