import { makeStyles } from "@material-ui/core/styles";

export const useNavItemStyles = makeStyles((theme) => ({
  menuItem: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    cursor: "pointer",
    color: theme.variables.$sidebarTextColor,

    "&.logout": {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    "&:hover": {
      "& $label": {
        color:
          theme.variables.$sidebarTextHoverColor || theme.palette.primary.main,
        "& .labelText": {
          fontWeight: theme.variables.$sidebarTextHoverWeight || "normal",
        },
        "& $info": {
          fontWeight: theme.variables.$sidebarTextHoverWeight || "normal",
        },
      },
      "& $item": {
        opacity: 1,
        "&:after": {
          content: "''",
          position: "absolute",
          right: theme.variables.$isRTLDirection ? "initial" : "-30px",
          left: theme.variables.$isRTLDirection ? "-30px" : "initial",
          width: "3px",
          top: "0",
          height: "50px",
          background: theme.palette.secondary.main,
        },
        "& svg .svgFillPrimary": {
          fill:
            theme.variables.$sidebarTextHoverColor ||
            theme.variables.$sidebarTextColor,
        },
        "& svg .svgStrokePrimary": {
          stroke:
            theme.variables.$sidebarTextHoverColor ||
            theme.variables.$sidebarTextColor,
        },
      },
    },
    "& $item": {
      opacity: 0.8,
      "&.active": {
        opacity: 1,
        "& svg .svgFillPrimary": {
          fill: theme.variables.$sidebarTextColor,
        },
        "& svg .svgStrokePrimary": {
          stroke: theme.variables.$sidebarTextColor,
        },
        "& svg .accentColor": {
          fill: "red",
        },
        "& $label .labelText": {
          color: theme.variables.$sidebarTextColor,
          fontWeight: "bold",
        },
      },
    },
  },
  item: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    fill: "white",
    padding: "10px",
    // continental assist partner setting
    "& $label .sentencecase": {
      textTransform: "lowercase",
      "&:first-letter": {
        textTransform: "uppercase",
      },
    },
  },
  icon: {
    padding: "0 10px",
    "& svg": {
      fontSize: "1.5rem",
    },
  },
  label: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "capitalize",
    "& span": {
      display: "block",
      // marginLeft: "20px",
      padding: "0 10px",
      whiteSpace: "normal",
    },
  },
  info: {
    marginLeft: 0,
  },
}));
