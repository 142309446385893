import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

import { InputField, SelectField } from '../../../../components/UI'
import useForm from '../../../../hooks/useForm'

import { isPhoneNumber } from '../../../../helpers/validators'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  formWrapper: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    }
  },
  phoneWrapper: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      marginBottom: theme.spacing(1),
    }
  },
  phoneCodesSelect: {
    flexShrink: 1,
    flexBasis: '160px',
    marginRight: theme.variables.$isRTLDirection ? 0 : theme.spacing(1),
    marginLeft: theme.variables.$isRTLDirection ? theme.spacing(1) : 0,
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
      marginRight: 0,
      marginLeft: 0,
      marginBottom: theme.spacing(1),
    }
  },
  addPhoneBox: {
    marginLeft: theme.variables.$isRTLDirection ? 0 : theme.spacing(2),
    marginRight: theme.variables.$isRTLDirection ? theme.spacing(2) : 0,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
      width: '100%'
    }
  },
  addPhoneBtn: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  }
}))

const stateSchema = {
  country_code: { value: '', error: '' },
  protected_phone_number: { value: '', error: '' },
};

const Form = ({ addPhoneNumber }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const phonePrefixes = useSelector(state => state.phonePrefixes)

  const validationStateSchema = {
    country_code: {
      required: true,
    },
    protected_phone_number: {
      required: true,
      validator: [
        { func: isPhoneNumber, error: t("phone_must_contains") },
      ],
    },
  };

  const [initialState, setInitialState] = useState({
    data: null,
    errors: null,
  });

  const handleSubmit = () => {
    addPhoneNumber(
      {
        protected_phone_number: Number(state.protected_phone_number.value),
        country_code: state.country_code.value.value,
      }).then(errors => {
        if (errors) {
          setInitialState({ ...initialState, errors });
        } else {
          setInitialState({ data: null, errors: null })
        }
      })
  }

  const { state, handleOnChange, handleOnBlur, handleOnSubmit, isDirty } = useForm(
    initialState,
    stateSchema,
    null,
    validationStateSchema,
    handleSubmit,
  );

  useEffect(() => {
    if ((!initialState.data || !initialState.data.country_code) && phonePrefixes) {
      let country_code = phonePrefixes[0]
      const state = { data: { country_code, protected_phone_number: '' } }
      setInitialState(state)
    }
  }, [phonePrefixes]);

  return (
    <form onSubmit={handleOnSubmit}>
      <Box width="100%" display='flex' alignItems='center' className={classes.formWrapper}>
        <Box width="100%" display='flex' className={classes.phoneWrapper}>
          <Box className={classes.phoneCodesSelect}>
            {phonePrefixes
              &&
              <SelectField
                name="country_code"
                options={phonePrefixes}
                value={state.country_code.value}
                className="sa_signup_country_code"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
              />
            }
          </Box>
          <Box width='100%'>
            <InputField
              name='protected_phone_number'
              placeholder={t("phone_number")}
              type='text'
              className='sa_monitored_data_phone_number'
              value={state.protected_phone_number.value}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              error={isDirty
                && ((isDirty.protected_phone_number && state.protected_phone_number.error)
                  || (isDirty.country_code && state.country_code.error))
              }
            />
          </Box>
        </Box>
        <Box flexShrink={0} className={classes.addPhoneBox}>
          <Button
            style={{ padding: '8px 20px' }}
            type='submit'
            variant='contained'
            color='primary'
            className={`${classes.addPhoneBtn} sa_monitored_data_add_phone`}
            disabled={!state.protected_phone_number.value}
          >
            {t("add_phone")}
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default Form
