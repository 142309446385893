const PLAN_EXPIRED = "PLAN_EXPIRED";
const SET_CURRENT_PLAN = "SET_CURRENT_PLAN";
const SET_CURRENT_PLAN_FEATURES = "SET_CURRENT_PLAN_FEATURES";
const SET_PLANS = "SET_PLANS";

// phonePrefixes
const SET_PHONE_PREFIXES = "SET_PHONE_PREFIXES";

// partner settings
const SET_PARTNER_SETTINGS = "SET_PARTNER_SETTINGS";

// identity
const SET_IDENTITY = "SET_IDENTITY";
const CLEAR_IDENTITY = "CLEAR_IDENTITY";

// checks
const SET_CHECKS = "SET_CHECKS";

//quiz
const SET_QUESTIONS = 'SET_QUESTIONS';
const SET_ANSWER = 'SET_ANSWER';
const COMPLETE_QUIZ = 'COMPLETE_QUIZ';
const RESET_QUIZ = "RESET_QUIZ";

//course
const SET_COURSE = 'SET_COURSE';
const SET_COURSE_ITEMS = "SET_COURSE_ITEMS";
const SET_CERTIFICATES_LIST = "SET_CERTIFICATES_LIST";

export {
  PLAN_EXPIRED,
  SET_CURRENT_PLAN,
  SET_PHONE_PREFIXES,
  SET_PARTNER_SETTINGS,
  SET_IDENTITY,
  CLEAR_IDENTITY,
  SET_CURRENT_PLAN_FEATURES,
  SET_CHECKS,
  SET_PLANS,
  SET_QUESTIONS,
  SET_ANSWER,
  COMPLETE_QUIZ,
  RESET_QUIZ,
  SET_COURSE,
  SET_COURSE_ITEMS,
  SET_CERTIFICATES_LIST,
};
