export const flagSettingsActionTypes = {
  SET_FLAG_SETTINGS: "SET_FLAG_SETTINGS",
  UPDATE_FLAG_SETTINGS: "UPDATE_FLAG_SETTINGS",
}

export const setFlagSettings = (settings) => ({
  type: flagSettingsActionTypes.SET_FLAG_SETTINGS,
  payload: settings
})

export const updateFlagSetting = (setting) => ({
  type: flagSettingsActionTypes.UPDATE_FLAG_SETTINGS,
  payload: setting
})