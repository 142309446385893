import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useSwitchInputStyle = makeStyles(theme => ({
  switchBtn: {
    position: 'relative',
    display: 'inline-block',
    width: '80px',
    height: '30px',
    '& input': {
      display: 'none'
    }
  },
  slideDot: {
    position: 'absolute',
    cursor: 'pointer',
    fontSize: '14px',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: theme.variables.$lightGray,
    transition: '.4s',
    '&:before': {
      position: 'absolute',
      content: '""',
      height: '26px',
      width: '26px',
      left: '2px',
      bottom: '2px',
      backgroundColor: 'white',
      transition: '.4s',
    }
  },
  input: {
    '&:checked + $slideDot': {
      backgroundColor: theme.variables.$black,
      '&:before': {
        transform: 'translateX(50px)'
      },
      '& $on': {
        display: 'block',
        top: '50%',
        left: '35%',
      },
      '& $off': {
        display: 'none'
      }
    },

    '&:focus + $slideDot': {
      boxShadow: '0 0 1px #2196F3',
    },
  },
  switchInputText: {
    color: 'white',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '65%',
    textTransform: 'capitalize',
    fontSize: '14px',
  },
  on: {
    display: 'none',
  },
  off: {},
  round: {
    borderRadius: '34px',
    '&:before': {
      borderRadius: '50%'
    }
  },
  switchLabel: {
    color: theme.variables.$black,
    lineHeight: '30px',
    margin: '0 10px',
    display: 'inline-block',
  },
  horizontal: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}))

export default function SwitchInput({
  onChange,
  value,
  onText = 'on',
  offText = 'off',
  name = 'switch-input',
  label = null,
  horizontal=true
}) {
  const classes = useSwitchInputStyle()
  return (
    <div className={horizontal ? classes.horizontal : ''}>
      <label className={classes.switchBtn}>
        <input
          className={classes.input}
          name={name}
          type="checkbox"
          id="togBtn"
          checked={value}
          onChange={onChange}
        />
        <div className={`${classes.slideDot} ${classes.round}`}>
          <span className={`${classes.on} ${classes.switchInputText}`}>{onText}</span>
          <span className={`${classes.off} ${classes.switchInputText}`}>{offText}</span>
        </div>
      </label>
      {label &&
        <div className={classes.switchButton}>
          <span className={classes.switchLabel}>{label}</span>
        </div>
      }
    </div>
  )
}