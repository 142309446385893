import client from '../axiosInstance';

/**
 * @typedef {{hash:string,first_name:string,last_name:string,password:string,country_code:string,phone_number:string}} ActiveteUserStuffData
 */
export default {
  /**
   * @param {ActiveteUserStuffData} data
   * @returns {Promise<AxiosResponse<*>>}
   */
  activateStuffUser(data) {
    return client.post(`/user/api/v2/users/profiles/activate`, data, {headers: {'Authorization': null}});
  },
  /**
   * @param {{hash:string}} data
   * @returns {Promise<AxiosResponse<*>>}
   */
  validateHashStuffUser(data) {
    return client.post(`/user/api/v2/users/profiles/activate/validate/hash`, data);
  }
}
