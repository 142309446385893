import client from '../axiosInstance';
import { Log } from "services/log/logger";

export default {
  getDevices() {
    return client.get('/device/api/v1/users/devices')
      .then(response => {
        if (response && response.data && Array.isArray(response.data.items)) {
          return Promise.resolve(response.data)
        }
        return Promise.reject()
      })
  },

  async addDevice(payload) {
    try {
      return client.post('/device/api/v1/users/devices', payload)
    } catch (e) {
      Log.error("Error add device", { request: payload, response: e.body });
      throw e;
    }
  },

  updateDevice(payload, uuid) {
    return client.put(`/device/api/v1/users/devices/${uuid}/`, payload)
  },

  deleteDevice({ uuid }) {
    return client.delete(`/device/api/v1/users/devices/${uuid}/`)
  },

  getDeviceActions({ uuid }) {
    return client.get(`/device/api/v1/users/actions/${uuid}`)
      .then(response => response.data)
  },

  getDeviceByHash({ token }) {
    return client.get(`/device/api/v1/users/devices/by-hash/${token}/`)
  },

  activateDevice(deviceToken, body) {
    const url = deviceToken ? `/device/api/v1/users/devices/activate/${deviceToken}/` : '/device/api/v1/users/devices/activate'
    return client.post(url, body)
  },

  activateDeviceResend(uuid) {
    return client.post(`/device/api/v1/users/devices/${uuid}/resend`)
  },

  getUserDevice(device_uuid) {
    return client.get(`/device/api/v1/users/devices/${device_uuid}/`, {})
      .then(response => {
        if (response && response.data) {
          return Promise.resolve(response.data);
        }
        return Promise.reject();
      })
  },

  triggerVulnerabilityScan(payload, device_uuid) {
    return client.put(`/device/api/v1/users/devices/${device_uuid}`, payload);
  },

  getTasks(page_current, filter) {
    filter = filter && `?${filter}`
    const url = page_current ? `/device/api/v1/users/actions/page/${page_current}${filter}` : `/device/api/v1/users/actions${filter}`
    return client.get(url)
  },

  updateTaskStatus(uuid, status, remind_value) {
    return client.post(`/device/api/v1/users/actions/${uuid}/status`, { status, remind_value })
  },
}
