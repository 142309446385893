/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import RequestUserQuizAnswerItem from './RequestUserQuizAnswerItem';

/**
 * The RequestUserQuizCreateForm model module.
 * @module model/RequestUserQuizCreateForm
 * @version 1.0.0
 */
class RequestUserQuizCreateForm {
    /**
     * Constructs a new <code>RequestUserQuizCreateForm</code>.
     * @alias module:model/RequestUserQuizCreateForm
     * @param answers {Array.<module:model/RequestUserQuizAnswerItem>} 
     * @param quizUuid {String} 
     * @param userCourseUuid {String} 
     */
    constructor(answers, quizUuid, userCourseUuid) { 
        
        RequestUserQuizCreateForm.initialize(this, answers, quizUuid, userCourseUuid);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, answers, quizUuid, userCourseUuid) { 
        obj['answers'] = answers;
        obj['quiz_uuid'] = quizUuid;
        obj['user_course_uuid'] = userCourseUuid;
    }

    /**
     * Constructs a <code>RequestUserQuizCreateForm</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RequestUserQuizCreateForm} obj Optional instance to populate.
     * @return {module:model/RequestUserQuizCreateForm} The populated <code>RequestUserQuizCreateForm</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RequestUserQuizCreateForm();

            if (data.hasOwnProperty('answers')) {
                obj['answers'] = ApiClient.convertToType(data['answers'], [RequestUserQuizAnswerItem]);
            }
            if (data.hasOwnProperty('quiz_uuid')) {
                obj['quiz_uuid'] = ApiClient.convertToType(data['quiz_uuid'], 'String');
            }
            if (data.hasOwnProperty('user_course_uuid')) {
                obj['user_course_uuid'] = ApiClient.convertToType(data['user_course_uuid'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>RequestUserQuizCreateForm</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RequestUserQuizCreateForm</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of RequestUserQuizCreateForm.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        if (data['answers']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['answers'])) {
                throw new Error("Expected the field `answers` to be an array in the JSON data but got " + data['answers']);
            }
            // validate the optional field `answers` (array)
            for (const item of data['answers']) {
                RequestUserQuizAnswerItem.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['quiz_uuid'] && !(typeof data['quiz_uuid'] === 'string' || data['quiz_uuid'] instanceof String)) {
            throw new Error("Expected the field `quiz_uuid` to be a primitive type in the JSON string but got " + data['quiz_uuid']);
        }
        // ensure the json data is a string
        if (data['user_course_uuid'] && !(typeof data['user_course_uuid'] === 'string' || data['user_course_uuid'] instanceof String)) {
            throw new Error("Expected the field `user_course_uuid` to be a primitive type in the JSON string but got " + data['user_course_uuid']);
        }

        return true;
    }


}

RequestUserQuizCreateForm.RequiredProperties = ["answers", "quiz_uuid", "user_course_uuid"];

/**
 * @member {Array.<module:model/RequestUserQuizAnswerItem>} answers
 */
RequestUserQuizCreateForm.prototype['answers'] = undefined;

/**
 * @member {String} quiz_uuid
 */
RequestUserQuizCreateForm.prototype['quiz_uuid'] = undefined;

/**
 * @member {String} user_course_uuid
 */
RequestUserQuizCreateForm.prototype['user_course_uuid'] = undefined;






export default RequestUserQuizCreateForm;

