import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Link, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Page from 'components/Page'
import Tasks from 'components/Tasks'
import DeviceStatusPanel from './DeviceStatusPanel'
import ContentPlaceholder from 'components/ContentPlaceholder'
import API from 'api/device'
import SRS from 'helpers/systemResourcesScanner';
import { useActionsContext } from '../../../contexts/ActionsContext'

import Button from '@material-ui/core/Button'
import { SquareButton, EditableContent, CardTitle } from '../../../components/UI'
import { ReactComponent as BackArrowIcon } from '../../../assets/icons/arrow-back.svg'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
	header: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3rem',
		'& :nth-child(2)': {
			flexGrow: 1,
			marginLeft: theme.variables.$isRTLDirection ? " 0" : "1rem",
			marginRight: theme.variables.$isRTLDirection ? "1rem" : "0",
		},
		[theme.breakpoints.down('xs')]: {
			flexWrap: 'wrap'
		}
	},
	deleteBtn: {
		[theme.breakpoints.down('xs')]: {
			marginTop: '1rem',
			width: '100%'
		}
	}
}))

const Device = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const classes = useStyles()
	const { uuid, locale } = useParams()
	const [loading, setLoading] = useState(false)
	const [device, setDevice] = useState(null);
	const { fingerprint_v2, fingerprint } = SRS.run({});
	const { deviceActions, actionsLoading, saveFilters } = useActionsContext();
	useEffect(() => {
		getDevice()
	}, [])

	const deleteDevice = (uuid, name, title) => () => {
		API.deleteDevice({ uuid })
			.then(() => {
				toast(t("device_was_deleted", { name, title }), { type: toast.TYPE.INFO })
				history.push(`/${locale}/devices`)
			})
			.catch(() => {
				toast(t("device_delete_error", { name, title }), { type: toast.TYPE.ERROR })
			})
	}

	const updateDevice = (evt) => {
		if (evt.target.value.toLowerCase() !== device.name) {
			updateDeviceName(uuid, evt.target.value)
		}
	}

	const updateDeviceName = (uuid, name) => {
		setDevice({ ...device, title: name })
		API.updateDevice({ ...device, name, scan_by: 'browser', fingerprint: null }, uuid)
			.catch(err => { })
	}

	const getDevice = () => {
		setLoading(true)
		API.getUserDevice(uuid)
			.then(response => {
				setLoading(false)
				if (!response.title) {
					response.title = response.name;
				}
				if (!response.os) {
					response.os = response.name.toLowerCase();
				}

				if (response.name === response.title) {
					response.name = "desktop";
				}
				setDevice(response)
				saveFilters({ status: 'to_be_completed', device_uuid: uuid })
			})
			.catch(err => {
				setLoading(false)
				toast(t("can_t_get_tasks"), { type: toast.TYPE.ERROR });
				history.push(`/${locale}/devices`);
			})
	}

	if (loading) {
		return (
			<Page
				name='devices'
				withAside
				withSubHeader
			>
				<div style={{ margin: '20px 0' }}>
					<ContentPlaceholder type='infoCard' />
				</div>
				<ContentPlaceholder type='actionsList' quantity={5} />
			</Page>
		)
	}

	if (device) {
		return (
			<Page
				name='devices'
				withAside
				withSubHeader
			>
				<div className={classes.header}>
					<SquareButton
						variant='contained'
						color='secondary'
						className="sa_devices_back"
						onClick={() => {
							history.push(`/${locale}/Devices`)
						}}
					>
						<BackArrowIcon />
					</SquareButton>
					<EditableContent
						validate={/^([a-zA-Z0-9\s\-\_]{0,32})$/}
						value={device.title}
						callback={updateDevice}
					/>
					<Button
						variant='contained'
						color='primary'
						onClick={deleteDevice(device.uuid, device.device_type, device.title)}
						className={`sa_devices_delete_device ${classes.deleteBtn}`}>
						{t("device.delete_device")}
					</Button>
				</div>
				<br />
				{
					device.status === "active"
					&& <DeviceStatusPanel t={t} data={device} />
				}

				{device.os === "linux" ? "" :
					<>
						<br />
						<br />
						{device.status === "active"
							&& !!deviceActions.items.length
							&& <Tasks
								data={deviceActions}
								title={t("device_tasks")}
								loading={actionsLoading}
							/>
						}
						{
							device.status === "pending" &&
							(fingerprint_v2 === device.fingerprint_browser
								|| fingerprint === device.fingerprint_browser)
							&&
							<Paper>
								<Box p={3}>
									<CardTitle textAlign='center'>
										{t('complete_device_assessment')}
									</CardTitle>
									<Button
										fullWidth
										variant='contained'
										color='primary'
										size='small'
										className='sa_devices_device_assessment'
										onClick={() => { history.push(`/${locale}/deviceScanning/${device.uuid}`) }}
									>
										{t('take_device_assessment')}
									</Button>
								</Box>
							</Paper>
						}
					</>
				}
			</Page>
		)
	}

	return null
}

export default Device
