import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiChip from '@material-ui/core/Chip';

const Chip = withStyles((theme) => ({
  root: {
    background: '#fff',
    borderRadius: '4px',
  },
  labelSmall: {
    padding: '4px 8px',
    fontSize: '14px',
  }
}))(MuiChip);

const useStyles = makeStyles((theme) => ({
  alertPriorityChip: {
    '&-enabled': {
      marginRight: `${theme.spacing(1)}px`,
      background: theme.variables.$green10,
      textTransform: 'capitalize',
      color: theme.variables.$green100,
      fontWeight: 'normal',
    },
    '&-disabled': {
      marginRight: `${theme.spacing(1)}px`,
      background: theme.variables.$red10,
      textTransform: 'capitalize',
      color: theme.variables.$red100,
      fontWeight: 'normal',
    },
  },
  enabled: {
    background: theme.variables.$green10,
  },
  disabled: {
    background: theme.variables.$red10
  },
}));

const SeverityChip = ({ type, size = 'medium', isCaps = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const label = isCaps ? `${t(type).toUpperCase()}` : `${t(type)}`;

  return (
    <Chip
      size={size}
      label={label}
      className={`${classes.alertPriorityChip}-${type ? type : ''}`}
    />
  );
};

export default SeverityChip;
