import React from "react";
import { useToggle } from "react-hooks-lib";
import Box from "@material-ui/core/Box";
import SpringScrollbars from "../../pages/SignUp/SpringScrollbars";

const ReadMore = (props) => {
  let { children, textLength } = props;
  const { on } = useToggle(false);
  const minTextLength = 330;
  const truncateText = textLength > minTextLength;
  return (
    <>
      <div
        className={`readmore-main ${truncateText ? (on ? "" : "short") : ""}`}
      >
        <SpringScrollbars>
          <Box mr={2.75}>{children}</Box>
        </SpringScrollbars>
      </div>
    </>
  );
};

export default ReadMore;
