import { FormikErrorConverter } from './Converters'

/**
 * @typedef {{propertyPath: string, title:string, type?:string}} SymfonyViolation
 */

export class ValidationErrorStack {
  /**
   * @type {ValidationError[]}
   */
  errors = []

  /**
   * @param data
   * @return {void}
   */
  loadFromData(data) {
    this.clear()
    if (data.violations) {
      this.loadFromSymfony(data.violations)
    } else {
      this.loadFromRespect(data.errors)
    }
  }

  /**
   * @param {SymfonyViolation[]} data
   */
  loadFromSymfony(data) {
    data.forEach(i => this.errors.push(new ValidationError(i.propertyPath, i.type, i.title)))
  }

  /**
   * @param {Object} data
   */
  loadFromRespect(data) {
    for (const [field, errors] of Object.entries(data)) {
      for (const [validator, message] of Object.entries(errors)) {
        this.errors.push(new ValidationError(field, validator, message))
      }
    }
  }

  /**
   * @return {boolean}
   */
  get isHaveError() {
    return !!this.errors.length
  }

  /**
   * @param {string} name
   * @return {ValidationError[]}
   */
  getFieldErrors(name) {
    return this.errors.filter(i => i.name === name)
  }

  /**
   * @param {string} name
   * @return {ValidationError|null}
   */
  getFieldFirstError(name) {
    return this.isFieldError(name) ? this.errors.find(e => e.name === name) : null
  }

  /**
   * @param {string} name
   * @return {boolean}
   */
  isFieldError(name) {
    return this.errors.filter(i => i.name === name).length > 0
  }

  /**
   * @return {ValidationErrorStack}
   */
  clear() {
    this.errors = []
    return this
  }

  /**
   * Converts error stack to formik
   * @returns {{}}
   */
  toFormik(map = null) {
    return FormikErrorConverter.convert(this, map)
  }
}

export class ValidationError {
  name
  validatorName
  message

  /**
   *
   * @param {string} name
   * @param {string} validatorName
   * @param {string} message
   */
  constructor(name, validatorName, message) {
    this.name = name
    this.validatorName = validatorName
    this.message = message
  }
}
