import {makeStyles} from '@material-ui/core/styles'

export const useMonitoredDataStyles = makeStyles(theme => ({
  modalComponent: {
    paddingTop: '30px',
    '& .wrapper': {
      margin: '0 20px',
      '& h3': {
        lineHeight: '2.4rem',
      }
    },
  },
  modalContent:{
    maxWidth: '600px',
  },
  closeBtn: {
    position: 'absolute',
    top: '20px',
    right: theme.variables.$isRTLDirection ? "auto" : "20px",
    left: theme.variables.$isRTLDirection ? "20px": "auto",
  }
}))