import { makeStyles } from "@material-ui/core/styles";

export const useAcrionRiskIndicatorStyles = makeStyles((theme) => ({
    icon: {
      display: "inline-block",
      padding: "8px",
      boxShadow: "0 0 1px 0 white inset, 0 0 1px 0 white",
      borderRadius: "50%",
      cursor: "pointer",
      position: "relative",
  
      "&.iconInsideDialog": {
        display: "inline-block",
      },
    },
    quarter: {
      position: "absolute",
      width: "50%",
      height: "50%",
      border: "2px dotted #d9d9d9",
      "&.high": {
        border: "2px solid red",
      },
      "&.medium": {
        border: "2px solid rgb(241, 239, 111)",
      },
      "&.low": {
        border: "2px solid #75B78B",
      },
      "&.quarter1": {
        bottom: 0,
        left: 0,
        borderRadius: "0 0 0 100%",
        borderTop: "0px",
        borderRight: "0px",
      },
  
      "&.quarter2": {
        top: 0,
        left: 0,
        borderRadius: "100% 0 0 0",
        borderBottom: "0px",
        borderRight: "0px",
      },
  
      "&.quarter3": {
        top: 0,
        right: 0,
        borderRadius: "0 100% 0 0",
        borderBottom: "0px",
        borderLeft: "0px",
      },
  
      "&.quarter4": {
        bottom: 0,
        right: 0,
        borderRadius: "0 0 100% 0",
        borderTop: "0px",
        borderLeft: "0px",
      },
    },
    riskIndicatorIcon: {
      position: "relative",
      width: "0.8rem",
      height: "0.8rem",
      "& svg": {
        position: "absolute",
        width: "18px",
        height: "18px",
        left: "-3px",
        top: "-3px",
      },
    },
  }));