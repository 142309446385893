import { makeStyles } from "@material-ui/core/styles";

export const useFilterStyles = makeStyles((theme) => ({
  systemIcon: {
    display: "flex",
    justifyContent: "space-between",
    width: "160px",
    "& svg": {
      position: "absolute",
      right: "10px",
    },
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "16px",
    flexWrap: "wrap",
  },
  filters: {
    display: "flex",
    width: "75%",
    fontSize: "0.85rem",
    margin: "0 -10px",
    "&>div": {
      width: "100%",
      margin: "0 10px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "16px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      flexBasis: "100%",
    },
    // device filter option with icon
    "& .deviceSelectOption": {
      display: "flex",
      justifyContent: "space-between",
      width: "160px",
      "& svg": {
        position: "absolute",
        right: theme.variables.$isRTLDirection ? "unset" : "10px",
        left: theme.variables.$isRTLDirection ? "10px" : "unset",
      },
    },
  },
}));
