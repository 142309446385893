import React, { useEffect, useState } from 'react'
// import Form from './Form'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { CardTitle, BodyText, RoundButton } from '../../../../components/UI'
import { ReactComponent as EnvelopeIcon } from '../../../../assets/icons/envelope_3.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg'

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    position: 'absolute',
    top: '10px',
    right: '8px',
  }
}))

const CountDownAndLogout = ({ t, deleteProfile }) => {
  const [count, countdown] = useState(10)

  useEffect(() => {
    if (count <= 0) { deleteProfile() }
    const interval = setInterval(() => countdown(`${count - 1}`), 1000)
    return (() => clearInterval(interval))
  }, [count])
  
  return (
    <BodyText textAlign='center' variant='body2'>
      <strong>{t("logout_x_sec", { count })}</strong>
    </BodyText>
  )
}


const DeleteAccountInfo = ({ t, deleteProfile, reasons }) => {
  const classes = useStyles()

  const deleteProfileWithReason = () => {
    deleteProfile(reasons)
  }

  return (
    <Box maxWidth='480px'>
      <RoundButton
        color='primary'
        className={classes.closeBtn}
        onClick={() => { deleteProfile(reasons) }}
      >
        <CloseIcon />
      </RoundButton>
      <Box textAlign='center' mb={2}>
        <EnvelopeIcon />
      </Box>
      <Box my={2}>
        <CardTitle textAlign='center' variant='h2' mb={0}>
          {t('confirm_delition_email')}
        </CardTitle>
      </Box>
      <CountDownAndLogout t={t} deleteProfile={deleteProfileWithReason} />
    </Box >
  )
}

export default DeleteAccountInfo
