import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setPartnerSettings } from "../store/actions/partnerSettings";
import API from "api/crossservice";
import { setItem, getItem } from "helpers/localStorage";
import { getLanguageFiles } from "i18n/languageProvider";

export const usePartnerSettings = () => {
  const dispatch = useDispatch();
  const [warning, setCNameWarning] = useState("");
  const [domain] = window.location.host.split(":");
  const selectedLang = getItem("selectedLang");
  const lang =
    selectedLang ||
    (window.location.pathname.split("/").length > 1
      ? window.location.pathname.split("/")[1]
      : null);

  const getPartnerSettings = () => {
    //hax for mweb recognition...
    const contentLanguage =
      getItem("locale") || window.location.pathname.split("/")[1];
    let partner = domain.toLowerCase();

    if (window.location.href.indexOf("partner=mweb") > -1) {
      partner = "mweb";
    }
    if (partner === "localhost") {
      partner =
        process.env.REACT_APP_LOCALHOST_PARTNER || "staging.dyna-sim.org";
    }

    API.getPartnerSettingsByDomain(
      {
        domain: partner,
      },
      {
        headers: {
          "Content-Language": contentLanguage,
        },
      }
    )
      .then(async (ps) => {
        // if there coupon in partner settings we need apply it on coupon page
        // but not for mweb
        if (ps.plan_coupon_code && ps.plan_coupon_code !== "mweb") {
          setItem("coupon", ps.plan_coupon_code);
        }
        // temporary hack for cyhunt partner
        if (ps.slug === "cyhunt") {
          ps.default_language = "es_AR";
          if (process.env.REACT_APP_LANGS_SOURCE === "staging") {
            ps.redirect_back_url = "http://app.cyhunt.net";
          }
        }
        // hack for enelx
        if (ps.name === "Cyber Xpert") {
          ps.name = "Breach Defence X";
        }
        const locale = lang || ps.default_language || "en_GB";
        localStorage.setItem("locale", locale);

        let dir = locale.slice(0,2) === "ar" ? "rtl" : "ltr"

        document.dir = dir;
        
        // wait for language


        await getLanguageFiles(locale, ps);
        const formattedSettings = {
          // ...ps,
          partnerName: ps.name,
          partnerCopyrightName: ps.settings["partner.copyright"],
          partnerSlug: ps.slug,
          redirectBackUrl: ps.redirect_back_url,
          defaultLanguage: ps.default_language,
          autoLogout: Boolean(ps.auto_logout),
          autoLogoutInterval: ps.auto_logout_interval,
          faviconUrl: ps.settings["partner.favicon"],
          privacyPolicyExternalUrl:
            ps.settings["partner.privacy_policy.external_url"],
          isCrossServicePartner: Boolean(
            ps.settings["partner.isCrossServicePartner"]
          ),
          logoUri: ps.settings["partner.logo_uri"],
          logoWidthSize: ps.settings["partner.logo_size"],
          logoWidthMobile: ps.settings["partner.mobile_logo_size"],
          shortLogoUrl: ps.settings["partner.shortLogoUrl"],
          isHaveBeenHackedSection: Boolean(
            ps.settings["partner.iHaveBeenHacked.section"]
          ),
          iHaveBeenHackedRedirectUrl:
            ps.settings["partner.iHaveBeenHacked.url"],
          isSelectedPlanNameInHeader:
            ps.settings["partner.header.plan.section"],
          cookiePolicyContent: ps.settings["partner.cp.content"],
          privacyPolicyContent: ps.settings["partner.pp.content"],
          termsOfServiceContent: ps.settings["partner.tos.content"],
          hideAccountDeleteOption: Boolean(
            ps.settings["partner.hide_account_delete_option"]
          ),
          skipConfirmation: Boolean(
            ps.settings["partner.skip_registration_confirmation"]
          ),
          partnerSchedule: ps.settings["partner.schedule"],
          isTourButtonDisplayed: Boolean(ps.settings["partner.isTourButtonDisplayed"]),
          themeSettings: ps.settings["partner.theme"],
          // continental assist partner
          partnerBtnTransform: ps.settings["partner.button.transform"],
          emailSupport: ps.settings["partner.email_support_contact_form"],
          termsAndConditionVisibility: Boolean(
            ps.settings["partner.terms.and.condition.visibility"]
          ),
          partnerCustomHeader: ps.settings["partner.custom.header"],
          partnerCustomFooter: ps.settings["partner.custom.footer"],
          partnerCustomDashboardFooter:
            ps.settings["partner.custom.dashboard.footer"],
          isTocCustomHeader: Boolean(
            ps.settings["partner.toc.display.custom.header"]
          ),
          isAssessmentCustomHeader:
            ps.settings["partner.assessments.display.custom.header"],
          isAssessmentCustomFooter:
            ps.settings["partner.assessments.display.custom.footer"],
          isScansCustomHeader:
            ps.settings["partner.scans.display.custom.header"],
          isScansCustomFooter:
            ps.settings["partner.scans.display.custom.footer"],
          monitoredDataTabs: ps.settings["partner.monitored.data"],
          isTocForCrossService: Boolean(ps.settings["partner.is_show_toc"]),
          hideProfileSettings: Boolean(
            ps.settings["partner.hide_profile_settings"]
          ),
          logoPoweredByPosition:
            ps.settings["partner.logo_powered_by_position"],
          sidebarFooterCustom: ps.settings["partner.sidebar.footer.md"],
          logoPoweredByUrl: ps.settings["partner.logo_powered_by_url"],
          isShowPoweredBy: Boolean(
            ps.settings["partner.is_show_powered_by_logo"]
          ),
          actionsButtonsStyle: ps.settings["partner.actions_buttons_style"],
          // `<div><img src='https://dyfcdn01.s3.eu-west-1.amazonaws.com/logos/partners/safetnet/STN_logo.png' width=130px/></div>`,
          // remove?
          hue: ps.hue,
          color: ps.color,
          context: ps.context, //?
          cyberShieldUrl: ps.settings["partner.cyber_shield_url"],
          scanSection: ps.settings["partner.scans_section"],
          languagesAvailable: ps.settings["partner.languages_available"],
          hidePersonalInfoSettings: Boolean(ps.settings["partner.cx.hide_personal_info_settings"]),
          hideUserName: Boolean(ps.settings["partner.cx.hide_user_name"]),
          hideSupportFormUserName: Boolean(ps.settings["partner.cx.hide_support_form_user_name"]),
          customNewDeviceIdenifiedModal: ps.settings["partner.customDeviceModal"],
          manageDataFamilyEmail: ps.settings["manage_data_family_email"],
          showFeaturesDescription: Boolean(ps.settings["show_features_description"]),
          hideDontHaveCodeButton: Boolean(ps.settings["partner.hide_coupon_code_button"]),
          noRegistrationFlow: Boolean(ps.settings["partner.no_registration_flow"]),
          direction: dir,
          // tour is disabled for rtl languages
          disableUserTour: dir === "rtl",
          diallingCode: ps.settings["partner.dialling_code"]
        };
        // logoPoweredByUrl: "<img src=\"https://dyfcdn01.s3.eu-west-1.amazonaws.com/logos/powered_by_white_final.png\" alt=\"partner.logo\">"
        // formattedSettings["isTocCustomHeader"] = true;
        // formattedSettings['partnerCustomHeader'] = `'<div class="customPartnerHeader">![](https://dyfcdn01.s3.eu-west-1.amazonaws.com/logos/partners/safetnet/STN_logo.png)</div>'`
        // formattedSettings[
        //   "partnerCustomHeader"
        // ] = `'<div class="customPartnerHeader w200">![](https://dyfcdn01.s3.eu-west-1.amazonaws.com/logos/partners/safetnet/STN_logo.png)</div>'`;
        // formattedSettings[
        //   "partnerCustomFooter"
        // ] = `'<div class="customPartnerFooter w200">![](https://dyfcdn01.s3.eu-west-1.amazonaws.com/logos/partners/safetnet/safetnet_powered_by_logo.png)</div>'`;
        // formattedSettings["isAssessmentCustomHeader"] = true;
        // formattedSettings["isAssessmentCustomFooter"] = true;
        // formattedSettings["isScansCustomHeader"] = true;
        // formattedSettings["isScansCustomFooter"] = true;
        // formattedSettings["monitoredDataTabs"] = ["emails", "phone_numbers"];
        dispatch(setPartnerSettings(formattedSettings));
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (domain.indexOf("albac.dynarisk.com") !== -1) {
      setCNameWarning(`Please, add CNAME record "albac.dynarisk.com"`);
    }
    getPartnerSettings();
  }, [domain]);

  return { warning };
};
