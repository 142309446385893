import React, { useState } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

// extend theme
// https://medium.com/javascript-in-plain-english/extend-material-ui-theme-in-typescript-a462e207131f

// import { TypographyVariant } from '@material-ui/core/styles';

// declare module '@material-ui/core/styles/createTypography' {
//   type Variant = TypographyVariant | 'wizzard'
// }

// declare module '@material-ui/core/styles/createPalette' {
//   interface PaletteOptions {
//       success?: PaletteColorOptions;
//       warning?: PaletteColorOptions;
//       sme?: PaletteColorOptions;
//   }
// }

const Theme = ({ children, themeSettings }) => {
  const {
    primaryColor,
    secondaryColor,
    primaryTextColor,
    secondaryTextColor,
    sidebar,
    inputTextColor,
    variables,
    partnerBtnTransform,
  } = themeSettings;

  const [theme, setTheme] = useState(
    createTheme({
      props: {
        MuiButton: {
          disableRipple: true,
        },
      },
      typography: {
        h1: {
          fontSize: "34px",
          lineHeight: "40px",
          color: variables.$headerText,
        },
        h2: {
          fontWeight: 600,
          fontSize: "20px",
          lineHeight: "32px",
          color: variables.$headerText,
        },
        h3: {
          fontWeight: 800,
          fontSize: "14px",
          lineHeight: "24px",
          color: variables.$headerText,
        },
        body1: {
          fontSize: "18px",
          lineHeight: "24px",
          color: variables.$black,
        },
        body2: {
          fontSize: "14px",
          lineHeight: "24px",
          color: variables.$black,
        },
        fontFamily: ["Montserrat", "Geometria", "sans-serif"].join(","),
        button: {
          fontFamily: "Montserrat,Geometria,sans-serif",
          fontWeight: 600,
          textTransform: "uppercase",
          color: variables.$black,
        },
      },
      palette: {
        // type: 'dark',
        primary: {
          main: `${primaryColor}`,
        },
        secondary: {
          main: `${secondaryColor}`,
        },
        error: {
          main: variables.$red100,
        },
      },
      overrides: {
        MuiButton: {
          root: {
            padding: "12px 20px",
            borderRadius: variables.$btnBorderRadius,
            fontWeight: 600,
            // continentalassist partner
            textTransform: partnerBtnTransform
              ? partnerBtnTransform
              : "uppercase",
          },
          outlined: {
            padding: "12px 20px",
          },
          text: {
            padding: "12px 20px",
          },
          contained: {
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#e0e0e0",
              boxShadow: "none",
            },
          },
          containedPrimary: {
            boxShadow: "none",
            color: `${primaryTextColor}`,
            "&:hover": {
              boxShadow: "none",
            },
            "&$disabled": {
              backgroundColor: `${primaryColor}`,
              opacity: 0.5,
              color: `${primaryTextColor}`,
            },
          },
          textSecondary: {
            fontWeight: "normal",
            textTransform: "none",
            color: `${variables.$black}`,
            "&:hover": {
              backgroundColor: `${secondaryColor}`,
              boxShadow: "none",
            },
          },
          textPrimary: {
            color: `${primaryTextColor}`,
          },
          containedSecondary: {
            backgroundColor: `${secondaryColor}`,
            boxShadow: "none",
            color: `${secondaryTextColor}`,
            "&:hover": {
              backgroundColor: `${secondaryColor}`,
              boxShadow: "none",
            },
            "&$disabled": {
              color: "rgba(26, 71, 185, 0.4)",
              backgroundColor: `${secondaryColor}`,
            },
          },
          outlinedSecondary: {
            color: `${primaryColor}`,
            borderWidth: "2px",
            borderColor: `${primaryColor}`,
            padding: "11px 18px",
            "&:hover": {
              borderWidth: "2px",
            },
          },
        },
        MuiRadio: {
          root: {
            "&.MuiIconButton-root:hover": {
              backgroundColor: "unset",
            },
          },
        },
        MuiFab: {
          secondary: {
            backgroundColor: "rgba(26, 71, 185, 0.1)",
            boxShadow: "none",
            color: `${primaryColor}`,
            "&:hover": {
              backgroundColor: `${secondaryColor}`,
              boxShadow: "none",
            },
          },
        },
        MuiChip: {
          colorSecondary: {
            color: "#fff",
          },
        },
        MuiSelect: {
          select: {
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
        },
        MuiOutlinedInput: {
          root: {
            borderRadius: 0,
            "&$notchedOutline": {
              borderWidth: "1px",
              borderColor: `${primaryColor}`,
            },
            "&:hover $notchedOutline": {
              borderColor: `${primaryColor}`,
            },
          },
          notchedOutline: {
            border: "1px solid #e8e9eb",
            borderColor: "#e8e9eb",
          },
          input: {
            padding: "10px 25px 10px 10px",
          },
        },
        MuiFilledInput: {
          root: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
          input: {
            padding: "16px 32px",
          },
          underline: {
            "&:before": {
              content: "",
            },
            "&:after": {
              transition: "none",
              borderBottom: "none",
            },
          },
        },
        MuiIconButton: {
          edgeEnd: {
            marginRight: 0,
          },
        },
        MuiCheckbox: {
          colorPrimary: {
            color: `${primaryColor}`,
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&$checked": {
              color: `${primaryColor}`,
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
          colorSecondary: {
            color: `${secondaryColor}`,
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&$checked": {
              color: `${secondaryColor}`,
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
        },
        MuiInputLabel: {
          root: {
            fontSize: "14px",
            lineHeight: "20px",
            "&$focused": {
              color: "initial",
            },
          },
          outlined: {
            transform: "translate(14px, 24px) scale(1)",
          },
          formControl: {
            top: "-15px",
            position: "absolute",
            transform: "translate(10px, 30px)",
          },
          shrink: {
            transform: "none",
          },
        },
        MuiTab: {
          root: {
            textTransform: partnerBtnTransform
              ? partnerBtnTransform
              : "uppercase",
          },
        },
        MuiTableCell: {
          root: {
            borderBottom: 0,
          },
          head: {
            fontSize: "12px",
            color: "#828282",
          },
        },
        MuiDialog: {
          paper: {
            borderRadius: "4px",
            padding: "24px",
          },
        },
        MuiDialogActions: {
          root: {
            padding: 0,
          },
        },
        MuiDialogTitle: {
          root: {
            padding: 0,
          },
        },
        MuiMenu: {
          paper: {
            borderRadius: 0,
          },
        },
        MuiDialogContent: {
          root: {
            padding: 0,
          },
        },
        MuiInputBase: {
          root: {
            fontSize: "14px",
          },
          input: {
            "&:-webkit-autofill": {
              transitionDelay: "9999s",
              transitionProperty: "background-color, color",
            },
          },
        },
        MuiInput: {
          formControl: {
            "label + &": {
              marginTop: "4px",
            },
          },
          root: {
            border: `1px solid ${variables.$lightGray}`,
            borderRadius: variables.$inputBorderRadius,
            "&:hover": {
              border: `1px solid ${primaryColor}`,
            },
            "&:active": {
              border: `1px solid ${primaryColor}`,
            },
            "&:focused": {
              border: `1px solid ${primaryColor}`,
            },
            "&$error": {
              border: `1px solid ${variables.$black}`,
            },
          },
          input: {
            padding: "11px 10px",
            fontSize: "14px",
            color: `${inputTextColor}`,
            "&::placeholder": {
              color: "black",
              opacity: 0.9,
            },
          },
          underline: {
            "&:before": {
              content: "",
            },
            "&:after": {
              transition: "none",
              borderBottom: "none",
              "&:hover": {
                borderBottom: "none",
              },
            },
          },
        },
        MuiFormHelperText:{
          root: {
            textAlign: "auto"
          }
        },
        MuiDrawer: {
          paper: {
            // background: sidebar.backgroundColor,
          },
        },
        MuiPaper: {
          rounded: {
            borderRadius: variables.$cardBorderRadius,
          },
        },
      },
    })
  );
  return (
    <MuiThemeProvider theme={{ ...theme, variables }}>
      {children}
    </MuiThemeProvider>
  );
};

export default Theme;
