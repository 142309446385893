import SRS from 'helpers/systemResourcesScanner';

export const validateDevice = (device) => {
  const { meta } = SRS.run({});
  const deviceOS = meta.os.product.replace(' ', '').toLowerCase()
  let oss = ['windows', 'macos', 'ios', 'android']
  for (let os of oss) {
    if (deviceOS.indexOf(os) !== -1){
      return deviceOS.indexOf(device.os) > -1;
    }
  }
  return false
}