import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Select, { components } from "react-select";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow_down.svg";

const useSelectFieldStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    "& .rs-icon": {
      "&-down svg": {
        transform: "rotate(0deg)",
      },
      "&-up svg": {
        transform: "rotate(180deg)",
      },
    },
  },
}));

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <i className="rs-icon rs-icon-up">
            <ArrowIcon />
          </i>
        ) : (
          <i className="rs-icon rs-icon-down">
            <ArrowIcon />
          </i>
        )}
      </components.DropdownIndicator>
    )
  );
};

const defaultCountryCodeOptionFormatter = ({ value, label }) => {
  const textLabel = `+${value}&nbsp;${label}`;
  return (
    <div style={{ alignItems: "center", display: "flex" }}>
      <span
        className="forceLTRDirection"
        dangerouslySetInnerHTML={{
          __html: `${textLabel}`,
        }}
      />
    </div>
  );
};

const defaultFormatterMap = {
  'country_code': defaultCountryCodeOptionFormatter,
}

export default function SelectField({
  isMenuOpen,
  options,
  name,
  formatOptionLabel,
  value,
  onChange,
  placeholder = "",
  variant = "default",
  isArrowVisible = true,
  optionValue = "value",
}) {
  const classes = useSelectFieldStyles();
  const theme = useTheme();
  const [inputvalue, setValue] = useState(null);

  useEffect(() => {
    if (value) {
      setValue(value);
    }
  }, [value]);

  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      width: 0,
      backgroundColor: theme.variables.$lightGray,
      marginTop: 0,
      marginBottom: 0,
    }),
    dropdownIndicator: () => ({
      display: isArrowVisible ? "block" : "none",
      padding: variant === "transparent" ? "0" : "0 8px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: "0 8px",
    }),
    control: (provided) => {
      return {
        ...provided,
        width: "100%",
        padding: "3.25px",
        backgroundColor: variant === "transparent" ? "transparent" : "#fff",
        border:
          variant === "transparent"
            ? "none"
            : `1px solid ${theme.variables.$lightGray}`,
        borderRadius: theme.variables.$inputBorderRadius,
        outline: 0,
        boxShadow: "none",
        "&:hover": {
          borderColor: theme.palette.primary.main,
        },
      };
    },
    // selected value styles
    singleValue: (provided) => ({
      ...provided,
      top: "calc(50% + 2px)",
      fontWeight: 600,
      fontFamily: "Montserrat, sans-serif",
    }),
    option: (provided, {isSelected}) => ({
      ...provided,
      backgroundColor: isSelected ?  theme.variables.$lightGray : "transparent",
      color: theme.variables.$black,
      ':active': {
        backgroundColor: "#F3F4F5",
      },
      ':hover': {
        backgroundColor: "#F3F4F5",
      }
    }),
  };

  const formatOptions = (option) => {
    if (typeof formatOptionLabel === "function") {
      return formatOptionLabel(option)
    }
    if (name in defaultFormatterMap) {
      return defaultFormatterMap[name](option)
    }
  }

  return (
    <Select
      menuIsOpen={isMenuOpen}
      components={{ DropdownIndicator }}
      name={name}
      formatOptionLabel={formatOptions}
      styles={customStyles}
      options={options}
      value={inputvalue}
      className={`${classes.select}`}
      getOptionValue={(option) => `${option[optionValue]}`}
      onChange={(selected) => onChange({ target: { name, value: selected } })}
      placeholder={placeholder}
    />
  );
}
