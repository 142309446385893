/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CxHttpException from '../model/CxHttpException';
import CxHttpValidationError from '../model/CxHttpValidationError';
import UserCourseCourseStartResponse from '../model/UserCourseCourseStartResponse';

/**
* StartUserCourse service.
* @module api/StartUserCourseApi
* @version 1.0.0
*/
export default class StartUserCourseApi {

    /**
    * Constructs a new StartUserCourseApi. 
    * @alias module:api/StartUserCourseApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Start user
     * Start user course by uuid
     * @param {String} uuid User course UUID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserCourseCourseStartResponse} and HTTP response
     */
    startUserCourseWithHttpInfo(uuid) {
      let postBody = null;
      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling startUserCourse");
      }

      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = UserCourseCourseStartResponse;
      return this.apiClient.callApi(
        '/api/v1/user_courses/{uuid}/start', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Start user
     * Start user course by uuid
     * @param {String} uuid User course UUID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserCourseCourseStartResponse}
     */
    startUserCourse(uuid) {
      return this.startUserCourseWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
