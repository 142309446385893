import React from 'react'
import { useTranslation } from 'react-i18next'
import ReadMore from 'components/ReadMore'
import MDRender from 'components/MDRender'
import { makeStyles } from '@material-ui/core/styles'
import { CardTitle } from '../../../../components/UI'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  cardSecondary: {
    background: theme.palette.secondary.main,
    '& .cardTitle': {
      color: theme.variables.$secondaryTextColor
    },
  },
  readMoreWrapper: {
    '& .readmore-component': {
      position: 'relative',
      bottom: '0px',
      height: '10px',
      '& .readmore-fideout': {
        position: 'absolute',
        left: 0,
        right: 0,
        height: '50px',
        top: '-35px',
        background: 'linear - gradient(transparent, var(--secondary), var(--secondary))',
      },
      '& .btn': {
        marginTop: '5px',
        outline: 'none'
      }
    },
    '& .readmore-main': {
        height: '66px',
      overflow: 'hidden',
    }
    // '& .short': {
    //   height: '66px',
    //   overflow: 'hidden',
    // },
    // '& .hide': {
    //   display: 'none',
    // }
  }
}))

const WhyItIsNecessary = ({ data = "" }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Paper className={classes.cardSecondary} elevation={0}>
      <Box p={3} pr={0.25}>
        <CardTitle variant='h2'>
          {t("why_ti_is_necessary")}
        </CardTitle>
        <div className={classes.readMoreWrapper}>
          <ReadMore t={t} textLength={data.length}>
            <MDRender escapeHtml={false} t={t} input={data} textColor='secondary'/>
          </ReadMore>
        </div>
      </Box>
    </Paper>
  )
}

export default WhyItIsNecessary
