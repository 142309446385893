import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import MDRender from "../MDRender";

const useStyles = makeStyles((theme) => ({
  footerComponent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "20px 0",
    lineHeight: "20px",
  },
  copyright: {
    color: theme.variables.$textDarkGray,
    fontSize: "12px",
  },
  termsOfPrivacy: {
    color: theme.variables.$textDarkGray,
    fontSize: "12px",
  },
  a: {
    color: theme.variables.$textDarkGray,
    fontSize: "12px",
  },
}));
export const Footer = ({ url, t, partnerSettings }) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    partnerCopyrightName,
    partnerName,
    partnerCustomDashboardFooter,
    privacyPolicyExternalUrl,
    isCrossServicePartner,
    termsAndConditionVisibility,
  } = partnerSettings;
  const displayedName = partnerCopyrightName || partnerName;

  const redirectToPrivacyPolicyPage = () => {
    if (privacyPolicyExternalUrl) {
      return window.open(
        privacyPolicyExternalUrl,
        "_blank",
        "noopener,noreferrer"
      );
    }
    history.push(`${url}/privacy-policy`);
  };

  const redirectToTermsAndConditionPage = () => {
    history.push(`${url}/terms-and-conditions`);
  };

  return (
    <>
      {partnerCustomDashboardFooter ? (
        <MDRender
          t={t}
          input={partnerCustomDashboardFooter}
          escapeHtml={false}
        />
      ) : (
        <div className={classes.footerComponent}>
          <span className={classes.copyright}>
            {displayedName} © {new Date().getFullYear()}{" "}
          </span>
          {termsAndConditionVisibility && (
            <div>
              {!isCrossServicePartner && (
                <button
                  onClick={redirectToTermsAndConditionPage}
                  style={{ background: "none", border: 0, cursor: "pointer" }}
                  className={classes.termsOfPrivacy}
                >
                  {t("terms_and_conditions")}
                </button>
              )}
              <button
                onClick={redirectToPrivacyPolicyPage}
                style={{ background: "none", border: 0, cursor: "pointer" }}
                className={classes.termsOfPrivacy}
              >
                {isCrossServicePartner
                  ? t("privacy_and_cookie_policy")
                  : t("privacy_policy")}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
