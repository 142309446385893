import React from 'react'

import { ReactComponent as IconDashboard } from '../../assets/icons/dasboard.svg'
import { ReactComponent as IconTodo } from '../../assets/icons/todo.svg'
import { ReactComponent as IconManageDevices } from '../../assets/icons/manage-devices.svg'
import { ReactComponent as IconSupport } from '../../assets/icons/support.svg'
import { ReactComponent as IconProtectedData } from '../../assets/icons/protected-data.svg'
import { ReactComponent as IconEye } from '../../assets/icons/eye.svg'
import { ReactComponent as IconLogout } from '../../assets/icons/logout.svg'
import { ReactComponent as IconPolicies } from "../../assets/icons/policies.svg";
import { ReactComponent as IconTraining } from "../../assets/icons/training.svg";

export const menuIconsMap = {
  dashboard: <IconDashboard />,
  todo: <IconTodo />,
  manageDevices: <IconManageDevices />,
  protectedData: <IconProtectedData />,
  support: <IconSupport />,
  userTour: <IconEye />,
  logout: <IconLogout />,
  policies: <IconPolicies />,
  training: <IconTraining />
};