import React from "react";
import Plans from "components/Plans";
import Box from "@material-ui/core/Box";
import { CardTitle } from "../../../components/UI";

const ProfilePlans = ({ t }) => {
  return (
    <>
      <Box my={4}>
        <CardTitle mb={0} variant="h2">
          {t("change_plan")}
        </CardTitle>
      </Box>
      <Plans t={t} />
    </>
  );
};

export default ProfilePlans;
