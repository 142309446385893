import React, { useState } from "react";
import { getItem } from "../../../helpers/localStorage";
import isEmail from "validator/lib/isEmail";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import useForm from "../../../hooks/useForm";

import { InputField, SquareButton } from "../../../components/UI";
import { ReactComponent as BackArrowIcon } from "../../../assets/icons/arrow-back.svg";

const stateSchema = {
  email: { value: "", error: "" },
};

const SignUpEditEmailForm = ({ resetEmail, loading }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { locale } = useParams();
  const email = sessionStorage.getItem("userEmail") || "";
  const [initialState, setInitialState] = useState({
    data: { email },
    errors: null,
  });

  const validationStateSchema = {
    email: {
      required: true,
      validator: {
        func: isEmail,
        error: t("enter_valid_email"),
      },
    },
  };

  const handleSubmit = () => {
    resetEmail({
      email: state.email.value,
      registration_hash: getItem("registration_hash"),
    });
    setInitialState({
      data: null,
      errors: null,
    });
  };

  const { state, handleOnChange, handleOnBlur, handleOnSubmit, isDirty } =
    useForm(
      initialState,
      stateSchema,
      null,
      validationStateSchema,
      handleSubmit
    );
  return (
    <form className="-sizeWidthFull" id="form-login" onSubmit={handleOnSubmit}>
      <Box mb={4}>
        <InputField
          name="email"
          placeholder={t("email_address")}
          value={state.email.value}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          error={isDirty && isDirty.email && state.email.error}
        />
      </Box>
      <Box mx={-0.5} display="flex">
        <Box mx={0.5}>
          <SquareButton
            variant="contained"
            color="secondary"
            onClick={() => {
              history.push({
                pathname: `/${locale}/signIn`,
                state: { skipAnimation: true },
              });
            }}
          >
            <BackArrowIcon />
          </SquareButton>
        </Box>
        <Box mx={0.5} width="100%">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size="14px" color="inherit" />
            ) : (
              t("change_email")
            )}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default SignUpEditEmailForm;
