import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import MDRender from "components/MDRender";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  quizQuestion: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "920px",
    textAlign: "left",
    justifyContent: "left",
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const QuizQuestion = ({
  currentQuestion,
  value,
  handleChange,
  currentQuestionIndex,
  questions,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.quizQuestion}>
      <Box>
        <Box>
          <Typography variant="caption">
            {currentQuestionIndex + 1} of {questions.length}
          </Typography>
          <Typography variant="h1">{currentQuestion.value}</Typography>
          <Box mt={1} mb={1}>
            {currentQuestion.subtitle && (
              <MDRender input={currentQuestion.subtitle} />
            )}
          </Box>
        </Box>
        <Box>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="answer"
              name="answer"
              value={value}
              onChange={handleChange}
            >
              {currentQuestion.answers.map((answer) => (
                <FormControlLabel
                  key={answer.uuid}
                  value={answer.uuid}
                  control={<Radio color="primary" />}
                  label={answer.value}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default QuizQuestion