import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography'

export default function CardTitle({
  children,
  textAlign = "initial",
  mb = 3,
  mt = 0,
  fontWeight = 500,
  variant = "h1",
  color = "initial",
  noWrap = false,
  className = "",
  onMouseOver = () => {}
}) {
  return (
    <Box textAlign={textAlign} mb={mb} mt={mt} className={className} onMouseOver={onMouseOver}>
      <Typography
        className="cardTitle"
        variant={variant}
        style={{ fontWeight }}
        color={color}
        noWrap={noWrap}
      >
        {children}
      </Typography>
    </Box>
  );
}