import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import Page from "components/Page";
import ScanAccountVulnerability from "./ScanAccountVulnerability";
import ScanSecurityBreaches from "./ScanSecurityBreaches";
import { RefreshTokenService } from "services/authentication/RefreshTokenService";
import DeviceAPI from "api/device";
import SRS from "helpers/systemResourcesScanner";
import { PROFILE_TYPE_STAFF } from "constants/common";
import { toast } from "react-toastify";
import useFlagSettings from "../../hooks/useFlagSettings";
import { CustomersErrorsCodes } from "../../constants/common";
import MDRender from "../../components/MDRender";

import Box from "@material-ui/core/Box";

const scanningStepsMap = {
  SCAN_ACCOUNT_VULNERABILITY: "scan_account_vulnerability",
  SCAN_SECURITY_BREACHES: "scan_security_breaches",
};

const Scanning = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const { flagsMap, updateUserSetting, userSettings } = useFlagSettings();
  const identity = useSelector((state) => state.identity);
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const animationStepLength = 5000;
  const [step, setStep] = useState(scanningStepsMap.SCAN_ACCOUNT_VULNERABILITY);
  const isCrossServicePartner = partnerSettings.isCrossServicePartner;
  const partnerCustomHeader = partnerSettings.partnerCustomHeader;
  const isScansCustomHeader = partnerSettings.isScansCustomHeader;

  const [browser, setBrowser] = useState("scanning");
  const [os, setOS] = useState(null);

  useEffect(() => {
    RefreshTokenService.refresh();
  }, []);

  const completeStartScreen = async () => {
    if (
      userSettings[flagsMap.WELCOME_SCREEN_SHOWN] &&
      userSettings[flagsMap.ASSESSMENT_SHOWN]
    ) {
      return history.push(`/${match.params.locale}/dashboard`);
    }
    await updateUserSetting(flagsMap.WELCOME_SCREEN_SHOWN, true);
    history.push(`/${match.params.locale}/assessment`);
  };

  const simplifyOSName = (osName) => {
    const os = osName.toLowerCase().replace(" ", "");
    if (os.indexOf("windows") > -1) {
      return "windows";
    } else if (os.indexOf("android") > -1) {
      return "android";
    } else if (os.indexOf("ios") > -1) {
      return "ios";
    } else if (os.indexOf("macos") > -1) {
      return "macosx";
    }
    return "unsuported";
  };

  const addAndChooseDevice = (callback) => {
    if (
      identity &&
      identity.profile &&
      identity.profile.profile_type === PROFILE_TYPE_STAFF
    ) {
      return completeStartScreen();
    }
    const srsData = SRS.run({});
    const device = {
      device_type: srsData.device_type,
      os: simplifyOSName(srsData.meta.os.product),
      scan_by: "browser",
      name: `${simplifyOSName(srsData.meta.os.product)}_${srsData.device_type}`,
    };
    DeviceAPI.getDevices().then((devices) => {
      const dev = devices.items.find((item) => {
        if (
          item.fingerprint_browser === srsData.fingerprint_v2 ||
          item.fingerprint_browser === srsData.fingerprint
        ) {
          return item;
        }
        return null;
      });
      if (dev) {
        device.meta = srsData.meta;
        device.fingerprint = srsData.fingerprint_v2;
        DeviceAPI.updateDevice(device, dev.uuid)
          .then((response) => {
            RefreshTokenService.refresh().then(() => {
              callback(response);
            });
          })
          .catch(() => {
            history.push(`/${match.params.locale}/assessment`);
          });
      } else {
        // auto activate device without email
        device.auto_activate = true;
        device.meta = srsData.meta;
        device.fingerprint = srsData.fingerprint_v2;
        DeviceAPI.addDevice(device)
          .then((response) => {
            RefreshTokenService.refresh().then(() => {
              callback(response);
            });
          })
          .catch((err) => {
            // logout if device is not supported
            if (err && err.response.status === 422) {
              if (err.response.data.errors.os) {
                toast(t("os_support_error"), { type: toast.TYPE.ERROR });
                setTimeout(() => {
                  history.push(`/${match.params.locale}/logout`);
                }, 1000);
              }
            }
          });
      }
    });
  };

  const wait = (callback, waitMs = 1500) => {
    setTimeout(() => {
      callback();
    }, waitMs);
  };

  const renderStep = () => {
    if (step === scanningStepsMap.SCAN_ACCOUNT_VULNERABILITY) {
      if (browser === "scanning") {
        wait(() => {
          const srsData = SRS.run({});
          setBrowser(srsData.meta.browser);
        });
      } else if (browser && browser !== "scanning" && !os) {
        wait(() => {
          setOS("scanning");
        });
      } else if (os === "scanning") {
        wait(() => {
          const srsData = SRS.run({});
          setOS(srsData.meta.os);
        });
      } else if (os && os !== "scanning" && browser && browser !== "scanning") {
        setBrowser(null);
        setOS(null);
        wait(() => {
          addAndChooseDevice((resp) => {
            if (identity && !identity.plan && isCrossServicePartner) {
              //TODO: add to translations
              toast(
                t("retail.please_contact_support_with_error_code", {
                  errorCode: CustomersErrorsCodes.NoActivePlan,
                }),
                {
                  type: toast.TYPE.ERROR,
                  autoClose: 1500,
                  onClose: () => history.push(`/${match.params.locale}/logout`),
                }
              );
            }
            !identity.plan ||
            (identity.plan && identity.plan.slug === "standard")
              ? completeStartScreen()
              : setStep(scanningStepsMap.SCAN_SECURITY_BREACHES);
          });
        });
      }
      return (
        <ScanAccountVulnerability
          t={t}
          locale={match.params.locale}
          browser={browser}
          os={os}
        />
      );
    }
    if (step === scanningStepsMap.SCAN_SECURITY_BREACHES) {
      wait(() => {
        completeStartScreen();
      }, animationStepLength);
      return <ScanSecurityBreaches t={t} locale={match.params.locale} />;
    }
  };

  return (
    <Page withSubHeader name="scanning">
      <Box
        height="100%"
        alignItems="center"
        justifyContent="center"
        display="flex"
        flexDirection="column"
      >
        {partnerCustomHeader && isScansCustomHeader && (
          <MDRender t={t} input={partnerCustomHeader} escapeHtml={false} />
        )}
        {identity && renderStep()}
      </Box>
    </Page>
  );
};

export default Scanning;
