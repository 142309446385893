import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { BodyText, SquareButton } from '../UI'
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow_right.svg'

const useStyles = makeStyles(theme => ({
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '10px -8px',
    height: '30px',
  },
  leftArrow: {
    transform: 'rotate(180deg)'
  }
}))

const Pagination = ({ t, pageCurrent, pageCount, getItems, filters }) => {
  const classes = useStyles()
  const changePage = direction => {
    let currentPage = filters.currentPage || 1
    getItems({ ...filters, currentPage: currentPage + direction })
  }
  if (!filters.currentPage) {
    filters.currentPage = 1
  }
  return (
    <>
      {((filters.currentPage === 1 && pageCount === 1)
        || !filters.currentPage
        || pageCount === undefined
        || isNaN(pageCount))
        ? ""
        : <Box className={classes.paginationWrapper} >
          {!filters.currentPage || filters.currentPage === 1 ? "" :
            <div
              onClick={() => changePage(-1)}>
              <SquareButton size='small' variant='contained' color='secondary' style={{ margin: '0 8px' }}>
                <ArrowRightIcon className={classes.leftArrow} />
              </SquareButton>
            </div>
          }
          <div>
          </div>
          <BodyText mx={1}>
            {t("page_x_of_count", { current: pageCurrent, count: pageCount })}
          </BodyText>
          {filters.currentPage === pageCount
            ? null
            : <div
              onClick={() => changePage(1)}
            >
              <SquareButton size='small' variant='contained' color='secondary' style={{ margin: '0 8px' }}>
                <ArrowRightIcon />
              </SquareButton>
            </div>
          }
        </Box>
      }
    </>
  )
}

export default Pagination