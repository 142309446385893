import React from 'react'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import CardItem from '../CardItem'
import { CardTitle, BodyText } from '../../../../components/UI'

const ItemsList = ({ t, protectedCards, deleteCard, loading }) => {
  return (
    <Box className='sa_monitored_data_cards_list'>
      <Box mt={3} mb={2}>
        <CardTitle variant='h2' mb={1}>
          {t("protected_cards")}
        </CardTitle>
        <BodyText textAlign='inherit'>
          {t("protected_cards_description")}
        </BodyText>
      </Box>
      <Paper className='itemsList' elevation={2}>
        <Box p={3}>
          {loading ?
            <Box height='100%' textAlign='center'>
              <CircularProgress />
            </Box>
            :
            <div>
              {protectedCards.map(card =>
                <CardItem
                  t={t}
                  key={card.uuid}
                  data={card}
                  deleteCard={deleteCard}
                />
              )}
            </div>
          }
        </Box>
      </Paper>
    </Box>
  )
}

export default ItemsList
