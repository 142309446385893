export const countriesPhoneCodes = [
  {
    value: 44,
    icon: '🇬🇧 +44',
    label: 'UK',
  },
  {
    value: 48,
    icon: '🇵🇱 +48',
    label: 'PL',
  },
  {
    value: 49,
    icon: '🇩🇪 +49',
    label: 'DE',
  },
  {
    value: 39,
    icon: '🇮🇹 +39',
    label: 'IT',
  },
  {
    value: 27,
    icon: '🇿🇦 +27',
    label: 'ZA',
  }
]

export const cyhuntPhoneCodes = [
  {
    value: 54,
    icon: '🇦🇷 +54',
    label: 'AR',
  },
  {
    value: 55,
    icon: '🇧🇷 +55',
    label: 'BR',
  },
  {
    value: 56,
    icon: '🇨🇱 +56',
    label: 'CL',
  },
  {
    value: 57,
    icon: '🇨🇴 +57',
    label: 'CO',
  },
  {
    value: 51,
    icon: '🇵🇪 +51',
    label: 'PE',
  },
  {
    value: 52,
    icon: '🇲🇽 +52',
    label: 'MX',
  }
]

export const gisPhoneCodes = [{ value: 1, icon: "🇺🇸 +1", label: "US" }];