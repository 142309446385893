import {
  SET_QUESTIONS,
  SET_ANSWER,
  COMPLETE_QUIZ,
  RESET_QUIZ,
} from "../constants";

export const setQuestions = (questions) => ({
  type: SET_QUESTIONS,
  payload: questions,
});

export const setAnswer = (questionUuid, answer) => ({
  type: SET_ANSWER,
  payload: { questionUuid, answer },
});

export const completeQuiz = () => ({
  type: COMPLETE_QUIZ,
});

export const resetQuiz = () => ({
  type: RESET_QUIZ,
});
