import client from '../axiosInstance';

export default {
  /**
   * @param {object} data
   * @param {function} validateStatus
   * @returns {Promise<AxiosResponse<*>>}
   */
   submitQuestion({data, validateStatus}) {
    return client.post('/assessment/api/v1/assessment/answers', data, {validateStatus})
  },
  /**
   * @param {function} validateStatus
   * @returns {Promise<AxiosResponse<*>>}
   */
   resetAssessment({validateStatus}) {
    return client.delete('/assessment/api/v1/assessment/answers', {validateStatus})
  },
  /**
   * @param {function} validateStatus
   * @returns {Promise<AxiosResponse<*>>}
   */
   getNextQuestion({validateStatus}) {
    return client.get('/assessment/api/v1/assessment/questions/unanswered', {validateStatus})
  },
  /**
   * @param {function} validateStatus
   * @param {string} uuid
   * @returns {Promise<AxiosResponse<*>>}
   */
   getNextQuestionForDevice({validateStatus, uuid}) {
    const url = `/assessment/api/v1/assessment/questions/unanswered/device/${uuid}/`
    return client.get(url, {validateStatus})
  },
  /**
   * @param {function} validateStatus
   * @param {string} uuid
   * @returns {Promise<AxiosResponse<*>>}
   */
   getNextQuestionForSession({validateStatus, uuid}) {
    const url = `/assessment/api/v1/assessment/questions/unanswered/session/${uuid}/`
    return client.get(url, {validateStatus})
  },
  /**
   * @returns {Promise<AxiosResponse<*>>}
   */
  getSessionsActive() {
    return client.get(`/assessment/api/v1/assessment/sessions/active`)
  },
  /**
   * @returns {Promise<AxiosResponse<*>>}
   */
  getPromoQuestionsTranslates() {
    return client.get(`/assessment/api/v1/assessment/questions/promo`)
  }
}
