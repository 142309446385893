import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  useHistory,
  useParams,
  useRouteMatch,
  useLocation,
  withRouter,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";

import Page from "components/Page";
import SignInForm from "./SignInForm";
import Logo from "components/Logo";
import { CardTitle, BodyText } from "../../components/UI";
import InitAnimation from "./InitAnimation";

import { useReddirectToDashbordOnLoged } from "../../hooks/authentication/useReddirectToDashbordOnLoged";
import { usePlanFromCMS } from "../../hooks/usePlanFromCMS";
import useAuth from "../../hooks/useAuth";

const Signin = (props) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const { locale } = useParams();
  const { state } = useLocation();
  const { authorizeUser } = useAuth();
  const partnerSettings = useSelector((state) => state.partnerSettings);

  const isStaff = window.location.href.indexOf("staff") > -1;

  // set plan selected by customer from cms
  usePlanFromCMS();

  const animation = state && state.skipAnimation ? false : true;
  const [isAnimationRuns, toggleAnimation] = useState(animation);
  // If user is loged redirrect to /dashboard
  useReddirectToDashbordOnLoged(!isAnimationRuns, props);

  const handleNotRegisteredBtnClick = () => {
    history.push(`/${locale}/signUp`);
  };

  return (
    <Page name="signIn">
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {isAnimationRuns && (
          <InitAnimation
            isEnded={!isAnimationRuns}
            toggleAnimation={toggleAnimation}
            slug={partnerSettings.partnerSlug}
          />
        )}
        {!isAnimationRuns && (
          <Fade in={!isAnimationRuns} timeout={{ enter: 750 }}>
            <Box style={{ width: "100%" }}>
              <Box mb={6} textAlign="center">
                <Logo preventRedirection={true} />
              </Box>
              <Paper
                elevation={2}
                style={{ maxWidth: "576px", margin: "0 auto" }}
              >
                <Box p={3}>
                  <CardTitle textAlign="center">
                    {t("sign_in_to_dynarisk", {
                      name: partnerSettings.partnerName,
                    })}
                  </CardTitle>
                  {/* <BodyText textAlign="center" mb={3}>
                    {t("sign_in_to_dynarisk_subheader", {
                      name: partnerSettings.partnerName,
                    })}
                  </BodyText> */}
                  <SignInForm authorizeUser={authorizeUser} />
                  <Box mt={2}>
                    {!isStaff && !partnerSettings.noRegistrationFlow && (
                      <Button
                        onClick={handleNotRegisteredBtnClick}
                        fullWidth
                        variant="text"
                        color="secondary"
                        className={"sa_signin_not_registered"}
                      >
                        {t("are_you_not_registered")}
                      </Button>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Fade>
        )}
      </Box>
    </Page>
  );
};

export default withRouter(Signin);
