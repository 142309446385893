import {
  SET_COURSE,
  SET_COURSE_ITEMS,
  SET_CERTIFICATES_LIST,
} from "../constants";

export const setCourse = (course) => ({
  type: SET_COURSE,
  payload: course,
});

export const setCourseItems = (courseItems) => ({
  type: SET_COURSE_ITEMS,
  payload: courseItems,
});

export const setCertificatesList = (coursesList) => ({
  type: SET_CERTIFICATES_LIST,
  payload: coursesList,
});
