/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ModelChapter from './ModelChapter';
import ModelUserCourse from './ModelUserCourse';

/**
 * The ModelUserChapter model module.
 * @module model/ModelUserChapter
 * @version 1.0.0
 */
class ModelUserChapter {
    /**
     * Constructs a new <code>ModelUserChapter</code>.
     * @alias module:model/ModelUserChapter
     * @param chapterUuid {String} 
     * @param createdAt {Number} 
     * @param isCompleted {Boolean} 
     * @param userCourseUuid {String} 
     * @param uuid {String} 
     */
    constructor(chapterUuid, createdAt, isCompleted, userCourseUuid, uuid) { 
        
        ModelUserChapter.initialize(this, chapterUuid, createdAt, isCompleted, userCourseUuid, uuid);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, chapterUuid, createdAt, isCompleted, userCourseUuid, uuid) { 
        obj['chapter_uuid'] = chapterUuid;
        obj['created_at'] = createdAt;
        obj['is_completed'] = isCompleted;
        obj['user_course_uuid'] = userCourseUuid;
        obj['uuid'] = uuid;
    }

    /**
     * Constructs a <code>ModelUserChapter</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ModelUserChapter} obj Optional instance to populate.
     * @return {module:model/ModelUserChapter} The populated <code>ModelUserChapter</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ModelUserChapter();

            if (data.hasOwnProperty('chapter')) {
                obj['chapter'] = ModelChapter.constructFromObject(data['chapter']);
            }
            if (data.hasOwnProperty('chapter_uuid')) {
                obj['chapter_uuid'] = ApiClient.convertToType(data['chapter_uuid'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Number');
            }
            if (data.hasOwnProperty('is_completed')) {
                obj['is_completed'] = ApiClient.convertToType(data['is_completed'], 'Boolean');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Number');
            }
            if (data.hasOwnProperty('user_course')) {
                obj['user_course'] = ModelUserCourse.constructFromObject(data['user_course']);
            }
            if (data.hasOwnProperty('user_course_uuid')) {
                obj['user_course_uuid'] = ApiClient.convertToType(data['user_course_uuid'], 'String');
            }
            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ModelUserChapter</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ModelUserChapter</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ModelUserChapter.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `chapter`
        if (data['chapter']) { // data not null
          ModelChapter.validateJSON(data['chapter']);
        }
        // ensure the json data is a string
        if (data['chapter_uuid'] && !(typeof data['chapter_uuid'] === 'string' || data['chapter_uuid'] instanceof String)) {
            throw new Error("Expected the field `chapter_uuid` to be a primitive type in the JSON string but got " + data['chapter_uuid']);
        }
        // validate the optional field `user_course`
        if (data['user_course']) { // data not null
          ModelUserCourse.validateJSON(data['user_course']);
        }
        // ensure the json data is a string
        if (data['user_course_uuid'] && !(typeof data['user_course_uuid'] === 'string' || data['user_course_uuid'] instanceof String)) {
            throw new Error("Expected the field `user_course_uuid` to be a primitive type in the JSON string but got " + data['user_course_uuid']);
        }
        // ensure the json data is a string
        if (data['uuid'] && !(typeof data['uuid'] === 'string' || data['uuid'] instanceof String)) {
            throw new Error("Expected the field `uuid` to be a primitive type in the JSON string but got " + data['uuid']);
        }

        return true;
    }


}

ModelUserChapter.RequiredProperties = ["chapter_uuid", "created_at", "is_completed", "user_course_uuid", "uuid"];

/**
 * @member {module:model/ModelChapter} chapter
 */
ModelUserChapter.prototype['chapter'] = undefined;

/**
 * @member {String} chapter_uuid
 */
ModelUserChapter.prototype['chapter_uuid'] = undefined;

/**
 * @member {Number} created_at
 */
ModelUserChapter.prototype['created_at'] = undefined;

/**
 * @member {Boolean} is_completed
 */
ModelUserChapter.prototype['is_completed'] = undefined;

/**
 * @member {Number} updated_at
 */
ModelUserChapter.prototype['updated_at'] = undefined;

/**
 * @member {module:model/ModelUserCourse} user_course
 */
ModelUserChapter.prototype['user_course'] = undefined;

/**
 * @member {String} user_course_uuid
 */
ModelUserChapter.prototype['user_course_uuid'] = undefined;

/**
 * @member {String} uuid
 */
ModelUserChapter.prototype['uuid'] = undefined;






export default ModelUserChapter;

