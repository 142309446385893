import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import apiUser from "api/user";
import Page from "components/Page";
import FormSendResetPasswordEmail from "./FormSendResetPasswordEmail";
import useFlagSettings from '../../../hooks/useFlagSettings';





const SignInPasswordForgot = () => {
  const history = useHistory();
  const { locale } = useParams();
  const { t } = useTranslation();

  const [loading, setloading] = useState(false);
  const { context } = useSelector(state => state.partnerSettings);
const { flagsMap, userSettings, updateUserSetting } = useFlagSettings();



  const sendResetLink = async (email) => {
    setloading(true);

    const payload = {
      email
    }
    // set profile type if exists
    if (context && context.domain_type) {
      payload['profile_type'] = context.domain_type
    }
    try {
      await apiUser.forgotPassword(payload)
      setloading(false);
      toast(t(`retail.check_email_to_reset_password`, {email}), {
        type: toast.TYPE.SUCCESS,
        onClose: () => { 
          history.push(`/${locale}/signIn`) 
        }
      })
    } catch (err) {
      setloading(false);
      // TODO: use json
      toast('An error occured while sending password. Please try again later', { type: toast.TYPE.ERROR })
    }
  };

  return (
    <Page>
      <FormSendResetPasswordEmail
        sendResetLink={sendResetLink}
        loading={loading}
      />
    </Page>
  );
};

export default SignInPasswordForgot;
