import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Page from "components/Page";
import { SubHeaderContext } from "components/SubHeader/SubHeaderContext";
import { Log } from "services/log/logger";
import GeneralAssessmentScreen from "pages/Welcome/GeneralAssessmentScreen";
import DashboardScreen from "pages/Welcome/DashboardScreen";
import WhatToDoNextScreen from "pages/Welcome/WhatToDoNextScreen";
import AssessmentAPI from "api/assessment";
import useFlagSettings from "../../hooks/useFlagSettings";
import { ReactComponent as IconArrowBack } from "../../assets/icons/arrow-back.svg";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/styles";
import { CardTitle } from "../../components/UI";
import { makeStyles } from "@material-ui/core/styles";
import {
  PROFILE_TYPE_STAFF,
  DEFAULT_PARTNER_NAME,
} from "../../constants/common";

const StaffButton = withStyles((theme) => ({
  root: {
    width: "180px",
    padding: "5px 15px",
    borderRadius: "4px",
    // background: "#1a47b9",
    // color: "#fff",
    height: "40px",
    lineHeight: "30px",
    // "&:hover": {
    //   background: "#1a47b9",
    //   color: "#fff",
    // },
  },
}))(Button);

const BackButton = withStyles((theme) => ({
  root: {
    minWidth: "40px",
    padding: "5px 5px",
    borderRadius: "4px",
    // background: "#1a47b9",
    // color: "#fff",
    height: "40px",
    lineHeight: "30px",
    // "&:hover": {
    //   background: "#1a47b9",
    // },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  arrowIcon: {
    transform: theme.variables.$isRTLDirection ? "scaleX(-1)" : "none"
  },
  columns: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      marginBottom: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  customScreenHeight: {
    [theme.breakpoints.up("md")]: {
      minHeight: "50vh",
    },
  },
  pt10vh: {
    paddingTop: "10vh",
  },
  step: {
    marginBottom: "30px",
    "&.active": {
      marginBottom: "10px",

      "& $stepHeading": {
        "& .circleWithNumber": {
          background: "#ffffff",
          boxShadow: "0 4px 8px rgba(26, 71, 185, 0.1)",
          borderColor: "transparent",
        },
        "& $stepText": {
          // color: "#1a47b9",
          color: theme.palette.primary.main,
        },
      },
      "& $stepContent": {
        display: "flex",
      },
    },
    "&.reviewed": {
      "& $stepText": {
        opacity: 0.5,
        color: "#111",
      },
      "& $circleWithNumber": {
        opacity: 0.5,
        color: "#111",
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
  stepHeading: {
    display: "flex",
    alignItems: "center",
  },
  circleWithNumber: {
    width: "32px",
    height: "32px",
    //border: '2px solid darkgray',
    border: "2px solid rgba(65, 87, 112, 0.2)",
    boxSizing: "border-box",
    filter: "drop-shadow(0px 4px 8px rgba(87, 160, 34, 0.12))",
    borderRadius: "24px",
    padding: "5px 10px",
    fontSize: "14px",
    fontWeight: "600",
    color: "rgba(65, 87, 112, 0.6)",
  },
  stepText: {
    marginLeft: theme.variables.$isRTLDirection ? "0" :"10px",
    marginRight: theme.variables.$isRTLDirection ? "10px" :"0",
    fontFamily: "Montserrat, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "32px",
    color: "rgba(65, 87, 112, 0.6)",
  },
  stepContent: {
    display: "none",

    "& .dots": {
      // borderLeft: "1px dashed #1a47b9",
      margin: "10px 16px 0",
      width: "1px",
      height: "auto",

      borderLeft: `1px dashed ${theme.palette.primary.main}`,
    },
    "& .content": {
      marginLeft: "10px",
      fontSize: "14px",
    },
  },
  col1: {
    flexBasis: "50%",
    flexShrink: 1,
    borderRight: theme.variables.$isRTLDirection ? 'none' :`1px solid ${theme.variables.$dividerLightGray}`,
    borderLeft: theme.variables.$isRTLDirection ? `1px solid ${theme.variables.$dividerLightGray}` : 'none',
    paddingRight: theme.variables.$isRTLDirection ? "0px" : "20px",
    paddingLeft: theme.variables.$isRTLDirection ? "20px" : "0px",
    "& .heading": {
      fontFamily: "Montserrat, serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "32px",
      lineHeight: "40px",
    },
    "& .content": {
      margin: "16px 0",
      fontFamily: "Montserrat, serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "146.9%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: "20px",
      border: "none"
    },
  },
  col3: {
    flexBasis: "40%",
    flexShrink: 1,
    "& .heading": {
      fontFamily: "Montserrat, serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "32px",
      lineHeight: "40px",
    },
    "& .content": {
      margin: "16px 0",
      fontFamily: "Montserrat, serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "146.9%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: "20px",
    },
  },
  col2: {
    flexBasis: "50%",
    paddingLeft: theme.variables.$isRTLDirection ? "0" : "40px",
    paddingRight: theme.variables.$isRTLDirection ? "40px" : "0px",
    "& img": {
      display: "none",
      width: "100%",
      background: "linear-gradient(0deg, #fafbfb, #fafbfb)",
      boxShadow: "-8px 0px 68px rgba(0, 0, 0, 0.04)",

      "&.displayable": {
        display: "block",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0
    },
  },
  col4: {
    flexBasis: "60%",
    paddingLeft: "40px",
    "& img": {
      display: "none",
      width: "100%",
      background: "linear-gradient(0deg, #fafbfb, #fafbfb)",
      boxShadow: "-8px 0px 68px rgba(0, 0, 0, 0.04)",

      "&.displayable": {
        display: "block",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 0,
    },
  },
  introduction: {
    animation: "opacity 300ms ease-in",
    [theme.breakpoints.up("md")]: {
      marginTop: "10%",
    },
    "& .partnerFill": {
      fill: theme.palette.primary.main,
    },
    "& .partnerStroke": {
      stroke: theme.palette.primary.main,
    },
    "& .partnerText": {
      color: theme.palette.primary.contrastText,
    },
    "& .partnerObjBg": {
      background: theme.palette.primary.main,
    },
  },
  defaultPartnerStaff: {
    step: {
      "&.active": {
        "& $stepHeading": {
          "& $stepText": {
            color: "#1a47b9",
          },
        },
      },
      "&.reviewed": {
        "& $stepText": {
          color: "rgba(26, 71, 185, 0.4)",
        },
        "& $circleWithNumber": {
          background: "rgba(26, 71, 185, 0.1)",
          border: "1px solid rgba(26, 71, 185, 0.2)",
          color: "rgba(26, 71, 185, 0.4)",
        },
      },
    },
    stepContent: {
      "& .dots": {
        borderLeft: "1px dashed #1a47b9",
      },
    },
    "& .partnerFill": {
      fill: "#1a47b9",
    },
    "& .partnerStroke": {
      stroke: "#1a47b9",
    },
    "& .partnerText": {
      color: "#fff",
    },
    "& .partnerObjBg": {
      background: "#1a47b9",
    },
  },
}));

const Welcome = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const identity = useSelector((state) => state.identity);
  const { partnerSlug } = useSelector((state) => state.partnerSettings);
  const { flagsMap, userSettings, updateUserSetting } = useFlagSettings();
  const [isLoading, setLoading] = useState(true);
  const [currentActiveStep, setActiveStep] = useState(0);
  const { subHeaderContext, useSubHeaderContext } =
    useContext(SubHeaderContext);
  const [assessmentTranslates, setAssessmentTranslates] = useState(null);
  const defaultPartnerStaff = partnerSlug === DEFAULT_PARTNER_NAME;
  const handleStepActive = (stepNumber) => {
    return currentActiveStep === stepNumber
      ? "active"
      : "" + currentActiveStep > stepNumber
      ? " reviewed"
      : "";
  };

  const getButtonText = () => {
    return currentActiveStep === 0 || currentActiveStep === 3
      ? t("start")
      : t("next");
  };

  const changeStep = (stepNumber) => {
    if (stepNumber === 4) {
      return updateWelcomeShownFlag();
    }
    setActiveStep(stepNumber);
  };

  useEffect(() => {
    getAssessmentTranslates();
    useSubHeaderContext({
      logo: { display: true },
      userProfile: { display: true },
      logout: { display: true },
    });
  }, []);

  useEffect(() => {
    if (
      userSettings &&
      identity &&
      identity.profile &&
      identity.profile.profile_type !== PROFILE_TYPE_STAFF
    ) {
      switchToNextScreen();
    }
    if (
      identity &&
      identity.profile &&
      identity.profile.profile_type === PROFILE_TYPE_STAFF
    ) {
      setLoading(false);
    }
  }, [identity, userSettings]);

  const getAssessmentTranslates = () => {
    AssessmentAPI.getPromoQuestionsTranslates()
      .then((response) => {
        setAssessmentTranslates(response.data);
      })
      .catch(() => {});
  };

  const switchToNextScreen = () => {
    if (!userSettings[flagsMap.WELCOME_SCREEN_SHOWN]) {
      return history.push(`/${match.params.locale}/start-scan`);
    }
    if (!userSettings[flagsMap.ASSESSMENT_SHOWN]) {
      return history.push(`/${match.params.locale}/assessment`);
    }
    history.push(`/${match.params.locale}/dashboard`);
  };

  const updateWelcomeShownFlag = async () => {
    try {
      history.push(`/${match.params.locale}/start-scan`);
    } catch (err) {
      Log.error("Welcome screen error", { response: err });
      history.push(`/${match.params.locale}/start-scan`);
    }
  };

  return (
    <Page withSubHeader name="introduction">
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="250px"
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <Box
          className={`${classes.introduction} ${
            defaultPartnerStaff ? classes.defaultPartnerStaff : ""
          }`}
        >
          {currentActiveStep === 0 && (
            <div className={`${classes.columns} ${classes.pt10vh}`}>
              <div className={classes.col1}>
                <div className="heading">{t("staff.welcome_heading")}</div>
                <div className="content">{t("staff.welcome_sub_heading")}</div>
              </div>

              <div className={classes.col2}>
                <div className={classes.step}>
                  <div className={classes.stepHeading}>
                    <div className={classes.circleWithNumber}>1</div>
                    <div className={classes.stepText}>
                      {t("staff.knowledge_assessment")}
                    </div>
                  </div>
                </div>
                <div className={classes.step}>
                  <div className={classes.stepHeading}>
                    <div className={classes.circleWithNumber}>2</div>
                    <div className={classes.stepText}>
                      {t("staff.personalised_dashboard")}
                    </div>
                  </div>
                </div>
                <div className={classes.step}>
                  <div className={classes.stepHeading}>
                    <div className={classes.circleWithNumber}>3</div>
                    <div className={classes.stepText}>
                      {t("staff.action_plan")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentActiveStep !== 0 && (
            <div>
              <CardTitle mb={4} textAlign="inherit">{t("staff.welcome_heading")}</CardTitle>
              <div
                className={`${classes.columns} ${classes.customScreenHeight}`}
              >
                <div className={classes.col3}>
                  <div className={`${classes.step} ${handleStepActive(1)}`}>
                    <div className={classes.stepHeading}>
                      <div className={classes.circleWithNumber}>1</div>
                      <div className={classes.stepText}>
                        {t("staff.knowledge_assessment")}
                      </div>
                    </div>
                    <div className={classes.stepContent}>
                      <div className="dots" />
                      <div className="content">
                        {t("staff.you_will_answer_a_few_questions")}
                      </div>
                    </div>
                  </div>

                  <div className={`${classes.step} ${handleStepActive(2)}`}>
                    <div className={classes.stepHeading}>
                      <div className={classes.circleWithNumber}>2</div>
                      <div className={classes.stepText}>
                        {t("staff.personalised_dashboard")}
                      </div>
                    </div>
                    <div className={classes.stepContent}>
                      <div className="dots" />
                      <div className="content">
                        {t("staff.after_completing_the_assessment")}
                      </div>
                    </div>
                  </div>
                  <div className={`${classes.step} ${handleStepActive(3)}`}>
                    <div className={classes.stepHeading}>
                      <div className={classes.circleWithNumber}>3</div>
                      <div className={classes.stepText}>
                        {t("staff.action_plan")}
                      </div>
                    </div>
                    <div className={classes.stepContent}>
                      <div className="dots" />
                      <div className="content">
                        {t("staff.review_our_mini_training_guides")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classes.col4} style={{ padding: "0" }}>
                  {currentActiveStep === 1 && (
                    <GeneralAssessmentScreen promo={assessmentTranslates} />
                  )}
                  {currentActiveStep === 2 && <DashboardScreen />}
                  {currentActiveStep === 3 && <WhatToDoNextScreen />}
                </div>
              </div>
            </div>
          )}

          <Box
            display="flex"
            justifyContent={
              currentActiveStep === 0 ? "center" : "space-between"
            }
          >
            {currentActiveStep !== 0 && (
              <BackButton
                color="primary"
                variant="contained"
                onClick={() => changeStep(currentActiveStep - 1)}
                style={
                  defaultPartnerStaff
                    ? {
                        background: "#1a47b9",
                        color: "#fff",
                        "&:hover": {
                          background: "#0d2560",
                        },
                      }
                    : {}
                }
              >
                <IconArrowBack className={classes.arrowIcon} height={10} width={10} />
              </BackButton>
            )}

            <StaffButton
              color="primary"
              variant="contained"
              onClick={() => changeStep(currentActiveStep + 1)}
              style={
                defaultPartnerStaff
                  ? {
                      background: "#1a47b9",
                      color: "#fff",
                      "&:hover": {
                        background: "#0d2560",
                      },
                    }
                  : {}
              }
            >
              {getButtonText()}
            </StaffButton>
          </Box>
        </Box>
      )}
    </Page>
  );
};

export default Welcome;
