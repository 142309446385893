import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tippy";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

import API from "api/user";
import { featuresIcons } from "./constants";
import { TUA_CATTOLICA_PARTNERS } from "../../../constants/common";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { CardTitle, Chip, Card } from "../../../components/UI";
import PlaceholderBox from "components/ContentPlaceholder/PlaceholderBox";
import ContentPlaceholder from "components/ContentPlaceholder";
import { useSelector } from "react-redux";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";

const useStyles = makeStyles((theme) => ({
  featureCard: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  scanContent: {
    width: "100%",
    flexGrow: 1,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    padding: "0 30px",
  },
  features: {
    display: "flex",
    margin: "0 -10px",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  header: {
    color: theme.variables.$black,
    marginBottom: "10px",
    display: "block",
    textAlign: "center",
    flexGrow: 1,
  },
  lastScan: {
    display: "block",
    textAlign: "center",
    marginBottom: "10px",
    color: theme.variables.$textDarkGray,
  },
  icon: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    width: "100%",
    "& svg": {
      "& .svgStrokePrimary": {
        stroke: "var(--primary)",
      },
      "& .svgFillPrimary": {
        fill: "var(--primary)",
      },
    },
  },
}));

const InfoTransKeyMap = {
  data_breach_scanner: "cx.data_breach_scan_info",
  router_scan: "cx.data_router_scan_info",
  vulnerability_scan: "cx.vulnerability_scan_info",
};

const WhatWereScanningFor = ({
  t,
  partnerSettings,
  setLoading,
  featuresLoading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { locale } = useParams();
  const [features, setFeatures] = useState([]);
  const { scanSection, showFeaturesDescription } = partnerSettings;

  useEffect(() => {
    getFeatures();
  }, []);

  const getFeatures = () => {
    API.getPlanFeatures()
      .then((response) => {
        setLoading(false);
        if (response && response.data && Array.isArray(response.data.items)) {
          const items = response.data.items.filter(
            // also filter if not in scanSection form partner settings
            (scanner) =>
              scanner.name !== "security_scan" &&
              ((scanSection && scanSection.includes(scanner.name)) ||
                !scanSection)
          );
          setFeatures(items);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast(t("can_t_select_features"), { type: toast.TYPE.ERROR });
      });
  };

  const renderItems = () => {
    return features.map((featureItem) => {
      if (
        partnerSettings &&
        TUA_CATTOLICA_PARTNERS.indexOf(partnerSettings.partnerSlug) > -1 &&
        featureItem.name === "data_breach_scanner"
      ) {
        return null;
      }
      const infoTransKey = InfoTransKeyMap[featureItem.name];

      return (
        <Grid
          key={featureItem.name}
          item
          xs={12}
          sm={6}
          md={features.length % 2 === 0 ? 6 : 4}
        >
          {/* <Card className={classes.featureCard} p="32px 0"> */}
          <Paper elevation={2} className={classes.featureCard}>
            <Box p="32px 0" height="100%" display="flex" flexDirection="column">
              <Box
                mx={3}
                textAlign="left"
                display="flex"
                justifyContent="space-between"
              >
                <Chip
                  type={featureItem.is_enabled ? "enabled" : "disabled"}
                  isCaps={true}
                />
                {showFeaturesDescription && (
                  <Tooltip position="top" title={t(infoTransKey)}>
                    <InfoIcon width="1rem" height="1rem" />
                  </Tooltip>
                )}
              </Box>
              <Box
                display="flex"
                height="100px"
                justifyContent="center"
                alignItems="center"
              >
                <i className={`${classes.icon}`}>
                  {featuresIcons[featureItem.name]}
                </i>
              </Box>
              <div className={classes.scanContent}>
                <h3 className={classes.header}>
                  {t("scan_label", { label: t(featureItem.name) })}
                </h3>
                <span className={classes.lastScan}>
                  {t("background_scan_active")}
                </span>
                {!featureItem.is_enabled && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    fullWidth
                    id="sa_dashboard_feature_enable"
                    onClick={() => {
                      history.push(`/${locale}/profile/plans`);
                    }}
                  >
                    {t("enable_scan")}
                  </Button>
                )}
              </div>
              {/* </Card> */}
            </Box>
          </Paper>
        </Grid>
      );
    });
  };

  if (featuresLoading) {
    return (
      <>
        <Box mb={3}>
          <PlaceholderBox height="2rem" width="50%" ml="0" mb="0" />
        </Box>
        <ContentPlaceholder type="cardsGrid" quantity={3} />
      </>
    );
  }

  return (
    <div>
      <CardTitle>{t("what_we_scan")}</CardTitle>
      <Grid container className={classes.features} spacing={2}>
        {features && partnerSettings && renderItems()}
      </Grid>
    </div>
  );
};

export default WhatWereScanningFor;
