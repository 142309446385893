import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ReactGA from 'react-ga';

const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
      const script = document.createElement('script');
      const hjScriptText = document.createTextNode(`(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:2794785,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`);
      script.appendChild(hjScriptText);
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
      ReactGA.pageview(window.location.href + window.location.search);
    }
}, [location.pathname]);

};

export default useAnalytics;
