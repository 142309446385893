import React from "react";
import Tag from "components/Chip";
import { SquareButton } from "components/UI";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { ReactComponent as VideoIcon } from "../../../../assets/icons/camera.svg";
import { ReactComponent as QuizIcon } from "../../../../assets/icons/quiz.svg";
import { ReactComponent as ClockIcon } from "../../../../assets/icons/clock.svg";
import { ReactComponent as BackArrowIcon } from "../../../../assets/icons/arrow-back.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import { useHistory, useParams } from "react-router-dom";
import { formatDuration } from "../../utils/parseTime";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { calculateRemainingTime } from "../../utils/parseTime";
import CourseNavigationBtn from "pages/Training/CommonComponents/NavigationButton";

const useStyles = makeStyles((theme) => ({
  infoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    flexWrap: "wrap",
  },
  overviewWrapper: {
    padding: theme.spacing(3),
  },
  tagWrapper: {
    display: "flex",
    "& svg": {
      height: "16px",
      width: "16px",
    },
  },
  courseNameWrapper: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  remainingTime: {
    display: "flex",
    "& svg": {
      height: "16px",
      width: "16px",
    },
  },
}));
const CourseSummaryCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const course = useSelector((store) => store.course.course);
  const { locale } = useParams();
  const history = useHistory();

  return (
    <Paper className={classes.overviewWrapper}>
      <Box className={classes.courseNameWrapper}>
        <Box mr={2}>
          <SquareButton
            variant="contained"
            color="secondary"
            onClick={() => {
              history.push(`/${locale}/training/tasks`);
            }}
            aria-label="go back"
          >
            <BackArrowIcon />
          </SquareButton>
        </Box>
        <Typography variant="h1">{course.title}</Typography>
      </Box>
      <Typography variant="body2">{course.description}</Typography>
      <Box className={classes.infoWrapper}>
        <Box className={classes.tagWrapper} mt={1} mb={1}>
          <Tag
            icon={<VideoIcon />}
            label={`${t("retail.video")}: ${course.video_lessons_count}`}
            mr={0.5}
          />
          <Tag
            icon={<QuizIcon />}
            label={`${t("retail.quiz")}: ${course.quizzes_count}`}
            mr={0.5}
          />
          <Tag
            icon={<ClockIcon />}
            label={formatDuration(course.duration)}
            mr={1}
          />
        </Box>
        <Box className={classes.remainingTime}>
          {course.completion_date && course.progress_status !== "COMPLETED" && (
            <>
              <Box mr={1}>
                <InfoIcon
                  style={{
                    height: "100%",
                  }}
                />
              </Box>
              <Typography variant="body2" component="span">
                {`${calculateRemainingTime(course.completion_date)} ${t(
                  "retail.remaining"
                )}`}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <CourseNavigationBtn />
    </Paper>
  );
};

export default CourseSummaryCard