import { makeStyles } from "@material-ui/core/styles";

export const useCardItemStyles = makeStyles(theme => ({
  cardItem: {
    height: '48px',
    transition: 'height .4s',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.variables.$dividerLightGray}`,
    },
    [theme.breakpoints.down('xs')]: {
      height: 'initial',
    },
    '&.flipable': {
      height: '70px',
      transition: 'height .4s'
    }
  },
  frontWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignContent: 'center',
    height: '100%'
  },
  info: {
    color: theme.variables.$black,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '20px',
    margin: '5px 0px',
  },
  btns: {
    display: 'flex',
    marginTop: '5px',
    marginBottom: '5px'

  },
  closeBtn: {
    position: 'absolute',
    top: '-12px',
    right: '-24px'
  },
  emailType: {
    width: "22px",
    height: "22px",
    margin: "5px",
  }

}))