import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import DevicesList from './DevicesList'
import Page from 'components/Page'

const DevicesPage = ({ t, match }) => {
  return (
    <Page
      name='devices'
      withAside
      withSubHeader
    >
      <DevicesList t={t} match={match}/>
    </Page>
  )
}

export default withTranslation()(withRouter(DevicesPage))
