import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { setItem } from 'helpers/localStorage'

const CouponFromPath = ({ match }) => {
  const coupon = match.params.couponName.toUpperCase()
  setItem('coupon', coupon)
  return <Redirect to={`/${match.params.locale}/signUp`} />
}

export default withRouter(CouponFromPath)
