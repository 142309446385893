import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from "api/user";
import {
  isEmail,
  isValidString,
  isStringMinMaxLength,
} from "../../helpers/validators";
import isMobilePhone from "validator/lib/isMobilePhone";
import PasswordStrengthComponent from "components/PasswordStrength";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { InputField, SelectField } from "../../components/UI";

import useForm from "../../hooks/useForm";

const stateSchema = {
  first_name: { value: "", error: "" },
  last_name: { value: "", error: "" },
  email: { value: "", error: "" },
  password: { value: "", error: "" },
  repeat_password: { value: "", error: "" },
  phone_number: { value: "", error: "" },
  country_code: { value: "", error: "" },
  coupon: { value: "", error: "" },
};

const SignUpForm = ({ validateForm, phonePrefixes, formValues, coupon }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { locale } = useParams();

  const handleSubmit = () => {
    const formData = {
      country_code: state.country_code.value.value,
      email: state.email.value,
      first_name: state.first_name.value,
      last_name: state.last_name.value,
      password: state.password.value,
      phone_number: state.phone_number.value,
    };

    if (state.coupon.value) {
      formData["coupon"] = state.coupon.value;
    }
    validateForm(formData);
  };

  const validationStateSchema = {
    first_name: {
      required: true,
      validator: [
        { func: isValidString, error: t("first_name_invalid") },
        {
          func: (str) => isStringMinMaxLength(str, null, 100),
          error: t("too_long_max_x", { count: 100 }),
        },
      ],
    },
    last_name: {
      required: true,
      validator: [
        { func: isValidString, error: t("last_name_invalid") },
        {
          func: (str) => isStringMinMaxLength(str, null, 100),
          error: t("too_long_max_x", { count: 100 }),
        },
      ],
    },
    email: {
      required: true,
      validator: {
        func: isEmail,
        error: t("enter_valid_email"),
      },
    },
    country_code: {
      required: true,
    },
    phone_number: {
      required: true,
      validator: {
        func: isMobilePhone,
        error: t("enter_valid_phone_number"),
      },
    },
    password: {
      required: true,
    },
    repeat_password: {
      required: true,
    },
    coupon: {},
  };

  const [initialState, setInitialState] = useState({
    data: {
      first_name: formValues.first_name ? formValues.first_name : "",
      last_name: formValues.last_name ? formValues.last_name : "",
      email: formValues.email ? formValues.email : "",
      password: "",
      repeat_password: "",
      phone_number: formValues.phone_number ? formValues.phone_number : "",
      country_code: null,
      coupon,
    },
    errors: null,
  });

  const {
    state,
    handleOnChange,
    handleOnBlur,
    handleOnSubmit,
    disable,
    isDirty,
  } = useForm(
    initialState,
    stateSchema,
    null,
    validationStateSchema,
    handleSubmit
  );

  const handleEmailBlur = (evt) => {
    handleOnBlur(evt);
    if (!state.email.error) {
      validateEmailUniqueness(state.email.value);
    }
  };

  const validateEmailUniqueness = async (email) => {
    try {
      const resp = await API.checkEmailUniqueness({ email });
      if (resp.status !== 200) {
        const state = { errors: { email: t("email_already_registered") } };
        setInitialState(state);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (!initialState.data.country_code && phonePrefixes) {
      let country_code = phonePrefixes[0];
      if (formValues && formValues.country_code) {
        country_code = phonePrefixes.filter(
          (code) => code.value === formValues.country_code
        );
      }
      const state = { data: { ...initialState.data, country_code } };
      setInitialState(state);
    }
  }, [phonePrefixes]);

  return (
    <>
      <form
        className="-sizeWidthFull"
        onSubmit={handleOnSubmit}
        id="form-register"
      >
        <Box mb={4}>
          <InputField
            name="first_name"
            placeholder={t("first_name")}
            type="text"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            value={state.first_name.value}
            className="sa_signup_first_name"
            error={isDirty && isDirty.first_name && state.first_name.error}
          />
        </Box>
        <Box mb={4}>
          <InputField
            name="last_name"
            placeholder={t("last_name")}
            type="text"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            value={state.last_name.value}
            className="sa_signup_last_name"
            error={isDirty && isDirty.last_name && state.last_name.error}
          />
        </Box>
        <Box mb={4}>
          <InputField
            name="email"
            placeholder={t("email_address")}
            type="text"
            onChange={handleOnChange}
            onBlur={handleEmailBlur}
            value={state.email.value}
            className="sa_signup_email"
            error={state.email.error}
          />
        </Box>
        <Box mb={4} mx={-1} display="flex">
          <Box mx={1} flexShrink="0" flexBasis="160px">
            {phonePrefixes && (
              <SelectField
                name="country_code"
                options={phonePrefixes}
                value={state.country_code.value}
                className="sa_signup_country_code"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
              />
            )}
          </Box>
          <Box width="100%" mx={1}>
            <InputField
              name="phone_number"
              placeholder={t("phone_number")}
              type="text"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              value={state.phone_number.value}
              className="sa_signup_phone_number"
              error={
                isDirty &&
                ((isDirty.phone_number && state.phone_number.error) ||
                  (isDirty.country_code && state.country_code.error))
              }
            />
          </Box>
        </Box>
        <Box mb={4}>
          <InputField
            name="password"
            placeholder={t("password")}
            type="password"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            value={state.password.value}
            className="sa_signup_password"
            error={isDirty && isDirty.password && state.password.error}
          />
        </Box>
        <Box mb={4}>
          <InputField
            name="repeat_password"
            placeholder={t("confirm_password")}
            type="password"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            value={state.repeat_password.value}
            className="sa_signup_confirm_password"
            error={
              isDirty &&
              ((isDirty.repeat_password && state.repeat_password.error) ||
                (isDirty.repeat_password &&
                  isDirty.password &&
                  state.repeat_password.value !== state.password.value &&
                  t("passwords_doesn_t_match")))
            }
          />
        </Box>

        <input type="hidden" name="coupon" value={state.coupon.value} />

        <PasswordStrengthComponent
          value={state.password.value}
          touched={false}
          t={t}
        />

        <Box mb={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={
              disable || state.password.value !== state.repeat_password.value
            }
            id="btn-continue"
            className="sa_signup_continue"
          >
            {t("continue")}
          </Button>
        </Box>
        <Box></Box>
      </form>
      <Button
        variant="text"
        color="secondary"
        fullWidth
        onClick={() => {
          history.push({
            pathname: `/${locale}/signIn`,
            state: { skipAnimation: true },
          });
        }}
        className="sa_signup_already_registrated"
      >
        {t("are_you_already_registered")}
      </Button>
    </>
  );
};

export default SignUpForm;
