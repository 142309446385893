import React from 'react'
import { ReactComponent as NewZelandIcon } from '../assets/icons/new-zeland-flag.svg'
import { ReactComponent as ArgentinaIcon } from '../assets/icons/argentina-flag.svg'
import { ReactComponent as BrasilFlag } from '../assets/icons/brasil-flag.svg'
import { ReactComponent as ChileFlag } from '../assets/icons/chilie-flag.svg'
import { ReactComponent as MexicoFlag } from '../assets/icons/mexico-flag.svg'
import { ReactComponent as ColombiaFlag } from '../assets/icons/colombia-flag.svg'
import { ReactComponent as PeruFlag } from '../assets/icons/peru-flag.svg'

export const countriesFlags = {
  argentina: <ArgentinaIcon />,
  brasil: <BrasilFlag />,
  colombia: <ColombiaFlag />,
  chile: <ChileFlag />,
  mexico: <MexicoFlag />,
  peru: <PeruFlag />,
  'new zeland': <NewZelandIcon />
}