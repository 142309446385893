import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  completeCourse,
  listQuestionsWithAnswers,
  viewQuizScore,
  sendQuizAnswers,
} from "api/training";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useCourse } from "hooks/useCourse";
import { setAnswer, completeQuiz, setQuestions } from "store/actions/quiz";
import QuizScore from "./QuizScore/QuizScore";
import QuizQuestion from "./QuizQuestion/QuizQuestion";
import QuizButton from "./QuizButton/QuizButton";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    justifyContent: "space-between",
    overflow: "auto",
  },
  quizBtnWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "center",
    width: "100%",
    maxWidth: "920px",
  },
  finishQuizBtn: {
    marginLeft: "auto",
    backgroundColor: "rgba(114, 203, 87, 0.2)",
    color: "#57a022",
    "&:hover": {
      backgroundColor: "rgba(114, 203, 87, 0.3)",
    },
  },
  previousQuestionBtn: {
    "& svg.left": {
      transform: "scale(-1, 1)",
    },
  },
  nextQuestionBtn: {
    marginLeft: "auto",
    backgroundColor: "rgba(92, 194, 247, 0.8)",
    "&:hover": {
      backgroundColor: "rgba(92, 194, 247, 1)",
    },
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const CourseQuiz = ({ disableBtn }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { courseid, quizid } = useParams();
  const [fetchCourseDetails] = useCourse(courseid);
  const questions = useSelector((state) => state.quizes.questions);
  const answers = useSelector((state) => state.quizes.answers);
  const isQuizCompleted = useSelector((state) => state.quizes.isQuizCompleted);
  const isFinalQuiz = location.pathname.includes("final-quiz");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [value, setValue] = useState("");
  const [correctAnswers, setCorrectAnswers] = useState(null);
  const [total, setTotal] = useState(null);
  const [isQuizPassed, setIsQuizPassed] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    disableBtn(true);
    fetchQuizScore();
    fetchQuestions();
  }, [dispatch, quizid]);

  useEffect(() => {
    if (questions.length > 0) {
      setCurrentQuestion(questions[currentQuestionIndex]);
    }
  }, [questions, currentQuestionIndex]);

  useEffect(() => {
    if (answers.length === 0) {
      setCurrentQuestionIndex(0);
    }
    if (currentQuestion) {
      const answer = answers.find(
        (ans) => ans.question_uuid === currentQuestion.uuid
      );
      setValue(answer ? answer.answer_question_item_uuid : "");
    }
  }, [currentQuestion, answers]);

  const fetchQuizScore = async () => {
    try {
      const res = await viewQuizScore(quizid);
      if (res.count_answers) {
        setCorrectAnswers(res.count_correct_answers);
        setTotal(res.count_answers);
        setIsQuizPassed(res.is_completed);
        dispatch(completeQuiz());
        if (res.is_completed) {
          disableBtn(false);
        }
      }
    } catch (e) {
      if (e.status !== 404) {
        toast(t("an_error_occured"), { type: toast.TYPE.ERROR });
      }
    }
  };

  const fetchQuestions = async () => {
    try {
      const res = await listQuestionsWithAnswers(quizid);
      dispatch(setQuestions(res.items));
      setIsLoading(false);
    } catch (e) {
      toast(t("an_error_occured"), { type: toast.TYPE.ERROR });
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    if (currentQuestion) {
      dispatch(setAnswer(currentQuestion.uuid, newValue));
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      dispatch(completeQuiz());
    }
  };

  const handlePrevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSendAnswers = async () => {
    try {
      const res = await sendQuizAnswers({
        answers,
        quiz_uuid: quizid,
        user_course_uuid: courseid,
      });

      setCorrectAnswers(res.count_correct_answers);
      setTotal(res.count_answers);
      setIsQuizPassed(res.is_completed);
      if (res.is_completed) {
        disableBtn(false);
      }
      if (res.is_completed && isFinalQuiz) {
        await completeCourse(courseid);
        fetchCourseDetails();
      }
      dispatch(completeQuiz());
    } catch (e) {
      toast(t("an_error_occured"), { type: toast.TYPE.ERROR });
    }
  };

  return (
    <Box className={classes.container}>
      {isQuizPassed !== null && isQuizCompleted ? (
        <QuizScore
          correctAnswers={correctAnswers}
          total={total}
          isPassed={isQuizPassed}
        />
      ) : (
        <>
          {isLoading && (
            <Box className={classes.loadingContainer}>
              <CircularProgress />
            </Box>
          )}
          {!isLoading && currentQuestion && (
            <>
              <QuizQuestion
                currentQuestion={currentQuestion}
                value={value}
                handleChange={handleChange}
                currentQuestionIndex={currentQuestionIndex}
                questions={questions}
              />
              <Box mt={1} className={classes.quizBtnWrapper}>
                {currentQuestionIndex > 0 && (
                  <QuizButton
                    onClick={handlePrevQuestion}
                    variant="text"
                    text={t("retail.previous_question")}
                    startIcon="left"
                    className={classes.previousQuestionBtn}
                  />
                )}
                {currentQuestionIndex < questions.length - 1 ? (
                  <QuizButton
                    onClick={handleNextQuestion}
                    disabled={value === ""}
                    text={t("retail.next_question")}
                    endIcon="right"
                    className={classes.nextQuestionBtn}
                  />
                ) : (
                  <QuizButton
                    onClick={handleSendAnswers}
                    disabled={value === ""}
                    text={t("retail.finish_quiz")}
                    className={classes.finishQuizBtn}
                  />
                )}
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default CourseQuiz;
