import React from 'react'

import { ReactComponent as RouterIcon } from '../../../assets/icons/router.svg'
import { ReactComponent as IconShield } from '../../../assets/icons/shield.svg'
import { ReactComponent as VulnerabilityIcon } from '../../../assets/icons/malware_icon.svg'
import { ReactComponent as DataBreachIcon } from '../../../assets/icons/data_breach_feature.svg'

export const featuresIcons = {
  vulnerability_scan: <VulnerabilityIcon width='70px' height='70px' />,
  data_breach_scanner: <DataBreachIcon />,
  security_scan: <IconShield />,
  router_scan: <RouterIcon width='70px' height='70px' />,
}