import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCourseItemById } from "store/selectors/course";
import Box from "@material-ui/core/Box";
import MDRender from "components/MDRender";
const useStyles = makeStyles((theme) => ({
  textLessonWrapper: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    padding: theme.spacing(0, 4),
  },
  markdownWrapper: {
    "& ol": {
      listStyle: "decimal",
    },
    "& ul li::before": {
      color: "initial",
    },
  },
}));

const TextLesson = () => {
  const classes = useStyles();
  const { lessonid } = useParams();
  const lesson = useSelector((state) => getCourseItemById(state, lessonid));

  return (
    <Box className={classes.textLessonWrapper}>
      {lesson.content && (
        <MDRender input={lesson.content} className={classes.markdownWrapper} />
      )}
    </Box>
  );
};

export default TextLesson;