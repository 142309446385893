import React from 'react'
import Form from './Form'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'

const AddItem = ({ t, addEmail }) => {
  return (
    <Paper elevation={2} className='addItem'>
      <Box p={3}>
        <Form addEmail={addEmail} t={t} />
      </Box>
    </Paper>
  )
}
export default AddItem
