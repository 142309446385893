/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CourseStatisticResponseItem from './CourseStatisticResponseItem';
import CxCollectionMeta from './CxCollectionMeta';

/**
 * The CourseStatisticResponse model module.
 * @module model/CourseStatisticResponse
 * @version 1.0.0
 */
class CourseStatisticResponse {
    /**
     * Constructs a new <code>CourseStatisticResponse</code>.
     * @alias module:model/CourseStatisticResponse
     * @param items {Array.<module:model/CourseStatisticResponseItem>} 
     * @param meta {module:model/CxCollectionMeta} 
     */
    constructor(items, meta) { 
        
        CourseStatisticResponse.initialize(this, items, meta);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, items, meta) { 
        obj['items'] = items;
        obj['meta'] = meta;
    }

    /**
     * Constructs a <code>CourseStatisticResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CourseStatisticResponse} obj Optional instance to populate.
     * @return {module:model/CourseStatisticResponse} The populated <code>CourseStatisticResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CourseStatisticResponse();

            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], [CourseStatisticResponseItem]);
            }
            if (data.hasOwnProperty('meta')) {
                obj['meta'] = CxCollectionMeta.constructFromObject(data['meta']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CourseStatisticResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CourseStatisticResponse</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CourseStatisticResponse.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        if (data['items']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['items'])) {
                throw new Error("Expected the field `items` to be an array in the JSON data but got " + data['items']);
            }
            // validate the optional field `items` (array)
            for (const item of data['items']) {
                CourseStatisticResponseItem.validateJSON(item);
            };
        }
        // validate the optional field `meta`
        if (data['meta']) { // data not null
          CxCollectionMeta.validateJSON(data['meta']);
        }

        return true;
    }


}

CourseStatisticResponse.RequiredProperties = ["items", "meta"];

/**
 * @member {Array.<module:model/CourseStatisticResponseItem>} items
 */
CourseStatisticResponse.prototype['items'] = undefined;

/**
 * @member {module:model/CxCollectionMeta} meta
 */
CourseStatisticResponse.prototype['meta'] = undefined;






export default CourseStatisticResponse;

