import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ProgressBar from "components/ProgressBar";

const useStyles = makeStyles((theme) => ({
  progressWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const CourseProgressBar = ({ course }) => {
  const classes = useStyles();

  return (
    <Box className={classes.progressWrapper}>
      {course && (
        <>
          <ProgressBar
            value={course.count_items_completed}
            maxValue={course.count_items_to_do}
          />
          <Box ml={1}>
            <Typography variant="subtitle1" component="span">
              {course.count_items_completed}/{course.count_items_to_do}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CourseProgressBar;