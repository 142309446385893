import { useState } from 'react'
import SRS from "../helpers/systemResourcesScanner";

import deviceApi from "api/device";
import { getItem } from "helpers/localStorage";
import { Log } from "services/log/logger";

const logNewDeviceInfo = (systemData, devices) => {
  Log.info("New device identified", {
    systemFingerprint: systemData.fingerprint,
    systemFingerprintV2: systemData.fingerprint_v2,
    lsFingerprint: getItem("fingerprint"),
    devices,
    os: systemData.meta.os.product,
    fingerprint_meta: systemData.fingerprint_meta,
  });
}

function useDeviceDetection(checkIfNeedToSkip, bulildSelectOptions) {
  const [userDevices, setUserDevices] = useState(null)
  let systemData = SRS.run({});

  const addDevice = (device) => {
    return deviceApi.addDevice(device)
  }

  const findUserDevice = async (devices) => {
    for (let i = 0; i < devices.items.length; i++) {
      const item = devices.items[i]
      const fpBrowser = item.fingerprint_browser;
      const { fingerprint, fingerprint_v2 } = systemData;

      if (fpBrowser === fingerprint_v2) {
        return item
      }

      if (fpBrowser === fingerprint) {
        try {
          const deviceData = { ...item, fingerprint: systemData.fingerprint_v2, scan_by: "browser" }
          await deviceApi.updateDevice(deviceData, item.uuid)
          return item
        } catch (err) {
          return null
        }
      }
    }
  }

  const detectNewDevice = async (callback) => {
    const skipped = checkIfNeedToSkip(systemData.fingerprint_v2)
    try {
      const devices = await deviceApi.getDevices()
      setUserDevices(devices.items);
      const device = await findUserDevice(devices)
      // if the current device is not in the list of devices and not in skipped devices,
      // then display a modal window to add new device
      if (!device && !skipped) {
        logNewDeviceInfo(systemData, devices)
        if (typeof callback === 'function') {
          callback()
        }
      }
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        console.log(err)
      }
    }
  };


  return { userDevices, addDevice, detectNewDevice }

}

export default useDeviceDetection;