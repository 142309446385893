/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import RepositoryCourseProgressStatus from './RepositoryCourseProgressStatus';
import RepositoryDifficultyLevel from './RepositoryDifficultyLevel';
import TimeDuration from './TimeDuration';

/**
 * The UserCourseIndexResponseItem model module.
 * @module model/UserCourseIndexResponseItem
 * @version 1.0.0
 */
class UserCourseIndexResponseItem {
    /**
     * Constructs a new <code>UserCourseIndexResponseItem</code>.
     * @alias module:model/UserCourseIndexResponseItem
     * @param category {String} 
     * @param completionDate {Number} 
     * @param countItemsCompleted {Number} 
     * @param countItemsToDo {Number} 
     * @param courseSettingUuid {String} 
     * @param courseUuid {String} 
     * @param createdAt {Number} 
     * @param description {String} 
     * @param difficulty {module:model/RepositoryDifficultyLevel} 
     * @param duration {module:model/TimeDuration} 
     * @param imageUrl {String} 
     * @param profileUuid {String} 
     * @param progressStatus {module:model/RepositoryCourseProgressStatus} 
     * @param quizzesCount {Number} 
     * @param title {String} 
     * @param uuid {String} 
     * @param videoLessonsCount {Number} 
     */
    constructor(category, completionDate, countItemsCompleted, countItemsToDo, courseSettingUuid, courseUuid, createdAt, description, difficulty, duration, imageUrl, profileUuid, progressStatus, quizzesCount, title, uuid, videoLessonsCount) { 
        
        UserCourseIndexResponseItem.initialize(this, category, completionDate, countItemsCompleted, countItemsToDo, courseSettingUuid, courseUuid, createdAt, description, difficulty, duration, imageUrl, profileUuid, progressStatus, quizzesCount, title, uuid, videoLessonsCount);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, category, completionDate, countItemsCompleted, countItemsToDo, courseSettingUuid, courseUuid, createdAt, description, difficulty, duration, imageUrl, profileUuid, progressStatus, quizzesCount, title, uuid, videoLessonsCount) { 
        obj['category'] = category;
        obj['completion_date'] = completionDate;
        obj['count_items_completed'] = countItemsCompleted;
        obj['count_items_to_do'] = countItemsToDo;
        obj['course_setting_uuid'] = courseSettingUuid;
        obj['course_uuid'] = courseUuid;
        obj['created_at'] = createdAt;
        obj['description'] = description;
        obj['difficulty'] = difficulty;
        obj['duration'] = duration;
        obj['image_url'] = imageUrl;
        obj['profile_uuid'] = profileUuid;
        obj['progress_status'] = progressStatus;
        obj['quizzes_count'] = quizzesCount;
        obj['title'] = title;
        obj['uuid'] = uuid;
        obj['video_lessons_count'] = videoLessonsCount;
    }

    /**
     * Constructs a <code>UserCourseIndexResponseItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserCourseIndexResponseItem} obj Optional instance to populate.
     * @return {module:model/UserCourseIndexResponseItem} The populated <code>UserCourseIndexResponseItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserCourseIndexResponseItem();

            if (data.hasOwnProperty('category')) {
                obj['category'] = ApiClient.convertToType(data['category'], 'String');
            }
            if (data.hasOwnProperty('completion_date')) {
                obj['completion_date'] = ApiClient.convertToType(data['completion_date'], 'Number');
            }
            if (data.hasOwnProperty('count_items_completed')) {
                obj['count_items_completed'] = ApiClient.convertToType(data['count_items_completed'], 'Number');
            }
            if (data.hasOwnProperty('count_items_to_do')) {
                obj['count_items_to_do'] = ApiClient.convertToType(data['count_items_to_do'], 'Number');
            }
            if (data.hasOwnProperty('course_setting_uuid')) {
                obj['course_setting_uuid'] = ApiClient.convertToType(data['course_setting_uuid'], 'String');
            }
            if (data.hasOwnProperty('course_uuid')) {
                obj['course_uuid'] = ApiClient.convertToType(data['course_uuid'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Number');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('difficulty')) {
                obj['difficulty'] = ApiClient.convertToType(data['difficulty'], RepositoryDifficultyLevel);
            }
            if (data.hasOwnProperty('duration')) {
                obj['duration'] = TimeDuration.constructFromObject(data['duration']);
            }
            if (data.hasOwnProperty('image_url')) {
                obj['image_url'] = ApiClient.convertToType(data['image_url'], 'String');
            }
            if (data.hasOwnProperty('profile_uuid')) {
                obj['profile_uuid'] = ApiClient.convertToType(data['profile_uuid'], 'String');
            }
            if (data.hasOwnProperty('progress_status')) {
                obj['progress_status'] = ApiClient.convertToType(data['progress_status'], RepositoryCourseProgressStatus);
            }
            if (data.hasOwnProperty('quizzes_count')) {
                obj['quizzes_count'] = ApiClient.convertToType(data['quizzes_count'], 'Number');
            }
            if (data.hasOwnProperty('started_at')) {
                obj['started_at'] = ApiClient.convertToType(data['started_at'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Number');
            }
            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
            if (data.hasOwnProperty('video_lessons_count')) {
                obj['video_lessons_count'] = ApiClient.convertToType(data['video_lessons_count'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UserCourseIndexResponseItem</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UserCourseIndexResponseItem</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of UserCourseIndexResponseItem.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['category'] && !(typeof data['category'] === 'string' || data['category'] instanceof String)) {
            throw new Error("Expected the field `category` to be a primitive type in the JSON string but got " + data['category']);
        }
        // ensure the json data is a string
        if (data['course_setting_uuid'] && !(typeof data['course_setting_uuid'] === 'string' || data['course_setting_uuid'] instanceof String)) {
            throw new Error("Expected the field `course_setting_uuid` to be a primitive type in the JSON string but got " + data['course_setting_uuid']);
        }
        // ensure the json data is a string
        if (data['course_uuid'] && !(typeof data['course_uuid'] === 'string' || data['course_uuid'] instanceof String)) {
            throw new Error("Expected the field `course_uuid` to be a primitive type in the JSON string but got " + data['course_uuid']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['image_url'] && !(typeof data['image_url'] === 'string' || data['image_url'] instanceof String)) {
            throw new Error("Expected the field `image_url` to be a primitive type in the JSON string but got " + data['image_url']);
        }
        // ensure the json data is a string
        if (data['profile_uuid'] && !(typeof data['profile_uuid'] === 'string' || data['profile_uuid'] instanceof String)) {
            throw new Error("Expected the field `profile_uuid` to be a primitive type in the JSON string but got " + data['profile_uuid']);
        }
        // ensure the json data is a string
        if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
            throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
        }
        // ensure the json data is a string
        if (data['uuid'] && !(typeof data['uuid'] === 'string' || data['uuid'] instanceof String)) {
            throw new Error("Expected the field `uuid` to be a primitive type in the JSON string but got " + data['uuid']);
        }

        return true;
    }


}

UserCourseIndexResponseItem.RequiredProperties = ["category", "completion_date", "count_items_completed", "count_items_to_do", "course_setting_uuid", "course_uuid", "created_at", "description", "difficulty", "duration", "image_url", "profile_uuid", "progress_status", "quizzes_count", "title", "uuid", "video_lessons_count"];

/**
 * @member {String} category
 */
UserCourseIndexResponseItem.prototype['category'] = undefined;

/**
 * @member {Number} completion_date
 */
UserCourseIndexResponseItem.prototype['completion_date'] = undefined;

/**
 * @member {Number} count_items_completed
 */
UserCourseIndexResponseItem.prototype['count_items_completed'] = undefined;

/**
 * @member {Number} count_items_to_do
 */
UserCourseIndexResponseItem.prototype['count_items_to_do'] = undefined;

/**
 * @member {String} course_setting_uuid
 */
UserCourseIndexResponseItem.prototype['course_setting_uuid'] = undefined;

/**
 * @member {String} course_uuid
 */
UserCourseIndexResponseItem.prototype['course_uuid'] = undefined;

/**
 * @member {Number} created_at
 */
UserCourseIndexResponseItem.prototype['created_at'] = undefined;

/**
 * @member {String} description
 */
UserCourseIndexResponseItem.prototype['description'] = undefined;

/**
 * @member {module:model/RepositoryDifficultyLevel} difficulty
 */
UserCourseIndexResponseItem.prototype['difficulty'] = undefined;

/**
 * @member {module:model/TimeDuration} duration
 */
UserCourseIndexResponseItem.prototype['duration'] = undefined;

/**
 * @member {String} image_url
 */
UserCourseIndexResponseItem.prototype['image_url'] = undefined;

/**
 * @member {String} profile_uuid
 */
UserCourseIndexResponseItem.prototype['profile_uuid'] = undefined;

/**
 * @member {module:model/RepositoryCourseProgressStatus} progress_status
 */
UserCourseIndexResponseItem.prototype['progress_status'] = undefined;

/**
 * @member {Number} quizzes_count
 */
UserCourseIndexResponseItem.prototype['quizzes_count'] = undefined;

/**
 * @member {Number} started_at
 */
UserCourseIndexResponseItem.prototype['started_at'] = undefined;

/**
 * @member {String} title
 */
UserCourseIndexResponseItem.prototype['title'] = undefined;

/**
 * @member {Number} updated_at
 */
UserCourseIndexResponseItem.prototype['updated_at'] = undefined;

/**
 * @member {String} uuid
 */
UserCourseIndexResponseItem.prototype['uuid'] = undefined;

/**
 * @member {Number} video_lessons_count
 */
UserCourseIndexResponseItem.prototype['video_lessons_count'] = undefined;






export default UserCourseIndexResponseItem;

