import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Page from "components/Page";
import useFlagSettings from "../../hooks/useFlagSettings";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { CardTitle, BodyText } from "../../components/UI";
import { ReactComponent as IconDotWithVerticalLine } from "../../assets/icons/icon-dot-with-dashed-line.svg";
import { makeStyles } from "@material-ui/core";
import MDRender from "../../components/MDRender";

const useStyles = makeStyles((theme) => ({
  icon: {
    textAlign: "center",
    color: theme.palette.primary.main,
    "& svg": {
      fontSize: "10rem",
    },
  },
}));

const StartScan = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { locale } = useParams();
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const { updateUserSetting, flagsMap } = useFlagSettings();
  let sectionHeaderName = partnerSettings ? partnerSettings.partnerName : null;

  const partnerCustomHeader = partnerSettings.partnerCustomHeader;
  const isScansCustomHeader = partnerSettings.isScansCustomHeader;

  const goToScanningPage = () => {
    updateUserSetting(flagsMap.WELCOME_SCREEN_SHOWN, true);
    history.push(`/${locale}/scanning`);
  };

  return (
    <Page withSubHeader name="startScan">
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        >
        {partnerCustomHeader && isScansCustomHeader && (
          <MDRender t={t} input={partnerCustomHeader} escapeHtml={false} />
        )}
        <Box maxWidth="480px">
          <CardTitle textAlign="center">
            {t("welcome_to_dynarisk", { name: sectionHeaderName })}
          </CardTitle>
          <BodyText textAlign="center">{t("run_some_scans_to_check")}</BodyText>
        </Box>
        <i className={classes.icon}>
          <IconDotWithVerticalLine />
        </i>
        <Box textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={goToScanningPage}
            className="sa_welcome_start_scan"
          >
            {t("start_scan")}
          </Button>
        </Box>
      </Box>
    </Page>
  );
};

export default StartScan;
