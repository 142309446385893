import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

import { InputField } from '../../../../components/UI'
import useForm from '../../../../hooks/useForm'
import { makeStyles } from '@material-ui/core/styles'
import { isLuhnCompatible, isCreditCard } from '../../../../helpers/validators'

const stateSchema = {
  credit_card_number: { value: '', error: '' },
};

const useStyles = makeStyles((theme) => ({
  addCreditCardBtn: {
    marginRight: theme.variables.$isRTLDirection ? "16px" : "0",
    marginLeft: theme.variables.$isRTLDirection ? "0" : "16px",
  }
}))

const Form = ({ addCard, t }) => {
  const classes = useStyles()

  const handleSubmit = () => {
    addCard(state.credit_card_number.value)
      .then(errors => {
        if (errors) {
          setInitialState({ ...initialState, errors });
        } else {
          setInitialState({ data: null, errors: null })
        }
      })
  }

  const validationStateSchema = {
    credit_card_number: {
      required: true,
      validator: [
        { func: isCreditCard, error: t("card_must_contains") },
        { func: isLuhnCompatible, error: t('invalid_credit_card_number') },
      ],
    },
  };

  const [initialState, setInitialState] = useState({
    data: null,
    errors: null,
  });

  const { state, handleOnChange, handleOnBlur, handleOnSubmit, disable, isDirty } = useForm(
    initialState,
    stateSchema,
    null,
    validationStateSchema,
    handleSubmit,
  );

  return (
    <form onSubmit={handleOnSubmit}>
      <Box width="100%" display='flex'>
        <Box width="100%">
          <InputField
            name='credit_card_number'
            placeholder={t("add_credit_card")}
            type='text'
            className='sa_monitored_data_card'
            value={state.credit_card_number.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={isDirty && isDirty.credit_card_number && state.credit_card_number.error}
          />
        </Box>
        <Box flexShrink={0}>
          <Button
            style={{padding: '8px 20px'}}
            type='submit'
            variant='contained'
            color='primary'
            className={`sa_monitored_data_add_card ${classes.addCreditCardBtn}`}
            disabled={!state.credit_card_number.value}
          >
            {t("add_card")}
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default Form
