import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as LockedIcon } from "assets/icons/d.svg";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  typoBold: {
    fontWeight: 600,
  },
  listIcon: { minWidth: "inherit" },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(4),
  },
}));

const SidebarListItem = ({
  item,
  isSelected = false,
  handleItemClick,
  type = "item",
}) => {
  const classes = useStyles();

  if (type === "lockedSection") {
    return (
      <ListItem button disabled className={classes.listItem}>
        <ListItemText
          primary={
            <Typography variant="subtitle1">{item.section_title}</Typography>
          }
          disableTypography
        />
        <ListItemIcon className={classes.listIcon}>
          <LockedIcon />
        </ListItemIcon>
      </ListItem>
    );
  }

  return (
    <ListItem
      onClick={() => handleItemClick(item)}
      button
      className={classes.listItem}
      disabled={!item.isUnlocked}
      key={item.uuid}
      selected={isSelected}
    >
      <ListItemText
        primary={<Typography variant="subtitle2">{item.title}</Typography>}
        disableTypography
      />
      {!item.isUnlocked && (
        <ListItemIcon className={classes.listIcon}>
          <LockedIcon />
        </ListItemIcon>
      )}
    </ListItem>
  );
};

export default SidebarListItem;
