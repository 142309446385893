import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import API from "api/user";
import Form from "./Form";
import { RefreshTokenService } from "../../../../services/authentication/RefreshTokenService";
import { AuthContext } from "../../../../contexts/AuthContext";
import { CardTitle, Card } from "../../../../components/UI";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";

const ProfilePersonalInformation = () => {
  const { t } = useTranslation();
  const identity = useSelector((state) => state.identity);
  const { setJWT } = useContext(AuthContext);
  const email = identity && identity.user.email;

  const editInfo = (personalInfo) => {
    API.editPersonalInfo(personalInfo)
      .then((response) => {
        toast(t("personal_info_changed"), { type: toast.TYPE.INFO });
        RefreshTokenService.refresh().then((response) => {
          setJWT(response.data.auth);
        });
      })
      .catch((err) => {
        toast(t("can_t_change_personal_info"), { type: toast.TYPE.ERROR });
      });
  };

  return (
    <Box mb={5} className="personalInformation">
      <Box my={4}>
        <CardTitle variant="h2" mb={0}>
          {t("personal_information")}
        </CardTitle>
      </Box>
      <Card>
        <Form editInfo={editInfo} t={t} email={email} identity={identity} />
      </Card>
    </Box>
  );
};

export default ProfilePersonalInformation;
