import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { CardTitle } from "components/UI";
import CertificatesList from "./CertificatesList/CertificatesList";
import TaskList from "./TasksList/TasksList";

export const Training = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { activeTab, locale } = useParams();

  const handleChange = (evt, newValue) => {
    history.push(`/${locale}/training/${newValue}`);
  };

  function a11yProps(index) {
    return {
      id: `profile-data-tab-${index}`,
      "aria-controls": `profile-data-tabpanel-${index}`,
    };
  }

  function MuiTabPanel(props) {
    const { children, value, name, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== name}
        id={`profile-data-tabpanel-${name}`}
        aria-labelledby={`profile-data-tab-${name}`}
        {...other}
      >
        {value === name && children}
      </div>
    );
  }

  return (
    <Box>
      <CardTitle mt={3} textAlign="inherit">
        {t("retail.training.training")}
      </CardTitle>
      <AppBar position="static" elevation={0} color="transparent">
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="training tabs"
        >
          <Tab
            value="tasks"
            label={t("retail.training.tasks")}
            wrapped
            {...a11yProps("tasks")}
          />
          <Tab
            value="certificates"
            label={t("retail.training.certificates")}
            wrapped
            {...a11yProps("certificates")}
          />
        </Tabs>
      </AppBar>
      {activeTab === "tasks" && (
        <MuiTabPanel>
          <TaskList />
        </MuiTabPanel>
      )}
      {activeTab === "certificates" && (
        <MuiTabPanel>
          <CertificatesList />
        </MuiTabPanel>
      )}
    </Box>
  );
};
