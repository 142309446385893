import {
  ASSESSMENT_ALMOST_DONE_STEP,
  ASSESSMENT_CONNECTED_STEP,
  ASSESSMENT_CONNECTING_STEP,
  ASSESSMENT_DONE_STEP,
  ASSESSMENT_GET_QUESTION_STEP,
  ASSESSMENT_RESET_STEP,
  ASSESSMENT_SUBMIT_STEP,
} from 'constants/assessmentSteps'

export const actionTypes = {
  ASSESSMENT_ALMOST_DONE: 'ASSESSMENT_ALMOST_DONE',
  ASSESSMENT_CONNECTED: 'ASSESSMENT_CONNECTED',
  ASSESSMENT_CONNECTING: 'ASSESSMENT_CONNECTING',
  ASSESSMENT_DONE: 'ASSESSMENT_DONE',
  ASSESSMENT_GET_QUESTION: 'ASSESSMENT_GET_QUESTION',
  ASSESSMENT_RESET: 'ASSESSMENT_RESET',
  ASSESSMENT_SUBMIT: 'ASSESSMENT_SUBMIT',
}


const assessmentReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ASSESSMENT_ALMOST_DONE:
      return { currentStep: ASSESSMENT_ALMOST_DONE_STEP }
    case actionTypes.ASSESSMENT_CONNECTED:
      return { currentStep: ASSESSMENT_CONNECTED_STEP }
    case actionTypes.ASSESSMENT_RESET:
      return { currentStep: ASSESSMENT_RESET_STEP }
    case actionTypes.ASSESSMENT_CONNECTING:
      return { currentStep: ASSESSMENT_CONNECTING_STEP }
    case actionTypes.ASSESSMENT_DONE:
      return { currentStep: ASSESSMENT_DONE_STEP }
    case actionTypes.ASSESSMENT_SUBMIT:
      return {
        ...state,
        data: action.payload,
        currentStep: ASSESSMENT_SUBMIT_STEP
      }
    case actionTypes.ASSESSMENT_GET_QUESTION:
      return {
        ...state,
        ...action.payload,
        currentStep: ASSESSMENT_GET_QUESTION_STEP,
      }
    default:
      return state
  }
}

export default assessmentReducer
