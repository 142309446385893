import React, { useState } from "react";
import { SelectField } from "../";
import { getLanguageFiles } from "../../../i18n/languageProvider";
import { useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { setItem } from "../../../helpers/localStorage";

const getLabel = (lang) => {
  const flagCode = lang.language.split("_")[1].toLowerCase();
  return (
    <div style={{ alignItems: "center", display: "flex" }}>
      <span
        className={`fi fi-${flagCode}`}
        style={{ marginRight: "8px" }}
      ></span>
      <span>{lang.label}</span>
    </div>
  );
};

export default function LanguageSelect() {
  const { locale } = useParams();
  const history = useHistory();
  const location = useLocation();
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const partnerLang =
    partnerSettings.languagesAvailable &&
    partnerSettings.languagesAvailable.find((lang) => lang.language === locale);
  const [selectedLang, setSelectedLang] = useState(partnerLang);

  const handleLangChange = (evt) => {
    const lang = evt.target.value;
    setSelectedLang(lang);
    setItem("selectedLang", lang.language);
    getLanguageFiles(lang.language, partnerSettings);
    const currentPath = location.pathname.replace(`/${locale}`, "");
    history.push(`/${lang.language}${currentPath}`);
  };

  // check if there is only one language available
  if (
    !partnerSettings.languagesAvailable ||
    !partnerSettings.languagesAvailable.length > 1
  ) {
    return null;
  }

  return (
    <Box
      style={{
        display: partnerLang !== undefined ? "flex" : "none",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        maxWidth: "980px",
        margin: "0 auto",
      }}
    >
      <Box flexShrink="0" flexBasis="150px">
        <SelectField
          isArrowVisible={false}
          variant="transparent"
          name="selectLanguage"
          formatOptionLabel={getLabel}
          options={partnerSettings.languagesAvailable}
          value={selectedLang}
          className="sa_signup_country_code"
          onChange={handleLangChange}
          optionValue="language"
        />
      </Box>
    </Box>
  );
}
