import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'

const PublicRoute = ({ match, path, name, partnerSettings, component: Component }) => {
  if ((name === 'HOME' || name === 'SIGN_IN' || name === 'SIGN_UP')) {
    if (partnerSettings.isCrossServicePartner) {
      return <Redirect to={`/${match.params.locale}/signout`} />
    }
  }
  return (
    <Route
      path={path}
      render={(props) => (
        <Component {...props}>
          {props.children}
        </Component>
      )
      }
    />
  )
}

export default withRouter(PublicRoute)
