import React from "react";
import Page from "components/Page";
import { PoliciesList } from "./PoliciesList/PoliciesList";
import { useIdentityFeature } from "hooks/useIdentiyFeature";
import { useSelector } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import { PROFILE_TYPE_STAFF } from "constants/common";

const PoliciesPage = () => {
  const identity = useSelector((state) => state.identity);
  const hasPoliciesFeature = useIdentityFeature(identity, "sme_policy_module");
  const { locale } = useParams();

  if (
    identity &&
    !hasPoliciesFeature &&
    identity.profile.profile_type.toUpperCase() !== PROFILE_TYPE_STAFF
  ) return <Redirect to={`/${locale}/not-found`} />
  

  return (
    <Page name="policies" withAside withSubHeader>
      <PoliciesList />
    </Page>
  );
};

export default PoliciesPage;
