import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { CardTitle } from "components/UI";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as CertificatePlaceholder } from "assets/icons/certificate_placeholder.svg";
import { useHistory, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  certificateCard: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  placeholder: {
    display: "flex",
    "& svg": {
      maxHeight: "140px",
    },
  },
  contentWrapper: {
    flexGrow: 1,
  },
  btn: {
    width: "100%",
  },
}));

const CertificateCard = ({ certificateData }) => {
  const classes = useStyles();
  const history = useHistory();
  const { locale } = useParams();
  const { t } = useTranslation();

  const handleRedirect = () => {
    history.push(
      `/${locale}/training/course/${certificateData.course_uuid}/certificate`
    );
  };

  return (
    <Paper className={classes.certificateCard}>
      <Box className={classes.placeholder}>
        <CertificatePlaceholder />
      </Box>
      <Box mt={2} mb={1} className={classes.contentWrapper}>
        <CardTitle mb={0} variant="h2" textAlign="inherit">
          {certificateData.course_title}
        </CardTitle>
      </Box>
      <Typography variant="caption">
        {certificateData.course_end_date}
      </Typography>
      <Box mt={3}>
        <Button
          onClick={handleRedirect}
          size="small"
          variant="contained"
          color="primary"
          className={classes.btn}
        >
          {t("retail.show_certificate")}
        </Button>
      </Box>
    </Paper>
  );
};

export default CertificateCard;
