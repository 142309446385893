import React from "react";
import TermsAndConditionsContent from "./TermsAndConditionsContent";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { CardTitle } from "../../components/UI";

const TermsWrapper = ({ t, locale, tacData, register, setIsValid }) => {
  return (
    <>
      <CardTitle variant="h2" mb={0}>
        {t("terms_and_conditions")}
      </CardTitle>
      <div className="separated">
        <TermsAndConditionsContent t={t} locale={locale} tacData={tacData} />
        <Box mt={4} mx={-1} display="flex">
          <Box mx={1}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              id="sa_signup_decline"
              onClick={() => register("INIT")}
            >
              {t("decline")}
            </Button>
          </Box>
          <Box mx={1} flexGrow={1}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              id="btn-register-confirm"
              onClick={() => {
                setIsValid(true);
              }}
            >
              {t("accept_and_continue")}
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default TermsWrapper;
