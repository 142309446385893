
export const getCourseItemById = (state, uuid) => {
  return state.course.courseItems.find((item) => item.uuid === uuid);
};

export const getNextCourseItem = (state, uuid) => {
  const currentIndex = state.course.courseItems.findIndex(
    (item) => item.uuid === uuid
  );
  if (
    currentIndex === -1 ||
    currentIndex === state.course.courseItems.length - 1
  ) {
    return null;
  }

  return state.course.courseItems[currentIndex + 1];
};

export const getPreviousCourseItem = (state, uuid) => {
  const currentIndex = state.course.courseItems.findIndex(
    (item) => item.uuid === uuid
  );
  if (currentIndex <= 0) {
    return null;
  }
  return state.course.courseItems[currentIndex - 1];
};

export const divideCourseItemsIntoSections = (t, state) => {
  const chaptersMap = state.course.courseItems.reduce((acc, item) => {
    const chapterUuid = item.chapter_uuid || "course_summary";
    const sectionTitle = item.chapter_title || t("retail.course_summary");
    if (!acc[chapterUuid]) {
      acc[chapterUuid] = {
        chapter_uuid: chapterUuid,
        section_title: sectionTitle,
        isUnlocked: false,
        items: [],
      };
    }
    if (!acc[chapterUuid].isUnlocked && item.isUnlocked) {
      acc[chapterUuid].isUnlocked = true;
    }
    acc[chapterUuid].items.push(item);
    return acc;
  }, {});
  return Object.values(chaptersMap);
}

export const getLastUnlockedItem = (state) => {
  const courseItems = state.course.courseItems;

  for (let i = courseItems.length - 1; i >= 0; i--) {
    if (courseItems[i].isUnlocked === true) {
      return courseItems[i];
    }
  }
  return null; 
}