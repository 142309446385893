import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCourse } from "../store/actions/course";
import { viewUserCourse } from "../api/training";
import { parseCourseItems } from "pages/Training/utils/parseCourseItems";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const useCourse = (uuid) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    fetchCourseDetails();
  }, [dispatch, uuid]);

  const fetchCourseDetails = async () => {
    try {
      const res = await viewUserCourse(uuid);
      dispatch(setCourse(res));
      parseCourseItems(res, dispatch);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toast(t("an_error_occured"), { type: toast.TYPE.ERROR });
    }
  };

  return [fetchCourseDetails, isLoading];
};
