import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  ldsRipple: {
    display: 'inlineBlock',
    position: 'relative',
    width: '220px',
    height: '220px',
  },
  ring: {
    position: 'absolute',
    border: `4px solid ${theme.variables.$black}`,
    opacity: '1',
    borderRadius: '50%',
    animation: '$ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite',
    '&.withDelay': {
      animationDelay: '-0.5s'
    }
  },
  iconWrapper: {
    '& svg': {
      width: '90px',
      height: '90px'
    }
  },

  "@keyframes ripple": {
    "0%": {
      top: '35px',
      left: '35px',
      width: '150px',
      height: '150px',
      opacity: 0
    },
    "4.9%": {
      top: '35px',
      left: '35px',
      width: '150px',
      height: '150px',
      opacity: 0
    },
    "5%": {
      top: '35px',
      left: '35px',
      width: '150px',
      height: '150px',
      opacity: 1,
    },
    "100%": {
      top: '0px',
      left: '0px',
      width: '220px',
      height: '220px',
      opacity: 0
    }
  }
}))

const RippleLoading = ({ icon, mb=0 }) => {
  const classes = useStyles()
  return (
    <Box mb={mb} display='flex' textAlign='center' justifyContent='center'>
      <div class={classes.ldsRipple}>
        <div className={classes.ring} />
        <div className={`${classes.ring} withDelay`} />
        <span style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
          <div className={classes.iconWrapper}>
            {icon}
          </div>
          {/* <EyeIcon width='90px' height='90px' /> */}
        </span>
      </div>
    </Box>
  )
}

export default RippleLoading;