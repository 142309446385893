import isEmail from 'validator/lib/isEmail'

const isLuhnCompatible = (function (arr) {
  return function (ccNum) {
    let
      len = ccNum.length,
      bit = 1,
      sum = 0,
      val;

    while (len) {
      val = parseInt(ccNum.charAt(--len), 10);
      sum += (bit ^= 1) ? arr[val] : val;
    }

    return sum && sum % 10 === 0;
  };
}([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]));

const isCreditCard = (cardNumber, conditions = { minLength: 12, maxLength: 16 }) => {
  const regexp = /^\d*$/
  if (cardNumber.length < conditions.minLength || cardNumber.length > conditions.maxLength || !regexp.test(cardNumber)) {
    return false
  }
  return true
}

const isPhoneNumber = (cardNumber, conditions = { minLength: 5, maxLength: 14 }) => {
  const regexp = /^\d*$/
  if (cardNumber.length < conditions.minLength || cardNumber.length > conditions.maxLength || !regexp.test(cardNumber)) {
    return false
  }
  return true
}

const isValidString = (str) => {
  const regexp = /^[\u{00C0}-\u{01FF}\u{0400}-\u{04FF}a-zA-Z\s'\-]+$/u
  if (!regexp.test(str)) {
    return false
  }
  return true
}

const isStringMinMaxLength = (str, min, max) => {
  if (min && str.length < min) {
    return false
  }
  if (max && str.length > max) {
    return false
  }
  return true
}

export {
  isLuhnCompatible,
  isCreditCard,
  isEmail,
  isPhoneNumber,
  isValidString,
  isStringMinMaxLength
}