import { PLAN_EXPIRED, SET_CURRENT_PLAN, SET_CURRENT_PLAN_FEATURES, SET_PLANS } from '../constants'

export const setExpiredPlan = (isExpired) => (
  { type: PLAN_EXPIRED, payload: {isExpired} }
)

export const setCurrentPlan = plan => {
  return { type: SET_CURRENT_PLAN, payload: plan }
}

export const setCurrentPlanFeatures = planFeatures => (
  { type: SET_CURRENT_PLAN_FEATURES, payload: planFeatures }
)

export const setPlans = payload => ({ type: SET_PLANS, payload })
