import React, { useContext, useEffect } from "react";
import { SubHeaderContext } from "components/SubHeader/SubHeaderContext";
import Page from "components/Page";
import { useActionsContext } from "contexts/ActionsContext";

import TodoPage from "./TodoPage";

const TodoPageContainer = () => {
  const { subHeaderContext, useSubHeaderContext } =
    useContext(SubHeaderContext);
  const { actions, deviceActions, actionsLoading, saveFilters, filters } =
    useActionsContext();
  const setActionFilters = (newFilter) => {
    if (newFilter.priority && newFilter.priority === "all") {
      const updatedFilters = { ...filters, priority: "all", currentPage: 1 };
      saveFilters(updatedFilters);
      return;
    }
    saveFilters({ ...filters, ...newFilter, currentPage: 1 });
  };

  useEffect(() => {
    const nextState = {
      ...subHeaderContext,
      ...{
        isVisible: true,
      },
    };
    useSubHeaderContext(nextState);
    saveFilters({
      // status: ["to_be_completed", "overdue", "postponed"],
      status: "to_be_completed",
      device_uuid: "all",
      priority: "all",
    });
  }, []);

  return (
    <Page name="todoTasks" withAside withSubHeader>
      <TodoPage
        loading={actionsLoading}
        tasks={
          filters && filters.device_uuid && filters.device_uuid !== "all"
            ? deviceActions
            : actions
        }
        setFilters={setActionFilters}
        filters={filters}
        getItems={saveFilters}
      />
    </Page>
  );
};

export default TodoPageContainer;
