import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Logo from "components/Logo";
import {
  DEFAULT_PARTNER_NAME,
  PROFILE_TYPE_SME,
  PROFILE_TYPE_STAFF,
  TUA_CATTOLICA_PARTNERS,
} from "constants/common";
import { SubHeaderContext } from "components/SubHeader/SubHeaderContext";
import UserName from "components/UserName";
import Select from "react-select";
import API from "api/user";
import { toast } from "react-toastify";
import { User } from "../../services/authentication/AuthUser";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { AccentButton } from "../UI";
import { Hidden, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  subheaderComponent: {
    position: "sticky",
    top: 0,
    zIndex: 2,
    display: "none",
    justifyContent: "space-between",
    alignItems: "center",
    flex: "0 0 auto",
    padding: "20px",
    borderBottom: `1px solid ${theme.variables.$lightGray}`,
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.variables.$mainBg,
      display: "flex",
    },
  },
  rightSide: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    margin: "0 -8px",
  },

  upgradePlan: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    margin: "0 8px",
  },

  accountTypeSelected: {
    minWidth: "200px",
  },

  currentPlanName: {
    fontFamily: "Montserrat, Geometria,sans-serif",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "18px",
    marginLeft: "16px",
    textTransform: "uppercase",
    marginLeft: "20px",
    color: theme.variables.$black,
  },
}));

const SubHeader = ({ partnerSettings, identity }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { locale } = useParams();
  const planName = identity && identity.plan && identity.plan.slug;
  const profileType = identity && identity.profile.profile_type;
  const trial = identity && identity.trial;
  const isUltimatePlan = planName && planName === "ultimate" && !trial;
  const showHackedBtn = !partnerSettings.isHaveBeenHackedSection;
  const iHaveBeenHackedRedirectUrl = partnerSettings.iHaveBeenHackedRedirectUrl;
  const showPlan = partnerSettings.isSelectedPlanNameInHeader;
  const isCrossServicePartner = partnerSettings.isCrossServicePartner;

  const { subHeaderContext, useSubHeaderContext } =
    useContext(SubHeaderContext);
  const userProfiles =
    identity &&
    identity.profiles &&
    identity.profiles
      .map((profile) => ({
        value: profile.uuid,
        label: t(profile.profile_type.toLowerCase()),
      }))
      .filter((profileType) => profileType.value !== PROFILE_TYPE_SME); // exclude PROFILE_TYPE_SME profile because he have access to bd.dynarisk.com

  const activeProfile =
    userProfiles &&
    userProfiles.find((el) => el.value === identity.profile_uuid);

  if (subHeaderContext && subHeaderContext.isVisible === false) {
    return null;
  }

  const changeProfile = (profile) => {
    API.profileSwitch({
      refresh_token: User.refreshToken,
      profile_uuid: profile.value,
    })
      .then((data) => {
        User.refreshToken = data.refresh_token;
        const current = window.location.pathname;
        const search = window.location.search;
        window.location.href = `${current}${search}`;
      })
      .catch((err) => {
        toast(t("an_error_occured"), { type: toast.TYPE.ERROR });
      });
  };

  const redirectToSupport = () => {
    if (iHaveBeenHackedRedirectUrl) {
      return window.open(
        iHaveBeenHackedRedirectUrl,
        "_blank",
        "noopener,noreferrer"
      );
    }
    history.push(`/${locale}/support`);
  };

  return (
    <div className={classes.subheaderComponent}>
      {subHeaderContext &&
        subHeaderContext.logo &&
        subHeaderContext.logo.display && <Logo />}
      <div>
        {subHeaderContext &&
          subHeaderContext.iThinkIveBeenHacked &&
          subHeaderContext.iThinkIveBeenHacked.display &&
          showHackedBtn && (
            <>
              <AccentButton
                size="small"
                variant="outlined"
                accentColor="error"
                onClick={redirectToSupport}
              >
                {t("i_think_i_ve_been_hacked")}
              </AccentButton>
            </>
          )}
      </div>
      <Hidden smDown>
        <div className={classes.rightSide}>
          {subHeaderContext &&
            userProfiles &&
            activeProfile &&
            userProfiles.length > 1 && (
              <Box mx={1}>
                <Select
                  placeholder={"Account type"}
                  value={activeProfile}
                  id="account_type"
                  className={classes.accountTypeSelected}
                  onChange={changeProfile}
                  options={userProfiles}
                />
              </Box>
            )}
          {partnerSettings &&
            TUA_CATTOLICA_PARTNERS.indexOf(partnerSettings.partnerSlug) === -1 &&
              <UserName t={t} />
          }
          {subHeaderContext &&
            subHeaderContext.upgradePlan &&
            subHeaderContext.upgradePlan.display &&
            partnerSettings &&
            partnerSettings.partnerSlug === DEFAULT_PARTNER_NAME &&
            showPlan &&
            profileType !== PROFILE_TYPE_STAFF && (
              <div
                className={`${classes.upgradePlan} sa_dashboard_upgrade_paln`}
              >
                {!isUltimatePlan ? (
                  <Link to={`/${locale}/profile/plans`}>
                    <div className={classes.currentPlanName}>{t(planName)}</div>
                  </Link>
                ) : (
                  <div className={classes.currentPlanName}>{t(planName)}</div>
                )}
              </div>
            )}
          {subHeaderContext &&
            subHeaderContext.logout &&
            subHeaderContext.logout.display && (
              <Box mx={1}>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push(`/${locale}/logout`)}
                  className="sa_dashboard_logout"
                >
                  {isCrossServicePartner
                    ? t("exit_cyber_risk_assessment")
                    : t("log_out")}
                </Button>
              </Box>
            )}
        </div>
      </Hidden>
    </div>
  );
};

export default SubHeader;
