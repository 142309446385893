import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Date from "components/Date";
import Page from "components/Page";
import Modal from "components/Modal";
// import { RoundButton } from "../../../components/UI";
import { RiskValuation } from "./RiskValuation";
import Tasks from "components/Tasks";
import UserTour from "components/UserTour";
import { getItem, removeItem } from "helpers/localStorage";
import WhatWereScanningFor from "pages/Dashboard/WhatWereScanningFor";
import { SubHeaderContext } from "components/SubHeader/SubHeaderContext";

import { CardTitle, BodyText, RoundButton } from "../../components/UI";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useActionsContext } from "../../contexts/ActionsContext";
import { AddDeviceModal } from "./AddDeviceModal";
import { useUserTourContext } from "../../contexts";
import { useCyberScoreContect } from "../../contexts/CyberScoreContext";
import useFlagSettings from "../../hooks/useFlagSettings";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

const useStyles = makeStyles((theme) => ({
  dashboard: {
    "& .contentPlaceholder": {
      margin: "40px 0",
    },
  },
  closeBtn: {
    position: "absolute",
    top: "10px",
    right: theme.variables.$isRTLDirection ? "auto" : "8px",
    left: theme.variables.$isRTLDirection ? "8px": "auto",
  },
  headerText: {
    lineHeight: "40px",
    textAlign: "center",
    marginRight: "30px",
    color: theme.variables.$black,
    fontSize: "18px",
  },
  modalHeader: {
    position: "relative",
    background: theme.palette.secondary.main,
    margin: "-20px",
    position: "relative",
    marginBottom: "0",
  },
  modalContent: {
    maxWidth: "480px",
    textAlign: "center",
  },
  addDeviceControls: {
    display: "flex",
    justifyContent: "center",
  },
}));

let timer = null;
const DashboardPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { locale } = useParams();
  const history = useHistory();
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const { expired } = useSelector((state) => state.plans);
  const identity = useSelector((state) => state.identity);
  const checks = useSelector((state) => state.checks);
  const { canStartTour, setCanStartTour, skipTour } = useUserTourContext();
  const { subHeaderContext, useSubHeaderContext } =
    useContext(SubHeaderContext);
  const { actions, actionsLoading } = useActionsContext();
  const { flagsMap, userSettings, updateUserSetting } = useFlagSettings();
  const { cyberScore } = useCyberScoreContect();

  const [featuresLoading, setFeaturesLoadingStatus] = useState(true);
  const [maxDevicesFeature, setMaxDevicesFeature] = useState(false);

  // useEffect(() => {
  //   updateUserSetting(flagsMap.IS_RESET_PASSWORD_SHOWN, null);
  //   updateUserSetting("flag.user_tour.shown", null);
  //   updateUserSetting(flagsMap.ASSESSMENT_SHOWN, null);
  // }, []);

  useEffect(() => {
    if (getItem("coupon")) {
      removeItem("coupon");
    }
  }, []);

  useEffect(() => {
    if (
      userSettings &&
      cyberScore &&
      (userSettings[flagsMap.IS_RESET_PASSWORD_SHOWN] === undefined ||
        userSettings[flagsMap.IS_RESET_PASSWORD_SHOWN] === true)
    ) {
      showUserTour();
    }
    return () => {
      if (timer) clearTimeout(timer);
      removeItem("assessmentDone");
    };
  }, [userSettings, cyberScore]);

  useEffect(() => {
    const nextState = {
      ...subHeaderContext,
      isVisible: true,
      logo: { display: false },
    };
    useSubHeaderContext(nextState);
  }, []);

  useEffect(() => {
    if (expired) {
      history.push(`/${locale}/profile/plans`);
    }
  }, [expired]);

  useEffect(() => {
    if (identity && identity.features) {
      const [maxDevicesFeature] = identity.features.filter(
        (feature) => feature.name === "max_devices"
      );
      if (maxDevicesFeature) {
        setMaxDevicesFeature({
          isActive: maxDevicesFeature.is_active,
          maxValue: Number(maxDevicesFeature.value),
        });
      }
    }
  }, [identity]);

  const showUserTour = () => {
    // workaround for RTL languages
    // skipTour sets userSettings[flagsMap.USER_TOUR_SHOWN] to true
    if (
      userSettings &&
      !userSettings[flagsMap.USER_TOUR_SHOWN] &&
      partnerSettings.disableUserTour
    ) {
      return skipTour();
    }
    let delay = 1000;
    if (getItem("assessmentDone")) {
      delay = 5000;
    }
    if (userSettings && !userSettings[flagsMap.USER_TOUR_SHOWN]) {
      timer = setTimeout(() => {
        setCanStartTour(true);
      }, delay);
    }
  };

  const handleYes = () => {
    updateUserSetting(flagsMap.IS_RESET_PASSWORD_SHOWN, true);
    history.push(`/${locale}/profile/personal-data`);
  };

  const handleNo = () => {
    updateUserSetting(flagsMap.IS_RESET_PASSWORD_SHOWN, true);
  };

  return (
    <>
      {canStartTour && <UserTour />}
      <Page name="dashboard" withAside withSubHeader>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my={3}
        >
          <CardTitle mb={0}>{t("dashboard")}</CardTitle>
          <BodyText>
            <Date t={t} />
          </BodyText>
        </Box>
        <AddDeviceModal
          maxDevicesFeature={maxDevicesFeature}
          identity={identity}
          partnerSettings={partnerSettings}
        />

        <RiskValuation />
        <Box mb={4}>
          <Tasks data={actions} itemsToShow={5} loading={actionsLoading} />
        </Box>
        <Box mb={4}>
          {maxDevicesFeature && maxDevicesFeature.isActive && (
            <WhatWereScanningFor
              t={t}
              partnerSettings={partnerSettings}
              featuresLoading={featuresLoading}
              setLoading={setFeaturesLoadingStatus}
              checks={checks}
            />
          )}
        </Box>
        {userSettings &&
          userSettings[flagsMap.IS_RESET_PASSWORD_SHOWN] === null && (
            <Modal center handleClose={handleNo}>
              <div className={classes.modalHeader}>
                <div className={classes.headerText}>
                  {t("retail.modal_device_protection_header")}
                </div>
                <RoundButton
                  style={{ color: "#111" }}
                  className={classes.closeBtn}
                  onClick={handleNo}
                >
                  <CloseIcon />
                </RoundButton>
              </div>
              <div className={classes.modalContent}>
                <Box my={3}>
                  <CardTitle variant="h2" mb={0} textAlign="center">
                    {t("retail.modal_device_protection_body")}
                  </CardTitle>
                </Box>

                <div className={classes.addDeviceControls}>
                  <Box mx={1}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={handleYes}
                    >
                      {t("retail.modal_device_protection_ok_btn")}
                    </Button>
                  </Box>
                </div>
              </div>
            </Modal>
          )}
      </Page>
    </>
  );
};

export default DashboardPage;
