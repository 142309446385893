import React, { useState, createContext, useContext, useCallback } from 'react';
import riskScoreApi from 'api/riskScore';

export const getRiskHeaderByScoreValue = (score) => {
  if (score < 500) {
    return "risk_very_poor"
  } else if (score >= 500 && score <= 660) {
    return "risk_fair"
  } else if (score >= 661 && score <= 739) {
    return "risk_good"
  } else if (score >= 740 && score <= 799) {
    return "risk_very_good"
  } else if (score >= 800 && score <= 999) {
    return "risk_excellent"
  } else {
    return "risk_medium"
  }
}

const CyberScoreContext = createContext({
  cyberScore: null,
  setCyberScore: () => { }
});

const CyberScoreContextProvider = ({ children }) => {
  const [cyberScore, setCyberScore] = useState(null)
  const [cyberScoreHeader, setCyberScoreHeader] = useState('')
  const [isLoading, setLoading] = useState(false)

  const getRecentCyberScore = useCallback(() => {
    setLoading(true)
    riskScoreApi.getRiskScore()
      .then(({ data }) => {
        const scoreHeader = getRiskHeaderByScoreValue(data.value_formatted)
        setCyberScore(data)
        setCyberScoreHeader(scoreHeader)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const defaultContext = {
    cyberScore,
    setCyberScore,
    cyberScoreHeader,
    getRecentCyberScore,
    isLoading,
    setLoading,
  }

  return (
    <CyberScoreContext.Provider value={defaultContext}>
      {children}
    </CyberScoreContext.Provider>
  )
}

const useCyberScoreContect = () => useContext(CyberScoreContext)

export { CyberScoreContext, CyberScoreContextProvider, useCyberScoreContect }