import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactGA from 'react-ga';
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import State from "./contexts";

import { Provider } from 'react-redux'
import { store } from 'store'

// eslint-disable-next-line no-restricted-globals
if (location.protocol !== 'https:' && location.hostname !== 'localhost') {
  // eslint-disable-next-line no-restricted-globals
  location.replace(`https:${location.href.substring(location.protocol.length)}`);
}

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
}

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
  <Provider store={store}>
    <State>
      <App />
    </State>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
