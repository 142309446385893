export class FormikErrorConverter {
  /**
   * @param {ValidationErrorStack} errorStack
   * @param {string: string[]} map fieldName => values[]
   */
  static convert(errorStack, map) {
    let errors = {};
    if (!map) {
      errorStack.errors.forEach(e => errors[e.name] = e.message);
    } else {
      errorStack.errors.forEach(e => {
        if (map[e.name] || Array.isArray(map[e.name])) {
          const fields = Array.isArray(map[e.name]) ? map[e.name] : [map[e.name]];
          fields.forEach(field => errors[field] = e.message);
        }
      });
    }
    return errors;
  }
}