import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as BackArrowIcon } from "assets/icons/arrow-back.svg";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCourseItemById } from "store/selectors/course";
import CourseSidebar from "../CourseSidebar/CourseSidebar";
import NavigationFooter from "./NavigationFooter/NavigationFooter";
import CourseItem from "./CourseItem/CourseItem";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  wrapper: {
    width: "100%",
    height: "100vh",
    maxHeight: "100vh",
    overflow: "hidden",
  },
  topBar: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.variables.$dividerLightGray}`,
  },
  backButton: {
    height: "60px",
    borderRadius: "0px",
    padding: "5px 15px",
    background: theme.palette.secondary.main,
    border: "none",
    cursor: "pointer",
    "& svg": {
      transform: theme.variables.$isRTLDirection ? "scaleX(-1)" : "unset",
    },
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  contentSection: {
    height: "calc(100% - 60px)",
    display: "flex",
    marginTop: "60px",
    justifyContent: "center",
    padding: "32px 24px",
  },
  lessonContent: {
    height: "calc(100% - 60px)",
    margin: theme.spacing(0, 10),
    "@media (max-width: 1280px)": {
      margin: theme.spacing(0, 5),
    },
    "@media (max-width: 960px)": {
      margin: theme.spacing(0, 0),
    },
  },
  gridItem: {
    height: "100%",
  },
  contentWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

export const CourseContent = () => {
  const classes = useStyles();
  const { locale, lessonid, courseid, quizid, takeawayid } = useParams();
  const isCertificate = location.pathname.includes("certificate");
  const history = useHistory();
  const course = useSelector((state) => state.course.course);
  const courseItemId = getFirstDefined(lessonid, quizid, takeawayid);
  const currentCourseItem = useSelector((state) =>
    getCourseItemById(state, courseItemId)
  );
  const currentItemType = currentCourseItem ? currentCourseItem.type : null;

  const [isCompleteBtnDisabled, setIsCompleteBtnDisabled] = useState(false);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.topBar}>
        <button
          className={classes.backButton}
          onClick={() => {
            history.push(`/${locale}/training/course-overview/${courseid}`);
          }}
        >
          <BackArrowIcon />
        </button>
        <Box ml={2}>
          <Typography variant="h1">{course.title}</Typography>
        </Box>
      </Box>
      <Box className={classes.contentSection}>
        <Grid container spacing={2} className={classes.lessonContent}>
          <Grid item xs={12} sm={8} lg={9} className={classes.gridItem}>
            <Paper className={classes.contentWrapper}>
              <CourseItem
                isCertificate={isCertificate}
                type={currentCourseItem ? currentCourseItem.type : null}
                setIsDisabled={setIsCompleteBtnDisabled}
                title={currentCourseItem ? currentCourseItem.title : ""}
              />
            </Paper>
          </Grid>
          <Grid
            item
            xs={false}
            sm={4}
            lg={3}
            className={`${classes.gridItem} ${classes.sidebar}`}
          >
            <Box style={{ overflow: "auto", height: "100%" }}>
              <CourseSidebar />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {currentCourseItem && (
        <NavigationFooter
          isCertificate={isCertificate}
          currentItemType={currentItemType}
          courseItemId={courseItemId}
          isCompleteBtnDisabled={isCompleteBtnDisabled}
        />
      )}
    </Box>
  );
};

function getFirstDefined(...args) {
  return args.find((value) => value !== undefined);
}