import React, { useEffect, useState } from "react";
import MDRender from "components/MDRender";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { CardTitle } from "../../../../components/UI";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  stepTitle: {
    borderTop: `1px solid ${theme.variables.$lightGray}`,
    borderBottom: `1px solid ${theme.variables.$lightGray}`,
    padding: "10px 0",
    marginBottom: "10px",
  },
  item: {
    position: "relative",
    paddingLeft: theme.variables.$isRTLDirection ? "0" : "40px",
    paddingRight: theme.variables.$isRTLDirection ? "40px" : "0",
    marginBottom: "24px",
    "&:last-child": {
      paddingBottom: "0",
      marginBottom: "0",
      "& $marker:after": {
        bottom: "0",
      },
    },
  },
  content: {
    "& img": {
      display: "block",
      marginTop: "10px",
      maxWidth: "100%",
    },
    "& .info": {
      fontSize: "12px",
      fontWeight: "700",
      letterSpacing: "3px",
      margin: "0 0 .5em 0",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
    },
  },
  marker: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: theme.variables.$isRTLDirection ? "inherit" : 0,
    right: theme.variables.$isRTLDirection ? 0 : "inherit",
    "&:before": {
      content: '""',
      position: "absolute",
      fontSize: "12px",
      top: "2px",
      left: "2px",
      display: "block",
      background: theme.variables.$lightGray,
      borderRadius: "4px",
      height: "24px",
      width: "24px",
    },
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "28px",
      bottom: "-24px",
      left: "12px",
      width: "2px",
      background: theme.variables.$lightGray,
    },

    "& span": {
      position: "absolute",
      top: "0px",
      left: "8px",
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "28px",
    },
  },
}));

const Steps = ({data = {}}) => {
  const classes = useStyles();
  const {t} = useTranslation()

  if (!data || Object.keys(data).length === 0) {
    return null;
  }

  return (
    <Box mt={2} className="stepsComponent">
      <Box className={classes.stepTitle}>
        <CardTitle variant="h2" mb={0}>
          {t("steps")}
        </CardTitle>
      </Box>
      <ul>
        {Object.entries(data).map(([key, value], index) => (
          <li className={classes.item} key={index}>
            <div className={classes.marker}>
              <span>{index + 1}</span>
            </div>

            <div className={classes.content}>
              <MDRender t={t} input={value.data} escapeHtml={false} />

              {value.media &&
                value.media.map((image, index) => {
                  return <img src={image.url} key={index} />;
                })}
            </div>
          </li>
        ))}
      </ul>
      <br />
      <br />
    </Box>
  );
};

export default Steps;
