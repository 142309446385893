import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as LockedIcon } from "assets/icons/d.svg";
import { ReactComponent as QuizPlaceholder } from "assets/icons/quiz_placeholder.svg";
import { ReactComponent as TakeawayPlaceholder } from "assets/icons/takeaway_placeholder.svg";
import { ReactComponent as LessonPlaceholder } from "assets/icons/course_placeholder.svg";

const itemPlaceholderMap = {
  CHAPTER_QUIZ: QuizPlaceholder,
  CHAPTER_TAKEAWAY: TakeawayPlaceholder,
  COURSE_TAKEAWAY: TakeawayPlaceholder,
  VIDEO: LessonPlaceholder,
  MARKDOWN: LessonPlaceholder,
};

const useStyles = makeStyles((theme) => ({
  overviewWrapper: {
    padding: theme.spacing(3),
  },
  chapterOverview: {
    display: "flex",
  },
  chapterSectionHeader: {
    display: "flex",
    alignItems: "center",
  },
  locked: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 1,
    backgroundColor: "#001E2E",
    opacity: 0.4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  lockedBtnWrapper: {
    position: "absolute",
    zIndex: 1,
    opacity: 1,
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  lessonWrapper: {
    position: "relative",
  },
  lessonImg: {
    height: "140px",
    maxWidth: "180px",
    width: "100%",
    objectFit: "cover",
    marginRight: theme.spacing(2),
  },
  lessonText: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  finalTestWrapper: {
    padding: theme.spacing(4),
    minHeight: "250px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  startFinalTestBtn: {
    backgroundColor: "rgba(92, 194, 247, 0.8)",
    "&:hover": {
      backgroundColor: "rgba(92, 194, 247, 1)",
    },
  },
}));

const FinalQuizCard = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { locale } = useParams();
  const history = useHistory();

  return (
    <Box style={{ position: "relative" }} id={data.uuid}>
      <Paper mt={4} className={classes.finalTestWrapper}>
        <Typography variant="h5" component="h3">
          {t("retail.check_your_knowledge")}
        </Typography>
        <Box mt={2} mb={2}>
          <Typography variant="subtitle1">
            {t("retail.final_quiz_description")}
          </Typography>
        </Box>
        <Button
          className={classes.startFinalTestBtn}
          variant="contained"
          onClick={() => {
            history.push(`/${locale}/training/${data.view_path}`);
          }}
        >
          {t("retail.start_quiz")}
        </Button>
      </Paper>

      {!data.isUnlocked && (
        <>
          <Box className={classes.locked}></Box>
          <Box className={classes.lockedBtnWrapper}>
            <Button
              variant="contained"
              startIcon={<LockedIcon />}
              size="medium"
            >
              {t("retail.locked")}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

const CourseItemCard = ({ item, forceLock = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { locale } = useParams();
  const history = useHistory();
  const locked = !item.isUnlocked || forceLock;
  const ItemPlaceholder = itemPlaceholderMap[item.type];

  return item.type === "FINAL_QUIZ" ? (
    <FinalQuizCard data={item} />
  ) : (
    <Box
      mb={2}
      className={classes.lessonWrapper}
      key={item.uuid}
      id={item.uuid}
    >
      <Paper
        className={`${classes.overviewWrapper} ${classes.chapterOverview}`}
        onClick={() => history.push(`/${locale}/training/${item.view_path}`)}
      >
        {item.image_url ? (
          <img
            className={classes.lessonImg}
            src={item.image_url}
            alt={item.title || `${t("retail.lesson")} ${item.position}`}
          />
        ) : (
          <ItemPlaceholder className={classes.lessonImg} />
        )}
        <Box className={classes.lessonText}>
          <Typography variant="h6" component="h3">
            {item.title ? item.title : `${t("retail.lesson")} ${item.position}`}
          </Typography>
          <Typography variant="body2">{item.summary}</Typography>
        </Box>
      </Paper>
      {locked && (
        <Box>
          <Box className={classes.locked} />
          <Box className={classes.lockedBtnWrapper}>
            <Button
              variant="contained"
              startIcon={<LockedIcon />}
              size="medium"
            >
              {t("retail.locked")}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CourseItemCard;