import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import API from 'api/user'
import Modal from 'components/Modal'
import AddItem from './AddItem'
import ItemsList from './ItemsList'
import Box from '@material-ui/core/Box'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { RoundButton } from '../../../components/UI'
import { useMonitoredDataStyles } from '../MnitoredData.styles'

const MonitoredDataCreditCard = () => {
  const { t } = useTranslation()
  const classes = useMonitoredDataStyles()
  let isSubscribed = true
  const [isOpen, toggleModal] = useState(false)
  useEffect(() => {
    if (isSubscribed) {
      getCardsList()
    }
    return () => isSubscribed = false
  }, [])

  const [protectedCards, setProtectedCards] = useState([])
  const [loading, setLoading] = useState(true);

  const addCard = (credit_card_number) => {
    return API.addProtectedCreditCard({ credit_card_number })
      .then(() => {
        toggleModal(true)
        setTimeout(() => toggleModal(false), 4000)
        getCardsList()
        return Promise.resolve(null)
      })
      .catch(({ errorStack }) => {
        if (errorStack) {
          errorStack.errors.forEach(err => {
            if (err.name !== 'credit_card_number') {
              toast(err.message, { type: toast.TYPE.ERROR })
            }
          })
          return Promise.resolve(errorStack.toFormik())
        }
        toast(t("add_credit_card_error"), { type: toast.TYPE.ERROR })
        return Promise.resolve(null)
      })
  }

  const closeModalHandler = () => {
    toggleModal(false)
  }

  const getCardsList = () => {
    API.getProtectedCreditCards()
      .then(({data}) => {
        setLoading(false)
        if (data && Array.isArray(data.data)) {
          if (isSubscribed) {
            setProtectedCards(data.data.reverse())
          }
        }
      })
      .catch(() => {
        setLoading(false)
        toast(t("get_credit_cards_error"), { type: toast.TYPE.ERROR })
      })
  }

  const deleteCard = (card, uuid) => {
    API.deleteProtectedCreditCard({ uuid })
      .then((response) => {
        if (response && response.status && response.status === 200) {
          toast(t("credit_card_is_deleted", { card }), { type: toast.TYPE.INFO })
          getCardsList()
        }
      })
      .catch(() => {
        toast(t("delete_credit_card_error", { card }), { type: toast.TYPE.ERROR })
      })
  }

  // split translation by sentences and display each on new line
  const setModalContent = () => {
    return {
      __html:
        t("personal_data_scans_info", {
          data_type: `${t("data_card")}`
        })
          .split('.')
          .join('.<br/>')
    }
  }

  return (
    <Box mt={3}>
      {isOpen &&
        <Modal
          className={classes.modalComponent}
          center
          handleClose={closeModalHandler}
        >
          <RoundButton
            className={classes.closeBtn}
            onClick={closeModalHandler}
          >
            <CloseIcon />
          </RoundButton>
          <div className={classes.modalContent}>
            <h3 dangerouslySetInnerHTML={setModalContent()} />
          </div>
        </Modal>
      }
      <AddItem addCard={addCard} t={t} />
      {!!protectedCards.length && <ItemsList
        loading={loading}
        t={t}
        protectedCards={protectedCards}
        deleteCard={deleteCard}
      />
      }
    </Box>
  )
}

export default MonitoredDataCreditCard
