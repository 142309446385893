import { setCourseItems } from "store/actions/course";

export const parseCourseItems = (course, dispatch) => {
  const courseContent = [];

  course.user_chapters.forEach((userChapter) => {
    userChapter.chapter.user_lessons.forEach((lesson, lessonIndex) => {
      courseContent.push({
        is_completed: lesson.is_completed,
        uuid: lesson.uuid,
        chapter_uuid: lesson.user_chapter_uuid,
        chapter_title: `Chapter ${userChapter.chapter.position}`,
        chapter_position: userChapter.chapter.position,
        course_uuid: lesson.user_course_uuid,
        type: lesson.lesson.type,
        description: lesson.lesson.description,
        content: lesson.lesson.content,
        video_url: lesson.lesson.video_url,
        image_url: lesson.lesson.image_url,
        summary: lesson.lesson.summary,
        title: lesson.lesson.title || `Lesson ${lessonIndex + 1}`, //maybe lesson position should be used
        view_path: `course/${lesson.user_course_uuid}/lesson/${lesson.uuid}`,
      });
    });

    userChapter.chapter.user_takeaways.forEach((takeaway, takeawayIndex) => {
      courseContent.push({
        is_completed: takeaway.is_completed,
        uuid: takeaway.uuid,
        chapter_uuid: userChapter.uuid, // there is no user_chapter_uuid in res
        chapter_title: `Chapter ${userChapter.chapter.position}`,
        chapter_position: userChapter.chapter.position,
        course_uuid: takeaway.user_course_uuid,
        content: takeaway.takeaway.content,
        title: `Takeaway ${takeawayIndex + 1}`, //maybe takeaway position should be used
        type: "CHAPTER_TAKEAWAY",
        view_path: `course/${takeaway.user_course_uuid}/takeaway/${takeaway.uuid}`,
      });
    });

    if (userChapter.quiz_uuid) {
      courseContent.push({
        uuid: userChapter.quiz_uuid,
        title: "Quiz",
        chapter_uuid: userChapter.uuid,
        chapter_title: `Chapter ${userChapter.chapter.position}`,
        chapter_position: userChapter.chapter.position,
        type: "CHAPTER_QUIZ",
        is_completed: userChapter.chapter.is_completed ? true : false,
        view_path: `course/${course.uuid}/quiz/${userChapter.quiz_uuid}`,
      });
    }
  });

  if(course.user_course_takeaways.length > 0) {
    course.user_course_takeaways.forEach((courseTakeaway) => {
      courseContent.push({
        uuid: courseTakeaway.uuid,
        is_completed: courseTakeaway.is_completed,
        course_uuid: course.uuid,
        content: courseTakeaway.takeaway.content,
        title: `Course Takeaway`,
        type: "COURSE_TAKEAWAY",
        view_path: `course/${courseTakeaway.user_course_uuid}/takeaway/${courseTakeaway.uuid}`,
      })
    })
  }

  courseContent.push({
    course_uuid: course.uuid,
    uuid: course.quiz_uuid,
    title: "Final Quiz",
    type: "FINAL_QUIZ",
    view_path: `course/${course.uuid}/final-quiz/${course.quiz_uuid}`,
  })

  const itemsWithLockedProperty = addLockProperty(courseContent);

  dispatch(setCourseItems(itemsWithLockedProperty));
};

function addLockProperty(items) {
  if (items.length === 0) {
    return items;
  }

  items[0].isUnlocked = true;

  for (let i = 1; i < items.length; i++) {
    items[i].isUnlocked = items[i - 1].is_completed;
  }

  return items;
}