import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import { InputField, SwitchInput } from "../../../../components/UI";
import useForm from "../../../../hooks/useForm";

import { isEmail } from "../../../../helpers/validators";
import { makeStyles } from '@material-ui/core/styles'

const stateSchema = {
  protected_email: { value: "", error: "" },
  is_family: { value: false, error: "" },
};

const useStyles = makeStyles((theme) => ({
  addEmailBtn: {
    marginRight: theme.variables.$isRTLDirection ? "16px" : "0",
    marginLeft: theme.variables.$isRTLDirection ? "0" : "16px",
  }
}))

const Form = ({ t, addEmail }) => {
  const [isFamilyMemberEmail, setFamilyMemberEmail] = useState(false);
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const classes = useStyles()


  const validationStateSchema = {
    protected_email: {
      required: true,
      validator: {
        func: isEmail,
        error: t("enter_valid_email"),
      },
    },
    is_family: {
      required: false,
    },
  };

  const [initialState, setInitialState] = useState({
    data: null,
    errors: null,
  });

  const handleSubmit = (state) => {
    addEmail({
      protected_email: state.protected_email.value,
      is_family: state.is_family.value,
    })
      .then(() => {
        setInitialState({ data: null, errors: null });
        setFamilyMemberEmail(false);
      })
      .catch((err) => {
        if (err) {
          setInitialState({ ...initialState, errors: err });
        } else {
          setInitialState({ data: null, errors: null });
          setFamilyMemberEmail(false);
        }
      });
  };

  const { state, handleOnChange, handleOnBlur, handleOnSubmit, isDirty } =
    useForm(
      initialState,
      stateSchema,
      null,
      validationStateSchema,
      handleSubmit
    );

  return (
    <form onSubmit={handleOnSubmit}>
      <Box width="100%" display="flex" mb={3}>
        <Box width="100%">
          <InputField
            name="protected_email"
            placeholder={t("email_address")}
            type="text"
            className="sa_monitored_data_email"
            value={state.protected_email.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={
              isDirty && isDirty.protected_email && state.protected_email.error
            }
          />
        </Box>
        <Box
          flexShrink={0}
        >
          <Button
            style={{ padding: "8px 20px" }}
            type="submit"
            variant="contained"
            color="primary"
            className={classes.addEmailBtn}
            disabled={!state.protected_email.value}
          >
            {t("add_email")}
          </Button>
        </Box>
      </Box>
      {partnerSettings.manageDataFamilyEmail !== false && (
        <Box>
          <SwitchInput
            onChange={handleOnChange}
            value={state.is_family.value}
            onText={t("yes")}
            offText={t("no")}
            name="is_family"
            label={t("is_family_member")}
          />
        </Box>
      )}
    </form>
  );
};

export default Form;
