/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ModelChapterLesson from './ModelChapterLesson';
import ModelQuiz from './ModelQuiz';
import ModelTakeaway from './ModelTakeaway';
import ModelUserChapterTakeaway from './ModelUserChapterTakeaway';
import ModelUserLesson from './ModelUserLesson';

/**
 * The ModelChapter model module.
 * @module model/ModelChapter
 * @version 1.0.0
 */
class ModelChapter {
    /**
     * Constructs a new <code>ModelChapter</code>.
     * @alias module:model/ModelChapter
     * @param courseUuid {String} 
     * @param createdAt {Number} 
     * @param position {Number} 
     * @param uuid {String} 
     */
    constructor(courseUuid, createdAt, position, uuid) { 
        
        ModelChapter.initialize(this, courseUuid, createdAt, position, uuid);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, courseUuid, createdAt, position, uuid) { 
        obj['course_uuid'] = courseUuid;
        obj['created_at'] = createdAt;
        obj['position'] = position;
        obj['uuid'] = uuid;
    }

    /**
     * Constructs a <code>ModelChapter</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ModelChapter} obj Optional instance to populate.
     * @return {module:model/ModelChapter} The populated <code>ModelChapter</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ModelChapter();

            if (data.hasOwnProperty('course_uuid')) {
                obj['course_uuid'] = ApiClient.convertToType(data['course_uuid'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Number');
            }
            if (data.hasOwnProperty('lessons')) {
                obj['lessons'] = ApiClient.convertToType(data['lessons'], [ModelChapterLesson]);
            }
            if (data.hasOwnProperty('position')) {
                obj['position'] = ApiClient.convertToType(data['position'], 'Number');
            }
            if (data.hasOwnProperty('quiz')) {
                obj['quiz'] = ModelQuiz.constructFromObject(data['quiz']);
            }
            if (data.hasOwnProperty('quiz_uuid')) {
                obj['quiz_uuid'] = ApiClient.convertToType(data['quiz_uuid'], 'String');
            }
            if (data.hasOwnProperty('takeaways')) {
                obj['takeaways'] = ApiClient.convertToType(data['takeaways'], [ModelTakeaway]);
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Number');
            }
            if (data.hasOwnProperty('user_lessons')) {
                obj['user_lessons'] = ApiClient.convertToType(data['user_lessons'], [ModelUserLesson]);
            }
            if (data.hasOwnProperty('user_takeaways')) {
                obj['user_takeaways'] = ApiClient.convertToType(data['user_takeaways'], [ModelUserChapterTakeaway]);
            }
            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ModelChapter</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ModelChapter</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ModelChapter.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['course_uuid'] && !(typeof data['course_uuid'] === 'string' || data['course_uuid'] instanceof String)) {
            throw new Error("Expected the field `course_uuid` to be a primitive type in the JSON string but got " + data['course_uuid']);
        }
        if (data['lessons']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['lessons'])) {
                throw new Error("Expected the field `lessons` to be an array in the JSON data but got " + data['lessons']);
            }
            // validate the optional field `lessons` (array)
            for (const item of data['lessons']) {
                ModelChapterLesson.validateJSON(item);
            };
        }
        // validate the optional field `quiz`
        if (data['quiz']) { // data not null
          ModelQuiz.validateJSON(data['quiz']);
        }
        // ensure the json data is a string
        if (data['quiz_uuid'] && !(typeof data['quiz_uuid'] === 'string' || data['quiz_uuid'] instanceof String)) {
            throw new Error("Expected the field `quiz_uuid` to be a primitive type in the JSON string but got " + data['quiz_uuid']);
        }
        if (data['takeaways']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['takeaways'])) {
                throw new Error("Expected the field `takeaways` to be an array in the JSON data but got " + data['takeaways']);
            }
            // validate the optional field `takeaways` (array)
            for (const item of data['takeaways']) {
                ModelTakeaway.validateJSON(item);
            };
        }
        if (data['user_lessons']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['user_lessons'])) {
                throw new Error("Expected the field `user_lessons` to be an array in the JSON data but got " + data['user_lessons']);
            }
            // validate the optional field `user_lessons` (array)
            for (const item of data['user_lessons']) {
                ModelUserLesson.validateJSON(item);
            };
        }
        if (data['user_takeaways']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['user_takeaways'])) {
                throw new Error("Expected the field `user_takeaways` to be an array in the JSON data but got " + data['user_takeaways']);
            }
            // validate the optional field `user_takeaways` (array)
            for (const item of data['user_takeaways']) {
                ModelUserChapterTakeaway.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['uuid'] && !(typeof data['uuid'] === 'string' || data['uuid'] instanceof String)) {
            throw new Error("Expected the field `uuid` to be a primitive type in the JSON string but got " + data['uuid']);
        }

        return true;
    }


}

ModelChapter.RequiredProperties = ["course_uuid", "created_at", "position", "uuid"];

/**
 * @member {String} course_uuid
 */
ModelChapter.prototype['course_uuid'] = undefined;

/**
 * @member {Number} created_at
 */
ModelChapter.prototype['created_at'] = undefined;

/**
 * @member {Array.<module:model/ModelChapterLesson>} lessons
 */
ModelChapter.prototype['lessons'] = undefined;

/**
 * @member {Number} position
 */
ModelChapter.prototype['position'] = undefined;

/**
 * @member {module:model/ModelQuiz} quiz
 */
ModelChapter.prototype['quiz'] = undefined;

/**
 * @member {String} quiz_uuid
 */
ModelChapter.prototype['quiz_uuid'] = undefined;

/**
 * @member {Array.<module:model/ModelTakeaway>} takeaways
 */
ModelChapter.prototype['takeaways'] = undefined;

/**
 * @member {Number} updated_at
 */
ModelChapter.prototype['updated_at'] = undefined;

/**
 * @member {Array.<module:model/ModelUserLesson>} user_lessons
 */
ModelChapter.prototype['user_lessons'] = undefined;

/**
 * @member {Array.<module:model/ModelUserChapterTakeaway>} user_takeaways
 */
ModelChapter.prototype['user_takeaways'] = undefined;

/**
 * @member {String} uuid
 */
ModelChapter.prototype['uuid'] = undefined;






export default ModelChapter;

