import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import TermsAndConditions from "pages/SignUp/TermsAndConditions";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

import { CardTitle, BodyText } from "../../components/UI";
import Page from "../../components/Page";
import Logo from "../../components/Logo";
import MDRender from "../../components/MDRender";

import useFlagSettings from "../../hooks/useFlagSettings";
import { useRegisterContext } from "../../contexts";

const SignUpTermsAndConditions = () => {
  const { t } = useTranslation();
  const { locale } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { flagsMap, userSettings, updateUserSetting } = useFlagSettings();

  const partnerSettings = useSelector((state) => state.partnerSettings);
  const termsOfServiceContent = partnerSettings.termsOfServiceContent;
  const skipConfirmation = partnerSettings.skipConfirmation;
  const isCrossServicePartner = partnerSettings.isCrossServicePartner;
  const isTocCustomHeader = partnerSettings.isTocCustomHeader;
  const partnerCustomHeader = partnerSettings.partnerCustomHeader;

  const {
    setRegisterStatus,
    registerStatus,
    setIsValid,
    registerContextMap,
    authKey,
  } = useRegisterContext();

  const handleDecline = () => {
    setRegisterStatus(registerContextMap.INIT);
    history.replace(`/${locale}/signup`);
  };

  useEffect(() => {
    if (registerStatus === registerContextMap.EMAIL_VERYFICATION) {
      // controled by profile settings
      history.push({
        pathname: `/${locale}/signup/emailVerify`,
        state: { from: location, authKey },
      });
    }
    if (registerStatus === registerContextMap.AUTH_REQUEST_SUCCESS) {
      if (userSettings && !userSettings[flagsMap.TERMS_AND_CONDITIONS_SHOWN]) {
        updateUserSetting(flagsMap.TERMS_AND_CONDITIONS_SHOWN, true);
      }
      history.push(`/${locale}/start-scan`);
    }
    if (registerStatus === "registerFailed") {
      toast(t("an_error_occured"), { type: toast.TYPE.ERROR });
    }
  }, [registerStatus]);

  const handleAcceptClick = (isValid) => {
    if (isCrossServicePartner) {
      return setRegisterStatus(registerContextMap.AUTH_REQUEST_SUCCESS);
    }
    setIsValid(isValid);
  };

  return (
    <Page name="signUpTermsAndConditions">
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box mb={6} textAlign="center">
          {isTocCustomHeader && partnerCustomHeader ? (
            <MDRender t={t} input={partnerCustomHeader} escapeHtml={false} />
          ) : (
            <Logo preventRedirection={true} />
          )}
        </Box>
        <Paper elevation={2} style={{ maxWidth: "576px", margin: "0 auto" }}>
          <Box p={3}>
            <CardTitle textAlign="center">
              {t("sign_up_to_dynarisk", {
                name: partnerSettings.partnerName,
              })}
            </CardTitle>
            <BodyText textAlign="center" mb={3}>
              {t("sign_in_to_dynarisk_facebook_subheader", {
                name: partnerSettings.partnerName,
              })}
            </BodyText>
            <TermsAndConditions
              t={t}
              partnerSettings={partnerSettings}
              setIsValid={handleAcceptClick}
              register={handleDecline}
              tacData={termsOfServiceContent}
              locale={locale}
              const
            />
          </Box>
        </Paper>
      </Box>
    </Page>
  );
};

export default SignUpTermsAndConditions;
