import React, {
  useState,
  useContext,
  createContext,
} from 'react';


const PlansContext = createContext({})

const PlansContextProvider = ({ children }) => {
  const [planFromCMS, setCMSPlan] = useState(null)
  const [plans, setPlans] = useState(null)

  const defaultContext = {
    planFromCMS,
    setCMSPlan,
    plans,
    setPlans,
  }

  return (
    <PlansContext.Provider value={defaultContext}>
      {children}
    </PlansContext.Provider>
  )
}

const usePlansContext = () => useContext(PlansContext)

export { PlansContext, PlansContextProvider, usePlansContext }
