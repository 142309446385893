import { useContext, useEffect } from "react";
import qs from "qs";
import { setItem, getItem, removeItem } from "helpers/localStorage";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import useFlagSettings from "../../hooks/useFlagSettings";
import { setPartnerSettings } from "../../store/actions/partnerSettings";
import { useSelector } from "react-redux";

/**
 * @param {} history router history
 * @param {boolean} condition additional condition to redirect to '/dashboard' site
 */
export const useReddirectToDashbordOnLoged = (
  condition = true,
  redirProps = {}
) => {
  const { locale } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { jwtToken } = useContext(AuthContext);
  const isPlanSelected = getItem("isPlanSelected");
  const { flagsMap, userSettings } = useFlagSettings(condition);
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const { redirectTo } = qs.parse(location.search.replace("?", ""));

  const redirectToPageFromPath = () => {
    const pathToRedirect = getItem("redirectTo");
    if (redirectTo && !pathToRedirect) {
      setItem("redirectTo", redirectTo);
    }
    if (jwtToken && pathToRedirect) {
      removeItem("redirectTo");
      return history.push(`/${locale}/${pathToRedirect}`);
    }
  };

  useEffect(() => {
    redirectToPageFromPath();
    if (condition && userSettings && jwtToken) {
      if (isPlanSelected === "false") {
        return history.push(`/${locale}/signUp/addCoupon`);
      }
      if (
        !userSettings[flagsMap.TERMS_AND_CONDITIONS_SHOWN] &&
        partnerSettings.isTocForCrossService
      ) {
        return history.push(`/${locale}/signup/terms-and-conditions`);
      } else if (!userSettings[flagsMap.WELCOME_SCREEN_SHOWN]) {
        return history.push(`/${locale}/welcome`);
      } else if (!userSettings[flagsMap.ASSESSMENT_SHOWN]) {
        return history.push(`/${locale}/assessment`);
      } else if (redirProps.location) {
        if (redirProps.location.state) {
          if (redirProps.location.state.referrer) {
            if (
              redirProps.location.state.referrer.indexOf("/devices/activate/") >
              -1
            ) {
              return history.push(redirProps.location.state.referrer);
            }
          }
        }
      }
      history.push(`/${locale}/dashboard`);
    }
  }, [condition, userSettings, jwtToken]);
};
