import { toast } from 'react-toastify';
import i18n from 'i18next';

/**
 * @template T
 * @param {T} error
 * @returns {Promise<T>}
 */
export const onTooManyRequestsInterceptor = (error) => {
  if (!error || error.response.status !== 429) {
    return Promise.reject(error);
  }
  toast(i18n.t('too_many_requests'), { type: 'error' });
  return Promise.reject(error);
};
