import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import qs from "qs";

import { setItem } from "helpers/localStorage";

const SignUpWithPlanFromCms = ({ match, location }) => {
  const search = qs.parse(location.search.replace("?", ""));
  const { flow, partner } = search;
  const flowQs = flow ? `?flow=${flow}` : ''
  window.sessionStorage.setItem("partner", partner);

  const plan = {
    country: match.params.country,
    currency: match.params.currency,
    slug: match.params.plan,
    // hack. url param needs to be changed from cms
    type: match.params.type === "paid" ? "payable" : match.params.type,
    search,
  };
  setItem("planFromCms", plan);
  setItem("currency", plan.currency);

  return <Redirect to={`/${match.params.locale}/signUp${flowQs}`} />;
};

export default withRouter(SignUpWithPlanFromCms);
