import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import API from 'api/user'
import Modal from 'components/Modal'
import AddItem from './AddItem'
import ItemsList from './ItemsList'
import Box from '@material-ui/core/Box'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { RoundButton } from '../../../components/UI'
import { useMonitoredDataStyles } from '../MnitoredData.styles'

const MonitoredDataPhones = () => {
  const { t } = useTranslation()
  const classes = useMonitoredDataStyles()
  let isSubscribed = true
  const [isOpen, toggleModal] = useState(false)
  useEffect(() => {
    if (isSubscribed) {
      getPhonesList()
    }
    return () => isSubscribed = false
  }, [])

  const [protectedPhones, setProtectedPhones] = useState([])
  const [loading, setLoading] = useState(true);

  const addPhoneNumber = (phoneData) => {
    return API.addProtectedPhoneNumber(phoneData)
      .then(() => {
        toggleModal(true)
        setTimeout(() => toggleModal(false), 4000)
        getPhonesList()
        return Promise.resolve(true)
      })
      .catch(({ errorStack }) => {
        if (errorStack) {
          errorStack.errors.forEach(err => {
            if (!phoneData.hasOwnProperty(err.name)) {
              toast(err.message, { type: toast.TYPE.ERROR })
            }
          })
          return Promise.resolve(errorStack.toFormik())
        } else {
          toast(t("add_phone_error"), { type: toast.TYPE.ERROR })
          return Promise.resolve(true)
        }
      })
  }

  const getPhonesList = () => {
    API.getProtectedPhoneNumbers()
      .then(({data}) => {
        setLoading(false)
        if (data && Array.isArray(data.data)) {
          if (isSubscribed) {
            data = data.data.filter(phone => !!phone.phone_number)
            setProtectedPhones(data.reverse())
          }
        }
      })
      .catch((error) => {
        setLoading(false)
        toast(t("get_phones_error"), { type: toast.TYPE.ERROR })
      })
  }

  const deletePhoneNumber = (phone, countryCode, uuid) => {
    API.deleteProtectedPhoneNumber({ uuid })
      .then((response) => {
        if (response && response.status && response.status === 200) {
          toast(t("phone_number_is_deleted", { phone: `+${countryCode}${phone}` }), { type: toast.TYPE.INFO })
          getPhonesList()
        }
      })
      .catch((error) => {
        toast(t("delete_phone_error"), { type: toast.TYPE.ERROR })
      })
  }

  // split translation by sentences and display each on new line
  const setModalContent = () => {
    return {
      __html:
        t("personal_data_scans_info", {
          data_type: `${t("data_phone")}`
        })
          .split('.')
          .join('.<br/>')
    }
  }

  const closeModalHandler = () => {
    toggleModal(false)
  }

  return (
    <Box mt={3}>
      {isOpen &&
        <Modal
          className={classes.modalComponent}
          center
          handleClose={closeModalHandler}
        >
          <RoundButton
            className={classes.closeBtn}
            onClick={closeModalHandler}
          >
            <CloseIcon />
          </RoundButton>
          <div className={classes.modalContent}>
            <h3 dangerouslySetInnerHTML={setModalContent()} />
          </div>
        </Modal>
      }
      <AddItem
        addPhoneNumber={addPhoneNumber}
        t={t}
      />
      {!!protectedPhones.length && <ItemsList
        loading={loading}
        t={t}
        protectedPhones={protectedPhones}
        deletePhoneNumber={deletePhoneNumber}
      />
      }
    </Box>
  )
}

export default MonitoredDataPhones
