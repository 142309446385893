import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from "api/user";
import { isEmail } from "../../../helpers/validators";
import PasswordStrengthComponent from "components/PasswordStrength";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import { InputField, CheckboxInput, CardTitle } from "../../../components/UI";
import TermsAndConditionsContent from "../TermsAndConditionsContent";
import Modal from "../../../components/Modal";

import useForm from "../../../hooks/useForm";

const useSignUpStyles = makeStyles((theme) => ({
  minimalisticSignUp: {
    "& .wrapper": {
      width: "100%",
      height: "100%",
      "& .separated": {
        height: "100%",
        "& .calibre": {
          width: "100%",
          paddingRight: "20px",
        },
      },
    },
  },
}));

const stateSchema = {
  email: { value: "", error: "" },
  password: { value: "", error: "" },
  coupon: { value: "", error: "" },
};

const SignUpForm = ({ validateForm, termsOfServiceContent, coupon }) => {
  const { t } = useTranslation();
  const classes = useSignUpStyles();
  const history = useHistory();
  const { locale } = useParams();

  const handleSubmit = () => {
    const formData = {
      email: state.email.value,
      password: state.password.value,
    };
    if (state.coupon.value) {
      formData["coupon"] = state.coupon.value;
    }
    validateForm(formData);
  };

  const validationStateSchema = {
    email: {
      required: true,
      validator: {
        func: isEmail,
        error: t("enter_valid_email"),
      },
    },
    password: {
      required: true,
    },
    coupon: {},
  };

  const [initialState, setInitialState] = useState({
    data: {
      email: "",
      password: "",
      coupon,
    },
    errors: null,
  });

  const {
    state,
    handleOnChange,
    handleOnBlur,
    handleOnSubmit,
    disable,
    isDirty,
  } = useForm(
    initialState,
    stateSchema,
    null,
    validationStateSchema,
    handleSubmit
  );

  const [aggreeAndContinue, setTocAggreeStatus] = useState(false);
  const [tocModalState, setTocModalState] = useState(false);

  const aggreeToTerms = () => {
    setTocAggreeStatus(true);
    setTocModalState(false);
  };

  const showTermsModal = (evt) => {
    if (aggreeAndContinue) {
      return setTocAggreeStatus(false);
    }
    evt.preventDefault();
    setTocModalState(true);
  };

  const handleEmailBlur = (evt) => {
    handleOnBlur(evt);
    if (!state.email.error) {
      validateEmailUniqueness(state.email.value);
    }
  };

  const validateEmailUniqueness = async (email) => {
    try {
      const resp = await API.checkEmailUniqueness({ email });
      if (resp.status !== 200) {
        const state = { errors: { email: t("email_already_registered") } };
        setInitialState(state);
      }
    } catch (err) {}
  };

  return (
    <div className={classes.minimalisticSignUp}>
      {tocModalState && (
        <Modal center handleClose={() => setTocModalState(false)}>
          <Box height="100%" display="flex" flexDirection="column">
            <CardTitle variant="h2" mb={0}>
              {t("terms_and_conditions")}
            </CardTitle>
            <div className="separated">
              <TermsAndConditionsContent
                height={"100%"}
                t={t}
                tacData={termsOfServiceContent}
                locale={locale}
              />
            </div>
            <Box justifyContent="center" display="flex" mt={4} mx={-1}>
              <Box mx={1}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  id="sa_signup_decline"
                  onClick={() => setTocModalState(false)}
                >
                  {t("decline")}
                </Button>
              </Box>
              <Box mx={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={aggreeToTerms}
                >
                  {t("accept_and_continue")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      <form
        className="-sizeWidthFull"
        onSubmit={handleOnSubmit}
        id="form-register"
      >
        <Box mb={4}>
          <InputField
            name="email"
            placeholder={t("email_address")}
            type="text"
            onChange={handleOnChange}
            onBlur={handleEmailBlur}
            value={state.email.value}
            className="sa_signup_email"
            error={state.email.error}
          />
        </Box>
        <Box mb={4}>
          <InputField
            name="password"
            placeholder={t("password")}
            type="password"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            value={state.password.value}
            className="sa_signup_password"
            error={isDirty && isDirty.password && state.password.error}
          />
        </Box>

        <input type="hidden" name="coupon" value={state.coupon.value} />

        <PasswordStrengthComponent
          value={state.password.value}
          touched={false}
          t={t}
        />

        <Box mb={3}>
          <CheckboxInput
            size="small"
            id="checkbox_aggree_to_terms_and_conditions"
            checked={aggreeAndContinue}
            onChange={showTermsModal}
            name="aggreeToTermsAndConditions"
            label={t("retail.i_have_read_and_agree_to_the_terms_of_service")}
          />
        </Box>

        <Box mb={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={disable || !aggreeAndContinue}
            id="btn-continue"
            className="sa_signup_continue"
          >
            {t("continue")}
          </Button>
        </Box>
        <Box>
          <Button
            variant="text"
            color="secondary"
            className="sa_signup_already_registrated"
            fullWidth
            onClick={() => {
              history.push({
                pathname: `/${locale}/signIn`,
                state: { skipAnimation: true },
              });
            }}
          >
            {t("are_you_already_registered")}
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default SignUpForm;
