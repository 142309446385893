import { useContext, useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import userApi from "../api/user";
import softwareApi from "../api/software";

import { getItem, setItem } from "../helpers/localStorage";
import SRS, { browserMap } from "../helpers/systemResourcesScanner";
import { NULLABLE_DEVICE_ID } from "../constants/common";
import { AuthContext } from "../contexts/AuthContext";
import useFlagSettings from "../hooks/useFlagSettings";

const useAuth = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const { saveFetchedSettings } = useFlagSettings(false);
  const { User, setJWT, mfaData, setMfaData } = useContext(AuthContext);

  const [authData, setAuthData] = useState();
  const [isLoadingMfa, setIsLoadingMfa] = useState(false);
  const meta = SRS.run();

  useEffect(() => {
    if (authData) {
      setUserData();
      softwareMerge();
      checkPendingAssessmentAndRedirect();
    }
  }, [authData]);

  const setUserData = () => {
    const { auth, refresh_token, is_plan_selected, settings } = authData;

    saveFetchedSettings(settings.profile);
    setJWT(auth);
    User.refreshToken = refresh_token;
    User.isPlanSelected = is_plan_selected;
  };

  const softwareMerge = () => {
    if (!authData.device || authData.device.uuid === NULLABLE_DEVICE_ID) return;
    const metaData = meta.meta;
    const data = {
      slug: metaData.os.product.split("_")[0].toUpperCase(),
      version: metaData.os.version,
      build: null,
      browsers: [
        {
          slug: browserMap[metaData.browser.product.toLowerCase()],
          version: metaData.browser.version,
        },
      ],
    };
    softwareApi.softwareMerge(data).catch(() => {});
  };

  const checkPendingAssessmentAndRedirect = () => {
    const pendingDeviceAssessment = getItem("pendingDeviceAssessment");
    if (pendingDeviceAssessment) {
      try {
        const devices = JSON.parse(pendingDeviceAssessment);
        for (let key in devices) {
          if (key === meta.fingerprint) {
            return history.push(
              `/${match.params.locale}/deviceAssessment/${devices[key].uuid}`
            );
          }
        }
      } catch (err) {
        console.log("device activation error");
      }
    }
  };

  const authorizeUser = (payload) => {
    const metaData = { ...meta };

    metaData.fingerprint = metaData.fingerprint_v2;
    delete metaData.fingerprint_v2;

    payload = {
      ...payload,
      ...metaData,
    };
    const trustedDeviceHash = getItem("trustedDeviceHash");
    if (trustedDeviceHash) {
      payload["trusted_device_hash"] = trustedDeviceHash;
    }

    return userApi
      .login(payload)
      .then((data) => {
        if (data.status === 201) {
          const { token } = data;
          const type = token.type.toLowerCase();
          setMfaData(token);
          history.push({
            pathname: `/${match.params.locale}/signin/mfa/${type}`,
            state: { email: payload.email },
          });
        }
        setAuthData(data);
      })
      .catch((err) => {
        User.JWT = false;
        User.refreshToken = false;
        if (err.errorStack) {
          return Promise.reject(err.errorStack.toFormik());
        }
      });
  };

  const authWithMfaCode = async (code) => {
    setIsLoadingMfa(true);
    const metaData = { ...meta };
    metaData.fingerprint = metaData.fingerprint_v2;
    delete metaData.fingerprint_v2;
    const payload = {
      code,
      ...mfaData,
      ...metaData,
    };
    try {
      const resp = await userApi.loginWithMfaCode(payload);
      if (resp.status === 202) {
        const { token } = resp.data;
        setIsLoadingMfa(false);
        const type = token.type.toLowerCase();
        setMfaData(token);
        history.push({
          pathname: `/${match.params.locale}/signin/mfa/${type}`,
          state: { email: payload.email },
        });
        return;
      }
      const { trusted_device_hash } = resp.data;
      if (trusted_device_hash) {
        setItem("trustedDeviceHash", trusted_device_hash);
      }
      setAuthData(resp.data);
      setIsLoadingMfa(false);
    } catch (err) {
      setIsLoadingMfa(false);
      if (err.response) {
        const status = err.response.status;
        throw status;
      }
    }
  };

  return { authorizeUser, authWithMfaCode, setMfaData, isLoadingMfa };
};

export default useAuth;
