import client, { logOnError } from '../axiosInstance';
import qs from "qs"

export default {
  async softwareMerge(payload) {
    return logOnError(() => client.patch('/software/api/v1/software/current/merge', payload), "Cant merge software", { request: payload })
  },
  softwareCheck(payload) {
    const data = payload ? `?${qs.stringify(payload)}` : ""
    return logOnError(() => client.get(`/software/api/v1/check/schedule${data}`), "Software check failed", { request: payload })
  }
}
