import React from "react";
import { ReactComponent as IconAndroid } from "../../assets/icons/android-2.svg";
import { ReactComponent as IconApple } from "../../assets/icons/apple-3.svg";
import { ReactComponent as IconWindows } from "../../assets/icons/windows-2.svg";
import { ReactComponent as IconNoDevice } from "../../assets/icons/no-device.svg";

export const options_status = [
    { value: "all", label: "to_do_filter.all_risk" },
    { value: "to_be_completed", label: "to_do_filter.to_be_completed" },
    { value: "completed", label: "to_do_filter.completed" },
    { value: "overdue", label: "overdue" },
    { value: "postponed", label: "postponed" },
  ];
  
export const options_priority = [
    { value: "all", label: "to_do_filter.all_risk" },
    { value: "low", label: "to_do_filter.low_risk" },
    { value: "medium", label: "to_do_filter.medium_risk" },
    { value: "high", label: "to_do_filter.high_risk" },
  ];

export const detectedOsToIconMap = {
    windows: <IconWindows />,
    ios: <IconApple />,
    android: <IconAndroid />,
    noDevice: <IconNoDevice />,
  };