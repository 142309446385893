import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { setItem } from 'helpers/localStorage'
import Page from 'components/Page'
import ScanAccountVulnerability from './ScanAccountVulnerability'
import SRS from 'helpers/systemResourcesScanner'
import DeviceAPI from 'api/device';
import { RefreshTokenService } from 'services/authentication/RefreshTokenService'
import Box from '@material-ui/core/Box'

const ScanningDevice = ({ history, match, t }) => {
  const animationStepLength = 5000
  const additionalAnimMaxStepLength = 30000;
  const [step] = useState('scan_device_vulnerability')
  const [browser, setBrowser] = useState("scanning");
  const [os, setOS] = useState(null);
  const uuid = match.params.uuid;

  // temporary for timer
  const scannersReady = true;

  const updateUserSettings = (uuid) => {
    setItem(`flag.device_scan.${uuid}.shown`, true)
    history.push(`/${match.params.locale}/deviceAssessment/${uuid}`)
  }

  const addAndChooseDevice = (uuid, callback) => {
    const srsData = SRS.run({});
    DeviceAPI.getUserDevice(uuid).then(dev => {
      if (dev) {
        dev.scan_by = "browser";
        dev.meta = srsData.meta;
        dev.fingerprint = srsData.fingerprint_v2;
        DeviceAPI.updateDevice(dev, uuid).then((response) => {
          RefreshTokenService.refresh().then(() => {
            callback(response, uuid)
          })
        })
      } else {
        // unsupported device...
        // callback(response)
      }
    });
  }

  const awaitScansEnd = () => {
    // await here for response from server.
    // use websockets or eventsource (preffered).

    if (browser === "scanning") {
      setTimeout(() => {
        const srsData = SRS.run({});
        setBrowser(srsData.meta.browser);
      }, 1000)
    } else if (browser && browser !== "scanning" && !os) {
      setTimeout(() => {
        setOS("scanning")
      }, 1500);
    } else if (os === "scanning") {
      setTimeout(() => {
        const srsData = SRS.run({});
        setOS(srsData.meta.os);
      }, 1000);
    } else if (os && os !== "scanning" && browser && browser !== "scanning") {
      setTimeout(() => {
        setBrowser(null);
        setOS(null);
        addAndChooseDevice(uuid, (resp, uuid) => {
          updateUserSettings(uuid);
        });
      }, 1500);
    }
  }

  const renderStep = () => {
    if (step === 'scan_device_vulnerability') {
      if (scannersReady) {
        awaitScansEnd();
      } else {
        setTimeout(() => {
          updateUserSettings(uuid)
        }, animationStepLength + (additionalAnimMaxStepLength * Math.random()))
      }
      return <ScanAccountVulnerability t={t} locale={match.params.locale} browser={browser} os={os} />
    }
  }

  return (
    <Page withSubHeader>
      <Box display='flex' height='100%' alignItems='center'>
        {renderStep()}
      </Box>
    </Page>
  )
}

export default withTranslation()(withRouter(ScanningDevice))
