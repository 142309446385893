import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { CardTitle, BodyText } from "../../../../components/UI";

const useStyles = makeStyles((theme) => ({
  formLabel: {
    marginRight: theme.variables.$isRTLDirection ? "-11px" : "16px",
    marginLeft: theme.variables.$isRTLDirection ? "16px" : "-11px",
  },
}));

const DeleteReasonsForm = ({ t, setDeleteMsg, useShowModal, reasons }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState("");

  const handleChange = (evt, newValue) => {
    setSelected(newValue);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    setDeleteMsg(selected);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <CardTitle variant="h2" mb={1}>
          {t("delete_account")}
        </CardTitle>
        <BodyText>{t("reason_for_deleting_your_account")}</BodyText>
      </Box>
      <FormControl component="fieldset">
        <RadioGroup
          value={selected}
          aria-label="delete-account"
          name="delete-reason-options"
          onChange={handleChange}
        >
          {reasons.map((option) => (
            <FormControlLabel
              className={classes.formLabel}
              key={option.uuid}
              value={option.uuid}
              control={<Radio color="primary" />}
              label={option.reason}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Box display="flex" mx={-1} mt={2}>
        <Box mx={1} width="100%">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            className="sa_profile_delete_with_reason"
            disabled={!selected}
          >
            {t("delete_account")}
          </Button>
        </Box>
        <Box mx={1} width="100%">
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => useShowModal(false)}
            className="sa_profile_cancel_deletion"
          >
            {t("cancel")}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default DeleteReasonsForm;
