import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Measure from "react-measure";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import deviceApi from "api/device";

import WhyItIsNecessary from "./Renderers/WhyItIsNecessary";
import BreachRawData from "./Renderers/BreachRawData";
import Steps from "./Renderers/Steps";
import SpringScrollbars from "../SignUp/SpringScrollbars";

import MDRender from "../../components/MDRender";
import ActionRiskIndicator from "../../components/actionRiskIndicator";
import TaskContentSkeleton from "./TaskContentSkeleton";
import { useActionsContext } from "../../contexts/ActionsContext";

import { actionsStatuses } from "constants/actionsStatuses";
import { CardTitle, BodyText, CheckboxInput } from "../../components/UI";
import { ReactComponent as IconArrowBack } from "../../assets/icons/arrow-back.svg";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  taskDetails: {
    width: "100%",
    overflow: "hidden",
  },
  taskContainer: {
    width: "100%",
    height: "100%",
    position: "relative",
    "& .breachRawDataContener": {
      position: "relative",
      fontFamily: `'Courier New', Courier, monospace`,
      marginBottom: "30px",
      width: "100%",
      minHeight: "120px",
      background: "black",
      padding: "10px",
      color: "white",

      "&>.covered": {
        width: "100%",
        background: "black",
        top: 0,
        left: 0,
        color: "white",
        "& h3": {
          textAlign: "center",
        },
      },

      "&>.shown": {
        display: "none",
      },
    },
  },
  scrollbox: {
    position: "absolute",
    marginBottom: "50px",
    bottom: "0px",
    top: "60px",
    left: "0px",
    right: "0px",
  },
  scrollboxContent: {
    marginRight: "15px",
    padding: "16px",
  },
  footerContainer: {
    background: "white",
    bottom: 0,
    height: "50px",
    left: 0,
    position: "absolute",
    width: "100%",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
  footerContent: {
    lineHeight: "50px",
    paddingLeft: "16px",
  },
  actionTitleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.variables.$isRTLDirection ? 0 : "16px",
    paddingLeft: theme.variables.$isRTLDirection ? "16px" : 0,
    height: "60px",
    borderBottom: `1px solid ${theme.variables.$dividerLightGray}`,
  },
  actionTitleText: {
    margin: "0 16px",
  },
  backBtn: {
    height: "60px",
    borderRadius: "0px",
    margin: "0 16px",
    padding: "5px 15px",
    background: theme.palette.secondary.main,
    border: "none",
    cursor: "pointer",
    "& svg": {
      transform: theme.variables.$isRTLDirection ? "scaleX(-1)" : "unset",
    },
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

export default function TodoDetails() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { uuid, locale } = useParams();
  const history = useHistory();
  const { changeActionStatus } = useActionsContext();

  const ScrollbarsRef = useRef(null);

  const [taskDetails, setTaskDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    deviceApi
      .getDeviceActions({ uuid })
      .then((task) => {
        setTaskDetails(task);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const completeTask = (evt) => {
    const { checked } = evt.target;
    const status = checked
      ? actionsStatuses.completed
      : actionsStatuses.toBeCompleted;
    changeActionStatus({ uuid, status });
    setTaskDetails({ ...taskDetails, status: actionsStatuses.completed });
  };

  if (isLoading) {
    return <TaskContentSkeleton />;
  }

  return (
    <div className={classes.taskDetails}>
      {taskDetails && (
        <div className={classes.taskContainer}>
          <div className={classes.actionTitleWrapper}>
            <div style={{ display: "flex", alignItems: "center", margin: "0 -16px" }}>
              <button
                className={classes.backBtn}
                onClick={(event) => {
                  history.push(`/${locale}/todo`);
                }}
              >
                <IconArrowBack />
              </button>
              {<ActionRiskIndicator data={taskDetails} setPosition={true} />}
              <div className={classes.actionTitleText}>
                {taskDetails.status === actionsStatuses.completed ? (
                  <BodyText>
                    <strike className="colorGray">
                      {`${taskDetails.title} ${
                        taskDetails.status === actionsStatuses.postponed
                          ? ` - ${t("postponed").toUpperCase()}`
                          : ""
                      }`}
                    </strike>
                  </BodyText>
                ) : (
                  <BodyText>{taskDetails.title}</BodyText>
                )}
              </div>
            </div>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              id="task-details_help-btn"
              onClick={() => {
                history.push(`/${locale}/support`);
              }}
            >
              {t("need_more_help")}
            </Button>
          </div>
          <div className={classes.scrollbox}>
            <SpringScrollbars ref={ScrollbarsRef}>
              <Measure
                bounds
                onResize={() => {
                  ScrollbarsRef.current && ScrollbarsRef.current.forceUpdate();
                }}
              >
                {({ measureRef }) => (
                  <div ref={measureRef} className={classes.scrollboxContent}>
                    <CardTitle>{t("instructions_header")}</CardTitle>
                    <MDRender
                      escapeHtml={false}
                      t={t}
                      input={taskDetails.description}
                    />
                    <br />
                    {taskDetails.header && (
                      <Box mb={3} ml={3}>
                        <MDRender
                          t={t}
                          escapeHtml={false}
                          input={taskDetails.header}
                        />
                      </Box>
                    )}
                    {taskDetails.why_necessary && (
                      <WhyItIsNecessary
                        t={t}
                        data={taskDetails.why_necessary}
                      />
                    )}

                    {taskDetails.steps &&
                      Object.values(taskDetails.steps).length > 0 && (
                        <Steps t={t} data={taskDetails.steps} />
                      )}

                    <MDRender
                      t={t}
                      escapeHtml={false}
                      input={taskDetails.custom_description}
                    />

                    {taskDetails.breach_raw_data && (
                      <BreachRawData input={taskDetails.breach_raw_data} />
                    )}
                  </div>
                )}
              </Measure>
            </SpringScrollbars>
          </div>
          <div
            className={`${classes.footerContainer} ${
              taskDetails.status === actionsStatuses.postponed
                ? "postponed"
                : ""
            }`}
          >
            <div className={classes.footerContent}>
              <CheckboxInput
                color="primary"
                name="change-task-status"
                id={`singleCheckbox-${taskDetails.uuid}`}
                label={t("i_have_completed_this_action")}
                onChange={completeTask}
                checked={taskDetails.status === actionsStatuses.completed}
                disabled={taskDetails.status === actionsStatuses.completed}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
