import React, { useState } from "react";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import API from "api/user";
import Page from "components/Page";
import SetPasswordForm from "./SetPasswordForm";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { CardTitle } from "../../../components/UI";
import { ReactComponent as EnvelopeIcon } from "../../../assets/icons/envelope_3.svg";

const SetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { locale, token } = useParams();
  const [isLoading, setIsLoading] = useState("");

  const submitSetPassword = (data) => {
    setIsLoading("loading");
    API.resetPassword({ ...data, reset_password_token: token })
      .then(() => {
        history.push(`/${locale}/signIn`);
        toast(t("reset_password_success"), { type: toast.TYPE.INFO });
      })
      .catch(() => {
        toast(t("resetting_password_error"), {
          type: toast.TYPE.ERROR,
          onClose: () => {
            history.push(`/${locale}/signIn`);
          },
        });
      });
  };

  return (
    <Page>
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box textAlign="center" mb={2}>
          <EnvelopeIcon />
        </Box>
        <Paper elevation={2} style={{ maxWidth: "576px", margin: "0 auto" }}>
          <Box p={3}>
            <CardTitle textAlign="center">{t("type_new_password")}</CardTitle>
            <SetPasswordForm
              sendForm={submitSetPassword}
              t={t}
              loading={isLoading}
            />
            <Box mt={2}>
              <Button
                fullWidth
                variant="text"
                color="secondary"
                className="sa_signin_passwordreset_return"
                onClick={() => {
                  history.push(`/${locale}/signIn`);
                }}
              >
                {t("return_to_sign_in")}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Page>
  );
};

export default SetPassword;
