import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import API from 'api/user'
import Page from 'components/Page'
import DeleteAccountForm from './DeleteAccountForm'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { CardTitle } from '../../../../../components/UI'
import { ReactComponent as EnvelopeIcon } from '../../../../../assets/icons/envelope_3.svg';

const DeleteAccountVerify = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { locale, hash } = useParams()
  const [loading, setLoading] = useState(false)

  const deleteAccountConfirmation = () => {
    setLoading(true)
    API.deleteAccountConfirmation(hash)
      .then(() => {
        setLoading(false)
        history.push(`/${locale}/logout`)
      })
      .catch((err) => {
        setLoading(false)
        const error = err.data && err.data.message ? err.data.message : t("server_error")
        toast(error, { type: toast.TYPE.ERROR })
      })
  }

  const sayGoodbyeAndDelete = (data) => {
    deleteAccountConfirmation(data)
  }

  if (!hash) {
    return <Redirect to={`/${locale}/signIn`} />
  }

  return (
    <Page>
      <Box display='flex' alignItems='center' flexDirection='column' mt={10}>
        <Box textAlign='center' mb={2}>
          <EnvelopeIcon />
        </Box>
        <Paper elevation={2} style={{ maxWidth: '576px', margin: "0 auto" }}>
          <Box p={3}>
            <CardTitle textAlign='center'>
              {t("permanently_delete_an_account")}
            </CardTitle>
            <DeleteAccountForm
              loading={loading}
              hash={hash}
              deleteAccountConfirmation={sayGoodbyeAndDelete}
              t={t}
            />
          </Box>
        </Paper>
      </Box>
    </Page>
  )
}

export default DeleteAccountVerify
