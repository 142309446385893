import React, { useContext } from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import { useIdentity } from "../hooks/useIdentity";
import { store } from "store";

const ProtectedRoute = ({ match, path, component: Component }) => {
  const { identity, plans } = store.getState();
  const { User } = useContext(AuthContext)
  useIdentity(store.dispatch, identity);

  if (!User.JWT) {
    return <Redirect to={{ pathname: `/${match.params.locale}/signIn`, state: { referrer: `${window.location.pathname}/${window.location.search}` } }} />
  }

  if (identity && plans.expired) {
    if(identity.activeProfileType === 'SME_STAFF') {
      return <Redirect to={{ pathname: `/${match.params.locale}/expired-plan/staff`}} />
    }
    if(path.indexOf('profile') === -1 && path.indexOf('/payment/') === -1)
      return <Redirect to={{ pathname: `/${match.params.locale}/profile/plans` }} />
  }
  return <Route path={path} render={props => <Component {...props} />} />
}

export default withRouter(ProtectedRoute)

