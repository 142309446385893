import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import API from 'api/user'
import Page from 'components/Page'
import FormResetPassword from './FormResetPassword'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { CardTitle } from '../../../components/UI'
import { ReactComponent as EnvelopeIcon } from '../../../assets/icons/envelope_3.svg';

const SignInPasswordReset = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { token, locale } = useParams()
  const [loading, setLoading] = useState('')

  const sendForm = (data) => {
    setLoading(true)
    API.resetPassword({ ...data, reset_password_token: token })
      .then(() => {
        setLoading(false)
        history.push(`/${locale}`)
        toast(t("reset_password_success"), { type: toast.TYPE.INFO })
      })
      .catch(() => {
        setLoading(false)
        toast(t("resetting_password_error"), { type: toast.TYPE.ERROR })
      })
  }

  if (!token) {
    return <Redirect to={`/${locale}/signIn/passwordForgot`} />
  }

  return (
    <Page>
      <Box height='100%' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
        <Box textAlign='center' mb={2}>
          <EnvelopeIcon />
        </Box>
        <Paper elevation={2} style={{ maxWidth: '576px', margin: "0 auto" }}>
          <Box p={3}>
            <CardTitle textAlign='center'>
              {t("type_new_password")}
            </CardTitle>
            <FormResetPassword sendForm={sendForm} t={t} loading={loading} />
            <Box mt={2}>
              <Button
                fullWidth
                variant='text'
                color='secondary'
                className="sa_signin_passwordreset_return"
                onClick={() => { history.push(`/${locale}/signIn`) }}
              >
                {t("return_to_sign_in")}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Page>
  )
}

export default SignInPasswordReset
