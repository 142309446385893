import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { actionTypes } from "services/reducers/assessment";
import Page from "components/Page";
import AssessmentQuestion from "./AssessmentQuestion";
import MDRender from "../../components/MDRender";
import { setItem } from "helpers/localStorage";
import { useAssessment } from "./useAssessment";
import {
  ASSESSMENT_DONE_STEP,
  ASSESSMENT_INIT_STEP,
} from "constants/assessmentSteps";
import useFlagSettings from "../../hooks/useFlagSettings";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSoftwareMerge } from "../../hooks/useSoftwareMerge";

export default function AssessmentPage() {
  useSoftwareMerge();
  const identity = useSelector((state) => state.identity);
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { flagsMap, userSettings } = useFlagSettings();
  const uuid = match.params.uuid;
  const partnerCustomHeader = partnerSettings.partnerCustomHeader;
  const isAssessmentCustomHeader = partnerSettings.isAssessmentCustomHeader;

  const availableAssessments = {
    general: {
      storageKey: "ASSESSMENT_STARTED",
      tKey: "general",
      questionKey: "general",
    },
    deviceAssessment: {
      storageKey: `DEVICE_${uuid}_ASSESSMENT_STARTED`,
      tKey: "device",
      questionKey: "device",
    },
    session: {
      storageKey: `SESSION_${uuid}_ASSESSMENT_STARTED`,
      tKey: "session",
      questionKey: "session",
    },
  };
  const getAssessmentDetails = () => {
    let url = match.url.replace(/\/$/, "").split("/");
    let assessmentSlug = url[url.length - 2];
    return uuid && availableAssessments.hasOwnProperty(assessmentSlug)
      ? availableAssessments[assessmentSlug]
      : availableAssessments["general"];
  };
  const assessmentDetails = getAssessmentDetails();
  let { assessment, dispatchAssessmentAction, loading } = useAssessment(
    history,
    match,
    t,
    uuid,
    assessmentDetails
  );

  const storageKey = assessmentDetails.storageKey;

  useEffect(() => {
    if (
      identity &&
      identity.profile &&
      userSettings &&
      !userSettings[flagsMap.WELCOME_SCREEN_SHOWN]
    ) {
      history.push(`/${match.params.locale}/welcome`);
    }
  }, [identity, userSettings]);

  if (assessment.currentStep === ASSESSMENT_DONE_STEP) {
    if (uuid && assessmentDetails.questionKey === "device") {
      toast(t("device_is_now_activated"), { type: toast.TYPE.INFO });
      return <Redirect to={`/${match.params.locale}/deviceScanning/${uuid}`} />;
    }
    return <Redirect to={`/${match.params.locale}/dashboard`} />;
  }

  // CXWEB01-578 remove
  setItem(storageKey, "true");

  const assessmentPostAnswer = (payload) =>
    dispatchAssessmentAction({
      type: actionTypes.ASSESSMENT_SUBMIT,
      payload,
    });

  const assessmentHeader =
    (assessment.question && assessment.question.category.title) || "";
  const assessmentSubHeader =
    (assessment.question && assessment.question.category.description) || "";
  return (
    <Page name="assessment">
      {partnerCustomHeader && isAssessmentCustomHeader && (
        <MDRender t={t} input={partnerCustomHeader} escapeHtml={false} />
      )}
      {assessment.currentStep === ASSESSMENT_INIT_STEP && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
      {assessment.currentStep !== ASSESSMENT_INIT_STEP && (
        <AssessmentQuestion
          assessment={assessment}
          t={t}
          loading={loading}
          assessmentHeader={t(assessmentHeader)}
          assessmentSubheader={t(assessmentSubHeader)}
          assessmentPostAnswer={assessmentPostAnswer}
        />
      )}
    </Page>
  );
}
