import React from "react";
import { useCallback } from "react";
import { pdf } from "@react-pdf/renderer";
import Certificate from "../pages/Training/CourseContent/CourseCertificate/Certificate/Certificate"; 
import { useSelector } from "react-redux";

const useDownloadCertificate = (course) => {
  const identity = useSelector((state) => state.identity);

  const downloadCertificate = useCallback(async () => {
    const blob = await pdf(<Certificate identity={identity} course={course} />).toBlob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "certificate.pdf";
    document.body.appendChild(a); 
    a.click();
    document.body.removeChild(a); 
  }, [identity, course]);

  return { downloadCertificate };
};

export default useDownloadCertificate;