export const DEFAULT_PARTNER_NAME = 'dynarisk'
export const TUA_CATTOLICA_PARTNERS = ['tua', 'cattolica']
export const MANAGE_DATA_DISABLE_PARTNERS = ['tenerity_uk', 'tua', 'cattolica']
export const TERMS_AND_PRIVACY_LINK = {
  'cattolica': 'https://dyfcdn01.s3.amazonaws.com/partner/Privacy_Policy_Analisi_Vulnerabilitr_cat.pdf',
  'tua': 'https://dyfcdn01.s3.amazonaws.com/partner/Privacy_Policy_Analisi_Vulnerabilitr_tua.pdf'
}
export const WINDOWS_APP_URL = `https://www.dynarisk.com/downloads/windows`;
export const DYNARISK_LANDING_PAGE_URL = 'https://dynarisk.uat.finervision.com/'
export const DYNARISK_CMS_URL = 'https://www.dynarisk.com'

export const ASSESSMENT_SHOWN = 'flag.assessment.shown'
export const WELCOME_SCREEN_SHOWN = 'flag.welcome.shown'

export const PROFILE_TYPE_RETAIL = 'RETAIL' // old PERSONAL
export const PROFILE_TYPE_STAFF = 'SME_STAFF' // old BUSINESS
export const PROFILE_TYPE_SME = 'SME_OWNER' // old PERSONAL_SME

export const SUPPORTED_LANGUAGES = 'af_AF|sq_SQ|ar_DZ|ar_BH|ar_EG|ar_IQ|ar_JO|ar_KW|ar_LB|ar_LY|ar_MA|ar_OM|ar_QA|ar_SA|ar_SY|ar_TN|ar_AE|ar_YE|eu_EU|be_BE|bg_BG|ca_CA|zh_HK|zh_CN|zh_SG|zh_TW|hr_HR|cs_CS|da_DA|nl_BE|nl_NL|en_EN|en_AU|en_BZ|en_CA|en_IE|en_JM|en_NZ|en_ZA|en_TT|en_GB|en_US|et_ET|fo_FO|fa_FA|fi_FI|fr_BE|fr_CA|fr_LU|fr_FR|fr_CH|gd_GD|de_AT|de_LI|de_LU|de_DE|de_CH|el_EL|he_HE|hi_HI|hu_HU|is_IS|id_ID|ga_GA|it_IT|it_CH|ja_JA|ko_KO|ko_KO|ku_KU|lv_LV|lt_LT|mk_MK|ml_ML|ms_MS|mt_MT|no_NO|nb_NB|nn_NN|pl_PL|pt_BR|pt_PT|pa_PA|rm_RM|ro_RO|ro_MD|ru_RU|ru_MD|sr_SR|sk_SK|sl_SL|sb_SB|es_AR|es_BO|es_CL|es_CO|es_CR|es_DO|es_EC|es_SV|es_GT|es_HN|es_MX|es_NI|es_PA|es_PY|es_PE|es_PR|es_ES|es_UY|es_VE|sv_SV|sv_FI|th_TH|ts_TS|tn_TN|tr_TR|uk_UK|ur_UR|ve_VE|vi_VI|cy_CY|xh_XH|ji_JI|zu_ZU'

export const NULLABLE_DEVICE_ID = '00000000-0000-0000-0000-000000000000'

export const DEFAULT_SIDEBAR_LOGO_WIDTH = '130px'

export const DAYS_OF_WEEK_KEYS = [
  "day_of_week.sunday",
  "day_of_week.monday",
  "day_of_week.tuesday",
  "day_of_week.wednesday",
  "day_of_week.thursday",
  "day_of_week.friday",
  "day_of_week.sartuday",
 ]

 export const DAYS_OF_WEEK_MON_FIRST_KEYS = [
  "day_of_week.monday",
  "day_of_week.tuesday",
  "day_of_week.wednesday",
  "day_of_week.thursday",
  "day_of_week.friday",
  "day_of_week.sartuday",
  "day_of_week.sunday",
 ]

export const MOTHS_NAMES_KEYS = [
  "month_name.january",
  "month_name.february",
  "month_name.march",
  "month_name.april",
  "month_name.may",
  "month_name.june",
  "month_name.july",
  "month_name.august",
  "month_name.september",
  "month_name.october",
  "month_name.november",
  "month_name.december",
]

export const CustomersErrorsCodes = {
  NoActivePlan:'PLN010'
}
