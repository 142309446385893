import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

function useForm(initialState, stateSchema, blurCallback, validationSchema, callback) {
  const [state, setState] = useState(stateSchema);
  const [disable, setDisable] = useState(true);
  const [isDirty, setIsDirty] = useState();
  const { t } = useTranslation();

  // Disable button in initial render.
  useEffect(() => {
    setDisable(true);
  }, []);

  useEffect(() => {
    if (initialState && initialState.data) {
      const data = {};
      for (const key in initialState.data) {
        if (initialState.errors && initialState.errors[key]) {
          // @ts-ignore
          setIsDirty({ ...isDirty, [key]: true });
        }
        data[key] = {
          value: initialState.data[key],
          error: initialState.errors && initialState.errors[key] ? initialState.errors[key] : '',
        };
      }
      setState(data);
    }
    if (initialState && !initialState.data && initialState.errors) {
      const data = {};
      for (const key in state) {
        if (initialState.errors && initialState.errors[key]) {
          // @ts-ignore
          setIsDirty({ ...isDirty, [key]: true });
        }
        data[key] = {
          value: state[key].value,
          error: initialState.errors && initialState.errors[key] ? initialState.errors[key] : state[key].error,
        };
      }
      setState(data);
    }
  }, [initialState]);

  // For every changed in our state this will be fired
  // To be able to disable the button
  useEffect(() => {
    if (isDirty) {
      setDisable(validateState());
    }
  }, [state, isDirty]);

  // Used to disable submit button if there's an error in state
  // or the required field in state has no value.
  // Wrapped in useCallback to cached the function to avoid intensive memory leaked
  // in every re-render in component
  const validateState = useCallback(() => {
    const hasErrorInState = Object.keys(validationSchema).some((key) => {
      const isInputFieldRequired = validationSchema[key].required;
      const stateValue = state[key].value; // state value
      const stateError = state[key].error; // state error

      return (isInputFieldRequired && !stateValue) || stateError;
    });

    return hasErrorInState;
  }, [state, validationSchema]);

  // Used to handle every changes in every input
  const handleOnChange = useCallback(evt => {
    const { name, type } = evt.target;
    const value = type === 'checkbox' ? evt.target.checked : evt.target.value;
    let error = '';
    if (validationSchema[name].required) {
      if (!value) {
        error = t('sme.this_field_is_required');
      }
    }

    if (validationSchema[name].validator !== null && typeof validationSchema[name].validator === 'object') {
      const validator = validationSchema[name].validator
      if (Array.isArray(validator)) {
        for (let i = 0; i < validator.length; i++) {
          if (value && typeof validator[i].func === 'function' && !validator[i].func(value)) {
            error = validator[i].error;
            break;
          }
        }
      }
      const { regEx, func } = validator;

      if (value && regEx && !regEx.test(value)) {
        error = validator.error;
      }
      if (value && typeof func === 'function' && !func(value)) {
        error = validator.error;
      }
    }

    if (validationSchema[name].blacklist) {
      if (validationSchema[name].blacklist.forbiddenDomains.includes(value)) {
        error = validationSchema[name].blacklist.error;
      }
    }
    setState((prevState) => ({
      ...prevState,
      [name]: { value, error },
    }));
  },
    [validationSchema],
  );

  const handleOnBlur = (evt) => {
    setIsDirty({ ...isDirty, [evt.target.name]: true });
    if (typeof blurCallback === 'function') {
      blurCallback(evt);
    }
  };

  const handleOnSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      if (!validateState()) {
        callback(state);
      }
    },
    [state],
  );

  return {
    state,
    disable,
    handleOnChange,
    handleOnBlur,
    handleOnSubmit,
    isDirty,
    setIsDirty,
    setDisable,
  };
}

export default useForm;
