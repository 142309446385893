/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import QuestionAnswer from './QuestionAnswer';

/**
 * The QuestionViewResponse model module.
 * @module model/QuestionViewResponse
 * @version 1.0.0
 */
class QuestionViewResponse {
    /**
     * Constructs a new <code>QuestionViewResponse</code>.
     * @alias module:model/QuestionViewResponse
     * @param createdAt {Number} 
     * @param position {Number} 
     * @param quizUuid {String} 
     * @param uuid {String} 
     * @param value {String} 
     */
    constructor(createdAt, position, quizUuid, uuid, value) { 
        
        QuestionViewResponse.initialize(this, createdAt, position, quizUuid, uuid, value);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, position, quizUuid, uuid, value) { 
        obj['created_at'] = createdAt;
        obj['position'] = position;
        obj['quiz_uuid'] = quizUuid;
        obj['uuid'] = uuid;
        obj['value'] = value;
    }

    /**
     * Constructs a <code>QuestionViewResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/QuestionViewResponse} obj Optional instance to populate.
     * @return {module:model/QuestionViewResponse} The populated <code>QuestionViewResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new QuestionViewResponse();

            if (data.hasOwnProperty('answers')) {
                obj['answers'] = ApiClient.convertToType(data['answers'], [QuestionAnswer]);
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Number');
            }
            if (data.hasOwnProperty('position')) {
                obj['position'] = ApiClient.convertToType(data['position'], 'Number');
            }
            if (data.hasOwnProperty('quiz_uuid')) {
                obj['quiz_uuid'] = ApiClient.convertToType(data['quiz_uuid'], 'String');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Number');
            }
            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
            if (data.hasOwnProperty('value')) {
                obj['value'] = ApiClient.convertToType(data['value'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>QuestionViewResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>QuestionViewResponse</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of QuestionViewResponse.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        if (data['answers']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['answers'])) {
                throw new Error("Expected the field `answers` to be an array in the JSON data but got " + data['answers']);
            }
            // validate the optional field `answers` (array)
            for (const item of data['answers']) {
                QuestionAnswer.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['quiz_uuid'] && !(typeof data['quiz_uuid'] === 'string' || data['quiz_uuid'] instanceof String)) {
            throw new Error("Expected the field `quiz_uuid` to be a primitive type in the JSON string but got " + data['quiz_uuid']);
        }
        // ensure the json data is a string
        if (data['uuid'] && !(typeof data['uuid'] === 'string' || data['uuid'] instanceof String)) {
            throw new Error("Expected the field `uuid` to be a primitive type in the JSON string but got " + data['uuid']);
        }
        // ensure the json data is a string
        if (data['value'] && !(typeof data['value'] === 'string' || data['value'] instanceof String)) {
            throw new Error("Expected the field `value` to be a primitive type in the JSON string but got " + data['value']);
        }

        return true;
    }


}

QuestionViewResponse.RequiredProperties = ["created_at", "position", "quiz_uuid", "uuid", "value"];

/**
 * @member {Array.<module:model/QuestionAnswer>} answers
 */
QuestionViewResponse.prototype['answers'] = undefined;

/**
 * @member {Number} created_at
 */
QuestionViewResponse.prototype['created_at'] = undefined;

/**
 * @member {Number} position
 */
QuestionViewResponse.prototype['position'] = undefined;

/**
 * @member {String} quiz_uuid
 */
QuestionViewResponse.prototype['quiz_uuid'] = undefined;

/**
 * @member {Number} updated_at
 */
QuestionViewResponse.prototype['updated_at'] = undefined;

/**
 * @member {String} uuid
 */
QuestionViewResponse.prototype['uuid'] = undefined;

/**
 * @member {String} value
 */
QuestionViewResponse.prototype['value'] = undefined;






export default QuestionViewResponse;

