import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  scrollbarView: {
    marginRight: theme.variables.$isRTLDirection
      ? "0!important"
      : "-14px!important",
    marginLeft: theme.variables.$isRTLDirection
      ? "-14px!important"
      : "0!important",
  },
  scrollThumbVertical: {
    position: "relative",
    display: "block",
    width: "100%",
    cursor: "pointer",
    borderRadius: "inherit",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    height: "30px",
  },
  scrollTrackVertical: {
    position: "absolute",
    width: "6px",
    right: theme.variables.$isRTLDirection ? "unset" : "2px",
    left: theme.variables.$isRTLDirection ? "2px" : "unset",
    bottom: "2px",
    top: "2px",
    borderRadius: "3px",
  },
}));

export default function SpringScrollbars(props) {
  const classes = useStyles();
  return (
    <Scrollbars
      {...props}
      renderView={(props) => (
        <div className={classes.scrollbarView} {...props} />
      )}
      renderThumbVertical={(props) => (
        <div {...props} className={classes.scrollThumbVertical} />
      )}
      renderTrackVertical={(props) => (
        <div {...props} className={classes.scrollTrackVertical} />
      )}
    />
  );
}
