import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavItem from "./NavItem";
import { useActionsContext } from "../../contexts/ActionsContext";
import { useUserTourContext } from "../../contexts";
import { makeStyles } from "@material-ui/core/styles";
import { menuItems } from "./menu-items";
import { menuIconsMap } from "./icons-map";

const useStyles = makeStyles((theme) => ({
  navigation: {
    margin: "30px 0",
    "&:after": {
      content: "''",
      display: "block",
      [theme.breakpoints.up("md")]: {
        marginTop: "30px",
        borderBottom: `1px solid ${theme.variables.$dividerLightGray}`,
      },
    },
    "&.closed": {
      display: "none",
    },
  },
}));

const Navigation = ({ isOpen, setIsOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    url,
    params: { locale },
  } = useRouteMatch();
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const { expired } = useSelector((state) => state.plans);

  const identity = useSelector((state) => state.identity);
  const { actions, saveFilters } = useActionsContext();
  const { setCanStartTour, userTourStep } = useUserTourContext();
  const isCrossServicePartner = partnerSettings.isCrossServicePartner;

  const getTaskNumber = (actions) => {
    let temp = (actions.meta && actions.meta.total_items_unsolved) || 0;
    while (String(temp).length < 3) {
      temp = `0${temp}`;
    }
    return temp;
  };

  const handleNavLinkClick = ({ link, name }) => {
    if (expired && link !== "/logout") {
      return;
    }
    if (isOpen && typeof setIsOpen === "function") {
      setIsOpen(!isOpen);
    }
    if (link) {
      return history.push(`/${locale}${link}`);
    }
    if (name === "userTour") {
      startTheTour();
    }
  };

  const startTheTour = () => {
    if (url.indexOf("dashboard") === -1) {
      history.push(`/${locale}/dashboard`);
    }
    setTimeout(() => {
      setCanStartTour(true);
    }, 500);
  };

  useEffect(() => {
    if (!Object.keys(actions.meta).length) {
      saveFilters();
    }
  }, [actions]);

  // open mobile menu on second step
  useEffect(() => {
    if (typeof setIsOpen === "function" && !isOpen && userTourStep > 1) {
      setIsOpen(true);
    }
  }, [userTourStep]);

  return (
    <div
      className={`${classes.navigation}
      ${isOpen === false ? "closed" : "open"}`}
    >
      <div>
        {menuItems.map(
          ({
            name,
            translationKey,
            translationKeyCrossServicePartner,
            link,
            classes,
            requiredFeatures,
            disabledForPartnersList,
            controledBySetting,
          }) => {
            let additonalInfo = undefined;
            const active = url.indexOf(link) !== -1;
            if (name === "todo") {
              additonalInfo = getTaskNumber(actions);
            }
            if (requiredFeatures) {
              if (!identity) {
                return null;
              }
              const userFeatures = Object.values(identity.features).map(
                (feature) => feature.name
              );
              for (let i = 0; i < requiredFeatures.length; i++) {
                if (!userFeatures.includes(requiredFeatures[i])) {
                  return null;
                }
              }
            }
            if (
              disabledForPartnersList &&
              disabledForPartnersList.includes(partnerSettings.partnerSlug)
            ) {
              return null;
            }
            if (
              controledBySetting &&
              controledBySetting.some(
                (setting) =>
                  partnerSettings[setting.key].toString() !== setting.value
              )
            ) {
              return null;
            }
            return (
              <NavItem
                active={active}
                key={name}
                icon={menuIconsMap[name]}
                name={
                  translationKeyCrossServicePartner && isCrossServicePartner
                    ? t(translationKeyCrossServicePartner)
                    : t(translationKey)
                }
                url={link}
                onClick={() => handleNavLinkClick({ link, name })}
                classNames={classes.join(" ")}
                additonalInfo={additonalInfo}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default Navigation;
