import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import API from "api/user";
import Modal from "components/Modal";
import DeleteReasonsForm from "./DeleteReasonsForm";
import DeleteAccountInfo from "./DeleteAccountInfo";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CardTitle, BodyText, Card } from "../../../../components/UI";

const useStyles = makeStyles((theme) => ({
  btnWithLoadingWrapper: {
    position: "relative",
  },
  btnLoadingStatus: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const DeleteAccount = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { locale } = useParams();

  const [showModal, useShowModal] = useState(false);
  const [reasons, setDeleteReasons] = useState(null);
  const [deleteMsg, setDeleteMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteProfile = async (delete_reason) => {
    try {
      await API.deleteAccount({ delete_reason, delete_user: true });
      useShowModal(false);
      toast(t("profile_deleted"), { type: toast.TYPE.INFO });
      history.push(`/${locale}/logout`);
    } catch (err) {
      toast(t("can_t_delete_profile"), { type: toast.TYPE.ERROR });
      useShowModal(false);
    }
  };

  const getListOfReasonsForDelete = async () => {
    setLoading(true);
    try {
      const reasons = await API.getDeleteReasons();
      if (reasons) {
        setDeleteReasons(reasons);
        useShowModal(true);
        setLoading(false);
      }
    } catch (err) {
      toast(t("can_t_delete_account"), { type: toast.TYPE.ERROR });
      setLoading(false);
    }
  };

  return (
    <div>
      {showModal && (
        <Modal
          classNames={{
            modal: "devicesComponent addDevice",
          }}
          center
          handleClose={() => useShowModal(false)}
        >
          {deleteMsg && (
            <DeleteAccountInfo
              t={t}
              reasons={reasons}
              deleteProfile={deleteProfile}
            />
          )}
          {reasons && !deleteMsg && (
            <DeleteReasonsForm
              t={t}
              setDeleteMsg={setDeleteMsg}
              useShowModal={useShowModal}
              reasons={reasons}
            />
          )}
        </Modal>
      )}

      <CardTitle variant="h2" textAlign="inherit">{t("delete_account")}</CardTitle>
      <Card>
        <Box display="flex" justifyContent="space-between">
          <BodyText>{t("delete_account_warning")}</BodyText>
          <Box className={classes.btnWithLoadingWrapper}>
            <Button
              disabled={loading}
              size="small"
              variant="contained"
              color="primary"
              onClick={getListOfReasonsForDelete}
              className="sa_profile_delete_account"
            >
              {t("delete_account")}
            </Button>
            {loading && (
              <Box className={classes.btnLoadingStatus}>
                <CircularProgress color="primary" size={14} />
              </Box>
            )}
          </Box>
        </Box>
      </Card>
    </div>
  );
};

export default DeleteAccount;
