/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import RepositoryLessonsType from './RepositoryLessonsType';

/**
 * The ModelChapterLesson model module.
 * @module model/ModelChapterLesson
 * @version 1.0.0
 */
class ModelChapterLesson {
    /**
     * Constructs a new <code>ModelChapterLesson</code>.
     * @alias module:model/ModelChapterLesson
     * @param chapterUuid {String} 
     * @param content {String} 
     * @param courseUuid {String} 
     * @param createdAt {Number} 
     * @param description {String} 
     * @param imageUrl {String} 
     * @param position {Number} 
     * @param summary {String} 
     * @param title {String} 
     * @param type {module:model/RepositoryLessonsType} 
     * @param uuid {String} 
     * @param videoUrl {String} 
     */
    constructor(chapterUuid, content, courseUuid, createdAt, description, imageUrl, position, summary, title, type, uuid, videoUrl) { 
        
        ModelChapterLesson.initialize(this, chapterUuid, content, courseUuid, createdAt, description, imageUrl, position, summary, title, type, uuid, videoUrl);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, chapterUuid, content, courseUuid, createdAt, description, imageUrl, position, summary, title, type, uuid, videoUrl) { 
        obj['chapter_uuid'] = chapterUuid;
        obj['content'] = content;
        obj['course_uuid'] = courseUuid;
        obj['created_at'] = createdAt;
        obj['description'] = description;
        obj['image_url'] = imageUrl;
        obj['position'] = position;
        obj['summary'] = summary;
        obj['title'] = title;
        obj['type'] = type;
        obj['uuid'] = uuid;
        obj['video_url'] = videoUrl;
    }

    /**
     * Constructs a <code>ModelChapterLesson</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ModelChapterLesson} obj Optional instance to populate.
     * @return {module:model/ModelChapterLesson} The populated <code>ModelChapterLesson</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ModelChapterLesson();

            if (data.hasOwnProperty('chapter_uuid')) {
                obj['chapter_uuid'] = ApiClient.convertToType(data['chapter_uuid'], 'String');
            }
            if (data.hasOwnProperty('content')) {
                obj['content'] = ApiClient.convertToType(data['content'], 'String');
            }
            if (data.hasOwnProperty('course_uuid')) {
                obj['course_uuid'] = ApiClient.convertToType(data['course_uuid'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Number');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('image_url')) {
                obj['image_url'] = ApiClient.convertToType(data['image_url'], 'String');
            }
            if (data.hasOwnProperty('position')) {
                obj['position'] = ApiClient.convertToType(data['position'], 'Number');
            }
            if (data.hasOwnProperty('summary')) {
                obj['summary'] = ApiClient.convertToType(data['summary'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], RepositoryLessonsType);
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Number');
            }
            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
            if (data.hasOwnProperty('video_url')) {
                obj['video_url'] = ApiClient.convertToType(data['video_url'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ModelChapterLesson</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ModelChapterLesson</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ModelChapterLesson.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['chapter_uuid'] && !(typeof data['chapter_uuid'] === 'string' || data['chapter_uuid'] instanceof String)) {
            throw new Error("Expected the field `chapter_uuid` to be a primitive type in the JSON string but got " + data['chapter_uuid']);
        }
        // ensure the json data is a string
        if (data['content'] && !(typeof data['content'] === 'string' || data['content'] instanceof String)) {
            throw new Error("Expected the field `content` to be a primitive type in the JSON string but got " + data['content']);
        }
        // ensure the json data is a string
        if (data['course_uuid'] && !(typeof data['course_uuid'] === 'string' || data['course_uuid'] instanceof String)) {
            throw new Error("Expected the field `course_uuid` to be a primitive type in the JSON string but got " + data['course_uuid']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['image_url'] && !(typeof data['image_url'] === 'string' || data['image_url'] instanceof String)) {
            throw new Error("Expected the field `image_url` to be a primitive type in the JSON string but got " + data['image_url']);
        }
        // ensure the json data is a string
        if (data['summary'] && !(typeof data['summary'] === 'string' || data['summary'] instanceof String)) {
            throw new Error("Expected the field `summary` to be a primitive type in the JSON string but got " + data['summary']);
        }
        // ensure the json data is a string
        if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
            throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
        }
        // ensure the json data is a string
        if (data['uuid'] && !(typeof data['uuid'] === 'string' || data['uuid'] instanceof String)) {
            throw new Error("Expected the field `uuid` to be a primitive type in the JSON string but got " + data['uuid']);
        }
        // ensure the json data is a string
        if (data['video_url'] && !(typeof data['video_url'] === 'string' || data['video_url'] instanceof String)) {
            throw new Error("Expected the field `video_url` to be a primitive type in the JSON string but got " + data['video_url']);
        }

        return true;
    }


}

ModelChapterLesson.RequiredProperties = ["chapter_uuid", "content", "course_uuid", "created_at", "description", "image_url", "position", "summary", "title", "type", "uuid", "video_url"];

/**
 * @member {String} chapter_uuid
 */
ModelChapterLesson.prototype['chapter_uuid'] = undefined;

/**
 * @member {String} content
 */
ModelChapterLesson.prototype['content'] = undefined;

/**
 * @member {String} course_uuid
 */
ModelChapterLesson.prototype['course_uuid'] = undefined;

/**
 * @member {Number} created_at
 */
ModelChapterLesson.prototype['created_at'] = undefined;

/**
 * @member {String} description
 */
ModelChapterLesson.prototype['description'] = undefined;

/**
 * @member {String} image_url
 */
ModelChapterLesson.prototype['image_url'] = undefined;

/**
 * @member {Number} position
 */
ModelChapterLesson.prototype['position'] = undefined;

/**
 * @member {String} summary
 */
ModelChapterLesson.prototype['summary'] = undefined;

/**
 * @member {String} title
 */
ModelChapterLesson.prototype['title'] = undefined;

/**
 * @member {module:model/RepositoryLessonsType} type
 */
ModelChapterLesson.prototype['type'] = undefined;

/**
 * @member {Number} updated_at
 */
ModelChapterLesson.prototype['updated_at'] = undefined;

/**
 * @member {String} uuid
 */
ModelChapterLesson.prototype['uuid'] = undefined;

/**
 * @member {String} video_url
 */
ModelChapterLesson.prototype['video_url'] = undefined;






export default ModelChapterLesson;

