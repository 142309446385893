import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Redirect, useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import API from 'api/device'
import { getItem, setItem } from 'helpers/localStorage'
import SRS from 'helpers/systemResourcesScanner';
import { validateDevice } from 'helpers/validateDevice'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CardTitle } from '../../../components/UI'

const DeviceVerify = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { locale, token } = useParams()
  const partnerSettings = useSelector(state => state.partnerSettings)

  const [redirectUrl, setRedirectUrl] = useState(null)
  const { fingerprint_v2 } = SRS.run({});
  const isCrossServicePartner = partnerSettings.isCrossServicePartner

  const possibleResults = {
    UndefinedError: () => {
      toast(t("device_activation_error"), { type: toast.TYPE.ERROR })
      setRedirectUrl(`/${locale}/devices`)
    },
    WrongDeviceError: () => {
      toast(t("can_t_be_activated"), { type: toast.TYPE.ERROR })
      setRedirectUrl(`/${locale}/devices`)
    },
    AllOk: (device) => {
      // set device to localStorage and do logout
      // after user signin from matched device
      // he will automaticaly redirected to device assessment
      try {
        let prevDevice = JSON.parse(getItem('pendingDeviceAssessment'))
        if (prevDevice) {
          prevDevice = { ...prevDevice, [fingerprint_v2]: device }
          setItem('pendingDeviceAssessment', JSON.stringify(prevDevice))
        } else {
          setItem('pendingDeviceAssessment', JSON.stringify({ [fingerprint_v2]: device }))
        }
      } catch (err) {
        setItem('pendingDeviceAssessment', JSON.stringify({ [fingerprint_v2]: device }))
      }
      if (isCrossServicePartner) {
        toast(t("device_is_now_activated"), { type: toast.TYPE.INFO })
        setRedirectUrl(`/${locale}/logout`)
      } else {
        setRedirectUrl(`/${locale}/deviceScanning/${device.uuid}`)
      }
    }
  }

  const activateDevice = () => {
    API.getDeviceByHash({ token })
      .then((result) => {
        const device = result.data;
        if (
          device.status === 'disabled'
          // will pass if devece os is one of ['windows', 'macos', 'ios', 'android']
          && validateDevice(device)
        ) {
          const srs = SRS.run({});
          device.meta = srs.meta;
          device.fingerprint = srs.fingerprint_v2;
          device.scan_by = "browser";
          API.activateDevice(token, device)
            .then(() => {
              possibleResults.AllOk(device);
            }).catch((err) => {
              let status = err.response ? err.response.status : 0;
              let name = err.response && err.response.data.data ? err.response.data.data.name : null
              if (status !== 401 || (name !== 'InvalidTokenException' && name !== 'UnauthorizedHttpException')) {
                possibleResults.UndefinedError();
              }
            })
        } else {
          possibleResults.WrongDeviceError();
        }
      }).catch((err) => {
        let status = err.response ? err.response.status : 0;
        let name = err.response && err.response.data.data ? err.response.data.data.name : null
        if (status !== 401 || (name !== 'InvalidTokenException' && name !== 'UnauthorizedHttpException')) {
          possibleResults.UndefinedError();
        }
      });
  }

  useEffect(() => {
    let timer = null
    if (redirectUrl && partnerSettings) {
      timer = setTimeout(() => history.push(redirectUrl), 1500)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [redirectUrl, partnerSettings])

  useEffect(() => {
    if (partnerSettings) {
      activateDevice()
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  }, [])

  if (!token) {
    return <Redirect to={`/${locale}/devices`} />
  }

  if (redirectUrl && partnerSettings) {
    return <Redirect to={redirectUrl} />
  }

  return (
    <Box
      width='100%'
      height='100%'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <Box textAlign='center' mb={2}>
        <CircularProgress />
      </Box>
      <CardTitle textAlign='center'>
        {t("device_verifying")}
      </CardTitle>
    </Box>
  )
}

export default DeviceVerify
