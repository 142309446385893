import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as IconMonitorPc6 } from '../../../assets/icons/monitor-pc-6.svg'
import { ReactComponent as IconTablet2 } from '../../../assets/icons/tablet-2.svg'
import { ReactComponent as IconPhone4 } from '../../../assets/icons/phone-4.svg'

import { ReactComponent as IconApple2 } from '../../../assets/icons/apple-2.svg'
import { ReactComponent as IconWindows2 } from '../../../assets/icons/windows-2.svg'
import { ReactComponent as IconAndroid2 } from '../../../assets/icons/android-2.svg'
import { ReactComponent as IconNoDevice } from '../../../assets/icons/no-device.svg'

const useStyles = makeStyles(theme => ({
  iconWrapper: {
    '& svg': {
      fontSize: '6rem',
      '& .svgFillPrimary': {
        fill: theme.palette.primary.main
      },
      '& .svgStrokePrimary': {
        stroke: theme.palette.primary.main
      }
    }
  },
  osIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: '#fff',
    transform: 'translate(-50%,-50%)',

    '&.phone': {
      width: '20px',
      height: '30px',
    },
    '&.monitor, &.pc': {
      width: '8px',
      height: '46px',
    },
    '&.laptop': {
      width: '8px',
      height: '68px,'
    },
    '&.tablet': {
      width: '12px',
      height: '33px'
    }

  }
}))

export default function DeviceWithOsIcon({ type, os }) {
  const deviceOS = os.toLowerCase()
  const classes = useStyles()
  return (
    <div className={classes.iconWrapper} dir='ltr'>
      <div style={{ position: 'relative' }}>
        {type === 'phone' && <IconPhone4 />}
        {type === 'tablet' && <IconTablet2 />}
        {['desktop', 'pc'].includes(type) && <IconMonitorPc6 />}

        <div className={`${classes.osIcon} ${type}`}>
          {!['android', 'macos', 'windows', 'macosx'].includes(deviceOS) && <IconNoDevice />}
          {deviceOS === 'android' && <IconAndroid2 />}
          {['macos', 'macosx'].includes(deviceOS) && <IconApple2 />}
          {deviceOS === 'windows' && <IconWindows2 />}
        </div>
      </div>
    </div>
  )
}