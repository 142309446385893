import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import API from "api/user";
import { Log } from "services/log/logger";
import usePlans from "hooks/usePlans";
import { RefreshTokenService } from "../../services/authentication/RefreshTokenService";
import { getItem, removeItem, setItem } from "helpers/localStorage";
import { setCurrentPlan, setCurrentPlanFeatures } from "store/actions/plans";

// define context
const PlansContext = React.createContext();

const PlansProvider = ({ children, history, match }) => {
  let planFromCMS = getItem("planFromCms");
  const isPlanSelected = getItem("isPlanSelected");
  const { t } = useTranslation();
  const { plans, refetchPlans, loading } = usePlans(t);

  const dispatch = useDispatch();

  const currentPlan = useSelector((state) => state.plans.currentPlan);
  const [timeoutLock, setTimeoutLock] = useState(false);

  useEffect(() => {
    if (plans) {
      const isPlanRoute = testIfPlansPageUrl();
      if (isPlanRoute) {
        choosePlan(plans);
      }

      redirectOnOrder();

      if (plans.length === 1) {
        selectPlanAutomatically();
      }
    }
  }, [plans]);

  const redirectOnOrder = () => {
    if (getItem("paymentOrder")) {
      const nextPage = testIfPlansPageUrl()
        ? `/${match.params.locale}/start-scan`
        : `/${match.params.locale}/dashboard`;

      removeItem("paymentOrder");
      history.push(nextPage);
    }
  };

  const testIfPlansPageUrl = () => {
    return /plans$/.test(match.url);
  };

  const selectPlanAutomatically = () => {
    let planOptions = plans.options.payable;
    const { price, price_with_coupon } = planOptions;

    if (checkIfFree(price, price_with_coupon) && !timeoutLock) {
      dispatch(setCurrentPlan(planOptions));
      dispatch(setCurrentPlanFeatures(plans[0]));
      setTimeout(() => {
        if (currentPlan !== planOptions && plans) {
          tryTrialPlan(planOptions, true);
        }
      }, 3000);
      setTimeoutLock(true);
    }
  };

  const choosePlan = (plans) => {
    // if we have plan selected by customer from cms
    // select plan from payable plans and display payment modal
    // or automatically apply trial plans
    try {
      planFromCMS = JSON.parse(planFromCMS);
    } catch (err) {
      planFromCMS = null;
    }
    if (planFromCMS) {
      const selectedPlan = plans[planFromCMS.slug];
      if (!selectedPlan) {
        Log.error("Plan from cms doesn't found", {
          plans: plans,
          cmsPlan: planFromCMS,
        });
      }
      if (selectedPlan) {
        const planOptions = selectedPlan.options[planFromCMS.type];
        const { price, price_with_coupon, slug_full } = planOptions;

        dispatch(setCurrentPlan(planOptions));
        dispatch(setCurrentPlanFeatures(selectedPlan));
        if (!checkIfFree(price, price_with_coupon)) {
          const [planSlug] = slug_full.split(".");
          history.push(`/payment/${planSlug}/${planOptions.uuid}`);
        } else {
          tryTrialPlan(planOptions);
        }
      }
    }
  };

  const selectPayablePlan = (payablePlan) => {
    // In case if user switch plan in the middle of usage other payable plan
    // request server to get calculated plan price,
    // depends on duration of existing plan

    API.calculatePlanPrice({ uuid: payablePlan.uuid })
      .then(({ data }) => {
        const planDuration = data.plan_duration;
        if (
          planDuration &&
          planDuration.price &&
          planDuration.price.amount !== payablePlan.price.amount
        ) {
          const calculatedPlan = {
            ...payablePlan,
            price: data.plan_duration.price,
          };

          if (planDuration.price.amount === "0") {
            dispatch(setCurrentPlan(calculatedPlan));
            return tryTrialPlan(calculatedPlan);
          }

          dispatch(setCurrentPlan(calculatedPlan));
          const [planSlug] = calculatedPlan.slug_full.split(".");
          history.push(`/payment/${planSlug}/${calculatedPlan.uuid}`);
          return;
        }
        dispatch(setCurrentPlan(payablePlan));
        const [planSlug] = payablePlan.slug_full.split(".");
        history.push(`/payment/${planSlug}/${payablePlan.uuid}`);
      })
      .catch((err) => {
        removeItem("planFromCms");
        const error = err.response.data;

        if (error && error.data && error.data.message) {
          return toast(error.response.data.data.message, {
            type: toast.TYPE.ERROR,
          });
        }
        // TODO: ask backend about errors and error code for translation
        toast("Forbbiden", { type: toast.TYPE.ERROR });
      });
  };

  const handleSelectPlan = (type, data) => {
    API.selectPlan({ type, data })
      .then(() => {
        RefreshTokenService.refresh()
          .then(() => {
            if (isPlanSelected === "false") {
              setItem("isPlanSelected", true);
              refetchPlans();
              // in payable plans the payment status modal is shown
              history.push(`/${match.params.locale}/start-scan`);
            }
          })
          .catch(() => { });
      })
      .catch((error) => {
        if (error.errorStack && error.errorStack.isHaveError) {
          error.errorStack.errors.forEach((error) => {
            toast(error.message, { type: toast.TYPE.ERROR });
          });
        } else {
          toast(t("can_t_select_plan"), { type: toast.TYPE.ERROR });
        }
      });
  };

  const tryTrialPlan = (trialPlan) => {
    handleSelectPlan("free", { plan_option_uuid: trialPlan.uuid });
  };

  const checkIfFree = (price, price_with_coupon) => {
    return (
      (price_with_coupon && parseInt(price_with_coupon.amount, 10) === 0) ||
      (price && parseInt(price.amount, 10) === 0)
    );
  };

  const selectPlan = (planOptions, plan) => () => {
    const { price_with_coupon, price } = planOptions;
    dispatch(setCurrentPlanFeatures(plan));
    const isFreePlan = checkIfFree(price, price_with_coupon);
    isFreePlan ? tryTrialPlan(planOptions) : selectPayablePlan(planOptions);
  };

  const defaultContext = {
    plans,
    loading,
    t,
    selectPayablePlan,
    tryTrialPlan,
    selectPlan,
    isPlanSelected,
  };

  return (
    <PlansContext.Provider value={defaultContext}>
      {children}
    </PlansContext.Provider>
  );
};

const PlansConsumer = PlansContext.Consumer;

export { PlansContext, PlansProvider, PlansConsumer };
