import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import Logo from 'components/Logo'
import Navigation from 'components/Navigation'
import UserName from 'components/UserName'
import { TUA_CATTOLICA_PARTNERS } from 'constants/common'
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
  mobileNavComponent: {
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: '1000',
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, .1)',
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  partOne: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    minHeight: '60px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },


  rightSide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: '0',
    userNameComponent: {
      display: 'flex',
      marginRight: '10px',

      i: {
        marginLeft: '5px',

        '> svg': {
          fontSize: '1rem',
        }
      }
    }
  },

  hamburger: {
    '&: hover': {
      cursor: 'pointer'
    },
    '&.-active $line': {
      '&:nth-child(2)': {
        opacity: 0
      },
      '&:nth-child(1)': {
        transform: 'translateY(0.3rem) rotate(45deg)',
      },
      '&:nth-child(3)': {
        transform: 'translateY(-.8rem) rotate(-45deg)'
      }
    }
  },
  line: {
    width: '1.8rem',
    height: '.25rem',
    backgroundColor: theme.palette.primary.main,
    display: 'block',
    margin: '.35em auto',
    transition: 'all 0.3s ease-in-out',
  },
}))

const MobileNav = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const partnerSettings = useSelector(state => state.partnerSettings)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Hidden mdUp>
      <div className={classes.mobileNavComponent}>
        <div className={classes.wrapper}>
          <div className={classes.partOne}>
            <Logo isMobile={true}/>
            <div className={classes.rightSide}>
              {
                partnerSettings
                && TUA_CATTOLICA_PARTNERS.indexOf(partnerSettings.partnerSlug) === -1
                && <UserName t={t} shortName />
              }
              <div
                className={`${classes.hamburger} tourMobile4 ${isOpen ? '-active' : ''}`}
                onClick={() => setIsOpen(!isOpen)}
                id='mobile-menu-btn'>
                <span className={classes.line}></span>
                <span className={classes.line}></span>
                <span className={classes.line}></span>
              </div>
            </div>
          </div>
          <Navigation isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
    </Hidden>
  )
}

export default MobileNav;
