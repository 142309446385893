import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAssessmentStyles } from "./Assessment.styles";

export default function ChbxQuestionSubmitButton({
  handleSubmit,
  isLoading,
  disabled,
}) {
  const { t } = useTranslation();
  const submitBtnRef = useRef();
  const classes = useAssessmentStyles();

  useEffect(() => {
    window.addEventListener("keydown", submitAssessment);
    return () => {
      window.removeEventListener("keydown", submitAssessment);
    };
  }, []);

  const submitAssessment = (evt) => {
    if (evt.key === "Enter" && submitBtnRef.current) {
      submitBtnRef.current.click();
    }
  };

  return (
    <Box width="200px" className={classes.submitAssessment}>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        ref={submitBtnRef}
        onClick={handleSubmit}
        disabled={disabled}
      >
        {isLoading ? <CircularProgress color="inherit" size={14} /> : t("next")}
      </Button>
    </Box>
  );
}
