import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import API from 'api/user'
import Page from 'components/Page'
import SignUpEditEmailForm from './SignUpEditEmailForm'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { CardTitle } from '../../../components/UI';
import { ReactComponent as EnvelopeIcon } from '../../../assets/icons/envelope_3.svg';


const SignUpEmailEdit = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { locale } = useParams()
  const [loading, setLoading] = useState(false)

  const resetEmail = (data) => {
    setLoading(true)
    API.checkEmailUniqueness(data)
      .then(function (response) {
        if (response.status === 200) {
          sessionStorage.setItem('userEmail', data.email);
          // typo in json
          toast(t("email_cahnged", { email: data.email }), { type: toast.TYPE.INFO })
          history.push(`/${locale}/signup/emailVerify`)
        } else {
          setLoading(false)
          toast(t("can_t_change_email"), { type: toast.TYPE.ERROR })
        }
      })
      .catch(error => {
        setLoading(false)
        toast(t("can_t_change_email"), { type: toast.TYPE.ERROR })
      })
  }
  return (
    <Page name="signUpEditEmail">
      <Box
        width='100%'
        height='100%'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        <Box display='flex' alignItems='center' flexDirection='column'>
          <Box textAlign='center' mb={2}>
            <EnvelopeIcon />
          </Box>
          <Paper elevation={2} style={{ minWidth: '440px' }}>
            <Box p={3}>
              <CardTitle textAlign='center'>
                {t("edit_email")}
              </CardTitle>
              <SignUpEditEmailForm resetEmail={resetEmail} loading={loading} />
            </Box>
          </Paper>
        </Box>
      </Box>
    </Page>
  )
}

export default SignUpEmailEdit
