import React from "react";
import Page from "components/Page";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import CourseSummaryCard from "./CourseSummaryCard/CourseSummaryCard";
import CourseItemCard from "./CourseItemCard/CourseItemCard";
import CourseSidebar from "../CourseSidebar/CourseSidebar";

const useStyles = makeStyles(() => ({
  courseSidebar: {
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
}));

const CourseOverview = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const course = useSelector((state) => state.course.course);
  const courseItems = useSelector((state) => state.course.courseItems);
  const sections = divideItemsIntoSections(t, courseItems);

  function divideItemsIntoSections(t, courseItems) {
    const chaptersMap = courseItems.reduce((acc, item) => {
      const chapterUuid = item.chapter_uuid || "course_summary";
      const sectionTitle = item.chapter_title || t("retail.course_summary");

      if (!acc[chapterUuid]) {
        acc[chapterUuid] = {
          chapter_uuid: chapterUuid,
          section_title: sectionTitle,
          items: [],
        };
      }

      acc[chapterUuid].items.push(item);
      return acc;
    }, {});

    return Object.values(chaptersMap);
  }

  return (
    <Page name="courseoverview" withAside withSubHeader>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            {course && <CourseSummaryCard />}
            <Box mt={5}>
              {sections &&
                sections.map((section, index) => {
                  return (
                    <Box
                      key={index}
                      mb={1}
                      className={classes.chapterSectionHeader}
                      mt={4}
                    >
                      <Box mr={1} mb={2}>
                        <Typography variant="h5" component="h2">
                          {section.section_title}
                        </Typography>
                      </Box>
                      {section.items.map((item, index) => (
                        <CourseItemCard
                          key={index}
                          item={item}
                          forceLock={
                            index === 0 && course.progress_status === "PENDING"
                          }
                        />
                      ))}
                    </Box>
                  );
                })}
            </Box>
          </Grid>
          <Grid className={classes.courseSidebar} item xs={false} sm={4}>
            <CourseSidebar />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default CourseOverview
