import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCourseItemById } from "store/selectors/course";
import MDRender from "components/MDRender";

const useStyles = makeStyles(() => ({
  playerWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "920px",
  },
  videoLessonWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  descriptionWrapper: {
    maxWidth: "920px",
  },
  noDescription: {
    height: "100%",
  },
}));

export const VideoPlayer = ({ options, completeLesson, lessonId }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const allowCompleteRef = useRef(false);

  useEffect(() => {
    const videoElement = document.createElement("video-js");
    videoRef.current.appendChild(videoElement);
    const player = videojs(videoElement, options);

    playerRef.current = player;

    const timeUpdate = () => {
      const currentTime = player.currentTime();
      const duration = player.duration();
      if (currentTime / duration >= 0.8 && !allowCompleteRef.current) {
        allowCompleteRef.current = true;
        completeLesson();
      }
    };

    player.on("timeupdate", timeUpdate);

    return () => {
      if (player && !player.isDisposed()) {
        player.off("timeupdate", timeUpdate);
        player.dispose();
      }
      playerRef.current = null;
      allowCompleteRef.current = false;
    };
  }, [lessonId, videoRef]);

  useEffect(() => {
    if (playerRef.current) {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.source);
    }
  }, [videoRef, lessonId]);

  return (
    <div data-vjs-player style={{ width: "100%" }}>
      <div ref={videoRef} />
    </div>
  );
};

const VideoLesson = ({ disableBtn }) => {
  const classes = useStyles();
  const { lessonid } = useParams();
  const videoLesson = useSelector((state) =>
    getCourseItemById(state, lessonid)
  );

  const videoSetup = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    source: videoLesson.video_url,
  };

  useEffect(() => {
    disableBtn(true);
  }, [lessonid]);

  const handleCompleteLesson = () => {
    disableBtn(false);
  };

  return (
    <Box
      className={`${classes.videoLessonWrapper} ${
        !videoLesson.description ? classes.noDescription : ""
      }`}
    >
      <Box className={classes.playerWrapper}>
        <VideoPlayer
          options={videoSetup}
          completeLesson={handleCompleteLesson}
          lessonId={lessonid}
        />
      </Box>
      {videoLesson.description && (
        <Box className={classes.descriptionWrapper} mt={1}>
          <MDRender input={videoLesson.description} />
        </Box>
      )}
    </Box>
  );
};

export default VideoLesson;