import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Page from "components/Page";
import Logo from "components/Logo";
import TermsAndConditionsContent from "../SignUp/TermsAndConditionsContent";
import MDRender from "components/MDRender";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import { CardTitle } from "../../components/UI";

const useStyles = makeStyles((theme) => ({
  termsAndConditions: {
    "& .calibre": {
      width: "100%",
      marginRight: 0,
      marginLeft: 0,
      paddingRight: "20px",
      paddingTop: "1rem",
    },
  },
  terms: {
    "& .mdWrapper": {
      "&>*": {
        marginRight: "15px",
      },
      "& ol": {
        listStyleType: "decimal",
        "& li": {
          marginTop: "10px",
          "& p": {
            display: "inline",
          },
        },
        "& ol": {
          marginLeft: "20px",
        },
      },
    },
  },
}));

const TermsAndConditions = () => {
  const classes = useStyles();
  const { locale } = useParams();
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const { t } = useTranslation();

  const isTocCustomHeader = partnerSettings.isTocCustomHeader;
  const partnerCustomHeader = partnerSettings.partnerCustomHeader;
  const termsOfServiceContent = partnerSettings.termsOfServiceContent;

  return (
    <Page className={classes.termsAndConditions} name="termsAndConditions">
      <Box style={{ maxWidth: "980px", margin: "0 auto" }}>
        <Fade in={true} timeout={{ enter: 1500 }}>
          <Box mb={2} textAlign="center" minHeight="30px">
            {isTocCustomHeader && partnerCustomHeader ? (
              <MDRender t={t} input={partnerCustomHeader} escapeHtml={false} />
            ) : (
              <Logo redirectTo="dashboard" />
            )}
          </Box>
        </Fade>
        <hr />
        <div
          style={{
            overflow: "auto",
            height: "auto",
            left: "calc((100vw - 510px)/2)",
            right: "calc((100vw - 510px)/2)",
            top: "180px",
            bottom: "60px",
          }}
          className={`${classes.terms} ${partnerSettings.partnerName}`}
        >
          <CardTitle variant="h2" mt={3}>
            {t("terms_and_conditions")}
          </CardTitle>
          <TermsAndConditionsContent
            height={550}
            t={t}
            tacData={termsOfServiceContent}
            locale={locale}
          />
        </div>
      </Box>
    </Page>
  );
};

export default TermsAndConditions;
