import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import Plans from "components/Plans";
import Box from "@material-ui/core/Box";
import { CardTitle } from "../../../components/UI";

const WelcomeToDynariskPage = ({ t, partnerSettings }) => {
  let sectionHeaderName = partnerSettings ? partnerSettings.partnerName : null;

  return (
    <Page name="plans">
      <Box width="100%" style={{ maxWidth: "980px", margin: "0 auto" }}>
        <CardTitle mb={4} mt={4}>
          {t("welcome_to_dynarisk", { name: sectionHeaderName })}
        </CardTitle>
        <Plans t={t} />
      </Box>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  partnerSettings: state.partnerSettings,
});

export default connect(mapStateToProps)(
  withTranslation()(WelcomeToDynariskPage)
);
