import React from "react";
import { connect } from "react-redux";

import Box from "@material-ui/core/Box";
import {
  CardTitle,
  BodyText,
  DeviceWithOsIcon,
  Card,
} from "../../../components/UI";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  statusPanelContent: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      flexWrap: "wrap",
    },
  },
  text: {
    display: "flex",
    flexDirection: "column",
    margin: "0 2%",
    marginTop: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      flexBasis: "100%",
    },
  },
  textGreen: {
    color: theme.variables.$green100,
  },
}));

const DeviceStatusPanel = ({
  t,
  data = { device_type: "laptop", os: "windows" },
  partnerSettings,
}) => {
  const classes = useStyles();
  const jsUcfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getStatus = () => {
    return (
      <Box display="flex">
        <BodyText>
          <span>{`${jsUcfirst(t("status"))}:`}</span>
        </BodyText>
        &nbsp;
        <BodyText>
          <span className={classes.textGreen}>{`${jsUcfirst(
            t("active")
          )}`}</span>
        </BodyText>
      </Box>
    );
  };

  return (
    <Card>
      <Box className={classes.statusPanelContent}>
        <DeviceWithOsIcon type={data.device_type} os={data.os} />
        <div className={classes.text}>
          <CardTitle variant="h3" mb={0}>
            {data.os === "linux"
              ? t("wrong_os_device", {
                  partner_name: partnerSettings.partnerName,
                })
              : t("dashboard_copy.the_device_you_added_is_active_now")}
          </CardTitle>
          {getStatus()}
        </div>
      </Box>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  partnerSettings: state.partnerSettings,
});

export default connect(mapStateToProps)(DeviceStatusPanel);
