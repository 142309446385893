import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil.svg'
import { CardTitle } from '../'

const useStyles = makeStyles((theme) => ({
  editableContentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      opacity: '.8',
    }
  },
  name: {
    display:'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    fontSize: '1.4rem',
    fontWeight: '200',
    textTransform: 'capitalize',
    wordBreak: 'break-word',
    margin: '0',
    padding: '0',
    position: 'relative',
  },
  editName: {
    flexBasis: '20px',
    fontSize: '1.3rem',
    margin: theme.variables.$isRTLDirection ? '0 10px 0 0' : '0 0 0 10px',
    cursor: 'pointer',
    transition: 'all 0.1s',
    '&: hover': {
      opacity: 0.8,
      transition: 'all 0.2s',
    }
  },
  nameInput: {
    padding: 0,
    background: 'transparent',
    width: '100%',
    fontSize: '20px',
    fontWeight: 500,
    textTransform: 'capitalize',
    border: 0,
    outline: 'none',
    fontFamily: 'Montserrat, sans-serif'
  }
}))

export default function EditableContent({
  className,
  value,
  callback,
  isLink = false,
  validate,
  onClick = () => { },
}) {
  const classes = useStyles()
  const nameInput = useRef()
  const [content, setContent] = useState(value)
  const [isEditing, startEditing] = useState(false)

  useEffect(() => {
    if (isEditing) {
      nameInput.current.children[0].focus()
    }
    if (value !== content) {
      setContent(value)
    }
  }, [isEditing, value])

  const updateContent = (evt) => {
    callback(evt)
    startEditing(false)
  }

  const applyChanges = (evt) => {
    if (evt.key === 'Enter') {
      updateContent(evt)
    }
  }

  const validateInput = ({ target: { value } }) => {
    if (validate && validate.test(value)) {
      setContent(value)
    }
  }

  return (
    <>
      {!isEditing
        && <div className={`${classes.editableContentWrapper} ${className}`}>
          <span className={`${classes.name} ${isLink ? classes.link : ''}`}>
            <div onClick={onClick}>
              <CardTitle variant='h2' mb={0}>
                {content}
              </CardTitle>
            </div>
            <span className={classes.editName} onClick={() => startEditing(!isEditing)}>
              <PencilIcon />
            </span>
          </span>
        </div>
      }
      <span ref={nameInput}>
        {isEditing
          && <input
            className={`${classes.nameInput} ${className}`}
            value={content}
            onKeyDown={applyChanges}
            onChange={validateInput}
            onBlur={updateContent}
          />
        }
      </span>
    </>
  )
}
