import { clear, getItem, removeItem, setItem } from '../../helpers/localStorage'
import parseJwt from '../../helpers/parseJwt'

export class AuthUser {

  get JWT() {
    return getItem('JWT');
  }

  set JWT(value) {
    if (value) {
      return setItem('JWT', value);
    }
    return removeItem('JWT')
  }

  get isPlanSelected() {
    return getItem('isPlanSelected');
  }

  set isPlanSelected(value) {
    return setItem('isPlanSelected', value);
  }

  set ProfileSettings(settings) {
    if (settings && Array.isArray(settings.profile)) {
      settings.profile.forEach(setting => {
        localStorage.setItem(setting.key, setting.value)
      })
    }
  }

  get refreshToken() {
    return getItem('refresh_token');
  }

  set refreshToken(value) {
    if (value)
      return setItem('refresh_token', value);

    return removeItem('refresh_token')
  }

  get JWTStore() {
    return this.JWT ? Object.assign(new Identity(), parseJwt(this.JWT)) : null;
  }

  setIdentityDirty() {
    removeItem('identity')
  }

  logout() {
    // storage data that is needed after logout
    const storage = {}
    const pendingDeviceAssessment = getItem('pendingDeviceAssessment')
    const planFromCms = getItem('planFromCms')
    const userDevices = getItem('userDevices')
    const trustedDeviceHash = getItem('trustedDeviceHash')
    const selectedLang = getItem('selectedLang')
    storage['pendingDeviceAssessment'] = pendingDeviceAssessment
    storage['planFromCms'] = planFromCms
    storage['userDevices'] = userDevices
    storage['trustedDeviceHash'] = trustedDeviceHash
    storage['selectedLang'] = selectedLang
    clear();

    Object.entries(storage).forEach(sd => {
      if (sd[1]) {
        setItem(sd[0], sd[1])
      }
    })
  }
}

export class Identity {
  uuid = null;
  device_uuid = null;
  profile_uuid = null;
  plan = {
    slug: null,
    name: null,
    type: null
  }
  trial = null;
  profile = {
    uuid: null,
    profile_email: null,
    profile_type: null
  }
  user = {
    uuid: null,
    email: null,
    first_name: null,
    last_name: null,
    phone_number: null
  }
  profiles = []
}

export const User = new AuthUser();

