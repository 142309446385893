import React from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'


export default function Card({ children, maxWidth = 'initial', elevation = 2, p = 3, className, textAlign='initial' }) {
  return (
    <Paper
      className={className}
      elevation={elevation}
      style={{
        maxWidth
      }}>
      <Box p={p} textAlign={textAlign}>
        {children}
      </Box>
    </Paper>
  )
}