import React, { memo } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { BodyText } from "../UI";

import { useNavItemStyles } from "./NavItem.styles";

const NavItem = memo(
  ({ icon, name, active, classNames, additonalInfo, onClick }) => {
    const classes = useNavItemStyles();
    const { themeSettings } = useSelector((state) => state.partnerSettings);
    const { textHoverColor, fontWeight } = themeSettings.actionsSection
      ? themeSettings.actionsSection
      : {
          textHoverColor: themeSettings.primaryTextColor,
          fontWeight: "normal",
        };

    const menuItemTextTransformStyle =
      (themeSettings &&
        themeSettings.sidebar &&
        themeSettings.sidebar.textTransform) ||
      "";

    return (
      <Box className={`${classes.menuItem} ${classNames}`}>
        <Box
          className={`${classes.item} ${active ? "active" : ""}`}
          onClick={onClick}
        >
          <Box mx='-10px' display="flex">
            {icon && <i className={classes.icon}>{icon}</i>}
            <Box className={classes.label}>
              <BodyText color="inherit">
                <span className={`labelText ${menuItemTextTransformStyle}`}>
                  {name}
                </span>
              </BodyText>
              <BodyText color="inherit">
                <span className={classes.info}>{additonalInfo}</span>
              </BodyText>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default NavItem;
