import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
// TODO: lang can be detected by user's browser
// import LanguageDetector from 'i18next-browser-languagedetector';
import en_GB from "./translations/en.json";
import de_DE from "./translations/de.json";
import it_IT from "./translations/it.json";
import es_ES from "./translations/es.json";
import pl_PL from "./translations/pl.json";
import ru_RU from "./translations/ru.json";
import es_AR from "./translations/ar.json";

// TODO: find the solution with LanguageDetector
const lang = window.location.pathname.split('/')[1]

const detectorOptions = {
  // order and from where user language should be detected
  order: [
    'localStorage',
    'path'
  // 'querystring', 'cookie', 'htmlTag', 'path', 'subdomain'
],

  // keys or params to lookup language from
  // lookupQuerystring: 'lng',
  // lookupCookie: 'i18next',
  lookupLocalStorage: 'locale',
  lookupFromPathIndex: 1,
  // lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage',
  //  'cookie'
  ],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: window.location.host,

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement
}
const options = {
  interpolation: {
    escapeValue: false
  },

  detection: detectorOptions,

  // debug: process.env.NODE_ENV === "development",

  resources: {
    en_GB: {
      common: en_GB
    },
    de_DE: {
      common: de_DE
    },
    it_IT: {
      common: it_IT
    },
    es_ES: {
      common: es_ES
    },
    es_AR: {
      common: es_AR
    },
    pl_PL: {
      common: pl_PL
    },
    ru_RU: {
      common: ru_RU
    },
    fi_FI: {
      common: null
    }
  },

  lng: lang,
  fallbackLng: ["en_GB"],
  ns: ["common"],
  defaultNS: "common",
  keySeparator: "::",

  react: {
    wait: false,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default"
  }
};

i18n
  .use(LanguageDetector)
  .init(options);

export default i18n;
