import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";

import Page from "components/Page";
import Plans from "./Plans";
import UserSettings from "./UserSettings";
import {
  DEFAULT_PARTNER_NAME,
  PROFILE_TYPE_STAFF,
  PROFILE_TYPE_SME,
} from "constants/common";
import { useIdentityFeature } from "hooks/useIdentiyFeature";
import StaffUserExpiredPlanMsg from "./StaffUserExpiredPlanMsg";
import { CardTitle, BodyText, LanguageSelect } from "../../components/UI";

const useStyles = makeStyles((theme) => ({
  currentPlanName: {
    lineHeight: "2.3",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "600",
    marginLeft: "20px",
    color: theme.variables.$black,
  },
}));

function a11yProps(index) {
  return {
    id: `profile-data-tab-${index}`,
    "aria-controls": `profile-data-tabpanel-${index}`,
  };
}

function MuiTabPanel(props) {
  const { children, value, name, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== name}
      id={`profile-data-tabpanel-${name}`}
      aria-labelledby={`profile-data-tab-${name}`}
      {...other}
    >
      {value === name && children}
    </div>
  );
}

const Profile = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { tab, locale } = useParams();
  const identity = useSelector((state) => state.identity);
  const partnerSettings = useSelector((state) => state.partnerSettings);
  const { expired } = useSelector((state) => state.plans);
  const hideProfileSettings = partnerSettings.hideProfileSettings;
  const hideUserName = partnerSettings.hideUserName;

  const messageKey = location.state && location.state.message_key;
  const planName = identity && identity.plan && identity.plan.slug;
  const profileType =
    identity && identity.profile && identity.profile.profile_type;
  const maxDevicesFeature = useIdentityFeature(identity, "max_devices");

  const isUltimatePlan =
    identity && identity.plan && identity.plan.slug === "ultimate";
  const isFamily = identity && identity.plan && identity.plan.slug === "family";

  const handleChange = (evt, newValue) => {
    history.push(`/${locale}/profile/${newValue}`);
  };

  return (
    <Page name="profile" withAside withSubHeader>
      <Box mt={3}>
        <LanguageSelect />
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <CardTitle mb={1} textAlign="inherit">{t("setting")}</CardTitle>
            {identity && identity.user && !hideUserName && (
              <CardTitle variant="h2" textAlign="inherit">
                {`${identity.user.first_name} ${identity.user.last_name}`}
              </CardTitle>
            )}
          </Box>
          <Hidden mdUp>
            {partnerSettings &&
              partnerSettings.partnerSlug === DEFAULT_PARTNER_NAME && (
                <div>
                  {!isUltimatePlan ? (
                    <Link to={`/${locale}/profile/plans`}>
                      <div className={classes.currentPlanName}>
                        {t(planName)}
                      </div>
                    </Link>
                  ) : (
                    <div className={classes.currentPlanName}>{t(planName)}</div>
                  )}
                </div>
              )}
          </Hidden>
        </Box>

        <AppBar position="static" elevation={0} color="transparent">
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="profile-data-tabs"
          >
            {!hideProfileSettings && !expired && (
              <Tab
                value="personal-data"
                label={t("profile")}
                wrapped
                {...a11yProps("profile")}
              />
            )}
            {((partnerSettings &&
              partnerSettings.partnerSlug === DEFAULT_PARTNER_NAME &&
              !isUltimatePlan &&
              !isFamily &&
              maxDevicesFeature) ||
              expired) && (
              <Tab
                value="plans"
                label={t("plans")}
                wrapped
                {...a11yProps("plans")}
              />
            )}
          </Tabs>
        </AppBar>
        <MuiTabPanel value={tab} name="personal-data">
          {!hideProfileSettings && (
            <UserSettings
              t={t}
              partnerSettings={partnerSettings}
              identity={identity}
            />
          )}
        </MuiTabPanel>
        {((partnerSettings &&
          partnerSettings.partnerSlug === DEFAULT_PARTNER_NAME &&
          !isUltimatePlan &&
          !isFamily &&
          maxDevicesFeature) ||
          expired) && (
          <MuiTabPanel value={tab} name="plans">
            <>
              {messageKey && (
                <Box py={2}>
                  <BodyText textAlign="center">{t(messageKey)}</BodyText>
                </Box>
              )}
              {profileType === PROFILE_TYPE_STAFF ||
              profileType === PROFILE_TYPE_SME ? (
                <StaffUserExpiredPlanMsg t={t} />
              ) : (
                <Plans t={t} />
              )}
            </>
          </MuiTabPanel>
        )}
      </Box>
    </Page>
  );
};

export default Profile;
