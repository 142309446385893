import { useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";

import API from "api/assessment";
import { getItem, removeItem, setItem } from "helpers/localStorage";
import { RefreshTokenService } from "services/authentication/RefreshTokenService";
import assessmentReducer, { actionTypes } from "services/reducers/assessment";
import useFlagSettings from "../../hooks/useFlagSettings"

const setMobileAppAdvert = (data) => {
  const assessment_download_mobile_app_advert = getItem(
    "assessment_download_mobile_app_advert"
  );
  if (
    data["number_current_question"] === 3 &&
    assessment_download_mobile_app_advert !== "seen"
  ) {
    setItem("assessment_download_mobile_app_advert", "show");
  }
};

export const useAssessment = (history, match, t, uuid, assessmentDetails) => {
  const { flagsMap, updateUserSetting, updateDeviceAssessmentFlag } = useFlagSettings();
  const storageKey = assessmentDetails.storageKey;
  const [isInit, setIsInit] = useState(false);
  const [assessment, dispatch] = useReducer(assessmentReducer, {
    currentStep: "init",
  });
  const [isLoadingQuestion, setIsLoadingQuestion] = useState(false);

  let nextQuestionTimeout = null;

  useEffect(() => {
    // assessmentDeleteAnswers()
    if (assessment.currentStep === "init") {
      setIsInit(true);
      getQuestion();
    }
    if (
      assessment.currentStep === "assessment_question"
    ) {
      // prevent fetch second time initial question. 
      if (isInit) {
        return setIsInit(false);
      }
      getQuestion();
    }
    if (assessment.currentStep === "assessment_reset") {
      assessmentDeleteAnswers();
    }
    if (assessment.currentStep === "assessment_submit") {
      assessmentPostAnswer();
    }
    return () => {
      clearTimeout(nextQuestionTimeout);
    };
  }, [assessment.currentStep]);

  const updateAssessmentShownFlag = async () => {
    try {
      await updateUserSetting(flagsMap.ASSESSMENT_SHOWN, true)
      await RefreshTokenService.refresh()
      history.push(`/${match.params.locale}/dashboard`);
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        console.log(err)
      }
    }
  }

  const updateAssesmentFlagSetting = (uuid) => {
    if (uuid) {
      updateDeviceAssessmentFlag(uuid, true)
      history.push(`/${match.params.locale}/dashboard`);
    } else {
      updateAssessmentShownFlag()
    }
  };

  const assessmentPostAnswer = () => {
    const validateStatus = (status) => status < 500;
    API.submitQuestion({ data: assessment.data, validateStatus }).then(
      (response) => {
        if (localStorage.getItem(storageKey) !== "true") {
          localStorage.setItem(storageKey, "true");
        }
        if (response.status === 201) {
          // assessment.isLoadingQuestion = true;
          setIsLoadingQuestion(true);
          dispatch({
            type: actionTypes.ASSESSMENT_GET_QUESTION,
          });
        } else {
          toast(t("can_t_save_answer"), { type: toast.TYPE.ERROR });
        }
      }
    );
  };

  // delete assessment progress for test case
  const assessmentDeleteAnswers = () => {
    const validateStatus = (status) => status < 500;
    API.resetAssessment({ validateStatus }).then((response) => {
      if (response.status === 204) {
        toast(t("assessment reseted"), { type: toast.TYPE.INFO });
        dispatch({ type: actionTypes.ASSESSMENT_GET_QUESTION });
      } else {
        toast(t("assessment reset_error"), { type: toast.TYPE.ERROR });
      }
    });
  };

  function handleError() {
    toast(t("assessment_load_error"), { type: toast.TYPE.ERROR });
    history.push(`/${match.params.locale}/dashboard`);
  }

  /**
   * @returns {Promise<AxiosResponse<*>>}
   */
  const getNextQuestion = (validateStatus) => {
    if (assessmentDetails.questionKey === "session") {
      return API.getNextQuestionForSession({ validateStatus, uuid });
    } else if (assessmentDetails.questionKey === "device") {
      return API.getNextQuestionForDevice({ validateStatus, uuid });
    } else {
      return API.getNextQuestion({ validateStatus });
    }
  };

  const getQuestion = () => {
    const validateStatus = (status) => status < 500;
    getNextQuestion(validateStatus)
      .then(({ status, data }) => {
        // assessment.isLoadingQuestion = false;
        setIsLoadingQuestion(false);
        if (status === 423) {
          setItem("isPlanSelected", false);
          history.push(`/${match.params.locale}/plans`);
        }
        if (data.percentage_done === 100) {
          // hack with timeout, needs to be solved on server side first
          updateAssesmentFlagSetting(uuid);
          setItem("assessmentDone", true);

          const pendingDeviceAssessment = getItem("pendingDeviceAssessment");
          if (pendingDeviceAssessment) {
            try {
              let devices = JSON.parse(pendingDeviceAssessment);
              if (Object.entries(devices).length > 1) {
                for (let key in devices) {
                  if (devices[key].uuid === uuid) {
                    delete devices[key];
                  }
                }
                setItem("pendingDeviceAssessment", devices);
              } else {
                removeItem("pendingDeviceAssessment");
              }
            } catch (err) {
              removeItem("pendingDeviceAssessment");
            }
          }
          dispatch({
            type: actionTypes.ASSESSMENT_DONE,
          });
        }
        const timeout = localStorage.getItem(storageKey) !== "true" ? 6500 : 1;

        nextQuestionTimeout = setTimeout(() => {
          if (status === 200) {
            setMobileAppAdvert(data);

            dispatch({
              type: actionTypes.ASSESSMENT_GET_QUESTION,
              payload: data,
            });
          }
        }, timeout);

        if (localStorage.getItem(storageKey) !== "true") {
          dispatch({ type: actionTypes.ASSESSMENT_ALMOST_DONE });
        }
        if (status === 404) {
          handleError();
        }
      })
      .catch(() => {
        handleError();
      });
  };

  const dispatchAssessmentAction = (action) => {
    dispatch(action)
  }

  return { assessment, dispatch, loading: isLoadingQuestion, dispatchAssessmentAction };
};
