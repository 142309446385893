import React, { useState } from "react";
import API from "api/user";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import { InputField } from "../../../components/UI";
import { useSelector } from "react-redux";

const AddCouponForm = ({ t, couponCode, getPlans, loading }) => {
  const { hideDontHaveCodeButton } = useSelector(
    (state) => state.partnerSettings
  );
  const [coupon, setCoupon] = useState(
    couponCode ? couponCode.toUpperCase() : ""
  );
  const [error, setError] = useState({});

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      const name = coupon.toLowerCase();
      await API.applyCoupon({ name });
      toast(t("coupon_is_applied"), { type: toast.TYPE.INFO });
      getPlans(true);
    } catch (err) {
      if (err && err.errorStack) {
        const formattedErr = err.errorStack.toFormik();
        setError(formattedErr);
      } else {
        toast(t("coupon_not_applied"), { type: toast.TYPE.ERROR });
      }
    }
  };

  const handleSubmitWithoutCoupon = () => {
    getPlans(false);
  };

  const handleChange = (evt) => {
    const value = evt.target.value.toUpperCase();
    setCoupon(value);
    if (error) {
      setError({});
    }
  };

  return (
    <form className="-sizeWidthFull" onSubmit={handleSubmit}>
      <Box mb={3}>
        <InputField
          name="name"
          disabled={!!couponCode}
          placeholder={t("enter_code")}
          type="text"
          className="sa_signup_addcoupon_code"
          value={coupon}
          onChange={handleChange}
          error={error.name}
        />
      </Box>
      <Box mb={2}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading || !coupon}
          className="sa_signup_addcoupon_apply"
        >
          {loading && coupon ? (
            <Typography component="span" color="inherit">
              <CircularProgress color="inherit" size={12} />
            </Typography>
          ) : (
            t("apply_code")
          )}
        </Button>
      </Box>
      {!hideDontHaveCodeButton && (
        <>
          {!couponCode && (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              disabled={loading}
              className="sa_signup_addcoupon_dont_have_a_code"
              onClick={handleSubmitWithoutCoupon}
            >
              {loading && !coupon ? (
                <Typography component="span" color="inherit">
                  <CircularProgress color="inherit" size={12} />
                </Typography>
              ) : (
                t("don_t_have_a_code")
              )}
            </Button>
          )}
        </>
      )}
    </form>
  );
};

export default AddCouponForm;
