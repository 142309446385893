import { ValidationErrorStack } from '../validation/Error'

export class ValidationErrorResponse {
  config;
  /**
   * @var {XMLHTTPRequest}
   */
  request;
  /**
   * @var {object}
   */
  response;
  /**
   * @var {string}
   */
  message;
  /**
   * @type {ValidationErrorStack}
   */
  errorStack = new ValidationErrorStack();

  constructor(originalResponse) {
    this.config = originalResponse.config;
    this.request = originalResponse.request;
    this.response = originalResponse.response;
    this.message = originalResponse.message;
    this.errorStack.loadFromData(originalResponse.response.data);
  }
}