import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Page from "components/Page";
import CreditCards from "./CreditCards";
import Emails from "./Emails";
import PhoneNumbers from "./PhoneNumbers";

import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";

import { BodyText, CardTitle } from "../../components/UI";
import { useSelector } from "react-redux";

function a11yProps(index) {
  return {
    id: `protected-data-tab-${index}`,
    "aria-controls": `protected-data-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, name, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== name}
      id={`protected-data-tabpanel-${name}`}
      aria-labelledby={`protected-data-tab-${name}`}
      {...other}
    >
      {value === name && children}
    </div>
  );
}

const ManageData = ({}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { activeTab, locale } = useParams();
  const { monitoredDataTabs } = useSelector((state) => state.partnerSettings);
  const isEmailsTab =
    !monitoredDataTabs ||
    (monitoredDataTabs && monitoredDataTabs.indexOf("emails") !== -1);
  const isCreditCardsTab =
    !monitoredDataTabs ||
    (monitoredDataTabs && monitoredDataTabs.indexOf("credit_cards") !== -1);
  const isPhoneNumbersTab =
    !monitoredDataTabs ||
    (monitoredDataTabs && monitoredDataTabs.indexOf("phone_numbers") !== -1);

  const handleChange = (evt, newValue) => {
    history.push(`/${locale}/manage-data/${newValue}`);
  };

  return (
    <Page name="monitoredData" withAside withSubHeader>
      <Box mt={3}>
        <CardTitle>{t("monitored_data")}</CardTitle>
        <BodyText mb={3}>
          {t("retail.monitored_data_subtitle").indexOf("_") !== -1 ? '' :
          t("retail.monitored_data_subtitle")}
        </BodyText>

        <AppBar position="static" elevation={0} color="transparent">
          <Tabs
            value={activeTab}
            onChange={handleChange}
            aria-label="protected data tabs"
          >
            {isEmailsTab && (
              <Tab
                value="emails"
                label={t("emails")}
                wrapped
                {...a11yProps("emails")}
              />
            )}
            {isCreditCardsTab && (
              <Tab
                value="credit-cards"
                label={t("monitored_data.credit_cards")}
                wrapped
                {...a11yProps("credit_cards")}
              />
            )}
            {isPhoneNumbersTab && (
              <Tab
                value="phone-numbers"
                label={t("monitored_data.phone_numbers")}
                wrapped
                {...a11yProps("phone_numbers")}
              />
            )}
          </Tabs>
        </AppBar>
        {isEmailsTab && (
          <TabPanel value={activeTab} name="emails">
            <Emails />
          </TabPanel>
        )}
        {isCreditCardsTab && (
          <TabPanel value={activeTab} name="credit-cards">
            <CreditCards />
          </TabPanel>
        )}
        {isPhoneNumbersTab && (
          <TabPanel value={activeTab} name="phone-numbers">
            <PhoneNumbers />
          </TabPanel>
        )}
      </Box>
    </Page>
  );
};

export default ManageData;
