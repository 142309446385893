import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { ReactComponent as BackArrowIcon } from "assets/icons/arrow-back.svg";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  completeChapterLesson,
  completeTakeaway,
  completeUserCourseTakeaway,
} from "api/training";
import {
  getCourseItemById,
  getNextCourseItem,
  getPreviousCourseItem,
} from "store/selectors/course";
import { useCourse } from "hooks/useCourse";
import { makeStyles } from "@material-ui/core/styles";
import { getNavigationLabel } from "../../utils/getNavigationLabel";
import FooterButton from "./FooterButton/FooterButton";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
    height: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

const NavigationFooter = ({
  isCertificate,
  currentItemType,
  courseItemId,
  isCompleteBtnDisabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { locale, courseid, lessonid, takeawayid } = useParams();
  const history = useHistory();
  const [fetchCourseDetails] = useCourse(courseid);

  const currentCourseItem = useSelector((state) =>
    getCourseItemById(state, courseItemId)
  );
  const nextCourseItem = useSelector((state) =>
    getNextCourseItem(state, courseItemId)
  );
  const previousCourseItem = useSelector((state) =>
    getPreviousCourseItem(state, courseItemId)
  );

  const isOtherChapter =
    nextCourseItem &&
    nextCourseItem.chapter_uuid !== currentCourseItem.chapter_uuid;

  const completeLesson = async () => {
    try {
      await completeChapterLesson(lessonid);
      fetchCourseDetails();
    } catch (e) {
      toast.error(t("an_error_occured"));
    }
  };

  const completeChapterTakeaway = async () => {
    try {
      await completeTakeaway(takeawayid);
      fetchCourseDetails();
    } catch (e) {
      toast.error(t("an_error_occured"));
    }
  };

  const completeCourseTakeaway = async () => {
    try {
      await completeUserCourseTakeaway(takeawayid);
      fetchCourseDetails();
    } catch (e) {
      toast.error(t("an_error_occured"));
    }
  };

  const handleButtonClick = () => {
    if (currentItemType === "FINAL_QUIZ") {
      history.push(`/${locale}/training/course/${courseid}/certificate`);
    } else if (currentItemType === "MARKDOWN" || currentItemType === "VIDEO") {
      completeLesson();
    } else if (currentItemType === "CHAPTER_TAKEAWAY") {
      completeChapterTakeaway();
    } else if (currentItemType === "COURSE_TAKEAWAY") {
      completeCourseTakeaway();
    }
  };

  const handlePreviousClick = () => {
    history.push(`/${locale}/training/${previousCourseItem.view_path}`);
    fetchCourseDetails();
  };

  const handleNextClick = () => {
    history.push(`/${locale}/training/${nextCourseItem.view_path}`);
    fetchCourseDetails();
  };

  return (
    <Box className={classes.footer}>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<BackArrowIcon />}
        size="small"
        style={{
          visibility:
            previousCourseItem || isCertificate ? "visible" : "hidden",
        }}
        onClick={handlePreviousClick}
      >
        {previousCourseItem &&
          getNavigationLabel(
            t,
            "PREVIOUS",
            previousCourseItem,
            previousCourseItem.chapter_uuid !== currentCourseItem.chapter_uuid
          )}
      </Button>
      <Box display={"flex"}>
        {!currentCourseItem.is_completed &&
          currentItemType !== "CHAPTER_QUIZ" && (
            <Box mr={1}>
              <FooterButton
                t={t}
                option="CURRENT"
                item={currentCourseItem}
                isOtherChapter={false}
                itemToCompare={null}
                onClick={handleButtonClick}
                disabled={isCompleteBtnDisabled}
              />
            </Box>
          )}
        {currentItemType && currentItemType !== "FINAL_QUIZ" && (
          <FooterButton
            t={t}
            option="NEXT"
            item={nextCourseItem}
            isOtherChapter={isOtherChapter}
            itemToCompare={currentCourseItem}
            onClick={handleNextClick}
            disabled={!currentCourseItem.is_completed}
          />
        )}
      </Box>
    </Box>
  );
};

export default NavigationFooter;
