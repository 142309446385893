import React from 'react'
import { DAYS_OF_WEEK_KEYS, MOTHS_NAMES_KEYS } from 'constants/common'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  dateComponent: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.8rem',
    marginLeft: '10px',
  },
  capitalized: {
    textTransform: 'capitalize',
  }
}))

const DateRenderer = ({ t, timestamp, skipDay }) => {
  const date = timestamp ? new Date(timestamp) : new Date()
  const classes = useStyles()
  return (
    <div className={classes.dateComponent}>
      {
        <>
          {!skipDay && <span className={classes.capitalized}>{t(DAYS_OF_WEEK_KEYS[date.getDay()])}&nbsp;</span>}
          <span>{date.getDate()}&nbsp;</span>
          <span className={classes.capitalized}>{t(MOTHS_NAMES_KEYS[date.getMonth()])}&nbsp;</span>
          <span>{date.getFullYear()}&nbsp;</span>
        </>
      }
    </div>
  )
}

export default DateRenderer
