import {
  ApiClient,
  ViewQuestionsWithAnswersApi,
  ViewCourseApi,
  ViewUserQuizApi,
  UserCourseApi,
  CompleteUserLessonsApi,
  CreateUserQuizApi,
  CompleteUserChapterTakeawayApi,
  CompleteTakeawayApi,
  CompleteUserCourseApi,
  StartUserCourseApi,
} from "../openapi/training/src";

import { RefreshTokenService } from "services/authentication/RefreshTokenService";
import { getItem } from "../helpers/localStorage";

export const apiClient = new ApiClient(
  `${process.env.REACT_APP_API_GATEWAY}/courses`
);

export const setAuthHeader = () => {
  apiClient.defaultHeaders = {
    Authorization: `Bearer ${localStorage.getItem("JWT")}`,
  };
};
export const handleExpiredToken = async () => {
  if (RefreshTokenService.isRefreshing) {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!RefreshTokenService.isRefreshing) {
          clearInterval(interval);
          resolve(getItem("JWT"));
        }
      }, 100);
    });
  }
  return new Promise((resolve) => {
    RefreshTokenService.refresh().then((response) => {
      resolve(response);
    });
  });
};

export async function request(fn, args) {
  try {
    setAuthHeader();
    return await fn(args);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      await handleExpiredToken();
      setAuthHeader();
      return await fn(args);
    }
    //  catch errors in components
    throw err;
  }
}

const viewCourseApi = new ViewCourseApi(apiClient);
const viewQuestionsWithAnswersApi = new ViewQuestionsWithAnswersApi(apiClient);
const userCourseApi = new UserCourseApi(apiClient);
const completeUserLessonsApi = new CompleteUserLessonsApi(apiClient);
const completeUserChapterTakeawayApi = new CompleteUserChapterTakeawayApi(
  apiClient
);
const completeUserCourseTakeawayApi = new CompleteTakeawayApi(
  apiClient
);
const createUserQuizApi = new CreateUserQuizApi(apiClient);
const viewUserQuizApi = new ViewUserQuizApi(apiClient);
const startUserCourseApi = new StartUserCourseApi(apiClient);
const completeUserCourseApi = new CompleteUserCourseApi(apiClient);

export const courseApi = {
  getAllCourses: userCourseApi.userCourseIndex.bind(userCourseApi),
  completeUserCourse: completeUserCourseApi.completeUserCourse.bind(
    completeUserCourseApi
  ),
  startUserCourse: startUserCourseApi.startUserCourse.bind(startUserCourseApi),
  viewUserCourse: viewCourseApi.viewUserCourse.bind(viewCourseApi),
  viewUserQuiz: viewUserQuizApi.viewUserQuiz.bind(viewUserQuizApi),
  createUserQuiz: createUserQuizApi.createUserQuiz.bind(createUserQuizApi),
  completeUserChapterTakeaway:
    completeUserChapterTakeawayApi.completeUserChapterTakeaway.bind(
      completeUserChapterTakeawayApi
    ),
  completeUserCourseTakeaway:
    completeUserCourseTakeawayApi.completeUserCourseTakeaway.bind(
      completeUserCourseTakeawayApi
    ),
  completeUserLessons: completeUserLessonsApi.completeUserLessons.bind(
    completeUserLessonsApi
  ),
  viewQuestionsWithAnswers:
    viewQuestionsWithAnswersApi.viewQuestionsWithAnswers.bind(
      viewQuestionsWithAnswersApi
    ),
};
