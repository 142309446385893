import { useState, useEffect, useRef } from 'react';
import throttle from 'lodash.throttle';

const getDeviceConfig = (width) => {
  if (width < 500) {
    return 'sm';
  } else if (width > 499 && width < 770) {
    return 'md';
  } else if (width >= 770 && width < 1025) {
    return 'lg';
  } else if (width >= 1025 && width < 1200) {
    return 'xl';
  } else if (width >= 1200) {
    return 'xxl'
  }
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth));
  const mountedRef = useRef(true)

  const calcInnerWidth = throttle(function () {
    if (!mountedRef.current) return null
    const innerWidthValue = getDeviceConfig(window.innerWidth)
    setBrkPnt(innerWidthValue)
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', calcInnerWidth);
    return () => {
      mountedRef.current = false
      window.removeEventListener('resize', calcInnerWidth)
    };
  }, []);

  return brkPnt;
}
export default useBreakpoint;