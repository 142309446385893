import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Page from "components/Page";
import Logo from "components/Logo";
import Form from "pages/SME/ActivateUserStaff/Form";
import smeApi from "api/sme";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import { AuthContext } from "../../../contexts/AuthContext";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CardTitle } from "../../../components/UI";
import {PROFILE_TYPE_STAFF} from "constants/common";

/**
 * Active user staff for SME owner user
 * by link from email
 * @param t {TFunction}
 * @param history {History}
 * @param match
 * @param phonePrefixes {PhonePrefix[]}
 * @returns {React.Component}
 * @constructor
 */
const ActivateUserStaff = () => {
  const { hash, locale } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const phonePrefixes = useSelector((state) => state.phonePrefixes);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { authorizeUser } = useAuth();
  const { jwtToken } = useContext(AuthContext);

  const goToSignInPage = () => {
    history.push(`/${locale}/signIn`);
  };

  useEffect(() => {
    validateStaffUser();
  }, [hash]);

  useEffect(() => {
    if (jwtToken) {
      goToSignInPage();
    }
  }, [jwtToken]);

  const validateStaffUser = async () => {
    if (!hash) {
      return setIsError(true);
    }
    try {
      await smeApi.validateHashStuffUser({ hash });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
    }
  };
  /**
   * @param {ActiveteUserStuffData} value
   * @param {FormikActions} actions
   **/
  const onSubmit = (value) => {
    smeApi
      .activateStuffUser(value)
      .then((r) => {
        authorizeUser({
          email: r && r.data && r.data.profile_email,
          password: value && value.password,
          profile_type: PROFILE_TYPE_STAFF
        });
      })
      .catch((e) => {
        if (e.errorStack) {
          toast(t("an_error_occured"), { type: toast.TYPE.ERROR });
        }
      });
  };

  if (isLoading) {
    return (
      <Box
        height="100%"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box height='100%' width='100%' display='flex' justifyContent='center' alignItems='center'>
        <Box width='100%' maxWidth='576px'>
          <CardTitle variant='h2' textAlign="center">{t("link_has_now_expired_or_already")}</CardTitle>
          <Box display='flex' width='100%' height='100%' justifyContent='center' textAlign='center'>
            <Button
              variant='text'
              color='secondary'
              onClick={goToSignInPage}
              className="sa_invalid_token_back_to_login"
            >
              {t("back_to_login_page")}
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Page name="signIn">
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box mb={3}>
          <Logo url={`/${locale}`} />
        </Box>
        <Box textAlign="center" mb={2}>
          <CardTitle variant="h2" textAlign="center" mb={0}>
            {t("fill_form_for_account_activation")}
          </CardTitle>
        </Box>
        <Paper elevation={2} style={{ width: "100%", maxWidth: "576px", margin: "0 auto" }}>
          <Box p={3}>
            <Form
              hash={hash}
              phonePrefixes={phonePrefixes}
              onSubmit={onSubmit}
            />
          </Box>
        </Paper>
      </Box>
    </Page>
  );
};

export default ActivateUserStaff;
