import { withRouter } from 'react-router-dom'
import userApi from 'api/user'
import { withTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { AuthContext } from "../../../contexts/AuthContext";
import useFlagSettings from '../../../hooks/useFlagSettings';
import SRS from 'helpers/systemResourcesScanner';
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

const AcceptCrossServiceLink = ({ t, history, match }) => {
  const token = match.params.token;
  const { saveFetchedSettings } = useFlagSettings(false);
  const { User } = useContext(AuthContext);
  const meta = SRS.run({});
  const body = {
    ...meta
  }
  userApi.acceptCrossserviceLink(token, body)
    .then(({ data }) => {
      saveFetchedSettings(data.settings.profile);
      User.JWT = data.auth
      User.refreshToken = data.refresh_token
      User.isPlanSelected = data.is_plan_selected
      history.push(`/${match.params.locale}/dashboard`)
    });

  return (
    <Box display="flex" justifyContent='center' alignItems='center' width='100%' height='100%'>
      <CircularProgress />
    </Box>
  )
}

export default withTranslation()(withRouter(AcceptCrossServiceLink))
