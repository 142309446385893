import React, { useContext } from "react";
import { PlansContext } from "../PlansContext";
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

const SelectPlan = ({ plan, payableOption, trialOption }) => {
  const { selectPlan, t, isPlanSelected } = useContext(PlansContext);
  const planSlug = plan.slug;

  const enableTrialPlan = () => {
    const showTrialOption = isPlanSelected !== "true";
    if (
      showTrialOption &&
      trialOption &&
      trialOption.uuid 
      &&
      !payableOption.coupon
    ) {
      return (
        <Box ml={2}>
          <Button
            variant="text"
            className={`sa_plans_try_trial sa_plans_try_trial_${planSlug}`}
            onClick={selectPlan(trialOption, plan)}
          >
            {t("try_trial")}
          </Button>
        </Box>
      );
    }
  };

  return (
    <Box px={2} display='flex' justifyContent='center'>
      <Button
        variant='contained'
        color='primary'
        className={`sa_plans_select_plan sa_plans_select_plan_${planSlug}`}
        onClick={selectPlan(payableOption, plan)}
      >
        {t("upgrade_plane_standard.select_plan")}
      </Button>
      {enableTrialPlan()}
    </Box>
  );
};

export default SelectPlan;
