import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Page from "components/Page";
import { BodyText, CardTitle } from "../../components/UI";

import SupportForm from "./SupportForm";
import SupportSchedule from "./SupportSchedule";

const useSupportPageStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  leftSideCol: {
    flexBasis: "75%",
    flexShrink: 1,
    // paddingRight: "20px",
    [theme.breakpoints.down("md")]: {
      flexBasis: "100%",
      paddingRight: 0,
      marginBottom: "16px",
    },
  },
  rightSideCol: {
    flexBasis: "25%",
    // borderLeft: `1px solid ${theme.variables.$dividerLightGray}`,
    // paddingLeft: "20px",
    // flexGrow: 0,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      flexBasis: "100%",
      paddingLeft: 0,
      borderLeft: 0,
    },
  },
}));

const SupportPage = () => {
  const { t } = useTranslation();
  const classes = useSupportPageStyles();
  const { partnerSchedule, hideSupportFormUserName } = useSelector((state) => state.partnerSettings);

  return (
    <Page name="support" withAside withSubHeader>
      <Box mt={4}>
        <CardTitle variant="h1">{t("support")}</CardTitle>
        <BodyText>
          {t("retail.support_page_subtitle").indexOf("_") !== -1
            ? ""
            : t("retail.support_page_subtitle")}
        </BodyText>
      </Box>
      <Box display="flex" className={classes.container}>
        <div className={classes.leftSideCol}>
          <SupportForm hideSupportFormUserName={hideSupportFormUserName} />
        </div>
        {partnerSchedule && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              style={{ margin: "0 20px", borderColor: "#e8e9eb" }}
            />

            <div className={classes.rightSideCol}>
              <SupportSchedule />
            </div>
          </>
        )}
      </Box>
    </Page>
  );
};

export default SupportPage;
