import React, { useContext, useState } from "react";
import { getItem, removeItem, setItem } from "helpers/localStorage";
import { AuthContext } from "../../contexts/AuthContext";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ToastWrapper from "components/ToastWrapper";
import { toast } from "react-toastify";
import { RefreshTokenService } from "services/authentication/RefreshTokenService";

const MS_IN_SEC = 1000;
const SEC_IN_MIN = 60;
const DEFAULT_TIMER_INTERVAL = 15;

var logoutTimer = null;
var partnerSettings = null;
var refTokenPosterShown = false;

// event binder
const bindEventsToHandler = (element, events, handler) => {
  for (var i = 0; i < events.length; i++) {
    element.addEventListener(events[i], handler);
  }
};

// function for reseting interval
const restartTimerFunc = (t, history, match, User, isSessionEnd) => {
  //clear old interval
  if (logoutTimer !== null) {
    clearInterval(logoutTimer);
  }

  //getting partner data
  partnerSettings = JSON.parse(getItem("partner_settings"));

  //setting interval
  logoutTimer = setInterval(() => {
    if (process.env.REACT_APP_DEBUG === "true" && User.JWTStore) {
      let val =
        "T-" +
        (
          "" +
          (+new Date(User.JWTStore.exp * MS_IN_SEC) -
            60 * MS_IN_SEC -
            +new Date()) /
            MS_IN_SEC
        )
          .toString()
          .split(".")[0];
      // console.log(val);
      let el = document.getElementById("tminus");
      if (!el) {
        el = document.createElement("div");
        el.id = "tminus";
        el.style.position = "fixed";
        document.body.appendChild(el);
      }
      el.innerHTML = val;
    }
    // interval time (from partner settings or default)
    var interval = partnerSettings.autoLogoutInterval
      ? partnerSettings.autoLogoutInterval * MS_IN_SEC
      : DEFAULT_TIMER_INTERVAL * SEC_IN_MIN * MS_IN_SEC;

    // if user have no action sicne "interval" or JWT is expired
    if (
      User.JWTStore
        ? +new Date(User.JWTStore.exp * MS_IN_SEC) - 60 * MS_IN_SEC <
            +new Date() &&
          +new Date(User.JWTStore.exp * MS_IN_SEC) - 50 * MS_IN_SEC >
            +new Date()
        : false
    ) {
      let autoRefresh = getItem("isAutoRefresh") === "true";
      if (!refTokenPosterShown && !autoRefresh) {
        var toastID = ToastWrapper(
          <div
            className="text"
            style={{
              width: "100%",
              lineHeight: "25px",
            }}
          >
            {t("one_minute_left_to_auto_logout")}
            <button
              onClick={() => {
                RefreshTokenService.refresh();
                toast.dismiss(toastID);
                console.log(toastID);
              }}
              style={{
                border: "none",
                margin: "0 10px",
                display: "inline",
                background: "var(--text-alert)",
              }}
            >
              {t("refresh_session")}
            </button>
          </div>,

          "alert",
          null,
          60 * MS_IN_SEC,
          () => {
            refTokenPosterShown = false;
          },
          false,
          false
        );
        refTokenPosterShown = true;
      } else if (!refTokenPosterShown && autoRefresh) {
        setItem("action_timestamp", +new Date());
        RefreshTokenService.refresh();
      }
    }
    if (
      isSessionEnd ||
      +new Date(parseInt(getItem("action_timestamp"))) + interval <
        +new Date() ||
      (User.JWTStore
        ? +new Date(User.JWTStore.exp * MS_IN_SEC) + 60 * MS_IN_SEC <
          +new Date()
        : false)
    ) {
      // then logout, show popup, and redirect on popup close
      removeItem("isSessionEnd");
      clearInterval(logoutTimer);
      ToastWrapper(
        t("you_have_been_automaticly_logout"),
        "alert",
        null,
        true,
        () =>
          history.push({
            pathname: `/${match.params.locale}/logout`,
            state: { logoutState: 0 },
          })
      );
    }
  }, 1000);
};

const renderContent = (children) => {
  return <>{children}</>;
};

// main component
const PartnerSessionWrapper = ({ children, t, history, match }) => {
  const [isInstalled, install] = useState(false);

  // get partner data form localstorage
  const partnerSettings = JSON.parse(getItem("partner_settings"));
  if (!partnerSettings) {
    return renderContent(children);
  }

  // get user data
  const { User } = useContext(AuthContext);
  const isSessionEnd = getItem("isSessionEnd");
  // if partner has enabled auto logout and session wrapper not installed, and user is logged in
  if (partnerSettings.autoLogout || isSessionEnd) {
    if (!isInstalled && User.JWT) {
      // then set first action timestamp
      setItem("action_timestamp", +new Date());
      // start interval of checking activity
      restartTimerFunc(t, history, match, User, isSessionEnd);

      // bind event handler to whole page on mouse action "click"
      bindEventsToHandler(document.body, ["click"], () => {
        if (
          getItem("last_refresh") === undefined ||
          getItem("last_refresh") === null ||
          +new Date(parseInt(getItem("last_refresh"))) + 20000 < +new Date()
        ) {
          RefreshTokenService.refresh();
        }

        // if someone mess up with action timestamp, then make new on action
        if (
          getItem("action_timestamp") === undefined ||
          getItem("action_timestamp") === null
        ) {
          setItem("action_timestamp", +new Date());

          // action timestamp save debouncer... for spam click.
        } else if (
          +new Date(parseInt(getItem("action_timestamp"))) + 1000 <
          +new Date()
        ) {
          setItem("action_timestamp", +new Date());
        }
      });

      // mark as installed
      install(true);
    } else if (isInstalled && User.JWT) {
      restartTimerFunc(t, history, match, User, isSessionEnd);
    }
  }

  return renderContent(children);
};

export default withTranslation()(withRouter(PartnerSessionWrapper));
