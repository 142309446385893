const tooltipLabelKeyMap = {
  CURRENT_VIDEO: "retail.tooltip_complete_video_lesson",
  CURRENT_CHAPTER_QUIZ: "retail.tooltip_complete_chapter_quiz",
  CURRENT_FINAL_QUIZ: "retail.tooltip_complete_final_quiz",
  NEXT_VIDEO_lesson: "retail.tooltip_lesson_go_to_next",
  NEXT_VIDEO_lesson_other: "retail.tooltip_lesson_go_to_next_chapter",
  NEXT_MARKDOWN_lesson: "retail.tooltip_lesson_go_to_next",
  NEXT_MARKDOWN_lesson_other: "retail.tooltip_lesson_go_to_next_chapter",
  NEXT_CHAPTER_TAKEAWAY_lesson: "retail.tooltip_lesson_go_to_takeaway",
  NEXT_CHAPTER_TAKEAWAY_lesson_other: "retail.tooltip_lesson_go_to_takeaway",
  NEXT_COURSE_TAKEAWAY_lesson: "retail.tooltip_lesson_go_to_takeaway",
  NEXT_COURSE_TAKEAWAY_lesson_other: "retail.tooltip_lesson_go_to_takeaway",
  NEXT_CHAPTER_QUIZ_lesson: "retail.tooltip_lesson_go_to_quiz",
  NEXT_CHAPTER_QUIZ_lesson_other: "retail.tooltip_lesson_go_to_quiz",
  NEXT_FINAL_QUIZ_lesson: "retail.tooltip_lesson_go_to_final_quiz",
  NEXT_FINAL_QUIZ_lesson_other: "retail.tooltip_lesson_go_to_final_quiz",
  NEXT_CHAPTER_TAKEAWAY_takeaway: "retail.tooltip_takeaway_go_to_next",
  NEXT_CHAPTER_TAKEAWAY_takeaway_other:
    "retail.tooltip_takeaway_go_to_next_chapter",
  NEXT_COURSE_TAKEAWAY_takeaway: "retail.tooltip_takeaway_go_to_next",
  NEXT_COURSE_TAKEAWAY_takeaway_other:
    "retail.tooltip_takeaway_go_to_next_chapter",
  NEXT_CHAPTER_QUIZ_takeaway: "retail.tooltip_takeaway_go_to_quiz",
  NEXT_CHAPTER_QUIZ_takeaway_other: "retail.tooltip_takeaway_go_to_quiz",
  NEXT_FINAL_QUIZ_takeaway: "retail.tooltip_takeaway_go_to_final_quiz",
  NEXT_FINAL_QUIZ_takeaway_other: "retail.tooltip_takeaway_go_to_final_quiz",
  NEXT_CHAPTER_QUIZ_quiz: "retail.tooltip_quiz_go_to_next",
  NEXT_CHAPTER_QUIZ_quiz_other: "retail.tooltip_quiz_go_to_next_chapter",
  NEXT_FINAL_QUIZ_quiz: "retail.tooltip_quiz_go_to_final_quiz",
  NEXT_FINAL_QUIZ_quiz_other: "retail.tooltip_quiz_go_to_final_quiz",
  NEXT_VIDEO_takeaway_other: "retail.tooltip_takeaway_go_to_next_chapter",
  NEXT_MARKDOWN_takeaway: "retail.tooltip_takeaway_go_to_next",
  NEXT_MARKDOWN_takeaway_other: "retail.tooltip_takeaway_go_to_next_chapter",
  NEXT_VIDEO_quiz: "retail.tooltip_quiz_go_to_next",
  NEXT_VIDEO_quiz_other: "retail.tooltip_quiz_go_to_next_chapter",
  NEXT_MARKDOWN_quiz: "retail.tooltip_quiz_go_to_next",
  NEXT_MARKDOWN_quiz_other: "retail.tooltip_quiz_go_to_next_chapter",
};

const getCurrentItemString = (type) => {
  if (type === "VIDEO" || type === "MARKDOWN") return "lesson";
  if (type === "CHAPTER_TAKEAWAY" || type === "COURSE_TAKEAWAY")
    return "takeaway";
  if (type === "CHAPTER_QUIZ" || type === "FINAL_QUIZ") return "quiz";
};

export function getTooltipLabel(
  t,
  option,
  item,
  isOtherChapter = false,
  itemToCompare = null
) {
  const itemType = item.type;
  const currentItem = itemToCompare
    ? getCurrentItemString(itemToCompare.type)
    : "";

  const keySuffix = isOtherChapter ? "_other" : "";
  const key = `${option}_${itemType}${
    currentItem ? `_${currentItem}` : ""
  }${keySuffix}`;

  return t(tooltipLabelKeyMap[key] || "");
}
