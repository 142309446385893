/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The QuestionAnswerItem model module.
 * @module model/QuestionAnswerItem
 * @version 1.0.0
 */
class QuestionAnswerItem {
    /**
     * Constructs a new <code>QuestionAnswerItem</code>.
     * @alias module:model/QuestionAnswerItem
     * @param createdAt {Number} 
     * @param isCorrect {Boolean} 
     * @param position {Number} 
     * @param questionUuid {String} 
     * @param uuid {String} 
     * @param value {String} 
     */
    constructor(createdAt, isCorrect, position, questionUuid, uuid, value) { 
        
        QuestionAnswerItem.initialize(this, createdAt, isCorrect, position, questionUuid, uuid, value);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, isCorrect, position, questionUuid, uuid, value) { 
        obj['created_at'] = createdAt;
        obj['is_correct'] = isCorrect;
        obj['position'] = position;
        obj['question_uuid'] = questionUuid;
        obj['uuid'] = uuid;
        obj['value'] = value;
    }

    /**
     * Constructs a <code>QuestionAnswerItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/QuestionAnswerItem} obj Optional instance to populate.
     * @return {module:model/QuestionAnswerItem} The populated <code>QuestionAnswerItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new QuestionAnswerItem();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Number');
            }
            if (data.hasOwnProperty('is_correct')) {
                obj['is_correct'] = ApiClient.convertToType(data['is_correct'], 'Boolean');
            }
            if (data.hasOwnProperty('position')) {
                obj['position'] = ApiClient.convertToType(data['position'], 'Number');
            }
            if (data.hasOwnProperty('question_uuid')) {
                obj['question_uuid'] = ApiClient.convertToType(data['question_uuid'], 'String');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Number');
            }
            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
            if (data.hasOwnProperty('value')) {
                obj['value'] = ApiClient.convertToType(data['value'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>QuestionAnswerItem</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>QuestionAnswerItem</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of QuestionAnswerItem.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['question_uuid'] && !(typeof data['question_uuid'] === 'string' || data['question_uuid'] instanceof String)) {
            throw new Error("Expected the field `question_uuid` to be a primitive type in the JSON string but got " + data['question_uuid']);
        }
        // ensure the json data is a string
        if (data['uuid'] && !(typeof data['uuid'] === 'string' || data['uuid'] instanceof String)) {
            throw new Error("Expected the field `uuid` to be a primitive type in the JSON string but got " + data['uuid']);
        }
        // ensure the json data is a string
        if (data['value'] && !(typeof data['value'] === 'string' || data['value'] instanceof String)) {
            throw new Error("Expected the field `value` to be a primitive type in the JSON string but got " + data['value']);
        }

        return true;
    }


}

QuestionAnswerItem.RequiredProperties = ["created_at", "is_correct", "position", "question_uuid", "uuid", "value"];

/**
 * @member {Number} created_at
 */
QuestionAnswerItem.prototype['created_at'] = undefined;

/**
 * @member {Boolean} is_correct
 */
QuestionAnswerItem.prototype['is_correct'] = undefined;

/**
 * @member {Number} position
 */
QuestionAnswerItem.prototype['position'] = undefined;

/**
 * @member {String} question_uuid
 */
QuestionAnswerItem.prototype['question_uuid'] = undefined;

/**
 * @member {Number} updated_at
 */
QuestionAnswerItem.prototype['updated_at'] = undefined;

/**
 * @member {String} uuid
 */
QuestionAnswerItem.prototype['uuid'] = undefined;

/**
 * @member {String} value
 */
QuestionAnswerItem.prototype['value'] = undefined;






export default QuestionAnswerItem;

