import React from "react";
import { withTranslation } from "react-i18next";
import Filters from "components/filters";
import Tasks from "components/Tasks";

import Pagination from "components/Pagination";
import ContentPlaceholder from "components/ContentPlaceholder";
import Box from "@material-ui/core/Box";
import { BodyText, CardTitle } from "../../components/UI";

const TodoPage = ({ tasks, setFilters, filters, getItems, loading, t }) => {
  const meta = tasks.meta
    ? tasks.meta
    : { total_items: 0, page_current: 0, total_items_unsolved: 0 };

  const pageCount = meta.page_count;
  const pageCurrent =
    pageCount >= meta.page_current ? meta.page_current : meta.page_current - 1;

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={3}
      >
        <CardTitle mb={0}>{t("my_actions")}</CardTitle>
        <BodyText>
          {t("x_tasks", { number: meta.total_items_unsolved })}
        </BodyText>
      </Box>
      <Filters setFilters={setFilters} filters={filters} />
      {loading ? (
        <Box my={3}>
          <ContentPlaceholder type="actionsList" quantity={10} />
        </Box>
      ) : (
        <>
          <Tasks
            data={tasks}
            itemsToShow={tasks.items.length}
            showFilters
            loading={loading}
          />
          {!!tasks.items.length && (
            <Pagination
              t={t}
              pageCount={pageCount}
              pageCurrent={pageCurrent ? pageCurrent : 1}
              getItems={getItems}
              filters={filters}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default withTranslation()(TodoPage);
