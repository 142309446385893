import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { useIdentityFeature } from "hooks/useIdentiyFeature";
import { PROFILE_TYPE_SME } from "constants/common";
import API from "api/user";

import { InputField, SelectField, Card } from "../../components/UI";

import useForm from "../../hooks/useForm";

const stateSchema = {
  name: { value: "", error: "" },
  email: { value: "", error: "" },
  phone_number: { value: "", error: "" },
  country_code: { value: "", error: "" },
  subject: { value: "", error: "" },
  message: { value: "", error: "" },
};

export default function SupportForm({ hideSupportFormUserName }) {
  const { t } = useTranslation();

  const identity = useSelector((state) => state.identity);
  const phonePrefixes = useSelector((state) => state.phonePrefixes);

  const hasDeviceFeature = useIdentityFeature(identity, "max_devices");

  const [subjectPrefix, setSubjectPrefix] = useState("");

  const validateString = (str) => {
    const regexp = /^[\u{00C0}-\u{01FF}\u{0400}-\u{04FF}a-zA-Z\s'\-]+$/u;
    if (!regexp.test(str)) {
      return false;
    }
    return true;
  };

  const validateStringLength = (str, maxLength) => {
    if (str.length > maxLength) {
      return false;
    }
    return true;
  };

  const validateMinStringLength = (str, minLength) => {
    if (str.length < minLength) {
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const reqData = {
      country_code: state.country_code.value.value,
      email: state.email.value,
      name: state.name.value,
      phone_number: state.phone_number.value,
      subject: `${subjectPrefix} ${state.subject.value}`,
      message: state.message.value,
    };
    try {
      await API.contactSupport(reqData);
      toast(t("message_is_sent"), { type: toast.TYPE.INFO });
      setInitialState({
        data: {
          name: "",
          subject: "",
          message: "",
          email: "",
          phone_number: "",
          country_code: null,
        },
        errors: null,
      });
    } catch (err) {
      if (err.errorStack) {
        const errors = err.errorStack.toFormik();
        setInitialState({
          data: {
            name: state.name.value,
            subject: state.subject.value,
            message: state.message.value,
            email: state.email.value,
            phone_number: state.phone_number.value,
            country_code: state.country_code.value,
          },
          errors,
        });
      }
    }
  };

  const validationStateSchema = {
    name: hideSupportFormUserName
      ? { required: false }
      : {
          required: true,
          validator: [
            { func: validateString, error: t("first_name_invalid") },
            {
              func: (str) => validateStringLength(str, 100),
              error: t("too_long_max_x", { count: 100 }),
            },
          ],
        },
    subject: {
      required: true,
      validator: [
        {
          func: (str) => validateStringLength(str, 100),
          error: t("too_long_max_x", { count: 100 }),
        },
        {
          func: (str) => validateMinStringLength(str, 5),
          error: t("message_is_short", { count: 5 }),
        },
      ],
    },
    message: {
      required: true,
      validator: [
        {
          func: (str) => validateStringLength(str, 500),
          error: t("too_long_max_x", { count: 500 }),
        },
        {
          func: (str) => validateMinStringLength(str, 5),
          error: t("message_is_short", { count: 5 }),
        },
      ],
    },
    email: {
      required: true,
      validator: {
        func: isEmail,
        error: t("enter_valid_email"),
      },
    },
    country_code: {
      required: true,
    },
    phone_number: {
      required: true,
      validator: {
        func: isMobilePhone,
        error: t("enter_valid_phone_number"),
      },
    },
  };

  const [initialState, setInitialState] = useState({
    data: {
      name: "",
      subject: "",
      message: "",
      email: "",
      phone_number: "",
      country_code: null,
    },
    errors: null,
  });

  const {
    state,
    handleOnChange,
    handleOnBlur,
    handleOnSubmit,
    disable,
    isDirty,
    setIsDirty,
  } = useForm(
    initialState,
    stateSchema,
    null,
    validationStateSchema,
    handleSubmit
  );

  useEffect(() => {
    if (phonePrefixes && identity && identity.user) {
      const { email, phone_number, first_name, last_name, country_code } =
        identity.user;
      let phone_code = country_code || phonePrefixes[0];

      const state = {
        data: {
          ...initialState.data,
          country_code: phone_code,
          email: email || "",
          phone_number: phone_number || "",
          name: first_name && last_name ? `${first_name} ${last_name}` : "",
        },
      };
      setIsDirty({ country_code: true });
      setInitialState(state);
    }
  }, [phonePrefixes, identity]);

  const calculatePhonePrefixWidth = useMemo(() => {
    let basis = 65;
    if (state.country_code.value) {
      basis = basis + state.country_code.value.value.length * 14;
    }
    if (state.country_code.value) {
      basis = basis + state.country_code.value.label.length * 14;
    }
    return `${basis}px`;
  }, [state.country_code.value]);

  useEffect(() => {
    if (
      !hasDeviceFeature &&
      identity &&
      identity.plan &&
      identity.plan.name &&
      identity.plan.type &&
      identity.profile &&
      identity.profile.profile_type === PROFILE_TYPE_SME
    ) {
      const prefix = `${identity.plan.type} - ${identity.plan.name}:`;
      setSubjectPrefix(prefix);
    }
  }, [hasDeviceFeature, identity]);

  return (
    <Card>
      <form className="-sizeWidthFull" onSubmit={handleOnSubmit}>
        {!hideSupportFormUserName && (
          <Box mb={4}>
            <InputField
              name="name"
              className="sa_support_name"
              placeholder={t("name")}
              type="text"
              value={state.name.value}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              error={isDirty && isDirty.name && state.name.error}
            />
          </Box>
        )}
        <Box mb={4}>
          <InputField
            name="email"
            className="sa_support_email"
            placeholder={t("email_address")}
            type="text"
            value={state.email.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={isDirty && isDirty.email && state.email.error}
          />
        </Box>
        <Box mb={4} mx={-1} display="flex">
          <Box mx={1} flexShrink="0" flexBasis={calculatePhonePrefixWidth}>
            {phonePrefixes && (
              <SelectField
                name="country_code"
                options={phonePrefixes}
                value={state.country_code.value}
                className="sa_signup_country_code"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
              />
            )}
          </Box>
          <Box width="100%" mx={1}>
            <InputField
              name="phone_number"
              placeholder={t("phone_number")}
              type="text"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              value={state.phone_number.value}
              className="sa_signup_phone_number"
              error={
                isDirty &&
                ((isDirty.phone_number && state.phone_number.error) ||
                  (isDirty.country_code && state.country_code.error))
              }
            />
          </Box>
        </Box>
        <Box mb={4}>
          <InputField
            name="subject"
            className="sa_support_subject"
            placeholder={t("subject")}
            type="text"
            value={state.subject.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={isDirty && isDirty.subject && state.subject.error}
          />
        </Box>
        <Box mb={4}>
          <InputField
            rows={6}
            multiline={true}
            name="message"
            className="sa_support_message"
            placeholder={t("message")}
            type="text"
            value={state.message.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={isDirty && isDirty.message && state.message.error}
          />
        </Box>
        <Box>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            disabled={disable}
            className="-sizeX2 sa_support_send"
          >
            {t("send")}
          </Button>
        </Box>
      </form>
    </Card>
  );
}
