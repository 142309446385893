import { User } from "./AuthUser";
import SRS from "../../helpers/systemResourcesScanner";
import { setItem, getItem } from "../../helpers/localStorage";
import userApi from "api/user";

class RefreshTokenServiceClass {
  constructor() {
    this.isRefreshing = false;
  }

  unlockTokens() {
    this.isRefreshing = false;
  }

  /**
   * @returns {Promise<any>}
   */
  refresh() {
    return new Promise((resolve, reject) => {
      if (this.isRefreshing) {
        reject({});
      }
      this.isRefreshing = true;
      const meta = SRS.run({});
      meta.fingerprint = meta.fingerprint_v2;
      delete meta.fingerprint_v2;
      userApi
        .refreshTokenRaw({
          jwt: User.JWT,
          refresh_token: User.refreshToken,
          ...meta,
        })
        .then((response) => {
          setItem("last_refresh", +new Date());
          setItem("JWT", response.data.auth);
          User.JWT = response.data.auth;
          User.refreshToken = response.data.refresh_token;
          // temporary solutions
          window.dispatchEvent(new Event("refreshToken"));
          this.unlockTokens();
          resolve(response.data.auth);
        })
        .catch(() => {
          User.logout();
          window.location.reload();
        });
    });
  }
}

const RefreshTokenService = new RefreshTokenServiceClass();
export { RefreshTokenService };
