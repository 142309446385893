import React, { useState } from 'react'
import PasswordStrengthComponent from "components/PasswordStrength";
import passwordStrengthHelper from 'helpers/passwordStrength'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { InputField } from '../../../components/UI'

import useForm from '../../../hooks/useForm'

const stateSchema = {
  password: { value: '', error: '' },
  password_repeat: { value: '', error: '' },
};

const Form = ({ sendForm, t, loading }) => {

  const [initialState, setInitialState] = useState({
    data: null,
    errors: null,
  });

  const validationStateSchema = {
    password: {
      required: true,
    },
    password_repeat: {
      required: true,
    },
  };

  const handleSubmit = () => {
    sendForm({
      password: state.password.value,
      password_repeat: state.password_repeat.value
    })
    setInitialState({
      data: null,
      errors: null,
    })

  }

  const { state, handleOnChange, handleOnBlur, handleOnSubmit, isDirty, disable } = useForm(
    initialState,
    stateSchema,
    null,
    validationStateSchema,
    handleSubmit,
  );



  const passwordStrength = passwordStrengthHelper(state.password.value)

  return (
    <form className="-sizeWidthFull" onSubmit={handleOnSubmit}>
      <Box mb={4}>
        <InputField
          name='password'
          placeholder={t("password")}
          type='password'
          value={state.password.value}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          className='sa_signin_passwordreset_password'
          error={isDirty && isDirty.password && state.password.error}
        />
      </Box>
      <Box mb={4}>
        <InputField
          name='password_repeat'
          placeholder={t("confirm_password")}
          type='password'
          value={state.password_repeat.value}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          error={
            isDirty && (
              (isDirty.password_repeat && state.password_repeat.error)
              || (
                isDirty.password_repeat
                && isDirty.password
                && state.password_repeat.value !== state.password.value
                && t("passwords_doesn_t_match"))
            )
          }
        />
      </Box>
      <Box width='100%' overflow='hidden'>
        <PasswordStrengthComponent
          value={state.password.value}
          touched={false}
          t={t}
        />
      </Box>
      <Box width='100%'>
        <Button
          fullWidth
          type='submit'
          color='primary'
          variant='contained'
          disabled={disable || !passwordStrength.isValid || state.password_repeat.value !== state.password.value}
          className='sa_signin_passwordreset_reset_btn'>
          {loading
            ? <CircularProgress size='14px' color='inherit' />
            : t("reset_and_sign_in")
          }
        </Button>
      </Box>
    </form>
  )
}

export default Form

