import React from 'react';
import Modal from 'components/Modal'
import { remindTerms } from '../../../constants'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { CardTitle, RoundButton } from '../../UI'

const useStyles = makeStyles((theme) => ({
  remindModal: {
    position: 'relative',
    width: '220px',
    borderRadius: '7px',
  },
  remindTermBtn: {
    marginTop: '16px'
  },
  closeBtn: {
    position: 'absolute',
    top: 0,
    right: theme.variables.$isRTLDirection ? 'auto' : 0,
    left: theme.variables.$isRTLDirection ? "0" : 'auto',
  }
}))

const ItemRemindModal = ({ t, modalHandler, remindBtnClick }) => {
  const classes = useStyles()
  return (
    <Modal center>
      <div
        className={classes.remindModal}
      >
        <RoundButton
          className={classes.closeBtn}
          color='inherit'
          onClick={modalHandler}
        >
          <CloseIcon />
        </RoundButton>
        <CardTitle variant='h2' mb={0} textAlign='inherit'>{t("remind_me_later")}</CardTitle>
        <Box>
          {Object.keys(remindTerms).map(term => (
            <Button
              className={classes.remindTermBtn}
              size='small'
              fullWidth
              variant='contained'
              color='primary'
              key={remindTerms[term].value}
              onClick={remindBtnClick(remindTerms[term].value)}
            >
              {t(remindTerms[term].label)}
            </Button>
          ))}
        </Box>
      </div>
    </Modal>
  )
}

export default ItemRemindModal;