import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Routing from "routing";
import { Helmet } from "react-helmet";
import dynariskFavicon from "./assets/icons/favicon.ico";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

import "react-tippy/dist/tippy.css"; // @todo check which css imports are rly needed
import "react-toastify/dist/ReactToastify.min.css";
import "flag-icons/css/flag-icons.min.css";
import "./App.css";

import { ToastContainer } from "react-toastify";
import { SUPPORTED_LANGUAGES } from "constants/common";
import { usePartnerSettings } from "hooks/usePartnerSettings";
import { usePhonePrefixes } from "hooks/usePhonePrefixes";
import { ROUTES_NAMES } from "routing/routes";
import { getItem } from "./helpers/localStorage";

// try to find correct locale if user made typo
const findLocale = (str) =>
  str.split(/[-, _]+/).reduce((prev, curr, index) => {
    if (curr && index > 0) {
      return (prev += `_${curr.toUpperCase()}`);
    }
    return prev + curr;
  }, "");

// if url doesn't have locale param or this param is wrong
// try to find correct one or replace with default/provided 'lang' parameter
const validateLocale = ({ match }, lang) => {
  const supportedArr = SUPPORTED_LANGUAGES.split("|");
  const urlWithoutLocale = match.url.split(match.params.locale)[1];
  let path = findLocale(match.params.locale);
  if (supportedArr.indexOf(path) === -1) {
    path = "en_GB";
    if (urlWithoutLocale) {
      path += urlWithoutLocale;
    } else {
      const [locale, ...rest] = match.params.locale.split("/");
      path = lang || path;
      if (
        (locale && ROUTES_NAMES.indexOf(locale) !== -1) ||
        ROUTES_NAMES.indexOf(match.params.locale) !== -1
      ) {
        path += match.url;
      } else {
        const loc = findLocale(locale);
        if (supportedArr.indexOf(loc) !== -1) {
          path = loc;
        }
        path += `/${rest.join("/")}`;
      }
    }
  }

  return <Redirect to={`/${path}`} />;
};

const ZenDeskWidgetInit = () => {
  return (
    <Helmet>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=864ea060-c312-407c-83b1-154cf9ad4a85"
      >
        {" "}
      </script>
    </Helmet>
  );
};

const App = withTranslation()(({ t, partnerSettings, location }) => {
  const partnerName = partnerSettings.partnerName
    ? `${partnerSettings.partnerName} - `
    : "";

  // remove '-' if there is no website title
  const title = t("website_title", { partner: partnerName }).replace(
    /\ - $/,
    ""
  );
  const favicon = partnerSettings.faviconUrl || dynariskFavicon;
  const allowInitZendeskWidget =
    partnerSettings.partnerSlug === "cyhunt" &&
    location.pathname.indexOf("support") !== -1;
  const isRTL = partnerSettings.direction === "rtl";
  return (
    <>
      <CssBaseline />
      <div className="App">
        <Helmet
          title={title}
          link={[
            {
              rel: "icon",
              type: "image/png",
              href: favicon,
            },
          ]}
        ></Helmet>
        {allowInitZendeskWidget && <ZenDeskWidgetInit />}
        <ToastContainer
          rtl={isRTL}
          position={isRTL ? "top-left" : "top-right"}
        />
        <Routing />
      </div>
    </>
  );
});

const RootRouter = () => {
  const partnerSettings = useSelector((state) => state.partnerSettings);

  const { warning } = usePartnerSettings();
  const selectedLang = getItem("selectedLang");
  usePhonePrefixes();

  const redirectRoute =
    selectedLang ||
    (partnerSettings && partnerSettings.defaultLanguage) ||
    "en_GB";

  if (warning) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="#fafafa"
      >
        <Paper
          elevation={0}
          style={{
            textAlign: "center",
            margin: "32px 24px",
            padding: "32px",
            fontSize: "24px",
            background: "#fafafa",
          }}
        >
          {warning}
        </Paper>
      </Box>
    );
  }
  return (
    <>
      {partnerSettings && (
        <BrowserRouter>
          <Switch>
            <Route
              path="/"
              exact
              render={() => <Redirect to={`/${redirectRoute}`} />}
            />
            <Route
              sensitive
              path={`/:locale(${SUPPORTED_LANGUAGES})`}
              render={(props) => (
                <App
                  partnerSettings={partnerSettings}
                  location={props.location}
                />
              )}
            />
            <Route
              path="/:locale*"
              render={(props) =>
                validateLocale(props, partnerSettings.defaultLanguage)
              }
            />
          </Switch>
        </BrowserRouter>
      )}
    </>
  );
};

export default RootRouter;
