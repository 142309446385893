import {
  SET_CERTIFICATES_LIST,
  SET_COURSE,
  SET_COURSE_ITEMS,
} from "../constants";

const initialState = {
  course: {},
  courseItems: [],
  certificatesList: [],
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE:
      return {
        ...state,
        course: action.payload,
      };
    case SET_COURSE_ITEMS: {
      return {
        ...state,
        courseItems: action.payload,
      };
    }
    case SET_CERTIFICATES_LIST: {
      return {
        ...state,
        certificatesList: action.payload,
      };
    }
    default:
      return state;
  }
};

export default courseReducer;
