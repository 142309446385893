import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'

const BreachRawData = ({ input }) => {
  const { t } = useTranslation()

  const [isClicked, setIsClicked] = useState(false)

  return (
    <div className='stepsComponent'>
      <h3>{t('raw_data_exposed')}</h3>
      <Box className="breachRawDataContener">
        
        {isClicked && input}

        <div onClick={() => setIsClicked(true)} className={isClicked ? "shown" : "covered"}>
          <div>
            <h3>{t('action_data_breach_header')}</h3>
            <p>{t('action_data_breach_subheader')}</p>
          </div>
        </div>
      </Box>
    </div>
  )
}

export default BreachRawData;
