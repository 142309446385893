import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useCourse } from "hooks/useCourse";
import { useSelector } from "react-redux";
import { divideCourseItemsIntoSections } from "store/selectors/course";
import CourseProgressBar from "../CommonComponents/Progress";
import CourseNavigationBtn from "../CommonComponents/NavigationButton";
import SidebarListItem from "./SidebarListItem/SidebarListItem";

const useStyles = makeStyles((theme) => ({
  overviewWrapper: {
    padding: theme.spacing(3),
  },
  siedbarContentWrapper: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "4px",
    backgroundColor: theme.palette.grey[200],
  },
  sidebarChapter: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "4px",
    backgroundColor: theme.palette.grey[200],
    padding: 0,
  },
  typoBold: {
    fontWeight: 600,
  },
}));

const CourseSidebar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { locale, courseid, lessonid, takeawayid, quizid } = useParams();
  const [fetchCourseDetails] = useCourse(courseid);
  const isCourseOverview = location.pathname.includes("course-overview");
  const current = isCourseOverview
    ? null
    : getFirstDefined(lessonid, takeawayid, quizid);

  const [selectedChapter, setSelectedChapter] = useState(null);
  const course = useSelector((state) => state.course.course);
  const sections = useSelector((state) =>
    divideCourseItemsIntoSections(t, state)
  );
  const unlockedSections = sections.filter((section) => section.isUnlocked);
  const lockedSections = sections.filter((section) => !section.isUnlocked);

  useEffect(() => {
    if (selectedChapter) scrollToSelectedItem();
  }, [selectedChapter]);

  const scrollToSelectedItem = () => {
    const element = document.getElementById(selectedChapter);
    if (element) {
      var headerOffset = 70;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      setSelectedChapter(null);
    }
  };

  const handleItemClick = (item) => {
    if (isCourseOverview) {
      setSelectedChapter(item.uuid);
    } else {
      history.push(`/${locale}/training/${item.view_path}`);
      fetchCourseDetails();
    }
  };

  return (
    <Paper className={classes.overviewWrapper}>
      <Box mb={1}>
        <Typography variant="h6">{course.title}</Typography>
        <Box mb={4}>
          <CourseProgressBar course={course} />
        </Box>
      </Box>
      <Box>
        <List component="nav" className={classes.root}>
          {unlockedSections &&
            unlockedSections.map((section, index) => (
              <Box key={index}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle1"
                        className={classes.typoBold}
                      >
                        {section.section_title}
                      </Typography>
                    }
                    disableTypography
                  />
                </ListItem>
                <Collapse in={true} unmountOnExit>
                  <List component="div" disablePadding>
                    {section.items.map((item, index) => (
                      <SidebarListItem
                        key={index}
                        item={item}
                        isSelected={current && item.uuid === current}
                        handleItemClick={() => handleItemClick(item)}
                      />
                    ))}
                  </List>
                </Collapse>
              </Box>
            ))}
        </List>
      </Box>
      <Box mt={2}>
        {lockedSections && lockedSections.length > 0 && (
          <List component="nav" className={classes.root}>
            {lockedSections.map((section, index) => (
              <SidebarListItem
                key={index}
                item={section}
                type="lockedSection"
              />
            ))}
          </List>
        )}
      </Box>
      {isCourseOverview && (
        <Box mt={3}>
          <CourseNavigationBtn />
        </Box>
      )}
    </Paper>
  );
};
function getFirstDefined(...args) {
  return args.find((value) => value !== undefined);
}

export default CourseSidebar;
