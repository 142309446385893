import { makeStyles } from "@material-ui/core/styles";

export const useAssessmentStyles = makeStyles((theme) => ({
  assessmentTitleWrapper: {
    position: "relative",
    "& .tooltip .tippy-popper .arrow-regular": {
      top: "-6px",
    },
  },
  assessmentTooltipIcon: {
    position: "absolute",
    top: 0,
    right: "-20px",
    height: ".9rem",
    width: ".9rem",
    color: theme.variables.$textDarkGray,
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "400",
    marginTop: "20px",
    paddingTop: "20px",
    borderTop: `1px solid ${theme.variables.$dividerLightGray}`,
  },
  messageWrapper: {
    color: theme.variables.$textDarkGray,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: theme.spacing(2),
  },
  legend: {
    fontSize: "24px",
    fontWeight: 500,
    marginBottom: "16px",
  },
  questionTitleWrapper: {
    position: "relative",
    "& .tooltip .tippy-popper .arrow-regular": {
      top: "-6px",
    },
  },
  questionTooltipIcon: {
    marginBottom: "8px",
    marginLeft: "4px",
    right: "-20px",
    height: ".9rem",
    width: ".9rem",
    color: theme.variables.$textDarkGray,
  },
  questionWrapper: {
    position: "relative",
    right: theme.variables.$isRTLDirection ? "unset" : "-150%",
    left: theme.variables.$isRTLDirection ? "-150%" : "unset",
    animation: theme.variables.$isRTLDirection
      ? "$slideInRTL 1s cubic-bezier(0.6, 0, 0.4, 1)"
      : "$slideIn 1s cubic-bezier(0.6, 0, 0.4, 1)",
    animationFillMode: "forwards",
    "&.done": {
      animation: theme.variables.$isRTLDirection
        ? "$slideOutRTL 0.5s cubic-bezier(0.6, 0, 0.4, 1)"
        : "$slideOut 0.5s cubic-bezier(0.6, 0, 0.4, 1)",
    },
    "&.doneRight": {
      animation: theme.variables.$isRTLDirection
        ? "$slideOutRight 0.5s cubic-bezier(0.6, 0, 0.4, 1)"
        : "$slideOutRight 0.5s cubic-bezier(0.6, 0, 0.4, 1)",
    },
  },
  previousQuestion: {
    position: "relative",
    right: theme.variables.$isRTLDirection ? "unset" : "150%",
    left: theme.variables.$isRTLDirection ? "150%" : "unset",
    animation: theme.variables.$isRTLDirection
      ? "$slideInRTL 1s cubic-bezier(0.6, 0, 0.4, 1)"
      : "$slideIn 1s cubic-bezier(0.6, 0, 0.4, 1)",
    animationFillMode: "forwards",
    "&.done": {
      animation: theme.variables.$isRTLDirection
        ? "$slideOutRTL 0.5s cubic-bezier(0.6, 0, 0.4, 1)"
        : "$slideOut 0.5s cubic-bezier(0.6, 0, 0.4, 1)",
    },
    "&.doneRight": {
      animation: theme.variables.$isRTLDirection
        ? "$slideOutRightRTL 0.5s cubic-bezier(0.6, 0, 0.4, 1)"
        : "$slideOutRight 0.5s cubic-bezier(0.6, 0, 0.4, 1)",
    },
  },
  submitAssessment: {
    marginLeft: theme.variables.$isRTLDirection ? "initial" : "auto",
    marginRight: theme.variables.$isRTLDirection ? "auto" : "initial",
  },
  "@keyframes slideIn": {
    to: {
      right: 0,
    },
  },
  "@keyframes slideInRTL": {
    to: {
      left: 0,
    },
  },

  "@keyframes slideOut": {
    from: {
      right: 0,
    },
    to: {
      right: "150%",
    },
  },

  "@keyframes slideOutRTL": {
    from: {
      left: 0,
    },
    to: {
      left: "150%",
    },
  },

  "@keyframes slideOutRight": {
    from: {
      right: 0,
    },
    to: {
      right: "-150%",
    },
  },
  "@keyframes slideOutRightRTL": {
    from: {
      left: 0,
    },
    to: {
      left: "-150%",
    },
  },
}));
