import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { InputField } from "components/UI";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { useCourses } from "hooks/useCourses";
import TaskCard from "./TaskCard/TaskCard";

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: "flex",
  },
  searchInput: {
    backgroundColor: "white",
  },
  coursesWrapper: {
    marginTop: "16px",
  },
  noContentWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  loadingSpinner: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

const TaskList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [courses, isLoading] = useCourses({
    page: 1,
    perPage: 10,
  });

  return (
    <>
      {/* <Box className={classes.inputWrapper} mt={3}>
        <Box mr={1}>
          <InputField className={classes.searchInput} />
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SearchIcon />}
          size="small"
        >
          {t("retail.search_course")}
        </Button>
      </Box> */}
      {isLoading && (
        <Box className={classes.loadingSpinner}>
          <CircularProgress color="inherit" size={40} />
        </Box>
      )}
      {!isLoading && (
        <Grid className={classes.coursesWrapper} container spacing={2}>
          {courses && courses.length > 0 ? (
            courses.map((course) => (
              <Grid item xs={12} sm={6} lg={4} key={course.uuid}>
                <TaskCard courseData={course} />
              </Grid>
            ))
          ) : (
            <Box className={classes.noContentWrapper}>
              <Typography>{t("retail.no_courses")}</Typography>
            </Box>
          )}
        </Grid>
      )}
    </>
  );
};

export default TaskList;
