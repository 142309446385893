import React from "react";
import { Switch } from "react-router-dom";
import PublicRoute from "routing/PublicRoute";
import PrivateRoute from "routing/PrivateRoute";
import { routes } from "./routes";
import { useTheme } from "../hooks/useTheme";
import Theme from '../Theme'
import useAnalytics from '../hooks/useAnalytics'
import PartnerSessionWrapper from "services/authentication/PartnerSessionWrapper";
import { TUA_CATTOLICA_PARTNERS } from "constants/common";
import { store } from "store";

const Routing = () => {

  const { partnerSettings } = store.getState();
  //set default theme
  const { theme } = useTheme(partnerSettings);
  useAnalytics()

  return (
    <>
      {theme &&
        <Theme themeSettings={theme}>
          <PartnerSessionWrapper>
            <Switch>
              {Object.entries(routes).map((route) => {
                if (route[1].private) {
                  if (
                    (route[0] === "PROFILE" || route[0] === "MANAGE_DATA") &&
                    partnerSettings &&
                    TUA_CATTOLICA_PARTNERS.indexOf(partnerSettings.partnerSlug) !== -1
                  ) {
                    return null;
                  }
                  return (
                    <PrivateRoute
                      key={route[0]}
                      name={route[0]}
                      path={route[1].path}
                      routeName={route[1].name}
                      exact
                      component={route[1].component}
                    />
                  );
                }
                return (
                  <PublicRoute
                    key={route[0]}
                    name={route[0]}
                    exact
                    path={route[1].path}
                    partnerSettings={partnerSettings}
                    component={route[1].component}
                  />
                );
              })}
            </Switch>
          </PartnerSessionWrapper>
        </Theme>
      }
    </>
  );
};

export default Routing;
