import React from 'react'
import QRCode from 'qrcode.react'
import { withTranslation } from 'react-i18next'

import { ReactComponent as ConfirmIcon } from '../../assets/icons/confirm.svg'
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg'
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg'
import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg'
import { ReactComponent as QuestionIcon } from '../../assets/icons/question.svg'
import { ReactComponent as LightningIcon } from '../../assets/icons/lightning.svg'

const icons = {
	confirm: <ConfirmIcon />,
	info: <InfoIcon />,
	error: <ErrorIcon />,
	alert: <AlertIcon />,
	question: <QuestionIcon />,
	lightning: <LightningIcon />
}

const color = {
	confirm: {
		bg: "#e1f2eb",
		text: "#27AE60",
	},
	info: {
		bg: "#ddecf4",
		text: "#00a3da",
	},
	error: {
		bg: "#f5e9eb",
		text: "#eb5757",
	},
	alert: {
		bg: "#f6efe6",
		text: "#f5a341",
	},
	question: {
		bg: "#ddecf4",
		text: "#00a3da",
	},
	lightning: {
		bg: "#f5e9eb",
		text: "#eb5757",
	},
}

function ToastContent({ description, url, type, t }) {
	let style = document.documentElement.style

	style.setProperty(`--bg-${type}`, color[type].bg);
	style.setProperty(`--text-${type}`, color[type].text);

	return (
		<div style={{ alignItems: "center" }} className={`${document.body.offsetWidth < 750 ? 'toast-box-mobile' : 'toast-box'} error-box`}>
			<div style={{ width: '30px' }}>
				{icons[type]}
			</div>
			<div>
				{typeof description === "string" ?
					<div className="text">{description}</div>
					:
					description
				}

				{url &&
					<div className="Qrcode-box">
						<QRCode
							bgColor={color[type].bg}
							fgColor={color[type].text}
							renderAs="svg"
							value={url}
							size={64}
						/>
						<span className="text"> {t("qrcode_scan")}</span>
					</div>
				}
			</div>

		</div>
	)
}


export default withTranslation()(ToastContent);