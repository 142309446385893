import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import API from "api/user";
import DeviceAPI from "api/device";
import { getItem, removeItem, setItem } from "helpers/localStorage";
import selectPlanStatus from "constants/selectPlanStatus";
import axios from "axios";
import { RefreshTokenService } from "services/authentication/RefreshTokenService";
import { setExpiredPlan } from "store/actions/plans";
import { Log } from "services/log/logger";

let planUuuid = null;

const usePaypalLoader = () => {
  const [paypalSctipt, setPaypalScript] = useState(false);
  const [isPaypalLoaded, setLoadedStatus] = useState(false);
  const currency = window.localStorage.getItem("currency");
  const locale = window.localStorage.getItem("locale");
  const url = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAY_PAL_CLIENT_ID}&integration-date=2019-09-01&currency=${currency}&locale=${locale}`
  let timer = null

  const waitPaypalLoaded = () => {
    timer = setInterval(() => {
      if (window.hasOwnProperty('paypal')) {
        setLoadedStatus(true)
      }
    }, 250)
  }
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url
    document.body.appendChild(script);
    setPaypalScript(true)
  }, []);

  useEffect(() => {
    waitPaypalLoaded()
  }, [paypalSctipt])

  useEffect(() => {
    if (timer) {
      clearInterval(timer)
    }
  }, [isPaypalLoaded])

  return { isPaypalLoaded }
}

const PaypalButton = ({
  selectedPlan,
  setPlanStatusRequest,
  setExpiredPlan,
  expired,
  history,
  match,
  identity,
  partnerSettings,
}
) => {
  const { isPaypalLoaded } = usePaypalLoader();
  const profileUuid = identity && identity.profile_uuid;
  const partnerSlug = partnerSettings && partnerSettings.partnerSlug;

  const addDeviceAfterUpgrade = () => {
    const detectedDevice = getItem("detectedDevice");
    if (!detectedDevice) return;
    try {
      const device = JSON.parse(getItem("detectedDevice"));
      device.auto_activate = true;
      DeviceAPI.addDevice(device)
        .then(() => {
          removeItem("detectedDevice");
          history.push(`/${match.params.locale}/devices`);
        })
        .catch(() => { });
    } catch (err) { }
  };

  useEffect(() => {
    if ((isPaypalLoaded && window.paypal)) {
      const payload = { plan_option_uuid: selectedPlan.uuid };
      if (selectedPlan.coupon && selectedPlan.coupon.name) {
        payload["coupon"] = selectedPlan.coupon.name;
      }
      window.paypal
        .Buttons({
          // https://developer.paypal.com/docs/archive/checkout/how-to/customize-button/#multiple-button-layout
          style: {
            layout: "horizontal",
            fundingicons: "true",
            funding: {
              allowed: [window.paypal.FUNDING.CARD],
              disallowed: [window.paypal.FUNDING.CREDIT],
            },
            shape: "rect",
            color: "blue",
            label: "buynow",
            tagline: "false",
          },
          createOrder: (data, actions) => {
            setItem("isAutoRefresh", true);

            return API.createPaymentOrder(payload).then((res) => {
              planUuuid = res.data.uuid;
              return res.data.id;
            }).catch(err => {
              console.log(err)
            }
            );
          },
          onApprove: (data, actions) => {
            setPlanStatusRequest(selectPlanStatus.PROCESSING);
            setItem("paymentOrder", true);
            const checkState = () => {
              API.viewProfilePlan(planUuuid).then((res) => {
                if (res.data.status === "active") {
                  RefreshTokenService.refresh()
                    .then(() => {
                      setPlanStatusRequest(selectPlanStatus.SUCCESS);
                      // if 423 error user redirected to upgrade plan
                      // Change expired after upgrade
                      if (expired) {
                        setExpiredPlan(false);
                      }
                      // if user login with unknown device and redirected to upgrade plan.
                      // standard and trial plans
                      addDeviceAfterUpgrade();
                    })
                    .catch((err) => { });
                }
              });
            };
            // Capture the funds from the transaction
            return axios
              .post(
                `${process.env.REACT_APP_API_GATEWAY}/payment/api/v1/payments/pay-pal/${data.orderID}/capture`
              )
              .then(() => {
                setTimeout(checkState, 1000);
                setTimeout(checkState, 3000);
                setTimeout(checkState, 5000);
                setTimeout(checkState, 10000);
              });
          },
          onError: (err) => {
            removeItem("planFromCms");
            setPlanStatusRequest(selectPlanStatus.ERROR);
            Log.error("Paypal order error", {
              context: {
                plan: payload,
                profile_uuid: profileUuid,
                partner: partnerSlug,
              },
            });
          },
        })
        .render("#paypal-button-container");
    }
  }, [isPaypalLoaded]);

  return (
    <div className="paypalBtnsWrapper">
      <div
        id="paypal-button-container"
        style={{
          margin: '30px auto 0',
          maxWidth: '200px'
        }}>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  expired: state.plans.expired,
  identity: state.identity,
  partnerSettings: state.partnerSettings,
});

const mapDispatchToProps = (dispatch) => ({
  setExpiredPlan: (isExpired) => dispatch(setExpiredPlan(isExpired)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaypalButton)