import { getItem, setItem } from './localStorage'

export const fingerprintHelper = (hash) => {
  const localFingerprint = getItem('fingerprint')
  return localFingerprint || generateFingerprint(hash)
}

const generateFingerprint = (hash) => {
  setItem('fingerprint', hash)
  return hash
}