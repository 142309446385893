import { useEffect, useState } from "react";
import hslToRgb from "../helpers/hslToRgb";
import cssVars from "css-vars-ponyfill";

const DEFAULT_HUE_THEME = 195;
const DEFAULT_SATURATION_THEME = 100;
const DEFAULT_LIGHTNESS_THEME = 42;

/**
 * Set page theme
 */

const DefaultTheme = {
  PRIMARY_COLOR: "#001E2E",
  PRIMARY_TEXT_COLOR: "#fff",
  SECONDARY_COLOR: "#def1f7",
  SIDEBAR_BG: "#fff",
  MAIN_BG: "#f6f9fb",
  SIDEBAR_TEXT_COLOR: "#001E2E",
  INPUT_TEXT_COLOR: "#001E2E",
};

let root = document.documentElement;

export const useTheme = (partnerSettings) => {
  const { hue, color, themeSettings, partnerBtnTransform, direction } = partnerSettings;
  const [themeObj, setThemeObj] = useState(null);

  const getValue = (themeObj, propName, defaultValue) => {
    if (themeObj && themeObj[propName]) return themeObj[propName];

    if (Array.isArray(defaultValue)) {
      for (let i = 0; i < defaultValue.length; i++) {
        if (defaultValue[i]) {
          return defaultValue[i];
        }
      }
    }
    return defaultValue;
  };

  const transformCssCustomPropertiesToStatic = (variables) => {
    cssVars({
      variables,
      onlyLegacy: true,
      silent: true,
    });
  };

  const theme = themeSettings || null;

  useEffect(() => {
    // TODO remove hsl values
    const defaultHue = hue || DEFAULT_HUE_THEME;
    const _hue = getValue(theme, "hue", defaultHue);
    const _saturation = getValue(theme, "saturation", DEFAULT_SATURATION_THEME);
    const _lightness = getValue(theme, "lightness", DEFAULT_LIGHTNESS_THEME);
    const [r, g, b] = hslToRgb(_hue, _saturation, _lightness);

    const primaryColor = getValue(theme, "color", [
      color,
      DefaultTheme.PRIMARY_COLOR,
    ]);
    const secondaryColor = getValue(theme, "secondaryColor", [
      `rgb(${r},${g},${b})`,
      DefaultTheme.SECONDARY_COLOR,
    ]);
    const primaryTextColor = getValue(
      theme,
      "primaryTextColor",
      DefaultTheme.PRIMARY_TEXT_COLOR
    );
    const secondaryTextColor = getValue(theme, "secondaryTextColor", [
      primaryColor,
      DefaultTheme.PRIMARY_COLOR,
    ]);
    const sidebarBg =
      theme && theme.sidebar && theme.sidebar.backgroundColor
        ? theme.sidebar.backgroundColor
        : DefaultTheme.SIDEBAR_BG;
    const sidebarTextColor =
      theme && theme.sidebar && theme.sidebar.textColor
        ? theme.sidebar.textColor
        : DefaultTheme.SIDEBAR_TEXT_COLOR;

    const sidebarTextHoverColor =
      theme && theme.actionsSection && theme.actionsSection.textHoverColor
        ? theme.actionsSection.textHoverColor
        : primaryColor;

    const sidebarTextHoverWeight =
      theme && theme.actionsSection && theme.actionsSection.fontWeight
        ? theme.actionsSection.fontWeight
        : "normal";

    const actionsButtonTextColor =
      theme && theme.actionsSection && theme.actionsSection.buttonTextColor
        ? theme.actionsSection.buttonTextColor
        : theme.primaryTextColor;

    const mainBg = getValue(theme, "backgroundMain", DefaultTheme.MAIN_BG);
    const inputTextColor = getValue(
      theme,
      "inputTextColor",
      DefaultTheme.INPUT_TEXT_COLOR
    );

    let variables = {
      $green100: "#57a022",
      $green10: "rgba(114, 203, 87, 0.1)",
      $red100: "#ef4444",
      $red10: "rgba(239, 68, 68, 0.1)",
      // $blue: '#1a47b9', // staff color

      // black
      $black: "#111",
      $headerText: "#111",
      $overlay: "rgba(0, 0, 0, 0.6)",

      // white
      $white: "#fff",

      // dark gray
      $darkGray: "#7f8991",
      $textDarkGray: "#7f8991",
      $placeholderTextColor: "#7f8991",

      // light gray
      $lightGray: "#e8e9eb",
      $dividerLightGray: "#e8e9eb",
      $skeletonPlaceholder: "#e8e9eb",

      //theme
      $primary: primaryColor,
      $secondary: secondaryColor,
      $sidebarBgColor: sidebarBg,
      $sidebarTextColor: sidebarTextColor,
      $sidebarTextHoverColor: sidebarTextHoverColor,
      $sidebarTextHoverWeight: sidebarTextHoverWeight,
      $actionsOverdueTextColor: actionsOverdueTextColor,
      $actionsButtonTextColor: actionsButtonTextColor,
      $mainBg: mainBg,
      $secondaryTextColor: secondaryTextColor,
      $inputTextColor: inputTextColor,

      //borderRadius
      $borderRadius: "4px",
      $cardBorderRadius: "4px",
      $btnBorderRadius: "4px",
      $inputBorderRadius: "4px",
      $direction: direction === direction ? direction : "ltr",
      $isRTLDirection: direction === "rtl",
    };

    const actionsOverdueTextColor =
      theme && theme.actionsSection && theme.actionsSection.overdueColor
        ? theme.actionsSection.overdueColor
        : variables.$red100;

    variables = {
      ...variables,
      $actionsOverdueTextColor: actionsOverdueTextColor,
    };

    setThemeObj({
      primaryColor,
      secondaryColor,
      primaryTextColor,
      secondaryTextColor,
      inputTextColor,
      partnerBtnTransform,
      sidebar: {
        textColor: sidebarTextColor,
        backgroundColor: sidebarBg,
      },
      mainBackgroundColor: mainBg,
      variables,
    });

    // support for .svgIcon
    // remove once
    const customVariables = {
      "--primary": primaryColor,
      "--secondary": secondaryColor,
    };

    transformCssCustomPropertiesToStatic(customVariables);
    root.style.setProperty("--primary", primaryColor);
    root.style.setProperty("--secondary", secondaryColor);
  }, [themeSettings]);

  return { theme: themeObj };
};
