/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The RequestCourseSettingForm model module.
 * @module model/RequestCourseSettingForm
 * @version 1.0.0
 */
class RequestCourseSettingForm {
    /**
     * Constructs a new <code>RequestCourseSettingForm</code>.
     * @alias module:model/RequestCourseSettingForm
     * @param completionDate {Number} 
     * @param content {String} 
     * @param courseUuid {String} 
     * @param startDate {Number} 
     * @param subject {String} 
     */
    constructor(completionDate, content, courseUuid, startDate, subject) { 
        
        RequestCourseSettingForm.initialize(this, completionDate, content, courseUuid, startDate, subject);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, completionDate, content, courseUuid, startDate, subject) { 
        obj['completion_date'] = completionDate;
        obj['content'] = content;
        obj['course_uuid'] = courseUuid;
        obj['start_date'] = startDate;
        obj['subject'] = subject;
    }

    /**
     * Constructs a <code>RequestCourseSettingForm</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RequestCourseSettingForm} obj Optional instance to populate.
     * @return {module:model/RequestCourseSettingForm} The populated <code>RequestCourseSettingForm</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RequestCourseSettingForm();

            if (data.hasOwnProperty('completion_date')) {
                obj['completion_date'] = ApiClient.convertToType(data['completion_date'], 'Number');
            }
            if (data.hasOwnProperty('content')) {
                obj['content'] = ApiClient.convertToType(data['content'], 'String');
            }
            if (data.hasOwnProperty('course_uuid')) {
                obj['course_uuid'] = ApiClient.convertToType(data['course_uuid'], 'String');
            }
            if (data.hasOwnProperty('start_date')) {
                obj['start_date'] = ApiClient.convertToType(data['start_date'], 'Number');
            }
            if (data.hasOwnProperty('subject')) {
                obj['subject'] = ApiClient.convertToType(data['subject'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>RequestCourseSettingForm</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RequestCourseSettingForm</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of RequestCourseSettingForm.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['content'] && !(typeof data['content'] === 'string' || data['content'] instanceof String)) {
            throw new Error("Expected the field `content` to be a primitive type in the JSON string but got " + data['content']);
        }
        // ensure the json data is a string
        if (data['course_uuid'] && !(typeof data['course_uuid'] === 'string' || data['course_uuid'] instanceof String)) {
            throw new Error("Expected the field `course_uuid` to be a primitive type in the JSON string but got " + data['course_uuid']);
        }
        // ensure the json data is a string
        if (data['subject'] && !(typeof data['subject'] === 'string' || data['subject'] instanceof String)) {
            throw new Error("Expected the field `subject` to be a primitive type in the JSON string but got " + data['subject']);
        }

        return true;
    }


}

RequestCourseSettingForm.RequiredProperties = ["completion_date", "content", "course_uuid", "start_date", "subject"];

/**
 * @member {Number} completion_date
 */
RequestCourseSettingForm.prototype['completion_date'] = undefined;

/**
 * @member {String} content
 */
RequestCourseSettingForm.prototype['content'] = undefined;

/**
 * @member {String} course_uuid
 */
RequestCourseSettingForm.prototype['course_uuid'] = undefined;

/**
 * @member {Number} start_date
 */
RequestCourseSettingForm.prototype['start_date'] = undefined;

/**
 * @member {String} subject
 */
RequestCourseSettingForm.prototype['subject'] = undefined;






export default RequestCourseSettingForm;

